import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getSelectedCompanyId } from 'store/me'
import { wsLink } from 'utils/apollo'
import { getAuthToken } from 'utils/token'

import useCompanyTour from './useCompanyTour'
import useNotCompetedRequests from './useNotCompetedRequests'
import useReviewPending from './useReviewsPending'
import useUnreads from './useUnreads'

const useSubscriptions = () => {
  const selectedCompanyId = useSelector(getSelectedCompanyId)

  const token = getAuthToken()

  const route = document?.location?.pathname
  useEffect(() => {
    if (!selectedCompanyId || !token || route === '/acc/error') {
      if (wsLink?.client?.terminate) {
        wsLink.client.terminate()
        // @ts-ignore
      } else if (wsLink?.subscriptionClient?.close) {
        // @ts-ignore
        wsLink.subscriptionClient.close()
      }
    }
  }, [selectedCompanyId, token, route])

  useUnreads()
  useCompanyTour()
  useReviewPending()
  useNotCompetedRequests()
}

export default useSubscriptions
