import { TradeOfferValues, Validators } from './types'
import { isValidUrl } from 'utils/validators'

const required = (value: any) => (!value ? 'error.fieldRequired' : null)
//  Ошибка означающая, что пара [Product URL, Additional ID] существует
export const ERROR_WRONG_COUPLE_ADD_ID_PRODUCT_URL = 'wrongCoupleAdIdUrl'
//  Ошибка означающая, что Product URL существует и уникален
export const ERROR_PRODUCT_URL_USED_UNIQUE = 'usedUnique'

const wine = () => {
  return null
}

const productURL = (values: TradeOfferValues) => {
  if (!values.productURL) return 'error.fieldRequired'
  if (!isValidUrl(values.productURL)) return 'error.websiteExample'

  const isIDEqual = values?.id !== values?.tradeOfferIdByProductUrl
  const isUnqueUsed = values.isProductURLUnique && values.isProductURLUsed
  if ((values?.id && isIDEqual && isUnqueUsed) || (!values?.id && isUnqueUsed))
    return ERROR_PRODUCT_URL_USED_UNIQUE
  return null
}

const merchant = () => {
  return null
}

const additionalID = (values: TradeOfferValues) => {
  if (values.isProductURLUnique) return null
  if (values.wrongCoupleAdIdUrl) return ERROR_WRONG_COUPLE_ADD_ID_PRODUCT_URL
  return required(values.additionalID)
}

const lots = (values: TradeOfferValues) => {
  return values.lots.map(item => {
    return {
      lot: required(item.lot?.id),
      price: required(item.price),
    }
  })
}

const validators: Validators = {
  wine,
  productURL,
  merchant,
  additionalID,
  lots,
}

export default validators
