import { Task, TourTask } from 'store/me/types'
import {
  BUY_WINE_NEW_REQUEST_PATH,
  BUY_WINE_PATH,
  MY_WINES_PATH,
  SELL_WINE_MY_OFFERS_ALL_PATH,
} from 'utils/consts/path'

export const getTasksLeft = (tasks: TourTask[]): number => {
  return tasks.filter(item => !item.completed).length
}

export const getPercentCompleted = (tasks: TourTask[]): number => {
  const completed = tasks.filter(item => item.completed).length
  const total = tasks.length
  return Math.round((completed / total) * 100)
}

export const getTaskStepTotal = (item: TourTask) =>
  item.total === null ? '' : ` (${item.step ?? 0}/${item.total})`

export const titles: Record<Task, string> = {
  explore_full_wine_list: 'getting-started.tour.titles.explore-full-wine-list',
  add_wines_to_shortlist: 'getting-started.tour.titles.add-wines-to-shortlist',
  create_offers: 'getting-started.tour.titles.create-offers',
  complete_wine_description:
    'getting-started.tour.titles.complete-wine-description',
  add_e_labels: 'getting-started.tour.titles.add-e-labels',
  create_tech_sheets: 'getting-started.tour.titles.create-tech-sheets',

  explore_offers: 'getting-started.tour.titles.explore-offers',
  find_specific_wine: 'getting-started.tour.titles.find-specific-wine',
  create_wine_request: 'getting-started.tour.titles.create-wine-request',
  start_direct_communication:
    'getting-started.tour.titles.start-direct-communication',
  accept_offer_terms: 'getting-started.tour.titles.accept-offer-terms',
}

export const links: Record<Task, string> = {
  explore_full_wine_list: MY_WINES_PATH,
  add_wines_to_shortlist: MY_WINES_PATH,
  create_offers: SELL_WINE_MY_OFFERS_ALL_PATH,
  complete_wine_description: MY_WINES_PATH,
  add_e_labels: MY_WINES_PATH,
  create_tech_sheets: MY_WINES_PATH,

  explore_offers: BUY_WINE_PATH,
  find_specific_wine: BUY_WINE_PATH,
  create_wine_request: BUY_WINE_NEW_REQUEST_PATH,
  start_direct_communication: BUY_WINE_PATH,
  accept_offer_terms: BUY_WINE_PATH,
}

export const texts: Record<Task, string> = {
  explore_full_wine_list: 'getting-started.tour.texts.explore-full-wine-list',
  add_wines_to_shortlist: 'getting-started.tour.texts.add-wines-to-shortlist',
  create_offers: 'getting-started.tour.texts.create-offers',
  complete_wine_description:
    'getting-started.tour.texts.complete-wine-description',
  add_e_labels: 'getting-started.tour.texts.add-e-labels',
  create_tech_sheets: 'getting-started.tour.texts.create-tech-sheets',

  explore_offers: 'getting-started.tour.texts.explore-offers',
  find_specific_wine: 'getting-started.tour.texts.find-specific-wine',
  create_wine_request: 'getting-started.tour.texts.create-wine-request',
  start_direct_communication:
    'getting-started.tour.texts.start-direct-communication',
  accept_offer_terms: 'getting-started.tour.texts.accept-offer-terms',
}

export const buttons: Record<Task, string> = {
  explore_full_wine_list: 'getting-started.tour.go-to-my-wines',
  add_wines_to_shortlist: 'getting-started.tour.go-to-my-wines',
  create_offers: 'getting-started.tour.titles.go-to-offers',
  complete_wine_description: 'getting-started.tour.go-to-my-wines',
  add_e_labels: 'getting-started.tour.go-to-my-wines',
  create_tech_sheets: 'getting-started.tour.go-to-my-wines',

  explore_offers: 'getting-started.tour.go-to-buy-wine',
  find_specific_wine: 'getting-started.tour.go-to-buy-wine',
  create_wine_request: 'getting-started.tour.add-request',
  start_direct_communication: 'getting-started.tour.go-to-buy-wine',
  accept_offer_terms: 'getting-started.tour.go-to-buy-wine',
}
