import { FC } from 'react'
import styled from 'styled-components'
import { lineClamp } from 'components/Common/GlobalStyles'

const Info = styled.div<{ $align: string }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 0 0;
  align-items: ${props => props.$align};
`

const Name = styled.div`
  color: var(--grey-10, #e7e7ea);
  font: var(--typography-13-16-semibold);
  ${lineClamp()}
`

const Email = styled.div`
  color: var(--grey-50, #888998);
  font: var(--typography-12-16-medium);
  ${lineClamp()}
`

interface UserInfoProps {
  data: any
  align?: 'start' | 'center'
}

const UserInfo: FC<UserInfoProps> = ({ data, align = 'start' }) => {
  return (
    <Info $align={align}>
      <Name>{data?.username}</Name>
      <Email>{data?.email}</Email>
    </Info>
  )
}

export default UserInfo
