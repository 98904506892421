import { useMemo } from 'react'
import { ls } from 'utils/my-localstorage'
import { Roles } from 'utils/consts/userRoles'

const useUserRole = () => {
  const role = ls.getItem('wwl-role_code')

  return useMemo(
    () => ({
      isPerson: role === Roles.PERSON,
      isAdmin: role === Roles.ADMIN,
      isSuperAdmin: role === Roles.SUPERADMIN,
      isContent: role === Roles.CONTENT,
      isManager: role === Roles.MANAGER,
    }),
    [role]
  )
}

export default useUserRole
