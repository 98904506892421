import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PrimaryButtonFlexable32 } from 'components/Common/MasterComponents'
import { useTranslation } from 'react-i18next'
import { getLocaleForTermsPolitics } from 'utils/getLocaleId'
import Cookie from '../Image/cookie.png'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;

  @media (max-width: 880px) {
    display: none;
  }
`
const Text = styled.div`
  padding: 0 16px;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #717284;

  a {
    cursor: pointer;
    color: var(--color-velvet);
    text-decoration: none;
  }
`
const Button = styled(PrimaryButtonFlexable32)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  height: 32px;
  border-radius: 16px;
  font: var(--typography-12-15-semibold);
  margin-right: 8px;
`

interface IImage {
  link: string
}

const Image = styled.div<IImage>`
  height: 24px;
  min-width: 24px;
  background-image: url(${({ link }) => link});
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 8px;
  background-position: 50%;
`

const Desktop = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const closeCookiePolicy = () => {
    localStorage.setItem('wwl-cookie_policy', 'true')
    history.push('')
  }
  const locale = getLocaleForTermsPolitics()

  return (
    <Wrapper>
      <Image link={Cookie} />
      <Text>
        <div>
          {t('cookie.description')}{' '}
          <a
            href={`https://worldwinelist.com/${locale}/cookie-policy`}
            target='_blank'
            rel='noreferrer'
          >
            {t('cookie.policy')}
          </a>
          .
        </div>
      </Text>
      <Button onClick={closeCookiePolicy}>{t('common.ok')}</Button>
    </Wrapper>
  )
}

export default Desktop
