import { useEffect, useRef } from 'react'

const LoadMoreObserver = ({ onLoadMore }: { onLoadMore: () => void }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) return
    const observer = new IntersectionObserver(
      entries => {
        if (!ref.current || !entries?.[0]?.isIntersecting) return
        observer.unobserve(ref.current)
        onLoadMore()
        observer.observe(ref.current)
      },
      { rootMargin: '50px' }
    )
    observer.observe(ref.current)
    return () => {
      if (!ref.current) return
      observer.unobserve(ref.current)
    }
  }, [ref.current])

  return <div ref={ref} />
}

export default LoadMoreObserver
