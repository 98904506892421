import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getLocaleForTermsPolitics } from 'utils/getLocaleId'
import { breakpoint } from 'utils/consts/breakpoints'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and ${breakpoint.s} {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`
const Link = styled.a`
  text-decoration: none;
  font: var(--typography-14-20-regular);
  color: var(--color-sw-40);
`
const CompanyName = styled.div`
  font: var(--typography-14-20-regular);
  color: var(--color-sw-40);
`

const Footer = () => {
  const { t } = useTranslation()

  const locale = getLocaleForTermsPolitics()
  return (
    <Wrapper>
      <CompanyName>
        {t('login.companyName', { year: new Date().getFullYear() })}
      </CompanyName>
      <Link
        target='_blank'
        href={`https://worldwinelist.com/${locale}/terms-and-conditions`}
      >
        {t('login.termsConditions')}
      </Link>
    </Wrapper>
  )
}

export default Footer
