import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  font: var(--typography-14-16-semibold);
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-sw-60);
  margin-top: 16px;
`
const Title = styled.span`
  cursor: pointer;

  :hover {
    color: var(--color-night);
  }
`

const Forgot = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const redirect = () => {
    history.push('/acc/forgot-password')
  }

  return (
    <Wrapper>
      <Title onClick={redirect}>{t('login.forgotPwd')}</Title>
    </Wrapper>
  )
}

export default Forgot
