import styled from 'styled-components'
import Form from 'components/Pages/ConfirmPassword/Left/Main/Form'
import SideText from 'components/Pages/ForgotPassword/Common/SideText'
import { breakpoint } from 'utils/consts/breakpoints'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and ${breakpoint.m} {
    flex-direction: column;
    height: auto;
  }
`
const Block = styled.div`
  width: 384px;
  min-width: 384px;
  background-color: var(--color-white);
  border-radius: 16px;
  box-sizing: border-box;

  @media screen and ${breakpoint.m} {
    width: 100%;
    min-width: 100%;
  }

  @media screen and ${breakpoint.s} {
    width: 100%;
  }
`

const Main = () => {
  return (
    <Wrapper>
      <SideText />
      <Block>
        <Form />
      </Block>
    </Wrapper>
  )
}

export default Main
