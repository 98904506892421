import styled from 'styled-components'

export const Main = styled.div<{ width?: string | number; margin?: string }>`
  max-width: ${props =>
    typeof props.width === 'number'
      ? `${props.width}px`
      : props.width || '720px'};
  width: 100%;
  max-height: calc(100vh - 16px);
  margin: ${props => {
    return props?.margin ? props?.margin : '0'
  }};
  font-family: var(--typography-normal);

  &::after {
    content: '';
    display: block;
    min-height: 16px;
  }
`

export default Main
