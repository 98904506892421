import { FC } from 'react'
import styled from 'styled-components'
import { TourTask } from 'store/me/types'
import Item from './Item'
import Footer from './Footer'

const Wrapper = styled.div`
  display: flex;
  width: 304px;
  flex-direction: column;
  border-radius: 8px;
  background: #121432;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  left: calc(100% + 8px);
  bottom: 92px;
  z-index: var(--z-index-tooltip);
`

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 0;
`

interface TourProps {
  tasks: TourTask[]
  onClose: () => void
}

const Tour: FC<TourProps> = ({ tasks, onClose }) => {
  return (
    <Wrapper onClick={onClose}>
      <List>
        {tasks.map(item => (
          <Item key={item.task} item={item} />
        ))}
      </List>
      <Footer />
    </Wrapper>
  )
}

export default Tour
