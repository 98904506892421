import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
`
export const Container = styled.div`
  position: relative;
`
export const Notification = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Success = styled.div`
  margin-top: 16px;
  font: var(--typography-18-24-semibold);
  text-align: center;
`
export const Check = styled.div`
  margin-top: 8px;
  text-align: center;
  font: var(--typography-14-20-regular);
`
export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Error = styled.div`
  margin: 4px 0;
  min-height: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--color-red);
`
