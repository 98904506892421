import { setData, getUserData } from 'store/me'
import { useDispatch, useSelector } from 'react-redux'

export const useBecomeRepresentative = () => {
  const dispatch = useDispatch()
  const meData = useSelector(getUserData)

  const handleCreateCompany = () => {
    // show dialog BecomeRepresentative
    dispatch(setData({ ...meData, hideBecomeRepresentative: false }))
  }

  return { handleCreateCompany }
}
