import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  --transition-speed: 0.1s;
`

export const Content = styled.div`
  opacity: ${props => (props.$isLoading ? 0 : 1)};
  transition: opacity var(--transition-speed) ease-out;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.$isLoading ? 1 : 0)};
  transition: opacity var(--transition-speed) ease-in;

  div {
    margin: 0 auto;
  }

  svg {
    width: calc(var(--button-size, 32px) / 2);
    height: calc(var(--button-size, 32px) / 2);
  }
`
