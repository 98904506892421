import styled from 'styled-components'
import PrimaryButton from '../PrimaryButton'

export const PrimaryButtonFlexable32 = styled(PrimaryButton)`
  --button-size: 32px;

  min-height: var(--button-size);
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  padding: 8px 16px;
`
