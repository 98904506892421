import styled from 'styled-components'

const RADIUS = 18.5
const CIRCLE_LENGTH = 2 * Math.PI * RADIUS

const Svg = styled.svg`
  min-width: 40px;
  min-height: 40px;

  text {
    color: #e7e7ea;
    fill: #e7e7ea;
    font: var(--typography-10-16-normal);
    font-weight: 600;
  }
`

const Progress = ({ value }) => {
  const percent = value / 100
  // prettier-ignore
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <circle cx='20' cy='20' r={RADIUS} fill='none' strokeWidth='3' stroke='#41435B' />
      {percent > 0 && (
        <circle cx='20' cy='20' fill='none' strokeWidth='3' strokeLinecap='round'
                stroke={percent === 1 ? '#00A042' : '#6268B7'}
                r={RADIUS}
                transform='rotate(-90 20 20)'
                strokeDasharray={`${percent * CIRCLE_LENGTH} ${(1 - percent) * CIRCLE_LENGTH}`}
        />
      )}

      <text x='20' y='20' alignmentBaseline='central' textAnchor='middle'>
        {value}%
      </text>
    </Svg>
  )
}

export default Progress
