import { useTranslation } from 'react-i18next'
import Checkbox from 'components/Common/MasterComponents/Checkbox'
import FlagIcon from 'components/Common/FlagIcon'
import CompanyTypeBadge from 'components/Common/CompanyTypeBadge'
import {
  ICompany,
  ICountry,
} from 'components/Common/BecomeRepresentativeDialog/Modal/_methods'
import {
  Wrapper,
  Company,
  CompanyName,
  TextWrapper,
  CheckboxWrapper,
  CompanyData,
  Sub,
  IconWrapper,
} from './_styles'

export interface CompanyProps {
  selected: boolean | any
  onClick: () => void
}

interface IProps {
  onChange: (id: string) => void
  companies: ICompany[]
}

const CompaniesList = ({ companies, onChange }: IProps) => {
  const { t } = useTranslation()
  const handleChange = (id: string) => () => {
    onChange(id)
  }
  const getNumberLabel = (country: ICountry) => {
    let label
    if (!country?.id) {
      label =
        t('company.create.registrationNumber.label') +
        ' / ' +
        t('company.create.vat.label')
    } else if (country?.legalEntityDiscriminator === 'VAT') {
      label = country?.vatName ?? t('company.create.vat.label')
    } else {
      label =
        country?.identityNumberName ??
        t('company.create.registrationNumber.label')
    }
    return label
  }

  return (
    <Wrapper>
      {companies.map(item => (
        <Company
          key={item.id}
          selected={item.selected}
          onClick={handleChange(item.id)}
        >
          <TextWrapper>
            <CompanyName>
              {item.tradeName}
              <IconWrapper>
                <CompanyTypeBadge type={item.companyCategory.id} />
              </IconWrapper>
            </CompanyName>
            <Sub>
              <FlagIcon size={18} codeISO={item?.country?.codeISO} />
              <CompanyData>
                {item?.country.localizedName}, {getNumberLabel(item?.country)}{' '}
                {item.legalEntityDiscriminator}
              </CompanyData>
            </Sub>
          </TextWrapper>
          <CheckboxWrapper>
            <Checkbox
              onClick={handleChange(item.id)}
              onChange={() => {}}
              checked={!!item.selected}
              disabled={false}
            />
          </CheckboxWrapper>
        </Company>
      ))}
    </Wrapper>
  )
}

export default CompaniesList
