import Cookies from 'js-cookie'

export const isLogin = () => {
  return !!getAuthToken()
}

export const getAuthToken = (): string => {
  return Cookies.get('wwl-token') ?? ''
}

export const getRefreshToken = (): string => {
  return Cookies.get('wwl-token_r') || null
}

export const setTokens = (token: string, refreshToken: string) => {
  Cookies.set('wwl-token', token, { expires: 30 })
  Cookies.set('wwl-token_r', refreshToken, { expires: 90 })
}

export const removeTokens = () => {
  Cookies.remove('wwl-token')
  Cookies.remove('wwl-token_r')
}
