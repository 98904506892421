import styled from 'styled-components'
import EyeIcon from 'components/Common/Icons/Eye'
import EyeInvisibleIcon from 'components/Common/Icons/EyeInvisible'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/Common/MasterComponents'
import { Container, Block, Error } from '../../Common/_styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const IconWrapper = styled.div`
  min-width: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
  box-sizing: border-box;
`
const IconBlock = styled.div`
  padding: 0 16px 0 5px;
  margin-right: 1px;
  height: 38px;
  display: flex;
  align-items: center;
`
const Icon = styled.div`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Password = props => {
  const { t } = useTranslation()
  const { value, handleChange, type, toggle, handleRequest, error } = props

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleRequest()
    }
  }

  return (
    <Wrapper>
      <Container>
        <Block>
          <Input
            type={type}
            label={t('login.password')}
            id='password'
            maxLength={50}
            spellCheck='false'
            value={value}
            hasError={!!error}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
          />
        </Block>
        <IconWrapper>
          <IconBlock onClick={toggle}>
            <Icon>
              {type === 'password' ? (
                <EyeInvisibleIcon color='var(--color-sw-60)' />
              ) : (
                <EyeIcon color='var(--color-sw-60)' />
              )}
            </Icon>
          </IconBlock>
        </IconWrapper>
      </Container>
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default Password
