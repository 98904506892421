import styled from 'styled-components'
import {
  PrimaryButtonFlexable48,
  ButtonContent,
} from 'components/Common/MasterComponents'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`
const ButtonFlexable = styled(PrimaryButtonFlexable48)`
  width: 100%;
  cursor: ${props => (props.fakeDisabled ? 'auto' : 'pointer')};
  font: var(--typography-14-16-semibold);
  transition: background-color 0.2s;
`

const Button = props => {
  const { handleRequest, disabled, loading } = props

  const { t } = useTranslation()

  return (
    <Wrapper>
      <ButtonFlexable fakeDisabled={disabled} onClick={handleRequest}>
        <ButtonContent loading={loading}>{t('login.login')}</ButtonContent>
      </ButtonFlexable>
    </Wrapper>
  )
}

export default Button
