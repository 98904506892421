const initialItem = {
  id: -1,
  pricePerBottle: '',
  pricePerLot: '',
  incoterms: null,
  minimumDeliverySize: 0,
  periodOfValidity: null,
  lot: null,
  comments: null,
  currency: null,
  warehouse: null,
}

const initialState = {
  planned: {
    item: initialItem,
    history: [],
    items: [],
    selectedItems: [],
    pageInfo: {},
    allCheckboxChecked: false,
    sorting: {
      type: null,
      name: null,
      value: null,
    },
    filter: {
      type: null,
      value: null,
    },
  },
  active: {
    item: initialItem,
    history: [],
    items: [],
    selectedItems: [],
    pageInfo: {},
    allCheckboxChecked: false,
    sorting: {
      type: null,
      name: null,
      value: null,
    },
    filter: {
      type: null,
      value: null,
    },
  },
  accepted: {
    item: initialItem,
    items: [],
    selectedItems: [],
    pageInfo: {},
    history: [],
    allCheckboxChecked: false,
    sorting: {
      type: null,
      name: null,
      value: null,
    },
    filter: {
      type: null,
      value: null,
    },
  },
  archive: {
    items: [],
    selectedItems: [],
    pageInfo: {},
    history: [],
    allCheckboxChecked: false,
    sorting: {
      type: null,
      name: null,
      value: null,
    },
    filter: {
      type: null,
      value: null,
    },
  },
  newAnswers: {
    buyer: {
      total: 0,
      hasActive: false,
      hasAccepted: false,
      hasArchive: false,
    },
    seller: {
      total: 0,
      hasActive: false,
      hasAccepted: false,
      hasArchive: false,
    },
  },
}

export function negotiations(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    // -------- ACTIVE --------
    case 'NEGOTIATIONS_ACTIVE_SET_ITEMS':
      return { ...state, active: { ...state.active, items: payload } }
    case 'NEGOTIATIONS_ACTIVE_SET_ITEM':
      return { ...state, active: { ...state.active, item: payload } }
    case 'NEGOTIATIONS_ACTIVE_SET_ITEM_FIELD':
      return {
        ...state,
        active: {
          ...state.active,
          item: { ...state.active.item, [payload.field]: payload.data },
        },
      }
    case 'NEGOTIATIONS_ACTIVE_CLEAR_ITEM':
      return {
        ...state,
        active: {
          ...state.active,
          item: { ...initialState.active.item },
        },
      }
    case 'NEGOTIATIONS_ACTIVE_SET_SORTING_VALUE':
      return { ...state, active: { ...state.active, sorting: payload } }
    case 'NEGOTIATIONS_ACTIVE_SET_PAGE_INFO':
      return { ...state, active: { ...state.active, pageInfo: payload } }
    case 'NEGOTIATIONS_ACTIVE_SET_SELECTED_ITEMS':
      return { ...state, active: { ...state.active, selectedItems: payload } }
    case 'NEGOTIATIONS_ACTIVE_SET_ALL_CHECKBOX_CHECKED':
      return {
        ...state,
        active: { ...state.active, allCheckboxChecked: payload },
      }
    case 'NEGOTIATIONS_ACTIVE_RESET_SORTING':
      return {
        ...state,
        active: {
          ...state.active,
          sorting: initialState.active.sorting,
        },
      }
    case 'NEGOTIATIONS_ACTIVE_SET_HISTORY':
      return { ...state, active: { ...state.active, history: payload } }
    case 'NEGOTIATIONS_ACTIVE_CLEAR_HISTORY':
      return {
        ...state,
        active: { ...state.active, history: initialState.active.history },
      }
    case 'NEGOTIATIONS_ACTIVE_CLEAR_STATE':
      return { ...state, active: { ...initialState.active } }
    case 'NEGOTIATIONS_ACTIVE_SET_FILTER':
      return { ...state, active: { ...state.active, filter: payload } }

    // -------- PLANNED --------
    case 'NEGOTIATIONS_PLANNED_SET_ITEMS':
      return { ...state, planned: { ...state.planned, items: payload } }
    case 'NEGOTIATIONS_PLANNED_SET_ITEM':
      return { ...state, planned: { ...state.planned, item: payload } }
    case 'NEGOTIATIONS_PLANNED_SET_ITEM_FIELD':
      return {
        ...state,
        planned: {
          ...state.planned,
          item: { ...state.planned.item, [payload.field]: payload.data },
        },
      }
    case 'NEGOTIATIONS_PLANNED_CLEAR_ITEM':
      return {
        ...state,
        planned: {
          ...state.planned,
          item: { ...initialState.planned.item },
        },
      }
    case 'NEGOTIATIONS_PLANNED_SET_SORTING_VALUE':
      return { ...state, planned: { ...state.planned, sorting: payload } }
    case 'NEGOTIATIONS_PLANNED_SET_PAGE_INFO':
      return { ...state, planned: { ...state.planned, pageInfo: payload } }
    case 'NEGOTIATIONS_PLANNED_SET_SELECTED_ITEMS':
      return { ...state, planned: { ...state.planned, selectedItems: payload } }
    case 'NEGOTIATIONS_PLANNED_SET_ALL_CHECKBOX_CHECKED':
      return {
        ...state,
        planned: { ...state.planned, allCheckboxChecked: payload },
      }
    case 'NEGOTIATIONS_PLANNED_RESET_SORTING':
      return {
        ...state,
        planned: {
          ...state.planned,
          sorting: initialState.planned.sorting,
        },
      }
    case 'NEGOTIATIONS_PLANNED_SET_HISTORY':
      return { ...state, planned: { ...state.planned, history: payload } }
    case 'NEGOTIATIONS_PLANNED_CLEAR_HISTORY':
      return {
        ...state,
        planned: { ...state.planned, history: initialState.planned.history },
      }
    case 'NEGOTIATIONS_PLANNED_CLEAR_STATE':
      return { ...state, planned: { ...initialState.planned } }
    case 'NEGOTIATIONS_PLANNED_SET_FILTER':
      return { ...state, planned: { ...state.planned, filter: payload } }

    // -------- ACCEPTED --------
    case 'NEGOTIATIONS_ACCEPTED_SET_ITEMS':
      return { ...state, accepted: { ...state.accepted, items: payload } }
    case 'NEGOTIATIONS_ACCEPTED_SET_ITEM':
      return { ...state, accepted: { ...state.accepted, item: payload } }
    case 'NEGOTIATIONS_ACCEPTED_SET_ITEM_FIELD':
      return {
        ...state,
        accepted: {
          ...state.accepted,
          item: { ...state.accepted.item, [payload.field]: payload.data },
        },
      }
    case 'NEGOTIATIONS_ACCEPTED_CLEAR_ITEM':
      return {
        ...state,
        accepted: {
          ...state.accepted,
          item: { ...initialState.accepted.item },
        },
      }
    case 'NEGOTIATIONS_ACCEPTED_SET_SORTING_VALUE':
      return { ...state, accepted: { ...state.accepted, sorting: payload } }
    case 'NEGOTIATIONS_ACCEPTED_SET_PAGE_INFO':
      return { ...state, accepted: { ...state.accepted, pageInfo: payload } }
    case 'NEGOTIATIONS_ACCEPTED_SET_SELECTED_ITEMS':
      return {
        ...state,
        accepted: { ...state.accepted, selectedItems: payload },
      }
    case 'NEGOTIATIONS_ACCEPTED_SET_ALL_CHECKBOX_CHECKED':
      return {
        ...state,
        accepted: { ...state.accepted, allCheckboxChecked: payload },
      }
    case 'NEGOTIATIONS_ACCEPTED_RESET_SORTING':
      return {
        ...state,
        accepted: {
          ...state.accepted,
          sorting: initialState.accepted.sorting,
        },
      }
    case 'NEGOTIATIONS_ACCEPTED_SET_HISTORY':
      return { ...state, accepted: { ...state.accepted, history: payload } }
    case 'NEGOTIATIONS_ACCEPTED_CLEAR_HISTORY':
      return {
        ...state,
        accepted: { ...state.accepted, history: initialState.accepted.history },
      }
    case 'NEGOTIATIONS_ACCEPTED_CLEAR_STATE':
      return { ...state, accepted: { ...initialState.accepted } }
    case 'NEGOTIATIONS_ACCEPTED_SET_FILTER':
      return { ...state, accepted: { ...state.accepted, filter: payload } }

    // -------- ARCHIVE --------
    case 'NEGOTIATIONS_ARCHIVE_SET_ITEMS':
      return { ...state, archive: { ...state.archive, items: payload } }
    case 'NEGOTIATIONS_ARCHIVE_SET_SORTING_VALUE':
      return { ...state, archive: { ...state.archive, sorting: payload } }
    case 'NEGOTIATIONS_ARCHIVE_SET_PAGE_INFO':
      return { ...state, archive: { ...state.archive, pageInfo: payload } }
    case 'NEGOTIATIONS_ARCHIVE_SET_SELECTED_ITEMS':
      return {
        ...state,
        archive: { ...state.archive, selectedItems: payload },
      }
    case 'NEGOTIATIONS_ARCHIVE_SET_ALL_CHECKBOX_CHECKED':
      return {
        ...state,
        archive: { ...state.archive, allCheckboxChecked: payload },
      }
    case 'NEGOTIATIONS_ARCHIVE_RESET_SORTING':
      return {
        ...state,
        archive: {
          ...state.archive,
          sorting: initialState.archive.sorting,
        },
      }
    case 'NEGOTIATIONS_ARCHIVE_SET_HISTORY':
      return { ...state, archive: { ...state.archive, history: payload } }
    case 'NEGOTIATIONS_ARCHIVE_CLEAR_HISTORY':
      return {
        ...state,
        archive: { ...state.archive, history: initialState.archive.history },
      }
    case 'NEGOTIATIONS_ARCHIVE_CLEAR_STATE':
      return { ...state, archive: { ...initialState.archive } }
    case 'NEGOTIATIONS_ARCHIVE_SET_FILTER':
      return { ...state, archive: { ...state.archive, filter: payload } }

    case 'negotiations/setNewAnswers':
      return { ...state, newAnswers: payload }

    default:
      return state
  }
}
