import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
  border: 1px solid #e7e7ea;
  border-radius: 8px;
  margin-bottom: 8px;

  :last-child {
    margin-bottom: 0;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
`
export const Logo = styled.div`
  display: block;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  ${props =>
    props.url
      ? `background-image: url(${props.url})`
      : 'background: var(--color-grey)'};
  ${props => (props.url ? 'background-size: cover' : '')};
  background-position: center center;
  margin-right: 16px;
`
export const ParticipantInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ParticipantInfoTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 4px;
`
export const ParticipantInfoBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const Participant = styled.div`
  display: flex;
  width: calc(100% - 56px);
  flex: 1 0 auto;
`
export const ParticipantCompany = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - 140px);
`
export const ParticipantPerson = styled.div`
  display: block;
  font: var(--typography-12-16-medium);
  color: var(--color-sw-60);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props =>
    props.shrink ? 'max-width: calc(100% - 210px);' : 'width: calc(100% - 2px)'}
`
export const ParticipantCompanyName = styled.div`
  font: var(--typography-13-20-semibold);
  color: var(--color-night);
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const LogDate = styled.div`
  font: var(--typography-13-20-medium);
  text-align: right;
  color: var(--color-sw-60);
  width: 140px;
  /* flex: 1 0 auto; */
`
export const LogInfo = styled.div`
  font: var(--typography-12-16-medium);
  text-align: right;
  color: var(--color-orange);
  width: 210px;
  flex: 1 0 auto;
`
export const Main = styled.div``
export const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;

  :last-child {
    margin-bottom: 0;
  }
`
export const Label = styled.div`
  max-width: 134px;
  width: 100%;
  padding-right: 18px;
  font: var(--typography-13-20-medium);
  color: var(--color-sw-60);
`
export const PreviousState = styled.div`
  max-width: 150px;
  width: 100%;
  padding-right: 18px;
  display: flex;
  align-self: flex-start;
  font: var(--typography-13-20-medium);
  color: var(--color-night);
`
export const LastState = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex: 1;
  font: var(--typography-13-20-medium);
  color: var(--color-night);
`
export const LastStateValue = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
`
export const HelpTipWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`
export const TooltipContainer = styled.div`
  background-color: white;
  border: 1px solid #e7e7ea;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 5px rgba(95, 99, 104, 0.1),
    0 10px 10px rgba(95, 99, 104, 0.1), 0 1px 18px rgba(95, 99, 104, 0.1);
  border-radius: 8px;
  padding: 8px 12px;
  transition: opacity 0.3s;
  z-index: var(--z-index-tooltip);
  font: var(--typography-11-16-semibold);
  color: var(--color-night);
`
export const NoLogoWrapper = styled.div`
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  margin-right: 16px;

  & > * {
    width: 100%;
    height: 100%;
  }
`
