import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`
const Main = styled.div`
  position: absolute;
  top: 10px;
  right: 2px;
  background: var(--color-white);
  width: 30px;
  height: 30px;
  border-radius: 5px;
  transform: scaleX(0.8) rotate(45deg);

  @media (max-width: 1280px) {
    right: 3px;
  }
`

const Arrow = () => {
  return (
    <Wrapper>
      <Main />
    </Wrapper>
  )
}

export default Arrow
