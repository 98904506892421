import { lazy, Suspense } from 'react'
import LoadingState from './LoadingState'

const Content = lazy(() => import('./Content'))

const PaymentPage = () => {
  return (
    <Suspense fallback={<LoadingState />}>
      <Content />
    </Suspense>
  )
}

export default PaymentPage
