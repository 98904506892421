import { forwardRef } from 'react'
import { Block, Wrapper, Input, Label } from './_styles'

const InputControl = forwardRef((props, ref) => {
  const { hasError, label, after, ...inputProps } = props

  return (
    <Wrapper>
      <Block disabled={inputProps.disabled}>
        <Input
          spellCheck='false'
          autoComplete='off'
          {...inputProps}
          hasError={hasError}
          ref={ref}
        />

        <Label htmlFor={inputProps.id} hasError={hasError}>
          {label}
        </Label>

        {after}
      </Block>
    </Wrapper>
  )
})

export default InputControl
