import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const InputWrapper = styled.div`
  z-index: ${props => (props.opened ? 22 : 18)};
  position: relative;

  .arrow {
    color: ${props => (props.disabled ? '#D0D0D6' : '#717284')};

    :hover {
      color: ${props => (props.disabled ? '#D0D0D6' : 'var(--color-night)')};
    }
  }
`
