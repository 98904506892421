import styled, { css } from 'styled-components'

const sizes = {
  36: [36, 24],
  32: [32, 19],
  25: [25, 16],
  24: [24, 16],
  22: [22, 16],
  17: [17, 10],
  18: [18, 12],
  14: [14, 14],
  sm: [18, 12],
  default: [22, 16],
}

interface IconProps {
  $size?: 36 | 32 | 25 | 24 | 22 | 17 | 18 | 14 | 'sm'
}

interface FlagIconProps {
  codeISO: string | undefined
  size?: 36 | 32 | 25 | 24 | 22 | 17 | 18 | 14 | 'sm'
}

const Icon = styled.img<IconProps>`
  border-radius: 2px;
  background-color: #ebecf6;
  object-position: center;
  object-fit: cover;

  ${props => {
    const [w, h] =
      props.$size && sizes[props.$size] ? sizes[props.$size] : sizes.default
    return css`
      width: ${w}px;
      height: ${h}px;
      min-width: ${w}px;
      min-height: ${h}px;
    `
  }}
`

const NoImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='

const FlagIcon = (props: FlagIconProps) => {
  const { codeISO, size, ...rest } = props

  const src = codeISO
    ? `/acc/static/languages/${(codeISO ?? '').toLowerCase()}.svg`
    : NoImage

  const handleError = event => (event.target.src = NoImage)

  return <Icon {...rest} src={src} $size={size} onError={handleError} />
}

export default FlagIcon
