import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin: 8px 0;
`
const Line = styled.div`
  box-sizing: border-box;
  height: 1px;
  flex: 1 0 0;
  border-bottom: 1px solid var(--color-sw-10);
`
const Text = styled.div`
  color: var(--color-sw-60);
  text-align: center;
  font: var(--typography-11-16-regular);
  text-transform: uppercase;
`

const Divider = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Line />
      <Text>{t('login.divider')}</Text>
      <Line />
    </Wrapper>
  )
}

export default Divider
