import styled from 'styled-components'
import Dialog from 'components/Common/Modals/Dialog'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'
import { ErrorTextButton48 } from 'components/Common/MasterComponents'
import ClearLink from 'components/Common/MasterComponents/ClearLink'
import { useTranslation } from 'react-i18next'
import { LOGIN_PAGE_PATH } from 'utils/consts/path'

const Main = styled.div`
  max-width: 424px;
  width: 100%;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`
const CancelButton = styled(ErrorTextButton48)`
  color: var(--color-sw-60);
`
const LoginButton = styled(PrimaryButtonFlexable48)`
  width: 160px;
`
const Title = styled.div`
  font: var(--typography-18-28-bold);
  letter-spacing: 0.4px;
`

const LoginDialog = ({ onClose }) => {
  const { t } = useTranslation()
  return (
    <Dialog.Wrapper onClose={onClose}>
      <Main>
        <Dialog.Header title={t('common.attention')} onClose={onClose} />
        <Dialog.Content padding='32px'>
          <Title>{t('login.authorize')}</Title>
          <Footer>
            <CancelButton onClick={onClose}>{t('common.cancel')}</CancelButton>

            <ClearLink to={LOGIN_PAGE_PATH}>
              <LoginButton onClick={onClose}>{t('login.login')}</LoginButton>
            </ClearLink>
          </Footer>
        </Dialog.Content>
      </Main>
    </Dialog.Wrapper>
  )
}

export default LoginDialog
