import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { ChevronLeftIcon } from 'components/Common/Icons'
import Layout from '../Layout'
import { Sub, Title, TitleLink } from '../Layout/_styles'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'
import { isLogin } from 'utils/token'
import ClearLink from 'components/Common/MasterComponents/ClearLink'
import { LOGIN_PAGE_PATH } from 'utils/consts/path'

const Button = styled(PrimaryButtonFlexable48)`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  padding: 12px 24px;
`

const Error404: FC = () => {
  const { t } = useTranslation()
  const isLoggedIn = isLogin()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (isLoggedIn) return
    history.push(LOGIN_PAGE_PATH, { from: location })
  }, [isLoggedIn])

  return (
    <Layout>
      <Title>
        <Trans
          i18nKey='errorPage.404.title'
          t={t}
          components={[<TitleLink key='link' to='/acc' />]}
        />
      </Title>
      <Sub>
        <Trans
          i18nKey='errorPage.404.description'
          t={t}
          components={[<b key='bold' />]}
        />
      </Sub>
      <ClearLink to='/acc'>
        <Button>
          <ChevronLeftIcon size={25} color='white' />
          <div>{t('errorPage.404.home')}</div>
        </Button>
      </ClearLink>
    </Layout>
  )
}

export default Error404
