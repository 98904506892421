import styled from 'styled-components'
import { breakpoint } from 'utils/consts/breakpoints'

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid #e7e7ea;
  padding: 15px 24px 16px;

  @media screen and ${breakpoint.s} {
    padding: 16px;
  }
`

export default Footer
