import styled from 'styled-components'
import { breakpoint } from 'utils/consts/breakpoints'
import BG from 'assets/images/auth/login-background.jpg'

export const LeftWrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media screen and ${breakpoint.m} {
    max-width: 448px;
  }

  @media screen and ${breakpoint.s} {
    max-width: 328px;
  }
`
export const MainWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  background-color: #11132e;
  background-image: url(${BG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  min-width: 318px;
  display: flex;
  justify-content: center;
`
export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 32px;

  @media screen and ${breakpoint.m} {
    padding: 24px;
  }

  @media screen and ${breakpoint.s} {
    padding: 16px;
  }
`
