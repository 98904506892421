import { forwardRef, useRef, FocusEvent, ReactNode } from 'react'
import styled from 'styled-components'
import { Input } from 'components/Common/MasterComponents'
import Arrow from '../Arrow'
import { InputProps } from '../../Input'

const InputWrapper = styled.div<{
  opened: boolean
  disabled?: boolean
  readOnly?: boolean
  noCursor: boolean
}>`
  z-index: ${props =>
    props.opened ? 'var(--z-index-input-opened)' : 'var(--z-index-input)'};
  position: relative;
  color: var(--color-sw-60);

  :hover {
    color: ${props =>
      props.disabled ? 'var(--color-sw-20)' : 'var(--color-night)'};
  }

  & input {
    caret-color: ${props => (props.noCursor ? 'transparent' : undefined)};
  }
`

export interface InputControlProps
  extends Omit<InputProps, 'before' | 'beforeText' | 'after' | 'afterText'> {
  opened: boolean
  setOpen: (value: boolean) => void
  noCursor?: boolean
  noArrow?: boolean

  before?: ReactNode | Function
  beforeText?: ReactNode | Function
  after?: ReactNode | Function
  afterText?: ReactNode | Function
}

const InputControl = forwardRef<HTMLInputElement, InputControlProps>(
  (props, ref) => {
    const {
      setOpen,
      opened,
      noCursor = false,
      noArrow = false,
      ...inputProps
    } = props

    // @ts-ignore
    const inputRef = useRef<HTMLInputElement>(ref)

    const toggleOpened = () => {
      if (inputProps.disabled || inputProps.readOnly) return

      setOpen(!opened)

      if (!opened) {
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus()
          }
        }, 0)
      }
    }

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setOpen(true)

      if (inputProps.onFocus) {
        inputProps.onFocus(e)
      }
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setOpen(false)

      if (inputProps.onBlur) {
        inputProps.onBlur(e)
      }
    }

    return (
      <InputWrapper
        opened={opened}
        disabled={inputProps.disabled}
        readOnly={inputProps.readOnly}
        noCursor={noCursor}
      >
        <Input
          {...inputProps}
          inputMode={noCursor ? 'none' : inputProps.inputMode}
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={inputProps.onChange || (() => {})}
          before={
            typeof inputProps.before === 'function'
              ? inputProps.before({ opened })
              : inputProps.before
          }
          beforeText={
            typeof inputProps.beforeText === 'function'
              ? inputProps.beforeText({ opened })
              : inputProps.beforeText
          }
          afterText={
            typeof inputProps.afterText === 'function'
              ? inputProps.afterText({ opened, setOpen })
              : inputProps.afterText
          }
          after={
            <>
              {typeof inputProps.after === 'function'
                ? inputProps.after({ opened })
                : inputProps.after}
              {!noArrow && (
                <Arrow
                  toggleOpened={toggleOpened}
                  opened={opened}
                  disabled={inputProps.disabled}
                  readOnly={inputProps.readOnly}
                />
              )}
            </>
          }
        />
      </InputWrapper>
    )
  }
)

export default InputControl
