import { FC } from 'react'
import type { IconProps } from '../types'

// prettier-ignore
const EyeIcon: FC<IconProps> = ({ size = 16, color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.45005 8.10005C9.60724 8.10005 9.75816 8.07318 9.89846 8.02379C9.89952 8.04908 9.90005 8.0745 9.90005 8.10005C9.90005 9.09416 9.09416 9.90005 8.10005 9.90005C7.10594 9.90005 6.30005 9.09416 6.30005 8.10005C6.30005 7.10594 7.10594 6.30005 8.10005 6.30005C8.1256 6.30005 8.15102 6.30058 8.17631 6.30163C8.12691 6.44194 8.10005 6.59286 8.10005 6.75005C8.10005 7.49563 8.70446 8.10005 9.45005 8.10005Z'
      fill={color} />
    <path fillRule='evenodd' clipRule='evenodd'
          d='M15 8C11.4444 1.33333 4.55556 1.33333 1 8C4.55556 14.6667 11.4444 14.6667 15 8ZM10.9 8.10005C10.9 9.64645 9.64645 10.9 8.10005 10.9C6.55365 10.9 5.30005 9.64645 5.30005 8.10005C5.30005 6.55365 6.55365 5.30005 8.10005 5.30005C9.64645 5.30005 10.9 6.55365 10.9 8.10005Z'
          fill={color} />
  </svg>
)

export default EyeIcon
