import { FC, HTMLAttributes, useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EyeIcon, EyeInvisibleIcon } from 'components/Common/Icons'
import {
  Wrapper,
  Container,
  Block,
  IconWrapper,
  IconBlock,
  Icon,
  Error,
} from 'components/Pages/ConfirmPassword/Left/Main/Form/Main/PasswordInput/_styles'
import { Input } from 'components/Common/MasterComponents'

interface PasswordInputProps extends HTMLAttributes<HTMLInputElement> {
  label: string
  error: string
  autoComplete?: string
}

const PasswordInput: FC<PasswordInputProps> = ({ label, error, ...props }) => {
  const { t } = useTranslation()

  const id = useId()

  const [type, setType] = useState('password')
  const toggle = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  return (
    <Wrapper>
      <Container>
        <Block>
          <Input
            label={label}
            type={type}
            id={id}
            maxLength={50}
            spellCheck='false'
            hasError={!!error}
            {...props}
          />
        </Block>
        <IconWrapper>
          <IconBlock onClick={toggle}>
            <Icon>
              {type === 'password' ? (
                <EyeInvisibleIcon size={16} color={'var(--color-sw-60)'} />
              ) : (
                <EyeIcon size={16} color={'var(--color-sw-60)'} />
              )}
            </Icon>
          </IconBlock>
        </IconWrapper>
      </Container>
      {error && <Error>{t(error)}</Error>}
    </Wrapper>
  )
}

export default PasswordInput
