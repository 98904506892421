import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { emailMask } from 'utils/masks'
import EmailIcon from 'components/Pages/ForgotPassword/Icons/Email'
import Notify from 'components/Common/Notify'
import { getLocaleId } from 'utils/getLocaleId'
import Email from './Email'
import Login from './Login'
import Button from './Button'
import {
  Wrapper,
  Container,
  Notification,
  Success,
  Icon,
  Error,
  Check,
} from './_styles'
import { useResetPassword } from './_methods'

const Main = () => {
  const { t } = useTranslation()
  const localeId = getLocaleId()
  const params = new URLSearchParams(window.location.search)
  const peramsEmail = params.get('email') ?? ''

  const { resetPassword, isSuccess, loading, error } = useResetPassword()
  const [email, setEmail] = useState(peramsEmail ?? '')
  const [fieldError, setFieldError] = useState('')
  const [formError, setFormError] = useState('')

  useEffect(() => {
    if (error) {
      setFormError('forgotPwd.error.email') // error.message
    }
  }, [error, localeId])

  const isEmailValid = email && emailMask.test(email)

  const handleEmailChange = e => {
    const { value } = e.target
    setEmail(value)
    setFieldError('')
    setFormError('')
  }

  const handleEmailBlur = () => {
    if (!email) return setFieldError('error.required')
    setFieldError(isEmailValid ? '' : 'forgotPwd.error.email')
  }

  const handleRequest = async () => {
    if (isEmailValid && !isSuccess) {
      resetPassword(email)
    }
  }
  const handleFormSubmit = e => {
    e.preventDefault()
    handleRequest()
  }

  return (
    <Wrapper onSubmit={handleFormSubmit}>
      <Container>
        {isSuccess && (
          <Notification>
            <Icon>
              <EmailIcon />
            </Icon>
            <Success>{t('forgotPwd.message')}</Success>
            <Check>{t('forgotPwd.checkEmail')}</Check>
          </Notification>
        )}
        {!isSuccess && (
          <Email
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            value={email}
            error={fieldError ? t(fieldError) : ''}
          />
        )}
      </Container>
      {!isSuccess && (
        <>
          <Button
            handleRequest={handleRequest}
            disabled={!isEmailValid}
            loading={loading}
            error={error}
            isSuccess={isSuccess}
          />
          <Login />
        </>
      )}

      {formError && (
        <Error>
          <Notify text={t(formError)} color='#d00a00' />
        </Error>
      )}
    </Wrapper>
  )
}

export default Main
