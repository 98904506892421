import { formatNumber } from 'utils/format'
import { FC } from 'react'

interface IProps {
  value: number | undefined | null
  currencyCode?: string
  currencySide?: 'left' | 'right'
  defaultValue?: string
}

const Price: FC<IProps> = ({
  value,
  currencyCode,
  currencySide = 'right',
  defaultValue = '-',
}) => {
  if (!value && value !== 0 && (value as unknown) !== '0') return defaultValue

  return (
    <>
      {currencyCode && currencySide === 'left' && `${currencyCode} `}
      {formatNumber(+(value as number | string))}
      {currencyCode && currencySide === 'right' && ` ${currencyCode}`}
    </>
  )
}

export default Price
