import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { lineClamp } from '../../GlobalStyles'

type BadgeEmphasis = 'subtle' | 'bold' | 'minimal'
type BadgeSize = 16 | 20 | 24
export type BadgeStatus =
  | 'neutral'
  | 'negative'
  | 'positive'
  | 'attention'
  | 'info'
  | 'brand'

export interface BadgeProps {
  $status?: BadgeStatus
  $emphasis?: BadgeEmphasis
  $size?: BadgeSize
  children: ReactNode
}

// @ts-ignore
const paddings: { [size in BadgeSize]: string } = {
  16: '0px 4px',
  20: '2px 6px',
  24: '4px 8px',
}

// @ts-ignore
const fonts: { [size in BadgeSize]: string } = {
  16: '11px',
  20: '12px',
  24: '13px',
}

const backgrounds: { [status in BadgeStatus]: string } = {
  neutral: 'rgba(113, 114, 132, 0.08)',
  negative: 'rgba(208, 10, 0, 0.08)',
  positive: 'rgba(0, 160, 66, 0.08)',
  attention: 'rgba(204, 116, 0, 0.08)',
  info: 'rgba(59, 66, 165, 0.08)',
  brand: 'rgba(136, 18, 43, 0.08)',
}

const colors: { [status in BadgeStatus]: string } = {
  neutral: 'var(--grey-2-sw-60, #717284)',
  negative: 'var(--other-red, #D00A00);',
  positive: 'var(--other-green, #00A042)',
  attention: 'var(--other-orange, #CC7400)',
  info: 'var(--velvet-100, #3B42A5)',
  brand: 'var(--brand-red, #88122B)',
}

export const Badge = styled.div<BadgeProps>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;
  line-height: 16px;

  ${lineClamp()};

  ${props => {
    const emphasis = props.$emphasis ?? 'subtle'
    const status = props.$status ?? 'neutral'
    const size = props.$size ?? 16

    if (emphasis === 'minimal')
      return css`
        color: ${colors[status]};
        background: #fff;
        padding: ${paddings[size]};
        font-size: ${fonts[size]};
        box-shadow: 0 0 0 1px ${backgrounds[status]};
      `

    if (emphasis === 'subtle')
      return css`
        color: ${colors[status]};
        background: ${backgrounds[status]};
        padding: ${paddings[size]};
        font-size: ${fonts[size]};
      `

    if (emphasis === 'bold')
      return css`
        color: #fff;
        background: ${colors[status]};
        padding: ${paddings[size]};
        font-size: ${fonts[size]};
      `

    return ''
  }}
`

export default Badge
