import { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  ButtonContent,
  PrimaryButtonFlexable48,
} from 'components/Common/MasterComponents'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ButtonFlexable = styled(PrimaryButtonFlexable48)`
  margin-top: 24px;
  width: 100%;
  cursor: ${props => (props.fakeDisabled ? 'auto' : 'pointer')};
  font: var(--typography-14-16-semibold);
  transition: background-color 0.2s;
`
interface ButtonProps {
  disabled: boolean
  loading: boolean
  handleConfirm: () => void
}

const Button: FC<ButtonProps> = props => {
  const { disabled, loading, handleConfirm } = props
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ButtonFlexable fakeDisabled={disabled} onClick={handleConfirm}>
        <ButtonContent loading={loading}>
          {t('confirmPwd.confirm')}
        </ButtonContent>
      </ButtonFlexable>
    </Wrapper>
  )
}

export default Button
