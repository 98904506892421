import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { breakpoint } from 'utils/consts/breakpoints'

const Wrapper = styled(ToastContainer).attrs({
  className: 'container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  width: 312px;
  top: 16px;

  @media screen and ${breakpoint.m} {
    right: 2em;
    left: auto;
  }

  .toast {
    align-items: center;
    background: #fff;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: none;
    font: var(--typography-14-20-normal);
    font-style: normal;
    font-weight: 400;
    color: var(--color-night);
    padding: 16px;
  }

  .body {
    align-items: flex-start;
    padding: 0;
    margin: 0;
    word-break: break-word;
  }

  .progress {
    height: 4px;
    background: ${props =>
      props.color ? `${props.color}` : 'var(--color-red)'};
  }

  .Toastify__toast-icon {
    margin-right: 12px;
  }

  .Toastify__close-button {
    margin: -8px -8px 8px 8px;
  }
`

export default Wrapper
