import { createContext, useContext, Dispatch, SetStateAction } from 'react'
import { RefCallbackOptions } from './Provider'

type ContextType = {
  reactTableProps?: any
  setReactTableProps: Dispatch<SetStateAction<any>>
  setTableContentRef: Dispatch<SetStateAction<any>>
  tableContentRef: HTMLElement | null
  setCollapsibleHeader: (
    ref: HTMLElement | null,
    ops?: RefCallbackOptions
  ) => void
}

const TableContext = createContext<ContextType>({
  reactTableProps: {},
  tableContentRef: null,
  setReactTableProps: () => {},
  setTableContentRef: () => {},
  setCollapsibleHeader: () => {},
})

export const useTableContext = () => useContext(TableContext)

export default TableContext
