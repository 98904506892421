import styled from 'styled-components'
import Main from 'components/Pages/Login/Left/Main/Form/Main'
import Header from 'components/Pages/Login/Left/Main/Form/Header'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
`
const Block = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
`

const Form = () => {
  return (
    <Wrapper>
      <Header />
      <Block>
        <Main />
      </Block>
    </Wrapper>
  )
}

export default Form
