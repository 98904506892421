import styled from 'styled-components'
import BaseButton from 'components/Common/MasterComponents/Buttons/BaseButton'

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`

const Wrapper = styled(BaseButton)`
  --button-size: 40px;
  --bg-color: #fff;

  background: var(--bg-color);
  border: 1px solid #e7e7ea;
  border-radius: 8px;
  font: var(--typography-14-20-semibold);
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 8px;
  color: #717284;
  min-height: var(--button-size);
  padding: ${props => (props.iconOnly ? '8px 9px' : '8px 10px')};
  cursor: pointer;
  letter-spacing: 0.3px;

  svg {
    fill: currentColor;
  }

  :focus-visible,
  :hover {
    --bg-color: ${props =>
      props.disabled || props.fakeDisabled ? 'var(--color-white)' : '#E7E7EA'};

    color: ${props =>
      props.disabled || props.fakeDisabled
        ? 'var(--color-sw-20)'
        : 'var(--color-velvet)'};
  }

  :disabled,
  [disabled] {
    color: #e7e7ea;
    border-color: #e7e7ea;
    cursor: default;
  }
`

export const PageHeaderSeconaryButton = props => {
  const { icon, children, ...rest } = props
  const iconOnly = !children

  return (
    <Wrapper iconOnly={iconOnly} {...rest}>
      <Icon iconOnly={iconOnly}>{icon}</Icon>
      {children}
    </Wrapper>
  )
}
