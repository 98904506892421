import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/Common/MasterComponents'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Block = styled.div`
  width: 100%;
  position: relative;
`

const Error = styled.div`
  padding: 8px 16px 0;
  display: flex;
  align-items: center;
  color: var(--color-red);
  font: var(--typography-11-16-regular);
`
interface IEmailProps {
  error: string
}
const Email = props => {
  const { error, ...rest }: IEmailProps = props

  const { t } = useTranslation()

  return (
    <Wrapper>
      <Block>
        <Input
          label={t('forgotPwd.email')}
          type='email'
          id='email'
          maxLength={50}
          spellCheck='false'
          hasError={!!error}
          {...rest}
        />
      </Block>
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default Email
