import { FC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CompanyAvatar from '../CompanyAvatar'
import { getSelectedCompany } from 'store/me'
import CompanyInfo from '../CompanyInfo'
import { menuItemStyles } from '../../_styles'

const Wrapper = styled.button`
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px;
  width: 100%;
  ${menuItemStyles()};
  user-select: none;
`

const ExpandButton = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface SelectedCompanyProps {
  collapsed?: boolean
  onToggle: () => void
}

const SelectedCompany: FC<SelectedCompanyProps> = props => {
  const { onToggle } = props

  const data = useSelector(getSelectedCompany)

  return (
    <Wrapper type='button' onClick={onToggle}>
      <CompanyAvatar avatar={data?.avatar} isVerified={data?.isVerifiedUser} />

      <CompanyInfo data={data} />
      <ExpandButton>
        <ExpandIcon />
      </ExpandButton>
    </Wrapper>
  )
}

// prettier-ignore
const ExpandIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <path fill='#888998' fillRule='evenodd'
          d='M10 3.3c.23 0 .45.08.62.25l3.33 3.33a.87.87 0 1 1-1.24 1.24L10 5.4 7.29 8.12a.87.87 0 1 1-1.24-1.24l3.33-3.33a.87.87 0 0 1 .62-.26Zm3.95 8.58c.34.34.34.9 0 1.24l-3.33 3.33a.87.87 0 0 1-1.24 0l-3.33-3.33a.87.87 0 1 1 1.24-1.24L10 14.6l2.71-2.72a.87.87 0 0 1 1.24 0Z'
          clipRule='evenodd' />
  </svg>
)

export default SelectedCompany
