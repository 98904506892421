import { useCallback, useMemo, useState } from 'react'
import { isLogin } from 'utils/token'
import LoginDialog from 'components/Common/Modals/Login'
import GuestLoginDialogContext from 'components/Common/Modals/Login/Context'

const GuestLoginDialogProvider = ({ children }) => {
  const [visible, setVisible] = useState(false)

  const showDialogIfGuestOr = useCallback(
    (ifLogin = () => {}) =>
      event => {
        const isLoggedIn = isLogin()
        if (isLoggedIn) {
          ifLogin()
        } else {
          event.preventDefault()
          event.stopPropagation()
          setVisible(true)
        }
      },
    [setVisible]
  )

  const value = useMemo(
    () => ({ visible, showDialogIfGuestOr }),
    [visible, showDialogIfGuestOr]
  )

  return (
    <GuestLoginDialogContext.Provider value={value}>
      {children}
      {visible ? <LoginDialog onClose={() => setVisible(false)} /> : null}
    </GuestLoginDialogContext.Provider>
  )
}

export default GuestLoginDialogProvider
