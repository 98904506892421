import i18n from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

const loader = lang => import(`./translations/${lang}.json`)

const whitelist = ['de', 'de-DE', 'en', 'en-US', 'es', 'fr', 'it']

export const i18nWhitelistLangs = whitelist.map(item => item.slice(0, 2))

const getLanguage = () => {
  const lang = localStorage.getItem('wwl-lng') || navigator.language
  return i18nWhitelistLangs.includes(lang) ? lang : 'en'
}

i18n
  .use(resourcesToBackend(loader))
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en',
    whitelist,
    lng: getLanguage(),
    react: {
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'ol', 'li'],
    },
    escapeValue: false,
  })

export default i18n
