import { useSelector } from 'react-redux'
import { getUserData } from 'store/me'
import { ManagementRoles } from 'utils/consts/userRoles'

export const useCheckAccessToDatabase = () => {
  const userData = useSelector(getUserData)
  const role = userData?.role?.code

  return [
    ManagementRoles.CONTENT,
    ManagementRoles.MANAGER,
    ManagementRoles.SUPERADMIN,
  ].includes(role ?? '')
}
