import { useUserTutorialCompleteMutation } from 'api/hooks'

export const useCompleteTutorial = () => {
  const [runMutation, { loading }] = useUserTutorialCompleteMutation()

  const completeTutorial = (page: string) => {
    return runMutation({
      variables: { page },
    })
  }

  return { completeTutorial, loading }
}
