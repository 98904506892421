import { forwardRef, HTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.label`
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  appearance: none;

  /* G-3 */
  background: #f9f9f9;
  border: 1px solid var(--color-sw-10);
  box-sizing: border-box;
  border-radius: 8px;
  width: 32px;
  min-width: 32px;
  height: 16px;
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  position: relative;
  transition: background 0.1s, border-color 0.1s;

  :hover,
  :focus-visible {
    border-color: ${props =>
      props.disabled ? 'var(--color-sw-10)' : 'var(--color-velvet)'};
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    background: ${props => (props.disabled ? '#F9F9F9' : 'var(--color-white)')};
    border-radius: 7px;
    box-shadow: ${props =>
      props.disabled
        ? '0 0 0 1px var(--color-sw-10)'
        : '0 1px 3px rgba(95, 99, 104, 0.5)'};
    width: 14px;
    height: 14px;
    left: 0;
    transition: left 0.1s;
  }

  :checked {
    background: ${props =>
      props.disabled ? '#F9F9F9' : 'var(--color-velvet)'};
    border-color: ${props =>
      props.disabled ? 'var(--color-sw-10)' : 'var(--color-velvet)'};

    ::after {
      left: 16px;
    }

    :hover,
    :focus-visible {
      background: ${props =>
        props.disabled ? '#F9F9F9' : 'var(--color-night-sky)'};
      border-color: ${props =>
        props.disabled ? 'var(--color-sw-10)' : 'var(--color-night-sky)'};
    }
  }
`

const Label = styled.div<{ disabled?: boolean }>`
  margin-left: 8px;
  user-select: none;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${props => (props.disabled ? 'var(--color-sw-20)' : '#595a6f')};
`

interface Switcher16Props extends HTMLAttributes<HTMLInputElement> {
  children?: ReactNode
  disabled?: boolean
  checked?: boolean
}

export const Switcher16 = forwardRef<HTMLInputElement, Switcher16Props>(
  (props, ref) => {
    const { children, ...inputProps } = props
    return (
      <Wrapper>
        <Input type='checkbox' {...inputProps} ref={ref as any} />
        {children && <Label disabled={inputProps.disabled}>{children}</Label>}
      </Wrapper>
    )
  }
)

export default Switcher16
