import { FC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import MenuItemIcon from './MenuItemIcon'
import MenuItemFull from './MenuItemFull'
import { useMenuItems } from '../../_methods'
import { getCompanyVerified } from 'store/me'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  padding: 4px 8px;
  overflow-y: auto;
  overflow-x: initial;
`

interface NavigationProps {
  collapsed: boolean
}

const Navigation: FC<NavigationProps> = ({ collapsed }) => {
  const items = useMenuItems()
  const isVerified = useSelector(getCompanyVerified)

  const MenuItem = collapsed ? MenuItemIcon : MenuItemFull

  return (
    <Wrapper>
      {items.map(item => (
        <MenuItem
          key={item.id}
          item={item}
          disabled={!isVerified && item.forVerifiedOnly}
        />
      ))}
    </Wrapper>
  )
}

export default Navigation
