import { useParams } from 'react-router-dom'
import Confirm from './Confirm'
import Change from './Change'

const Main = () => {
  const { type } = useParams<{ type: string }>()

  if (type === 'confirm-mail') return <Confirm />
  if (type === 'change-email') return <Change />
  return null
}

export default Main
