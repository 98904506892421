import { init, addEventProcessor } from '@sentry/react'
import { getAuthToken } from 'utils/token'

export const configureSentry = () => {
  const SENTRY_DSN = process.env.SENTRY_DSN || ''

  if (!process.env.SENTRY_ENABLED) return

  init({
    dsn: SENTRY_DSN,
    integrations: [],
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })

  addEventProcessor(event => {
    const token = getAuthToken()
    event.extra = {
      ...(event.extra || {}),
      token,
    }
    return event
  })
}

export { captureException } from '@sentry/react'
