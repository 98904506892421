const Email = () => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2256_33789)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 0.5C11.1716 0.5 10.5 1.17157 10.5 2V15.3787L0.93934 24.9393C0.658035 25.2206 0.5 25.6022 0.5 26V62C0.5 62.8284 1.17157 63.5 2 63.5H62C62.8284 63.5 63.5 62.8284 63.5 62V26C63.5 25.6022 63.342 25.2206 63.0607 24.9393L53.5 15.3787V2C53.5 1.17157 52.8284 0.5 52 0.5H12ZM4.33945 25.7819L10.5 19.6213V29.9916L4.33945 25.7819ZM32 44.6832L13.5 32.0416V3.5H50.5V32.0416L32 44.6832ZM59.6605 25.7819L53.5 29.9916V19.6213L59.6605 25.7819ZM42.9533 40.832L60.5 28.8418V60.5H3.5V28.8418L21.0467 40.832L8.93934 52.9393C8.35355 53.5251 8.35355 54.4749 8.93934 55.0607C9.52513 55.6464 10.4749 55.6464 11.0607 55.0607L23.5671 42.5543L31.1537 47.7385C31.664 48.0872 32.336 48.0872 32.8463 47.7385L40.4329 42.5543L52.9393 55.0607C53.5251 55.6464 54.4749 55.6464 55.0607 55.0607C55.6464 54.4749 55.6464 53.5251 55.0607 52.9393L42.9533 40.832Z'
        fill='#3B42A5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.8942 28.3905L36.5073 26.3102L36.2992 26.4492C35.0691 27.2709 33.5908 27.75 32 27.75C27.7198 27.75 24.25 24.2802 24.25 20C24.25 15.7198 27.7198 12.25 32 12.25C36.2802 12.25 39.75 15.7198 39.75 20V21.5C39.75 22.1903 39.1903 22.75 38.5 22.75C37.8097 22.75 37.25 22.1903 37.25 21.5V15.75H35.0828C34.2171 15.121 33.1515 14.75 32 14.75C29.1005 14.75 26.75 17.1005 26.75 20C26.75 22.8995 29.1005 25.25 32 25.25C33.3806 25.25 34.6371 24.7168 35.574 23.8457C36.261 24.7014 37.3164 25.25 38.5 25.25C40.5711 25.25 42.25 23.5711 42.25 21.5V20C42.25 14.3391 37.6609 9.75 32 9.75C26.3391 9.75 21.75 14.3391 21.75 20C21.75 25.6609 26.3391 30.25 32 30.25C34.1032 30.25 36.0592 29.6163 37.6865 28.5293L37.8942 28.3905ZM29.25 20C29.25 18.4812 30.4812 17.25 32 17.25C33.5188 17.25 34.75 18.4812 34.75 20C34.75 21.5188 33.5188 22.75 32 22.75C30.4812 22.75 29.25 21.5188 29.25 20Z'
        fill='#88122B'
      />
    </g>
    <defs>
      <clipPath id='clip0_2256_33789'>
        <rect width='64' height='64' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Email
