import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { WinesStore, Sorting } from './types'
import type { Filters, PageInfo, Wine } from './types'
import type { Currency } from 'api/schemas'

const initialState: WinesStore = {
  items: [],
  item: {},
  pageInfo: null,
  tradeNameCount: 0,
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  loading: true,
  error: null,
  query: '',
  filters: [],
  currency: null,
}
export interface CurrencyBuyWine extends Omit<Currency, 'id'> {
  id: number | null
}

const winesSlice = createSlice({
  name: 'wines',
  initialState,
  reducers: {
    setItems: (state, { payload }: PayloadAction<Wine[]>) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload
    },
    setSortingValue: (state, { payload }: PayloadAction<Sorting | null>) => {
      state.sorting = payload
    },
    setTradeNameCount: (state, { payload }: PayloadAction<number>) => {
      state.tradeNameCount = payload
    },
    clear: () => {
      return initialState
    },
    resetItem: state => {
      state.item = initialState.item
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setQuery: (state, { payload }: PayloadAction<string>) => {
      state.query = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setFilters: (state, { payload }: PayloadAction<Filters>) => {
      state.filters = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setCurrency: (
      state,
      { payload }: PayloadAction<CurrencyBuyWine | null>
    ) => {
      state.currency = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: wines } = winesSlice
export const {
  setItems,
  setPageInfo,
  setSortingValue: setSort,
  clear,
  resetItem,
  setLoading,
  setQuery,
  setFilters,
  setError,
  reset,
  setTradeNameCount,
  setCurrency,
} = actions
