import { useContext } from 'react'
import ScreenContext from './Context'

type Image =
  | {
      thumbnailUrl?: string
      thumbnailRetinaUrl?: string
    }
  | null
  | undefined

export const getImageSrc = (
  image: Image | null | undefined,
  fallback: string = ''
) => ({
  src: image?.thumbnailUrl || fallback,
  srcSet: image?.thumbnailRetinaUrl
    ? `${image?.thumbnailRetinaUrl} 2x`
    : undefined,
})

const isRetina = 'devicePixelRatio' in window && window?.devicePixelRatio > 1

export const getScaledImage = image =>
  image?.[isRetina ? 'thumbnailRetinaUrl' : 'thumbnailUrl'] ??
  image?.imageUrl ??
  image

const useScreen = () => {
  return useContext(ScreenContext)
}

export default useScreen
