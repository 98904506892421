import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`
export const Error = styled.div`
  padding: 8px 16px 0;
  display: flex;
  align-items: center;
  color: var(--color-red);
  font: var(--typography-11-16-regular);
`
export const Block = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;

  :hover {
    box-shadow: 0 3px 5px rgba(95, 99, 104, 0.05),
      0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);
  }
`
