import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
`
export const Container = styled.div`
  position: relative;
`
export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
