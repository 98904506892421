import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import useScreen from 'utils/screen'
import { TutorialStep } from 'components/Common/Menu'
import breakpoints from 'utils/consts/breakpoints'

interface IImage {
  link: string
}

const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  background-color: var(--color-white);
`
const Title = styled.div`
  font: var(--typography-16-24-semibold);
  margin-bottom: 4px;
`
const Text = styled.div`
  font: var(--typography-14-20-medium);
  white-space: pre-line;

  a {
    text-decoration: none;
    color: var(--color-velvet);
  }
`
const Image = styled.div<IImage>`
  height: 100%;
  width: 100%;
  background-image: url(${({ link }) => link});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 8px;
`
const ImageWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  min-height: 256px;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--color-sw-10);
  background: var(--color-grey2-G-2);
  margin-bottom: 16px;
`

interface ITutorialContent {
  step: TutorialStep
}

const TutorialContent = ({ step }: ITutorialContent) => {
  const { t, i18n } = useTranslation()
  const { size = 0 } = useScreen()
  const isLaptop = size < breakpoints.container.m

  const lng = i18n.language

  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          link={
            (step.image[lng] || step.image.en)[isLaptop ? 'mobile' : 'desktop']
          }
        />
      </ImageWrapper>

      <Title>{t(step.title)}</Title>
      <Text>
        <Trans
          t={t}
          i18nKey={step.text}
          components={step.components?.[lng] || step.components?.en}
        />
      </Text>
    </Wrapper>
  )
}

export default TutorialContent
