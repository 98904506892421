import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from 'components/App'
import reduxStore from 'store'
import { Provider } from 'react-redux'
import { Normalize } from 'styled-normalize'
import { configureApollo } from 'utils/apollo'
import { configureSentry } from 'utils/sentry'
import { ApolloProvider } from '@apollo/client'
import { GlobalStyles } from 'components/Common/GlobalStyles'
import 'utils/i18n'
import 'utils/errorHandler'

const apollo = configureApollo()
configureSentry()

// <React.StrictMode>
createRoot(document.getElementById('root')!).render(
  <Suspense fallback={null}>
    <ApolloProvider client={apollo}>
      <Provider store={reduxStore}>
        <Normalize />
        <GlobalStyles />
        <App />
      </Provider>
    </ApolloProvider>
  </Suspense>
)
