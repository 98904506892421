import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SuccessIcon from 'components/Pages/ConfirmPassword/Icons/Success'

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Notification = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Success = styled.div`
  text-align: center;
  margin-top: 16px;
  color: var(--color-night);
  font: var(--typography-18-24-semibold);
`

const SuccessMessage: FC = () => {
  const { t } = useTranslation()

  return (
    <Notification>
      <Icon>
        <SuccessIcon />
      </Icon>
      <Success>{t('confirmPwd.success')}</Success>
    </Notification>
  )
}

export default SuccessMessage
