import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { lineClamp } from 'components/Common/GlobalStyles'
import { getSelectedCompanyTour } from 'store/me'
import {
  getPercentCompleted,
  getTasksLeft,
} from '../../Sidebar/GettingStarted/_methods'
import Progress from '../../Sidebar/GettingStarted/Progress'
import Tour from './Tour'

const Wrapper = styled.div``

const Main = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px;
  margin: 16px;
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%
    ),
    #121432;
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  flex: 1 0 0;
  max-width: 100%;
  user-select: none;
`

const Title = styled.div`
  color: #888998;
  font: var(--typography-12-16-medium);
  ${lineClamp()};
`

const TasksLeft = styled.div`
  color: #e7e7ea;
  font: var(--typography-13-16-semibold);
  ${lineClamp()};
`

const GettingStarted = ({ onClose }) => {
  const tour = useSelector(getSelectedCompanyTour)
  if (!tour || tour.completed || tour.skipped) return null
  return <Content tour={tour} onClose={onClose} />
}

const Content = ({ tour, onClose }) => {
  const { t } = useTranslation()

  const [opened, setOpened] = useState(false)

  return (
    <Wrapper>
      <Main onClick={() => setOpened(true)}>
        <TextContent>
          <Title>{t('getting-started.title')}</Title>
          <TasksLeft>
            {t('getting-started.tasks-left', { n: getTasksLeft(tour.tasks) })}
          </TasksLeft>
        </TextContent>
        <Progress value={getPercentCompleted(tour.tasks)} />
      </Main>

      {opened && <Tour tasks={tour.tasks} onClose={onClose} />}
    </Wrapper>
  )
}

export default GettingStarted
