import styled from 'styled-components'

export const Wrapper = styled.div``

export const Block = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  :hover,
  :focus-within {
    box-shadow: ${props =>
      props.disabled
        ? 'none'
        : '0 3px 5px rgba(95, 99, 104, 0.05), 0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05)'};
  }

  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  border: 1px solid ${props => (props.disabled ? '#e7e7ea' : '#e2e2ea')};
  box-sizing: border-box;
  height: 48px;
  padding: 0 15px;
`

export const Label = styled.label`
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 14px;
  left: 15px;
  color: ${props => (props.hasError ? 'var(--color-red)' : '#717284')};
  transition: top 0.2s ease, font-size 0.2s ease;
  font: var(--typography-14-20-semibold);
  pointer-events: none;
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  appearance: none;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: ${props => {
    if (props.hasError) return 'var(--color-red)'
    if (props.disabled) return '#d0d0d6'
    return 'var(--color-night)'
  }};
  font-size: 14px;
  font-family: var(--typography-semibold);
  padding: 23px 0 7px;

  &[disabled] + ${Label}, &:disabled + ${Label} {
    color: #d0d0d6;
  }

  :focus + ${Label}, :not([value='']) + ${Label} {
    top: 6px;
    font: var(--typography-10-16-regular);
    color: #717284;
  }

  :not(:focus)::placeholder {
    opacity: 0;
  }

  :not([disabled]):hover + ${Label} {
    color: var(--color-night);
  }

  :required {
    box-shadow: none;
  }

  transition: border 0.2s;
  background: transparent;
`
