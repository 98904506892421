import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { usePopperTooltip } from 'react-popper-tooltip'
import { useSelector } from 'react-redux'
import MenuSingleItem from './MenuSingleItem'
import MenuItemWithSubItems from './MenuItemWithSubItems'
import Indicator from '../Indicator'
import { MenuItem } from '../../../_methods'
import { TooltipWrapper } from '../../_styles'
import { TooltipText, MenuItemTooltipWrapper, IconWrapper } from '../_styles'

interface MenuItemProps {
  item: MenuItem
  disabled?: boolean
}

const NavMenuItem: FC<MenuItemProps> = ({ item, disabled }) => {
  const { t } = useTranslation()

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'right-start',
      offset: [0, 16],
    })

  const Icon = item.icon
  const isActive = !!useRouteMatch({ path: item?.link, exact: !item.items })

  const badge = item.badge ? useSelector(item.badge) : false

  const content = (
    <IconWrapper>
      <Icon />
      <Indicator value={badge} />
    </IconWrapper>
  )

  return (
    <>
      <MenuItemTooltipWrapper ref={setTriggerRef}>
        {item.items ? (
          <MenuItemWithSubItems
            title={item.title}
            isActive={isActive}
            items={item.items}
            disabled={disabled}
          >
            {content}
          </MenuItemWithSubItems>
        ) : (
          <MenuSingleItem
            isActive={isActive}
            link={item.link}
            disabled={disabled}
          >
            {content}
          </MenuSingleItem>
        )}
      </MenuItemTooltipWrapper>

      {visible && (
        <TooltipWrapper ref={setTooltipRef} {...getTooltipProps()}>
          <TooltipText>{t(item.title)}</TooltipText>
        </TooltipWrapper>
      )}
    </>
  )
}

export default NavMenuItem
