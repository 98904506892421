import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Header, Content, Wrapper, ModalWrapper } from './_styles'
import EmailBlock from './EmailBlock'
import AnotherBlock from './AnotherBlock'
import UserBlock from './UserBlock'
import Footer from './Footer'
import { useCheckEmail } from '../_methods'
import Dialog from 'components/Common/Modals/Dialog'
import { setMethod, resetForm, setTouched, setEmailError } from 'store/verify'

const Main = props => {
  const { onClose, userLists, website, onVerify, anotherDisabled } = props
  const { t } = useTranslation()

  const { email, emailError, user, userError, method } = useSelector(state => {
    return state.verify
  }, shallowEqual)

  const dispatch = useDispatch()

  useEffect(() => {
    let method = ''
    if (website) method = 'email'
    else if (userLists && userLists.length > 0) method = 'user'

    dispatch(setMethod(method))

    return () => dispatch(resetForm())
  }, [])

  const handleMethodChange = event => {
    if (event.target.name === 'method') {
      dispatch(setMethod(event.target.value))
      dispatch(setTouched(false))
    }
  }

  const isVerifyButtonActive =
    emailError === '' && userError === '' && method !== ''

  const checkEmail = useCheckEmail()
  const [submitting, setSubmitting] = useState(false)
  const handleSubmit = async e => {
    e.preventDefault()

    if (submitting) return

    if (!isVerifyButtonActive) {
      return dispatch(setTouched(true))
    }

    let value
    if (method === 'email') value = email
    if (method === 'user') value = user

    setSubmitting(true)

    if (method === 'email') {
      const isEmailOk = await checkEmail(email, website)

      if (!isEmailOk) {
        setSubmitting(false)
        dispatch(
          setEmailError(t('myCompany.verifyDialog.email.error.diffDomain'))
        )
        return
      }
    }

    const success = await onVerify(method, value)
    if (!success) {
      setSubmitting(false)
    }
  }

  if (method === null) return null

  return (
    <Dialog.Wrapper onClose={onClose}>
      <ModalWrapper>
        <Wrapper>
          <Dialog.Header
            title={t('myCompany.verifyDialog.title')}
            onClose={onClose}
          />
          <Content>
            <Form onSubmit={handleSubmit} onChange={handleMethodChange}>
              <Header>{t('myCompany.verifyDialog.chooseMethod')}</Header>

              <EmailBlock website={website} />
              <UserBlock userLists={userLists} />
              <AnotherBlock disabled={anotherDisabled} />

              <Footer
                active={isVerifyButtonActive}
                submitting={submitting}
                onClose={onClose}
              />
            </Form>
          </Content>
        </Wrapper>
      </ModalWrapper>
    </Dialog.Wrapper>
  )
}

export default Main
