import { createSlice } from '@reduxjs/toolkit'
import {
  ErrorPayload,
  FieldAndValue,
  FieldName,
  TradeOfferState,
  TradeOfferValues,
} from './types'
import validators from './validators'

const initialState: TradeOfferState = {
  values: {
    wine: null,
    productURL: '',
    isProductURLUnique: true,
    isProductURLUsed: false,
    additionalID: '',
    merchant: null,
    wineName: '',
    wineDescription: '',
    bottleSize: null,
    packageType: null,
    lots: [],
    wrongCoupleAdIdUrl: false,
    id: null,
    tradeOfferIdByProductUrl: null,
  },
  errors: {
    lots: [],
  },
}

const tradeOfferSlice = createSlice({
  name: 'tradeOffer',
  initialState,
  reducers: {
    setValue(state, { payload }: { payload: FieldAndValue }) {
      const { field, value } = payload
      // @ts-ignore
      state.values[field] = value
    },

    setError(state, { payload }: { payload: ErrorPayload }) {
      const { field, value } = payload
      // @ts-ignore
      state.errors[field] = value
    },

    reset(state) {
      state.values = initialState.values
      state.errors = initialState.errors
    },

    validateField(state, { payload }: { payload: FieldName }) {
      state.errors[payload] = validators[payload]?.(state.values)
    },

    validateForm(state) {
      const values = state.values

      const errors = Object.keys(validators).map(field => {
        return [field, validators[field](values)]
      })

      state.errors = Object.fromEntries(errors)
    },

    initForm(state, { payload }: { payload: TradeOfferValues }) {
      state.values = { ...initialState.values, ...payload }
      state.errors = initialState.errors
    },
  },
})

export const { actions, reducer: tradeOffer } = tradeOfferSlice
export const {
  setValue,
  setError,
  reset,
  validateField,
  validateForm,
  initForm,
} = actions
