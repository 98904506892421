import {
  CHECKBOX_LIST,
  filterTypes,
  INTERVAL,
  MULTISELECT_SWITCHER,
  NUMBER,
  PRICE_INTERVAL,
  RADIO_LIST,
  RHEOSTAT,
  RRP_INTERVAL,
  SCORE,
  SWITCHER,
} from './filters'
import i18n from '../i18n'
import {
  offersFiltersKey,
  offersFiltersName,
  offersFiltersTitle as baseOffersFiltersTitle,
} from './offersFilters'

export const getBuyWineOffersFiltersCollection = () => [
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(baseOffersFiltersTitle.countryOfShipmentFilter),
    filterName: offersFiltersName.countryOfShipmentFilter,
    apiFilterName: offersFiltersKey().countryOfShipmentFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(baseOffersFiltersTitle.deliveryTermsFilter),
    filterName: offersFiltersName.deliveryTermsFilter,
    apiFilterName: offersFiltersKey().deliveryTermsFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.wineCountryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: offersFiltersKey().countryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: offersFiltersKey().producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: offersFiltersKey().brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.nationalGITypeFilter),
    filterName: offersFiltersName.nationalGITypeFilter,
    apiFilterName: offersFiltersKey().nationalGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.geoIndicationFilter),
    filterName: offersFiltersName.geoIndicationFilter,
    apiFilterName: offersFiltersKey().geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: offersFiltersKey().specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.wineTypeFilter),
    filterName: offersFiltersName.wineTypeFilter,
    apiFilterName: offersFiltersKey().wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.wineColourFilter),
    filterName: offersFiltersName.wineColourFilter,
    apiFilterName: offersFiltersKey().wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.sweetnessTypeFilter),
    filterName: offersFiltersName.sweetnessTypeFilter,
    apiFilterName: offersFiltersKey().sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: offersFiltersKey().vintageFilter,
  },
  {
    type: filterTypes[PRICE_INTERVAL],
    title: i18n.t(baseOffersFiltersTitle.priceFilter),
    filterName: offersFiltersName.priceFilter,
    apiFilterName: offersFiltersKey().priceFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(baseOffersFiltersTitle.retailPriceFilter),
    filterName: offersFiltersName.retailPriceFilter,
    apiFilterName: offersFiltersKey().retailPriceFilter,
    defaultIntervalKey: 'priceInterval',
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(baseOffersFiltersTitle.rrpFilter),
    filterName: offersFiltersName.rrpFilter,
    apiFilterName: 'product.priceAtWinery',
    defaultIntervalKey: 'priceInterval',
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(baseOffersFiltersTitle.priceCoefFilter),
    filterName: offersFiltersName.priceCoefFilter,
    apiFilterName: 'priceCoefficient',
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(baseOffersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: offersFiltersKey().grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(baseOffersFiltersTitle.monocepageFilter),
    switchApiFilterName: offersFiltersKey().monocepageFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(baseOffersFiltersTitle.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: offersFiltersKey().alcoholLevelFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: offersFiltersKey().certificatesFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.bottleSizeFilter),
    filterName: offersFiltersName.bottleSizeFilter,
    apiFilterName: offersFiltersKey().bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.packageTypeFilter),
    filterName: offersFiltersName.packageTypeFilter,
    apiFilterName: offersFiltersKey().packageTypeFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(baseOffersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: offersFiltersKey().wwlRatingFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.exhibitionFilter),
    filterName: offersFiltersName.exhibitionFilter,
    apiFilterName: offersFiltersKey().exhibitionFilter,
  },
  {
    type: filterTypes[NUMBER],
    title: i18n.t(baseOffersFiltersTitle.minimalBatchFilter),
    filterName: offersFiltersName.minimalBatchFilter,
    apiFilterName: offersFiltersKey().minimalBatchFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(baseOffersFiltersTitle.exclusiveOffersFilter),
    filterName: offersFiltersName.exclusiveOffersFilter,
    apiFilterName: offersFiltersKey().exclusiveOffersFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(baseOffersFiltersTitle.onlyFromProducerFilter),
    filterName: offersFiltersName.onlyFromProducerFilter,
    apiFilterName: offersFiltersKey().onlyFromProducerFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(baseOffersFiltersTitle.forCompanyFilter),
    filterName: offersFiltersName.forCompanyOnlyFilter,
    apiFilterName: offersFiltersKey().forCompanyOnlyFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.statusFilter),
    filterName: offersFiltersName.statusFilter,
    apiFilterName: offersFiltersKey().statusFilter,
  },
]
