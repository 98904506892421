import { useEffect, useState } from 'react'
import Dialog from 'components/Common/Modals/Dialog'
import { ICompany, useHideModal, useLoadExternalCompanies } from './_methods'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'

interface IProps {
  onClose: () => void
}

const Content = ({ onClose }: IProps) => {
  const [companies, setCompanies] = useState<ICompany[]>([])
  const { data, loading } = useLoadExternalCompanies()
  const { hideModalRequest } = useHideModal()

  useEffect(() => {
    if (data) {
      const companiesList = data?.me?.externalCompanies || []
      if (companiesList.length === 1) {
        setCompanies(
          // @ts-ignore
          (data?.me?.externalCompanies || []).map(item => ({
            ...item,
            selected: true,
          }))
        )
      } else {
        setCompanies(
          // @ts-ignore
          (data?.me?.externalCompanies || []).map(item => ({
            ...item,
            selected: false,
          }))
        )
      }
    }
  }, [data])

  const handleSelectCompany = (id: string) => {
    setCompanies(prev =>
      prev.map(item =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    )
  }

  const handleClose = async () => {
    await hideModalRequest()
    onClose()
  }

  return (
    <Dialog.Wrapper onClose={handleClose}>
      <Dialog.Main width='448px' margin='16px'>
        <Dialog.Content>
          <Header />
          <Main
            loading={loading}
            companies={companies}
            onChange={handleSelectCompany}
          />
          <Footer onClose={onClose} companies={companies} />
        </Dialog.Content>
      </Dialog.Main>
    </Dialog.Wrapper>
  )
}

export default Content
