import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div<{ selectable?: boolean }>`
  font: var(--typography-10-16-normal);
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #595a6f;
  padding: 0 16px;
`

interface NoMatchesProps {
  selectable?: boolean
  children?: ReactNode
}

const NoMatches: FC<NoMatchesProps> = ({ children }) => {
  const { t } = useTranslation()
  const content = children || t('brand.create.producer.noMatches')
  return <Wrapper selectable={false}>{content}</Wrapper>
}

export default NoMatches
