import { OutlineButton, TextButton } from '../../../Common'
import { Footer as DesktopFooter, MobileFooter } from '../_styles'
import Loader from 'components/Common/Loader'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const VerifyButton = styled(PrimaryButtonFlexable48)`
  width: 208px;
`

const Footer = props => {
  const { onClose, submitting, active } = props
  const { t } = useTranslation()

  return (
    <>
      <DesktopFooter>
        <TextButton onClick={onClose}>
          {t('myCompany.verifyDialog.another.cancel')}
        </TextButton>
        <VerifyButton type='submit' fakeDisabled={!active}>
          {submitting ? (
            <Loader color='#fff' size={23} />
          ) : (
            t('myCompany.verifyDialog.another.verify')
          )}
        </VerifyButton>
      </DesktopFooter>

      <MobileFooter>
        <OutlineButton onClick={onClose}>
          {t('myCompany.verifyDialog.another.cancel')}
        </OutlineButton>
        <PrimaryButtonFlexable48 type='submit' fakeDisabled={!active}>
          {submitting ? (
            <Loader color='#fff' size={23} />
          ) : (
            t('myCompany.verifyDialog.another.verify')
          )}
        </PrimaryButtonFlexable48>
      </MobileFooter>
    </>
  )
}

export default Footer
