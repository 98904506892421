import { FC } from 'react'
import styled from 'styled-components'
import Mobile from 'components/Common/Cookies/Mobile'
import Desktop from 'components/Common/Cookies/Desktop'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 48px;
  width: 592px;
  min-height: 48px;
  background: var(--color-white);
  border: 1px solid #e7e7ea;
  box-sizing: border-box;
  box-shadow: 0 3px 5px rgba(95, 99, 104, 0.1),
    0 10px 10px rgba(95, 99, 104, 0.1), 0 1px 18px rgba(95, 99, 104, 0.1);
  border-radius: 32px;
  z-index: 1031;

  @media (max-width: 880px) {
    bottom: 0;
    width: 100%;
    border-radius: 16px 16px 0 0;
    align-items: normal;
    box-shadow: none;
  }
`

const Cookies: FC = () => {
  return (
    <Wrapper>
      <Desktop />
      <Mobile />
    </Wrapper>
  )
}

export default Cookies
