import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import {
  ARCHIVED_STATUS,
  BUYER_CONDITIONS_STATUS,
  wineOfferStatus,
} from 'utils/consts'
import { formatDate } from 'utils/date'
import i18n from 'utils/i18n'
import { roundPrice } from 'utils/price'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { HelpTipWrapper } from 'components/Pages/BuyWine/Offers/Common/Negotiations/Content/ChangeLog/Log/_styles'
import HelpTip from 'components/Common/Tooltips/HelpTip'
import styled from 'styled-components'
import Price from 'components/Common/Price'

const WarehouseWrapper = styled.div`
  display: flex;
`

export const useRepresentativeData = log => {
  const status = log.wineOfferStatus?.code ?? ''
  const isBuyerStatus = status === BUYER_CONDITIONS_STATUS
  const time = formatDate(parseISO(log?.createdAt), 'HH:mm')
  let logo, company, name, lastName, userName, id

  if (isBuyerStatus) {
    logo = log?.buyerCompany?.avatar
    company = log?.buyerCompany?.tradeName || `#${log?.buyerCompany?.id}`
    name = log?.buyerCompany?.representativeUser?.name ?? ''
    lastName = log?.buyerCompany?.representativeUser?.lastName ?? ''
    userName = log?.buyerCompany?.representativeUser?.username ?? ''
    id =
      log?.buyerCompany?.__typename !== 'HiddenCompany'
        ? `(#${log?.buyerCompany?.id})`
        : ''
  } else {
    logo = log?.sellerCompany?.avatar
    company = log?.sellerCompany?.tradeName ?? ''
    name = log?.sellerCompany?.representativeUser?.name ?? ''
    lastName = log?.sellerCompany?.representativeUser?.lastName ?? ''
    userName = log?.sellerCompany?.representativeUser?.username ?? ''
  }

  const companyCategoryID = isBuyerStatus
    ? log?.buyerCompany?.companyCategory?.id ?? ''
    : log?.sellerCompany?.companyCategory?.id ?? ''

  return {
    logo,
    company,
    name: name || lastName ? `${name} ${lastName}`.trim() : userName,
    companyCategoryID,
    id,
    time,
  }
}

export const useGetTimeDateOffer = log => {
  const langFromLs = localStorage.getItem('wwl-lng')
  let date = log.createdAt ?? ''
  let time = ''
  const parsedDate = parseISO(date)
  date = formatDate(parsedDate, 'dd MMM yyyy')
  time = formatDate(parsedDate, langFromLs === 'ru' ? 'HH:mm' : 'HH:mm:ss')
  const diff = differenceInDays(new Date(), parsedDate)

  let waitingDayText
  if (diff < 1) {
    waitingDayText = i18n.t(
      'sellWine.myOffers.modals.negotiations.waitingLessDay'
    )
  } else if (diff === 1) {
    waitingDayText = i18n.t('sellWine.myOffers.modals.negotiations.waitingDay')
  } else if (diff > 1) {
    waitingDayText = i18n.t(
      'sellWine.myOffers.modals.negotiations.waitingMoreDay',
      { diff }
    )
  }

  return { date, time, waitingDayText }
}
export const useGetChanges = ({ log, prevLog }) => {
  const prevStatus = prevLog.wineOfferStatus?.code ?? ''
  const currentStatus = log.wineOfferStatus?.code ?? ''

  const prevCurrency = prevLog?.currency?.code
  const currentCurrency = log?.currency?.code

  const prevBottleQuantity = prevLog?.lot?.bottleQuantity ?? 1
  const currentBottleQuantity = log?.lot?.bottleQuantity ?? 1
  const prevPricePerBottle =
    prevStatus === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? prevLog?.buyerPrice / prevBottleQuantity
      : prevLog?.price / prevBottleQuantity

  const currentPricePerBottle =
    currentStatus === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? log?.buyerPrice / currentBottleQuantity
      : log?.price / currentBottleQuantity

  const prevSellerDeliverySize = prevLog?.minimumDeliverySize ?? ''
  const prevBuyerDeliverySize =
    prevLog?.buyerDeliverySize ?? prevSellerDeliverySize
  const prevDeliverySize =
    prevStatus === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? prevBuyerDeliverySize || prevSellerDeliverySize
      : prevSellerDeliverySize

  const currentSellerDeliverySize = log?.minimumDeliverySize ?? ''
  const currentBuyerDeliverySize =
    log?.buyerDeliverySize ?? currentSellerDeliverySize
  const currentDeliverySize =
    currentStatus === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? currentBuyerDeliverySize || currentSellerDeliverySize
      : currentSellerDeliverySize

  const prevWarehouse = prevLog?.warehouse?.address ?? ''
  const prevWarehouseCountryCode = prevLog?.warehouse?.country?.codeISO ?? ''
  const prevWarehouseId = prevLog?.warehouse?.id ?? ''
  const prevSellerIncoterms = prevLog?.incoterms?.code ?? ''
  const prevBuyerIncoterms =
    prevLog?.buyerIncoterms?.code ?? prevSellerIncoterms
  const prevIncotermsCode =
    prevStatus === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? prevBuyerIncoterms || prevSellerIncoterms
      : prevSellerIncoterms

  const currentWarehouse = log?.warehouse?.address ?? ''
  const currentWarehouseCountryCode = log?.warehouse?.country?.codeISO ?? ''
  const currentWarehouseId = log?.warehouse?.id ?? ''
  const currentSellerIncoterms = log?.incoterms?.code ?? ''
  const currentBuyerIncoterms =
    log?.buyerIncoterms?.code ?? currentSellerIncoterms
  const currentIncotermsCode =
    currentStatus === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? currentBuyerIncoterms || currentSellerIncoterms
      : currentSellerIncoterms

  const prevSellerPperiodOfValidity = prevLog?.periodOfValidity ?? ''
  const prevBuyerPeriodOfValidity =
    prevLog?.buyerPeriodOfValidity ?? prevSellerPperiodOfValidity
  const prevPeriodOfValidity =
    prevStatus === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? formatDate(
          new Date(prevBuyerPeriodOfValidity || prevSellerPperiodOfValidity),
          'dd MMM yyyy'
        )
      : formatDate(new Date(prevSellerPperiodOfValidity), 'dd MMM yyyy')

  const currentSellerPperiodOfValidity = log?.periodOfValidity ?? ''
  const currentBuyerPeriodOfValidity =
    log?.buyerPeriodOfValidity ?? currentSellerPperiodOfValidity
  const currentPeriodOfValidity =
    currentStatus === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? formatDate(
          new Date(
            currentBuyerPeriodOfValidity || currentSellerPperiodOfValidity
          ),
          'dd MMM yyyy'
        )
      : formatDate(new Date(currentSellerPperiodOfValidity), 'dd MMM yyyy')

  return {
    prevWarehouseCountryCode,
    currentWarehouseCountryCode,
    prevWarehouse,
    currentWarehouse,
    prevWarehouseId,
    currentWarehouseId,
    prevCurrency,
    currentCurrency,
    prevPricePerBottle,
    currentPricePerBottle,
    prevDeliverySize,
    currentDeliverySize,
    prevIncotermsCode,
    currentIncotermsCode,
    prevPeriodOfValidity,
    currentPeriodOfValidity,
  }
}

export const useGetFinalChanges = ({ initialLog, lastLog }) => {
  const status = lastLog.wineOfferStatus?.code ?? ''

  const initialBottleQuantity = initialLog.lot?.bottleQuantity ?? 1
  const initialPrice = initialLog.price / initialBottleQuantity
  const roundedInitialPrice = roundPrice(initialPrice || 0)

  const currentBottleQuantity = lastLog?.lot?.bottleQuantity ?? 1
  const currentPrice =
    status === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? lastLog?.buyerPrice / currentBottleQuantity
      : lastLog?.price / currentBottleQuantity
  const roundedCurrentPrice = roundPrice(currentPrice || 0)

  const initialCurrency = initialLog.currency?.code
  const lastCurrency = lastLog?.currency?.code

  const initialMinimumDeliverySize = initialLog.minimumDeliverySize ?? ''
  const sellerMinimumDeliverySize = lastLog?.minimumDeliverySize ?? ''
  const buyerDeliverySize =
    lastLog?.buyerDeliverySize ?? sellerMinimumDeliverySize
  const currentDeliverySize =
    status === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? buyerDeliverySize || sellerMinimumDeliverySize
      : sellerMinimumDeliverySize

  const initialWarehouse = initialLog.warehouse?.address ?? ''
  const initialWarehouseCountryCode =
    initialLog.warehouse?.country?.codeISO ?? ''
  const initialWarehouseId = initialLog.warehouse?.id ?? ''
  const initialIncoterms = initialLog.incoterms?.code ?? ''
  const sellerIncoterms = lastLog?.incoterms?.code ?? ''
  const buyerIncoterms = lastLog?.buyerIncoterms?.code ?? sellerIncoterms
  const currentIncotermsCode =
    status === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? buyerIncoterms || sellerIncoterms
      : sellerIncoterms

  const currentWarehouse = lastLog?.warehouse?.address ?? ''
  const currentWarehouseCountryCode = lastLog?.warehouse?.country?.codeISO ?? ''
  const currentWarehouseId = lastLog?.warehouse?.id ?? ''

  const initialPeriodOfValidity = formatDate(
    new Date(initialLog.periodOfValidity ?? ''),
    'dd MMM yyyy'
  )
  const sellerPeriodOfValidity = lastLog?.periodOfValidity ?? ''
  const buyerPeriodOfValidity =
    lastLog?.buyerPeriodOfValidity ?? sellerPeriodOfValidity
  const currentPeriodOfValidity =
    status === wineOfferStatus[BUYER_CONDITIONS_STATUS]
      ? formatDate(
          new Date(buyerPeriodOfValidity || sellerPeriodOfValidity),
          'dd MMM yyyy'
        )
      : formatDate(new Date(sellerPeriodOfValidity), 'dd MMM yyyy')

  return {
    roundedInitialPrice,
    roundedCurrentPrice,
    initialCurrency,
    lastCurrency,
    initialMinimumDeliverySize,
    currentDeliverySize,
    initialIncoterms,
    initialWarehouse,
    initialWarehouseCountryCode,
    currentIncotermsCode,
    currentWarehouse,
    currentWarehouseCountryCode,
    initialPeriodOfValidity,
    currentPeriodOfValidity,
    initialWarehouseId,
    currentWarehouseId,
  }
}

const lastValueColors = {
  ACCEPTED_STATUS: 'var(--color-green)',
  BUYER_DECLINED_STATUS: 'var(--color-red)',
  SELLER_DECLINED_STATUS: 'var(--color-red)',
  EXPIRED_STATUS: 'var(--color-orange)',
}

export const getLastValueColor = status => {
  return lastValueColors[status] ?? 'var(--color-night)'
}

export const prepareToLogView = history => {
  let isNewMessageFlagApplied = false
  return history
    .filter(item => item?.wineOfferStatus?.code !== ARCHIVED_STATUS)
    .map((item, idx) => {
      const prev = history[idx - 1]
      const creationDate = new Date(item.createdAt)
      const showNewMessageBadge =
        !isNewMessageFlagApplied && item.hasRead === false
      if (showNewMessageBadge) {
        isNewMessageFlagApplied = true
      }
      return {
        ...item,
        prev: prev || item.baseWineOffer,
        dateId: format(creationDate, 'dd-MM-yyyy'),
        yearId: format(creationDate, 'yyyy'),
        showNewMessageBadge,
      }
    })
}

export const useGetDiff = changes => {
  const diff: any[] = []
  const { t } = useTranslation()
  const {
    prevWarehouseCountryCode,
    currentWarehouseCountryCode,
    prevWarehouse,
    currentWarehouse,
    prevWarehouseId,
    currentWarehouseId,
    prevCurrency,
    currentCurrency,
    prevPricePerBottle,
    currentPricePerBottle,
    prevDeliverySize,
    currentDeliverySize,
    prevIncotermsCode,
    currentIncotermsCode,
    prevPeriodOfValidity,
    currentPeriodOfValidity,
  } = changes

  if (
    prevPricePerBottle !== currentPricePerBottle ||
    prevCurrency !== currentCurrency
  ) {
    diff.push([
      [
        <Price
          key='prevPrice'
          value={prevPricePerBottle}
          currencyCode={prevCurrency}
        />,
      ],
      [
        <Price
          key='currentPrice'
          value={currentPricePerBottle}
          currencyCode={currentCurrency}
        />,
      ],
    ])
  }
  if (prevDeliverySize !== currentDeliverySize) {
    diff.push([
      [
        t('buyWine.offers.modals.negotiations.bottlesQuantity', {
          bottleQuantity: prevDeliverySize,
        }),
      ],
      [
        t('buyWine.offers.modals.negotiations.bottlesQuantity', {
          bottleQuantity: currentDeliverySize,
        }),
      ],
    ])
  }
  if (
    prevIncotermsCode !== currentIncotermsCode ||
    prevWarehouseCountryCode !== currentWarehouseCountryCode ||
    prevWarehouseId !== currentWarehouseId
  ) {
    diff.push([
      [
        <WarehouseWrapper key={1}>
          {prevIncotermsCode}{' '}
          {prevIncotermsCode === 'EXW' && prevWarehouseCountryCode}
          {prevIncotermsCode === 'EXW' && prevWarehouse && (
            <HelpTipWrapper>
              <HelpTip offset={16} width={192} placement='bottom'>
                {prevWarehouse}
              </HelpTip>
            </HelpTipWrapper>
          )}
        </WarehouseWrapper>,
      ],
      [
        <WarehouseWrapper key={2}>
          {currentIncotermsCode}{' '}
          {currentIncotermsCode === 'EXW' && currentWarehouseCountryCode}
          {currentIncotermsCode === 'EXW' && currentWarehouse && (
            <HelpTipWrapper>
              <HelpTip offset={16} width={192} placement='bottom'>
                {currentWarehouse}
              </HelpTip>
            </HelpTipWrapper>
          )}
        </WarehouseWrapper>,
      ],
    ])
  }
  if (prevPeriodOfValidity !== currentPeriodOfValidity) {
    diff.push([[prevPeriodOfValidity], [currentPeriodOfValidity]])
  }
  return diff
}
