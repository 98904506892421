export const ls = {
  setItem: function (key: string, value: string) {
    localStorage[key] = value
    return localStorage[key]
  },

  getItem: function (key: string) {
    return localStorage[key] || undefined
  },

  setObject: function (key: string, value: object) {
    localStorage[key] = JSON.stringify(value)
    return localStorage[key]
  },

  getObject: function (key: string) {
    const v = this.getItem(key)
    try {
      return JSON.parse(v)
    } catch {
      return undefined
    }
  },

  editObject: function (key: string, value: object) {
    const v = this.getObject(key)
    if (v) {
      const obj = Object.assign(v, value)
      localStorage[key] = JSON.stringify(obj)
      return localStorage[key]
    } else {
      return undefined
    }
  },

  removeItem: function (key: string) {
    return localStorage.removeItem(key)
  },

  clear: function () {
    return localStorage.clear()
  },
} as const
