import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  Observable,
  split,
} from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient as createWsClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import DebounceLink from 'apollo-link-debounce'
import { getAuthToken, getRefreshToken, setTokens } from 'utils/token'
import { fetchNewTokens } from './refreshTokens'
import { handleAuthError } from './handleAuthError'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'

if (process.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const API_URL = process.env.API_URL || ''
const WS_URL = process.env.WS_URL || ''

const httpLink = createHttpLink({
  uri: API_URL,
})

export const wsLink = new GraphQLWsLink(
  createWsClient({
    url: WS_URL,
    lazy: true,
    keepAlive: 10_000,
    shouldRetry: () => true,
    connectionParams: () => {
      const token = getAuthToken()
      if (!token) return {}
      return { authorization: `Bearer ${token}` }
    },
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const withToken = setContext(async (_, { headers }) => {
  const token = getAuthToken()
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

let tokenRefreshing: Promise<void> | null = null

const resetToken = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    const errorCode = graphQLErrors?.[0]?.extensions?.code
    switch (errorCode) {
      case 'TOKEN_EXPIRED': {
        const t_r = getRefreshToken()
        if (t_r) {
          return new Observable(observer => {
            let promise: Promise<void> | null
            if (tokenRefreshing) {
              promise = tokenRefreshing
            } else {
              promise = tokenRefreshing = fetchNewTokens().then(
                ({ token, refreshToken }) => {
                  if (token && refreshToken) {
                    setTokens(token, refreshToken)
                  } else {
                    handleAuthError()
                  }
                  tokenRefreshing = null
                }
              )
            }
            promise
              .then(() => {
                const token = getAuthToken()
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    Authorization: token ? `Bearer ${token}` : '',
                  },
                }))
              })
              .then(() => {
                const s = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                }
                forward(operation).subscribe(s)
              })
              .catch(error => {
                observer.error(error)
              })
          })
        }
        break
      }

      case 'INVALID_TOKEN':
      case 'TOKEN_NOT_ACTIVE':
      case 'ACCESS_DENIED':
        if (operation.getContext().ignoreAccessErrors) break
        handleAuthError()
        break
    }
  }
})

const DEFAULT_DEBOUNCE_TIMEOUT = 500
const debounceLink = new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT)

const link = debounceLink
  .concat(withToken)
  .concat(removeTypenameFromVariables())
  .concat(resetToken)
  .concat(splitLink)

export const configureApollo = () => {
  return new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          fields: {
            notificationSettings: {
              merge: true,
            },
          },
        },
        Company: {
          fields: {
            notificationSettings: {
              merge: true,
            },
            avatar: {
              merge: true,
            },
          },
        },
        UserNotificationSettings: {
          keyFields: [],
        },
        Image: {
          merge: false,
        },
        AdminQueries: {
          merge: false,
        },
      },
      possibleTypes: {
        WineOfferBase: [
          'WineOfferPublic',
          'WineOfferPersonal',
          'WineOfferCompany',
          'WineOfferPublicDraft',
          'WineOfferCompanyDraft',
          'WineOfferDeal',
        ],
      },
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}
