import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/Common/MasterComponents'
import { Block, Error } from '../../Common/_styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Email = props => {
  const { t } = useTranslation()
  const { value, handleChange, handleBlur, error } = props

  return (
    <Wrapper>
      <Block>
        <Input
          type='email'
          id='email'
          label={t('login.email')}
          maxLength={50}
          spellCheck='false'
          value={value}
          placeholder=' '
          onChange={handleChange}
          hasError={!!error}
          onBlur={handleBlur}
        />
      </Block>
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

export default Email
