const Declined = () => (
  <svg
    width='100'
    height='100'
    viewBox='0 0 100 100'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.499512 50C0.499512 22.6756 22.6751 0.5 49.9995 0.5C77.3239 0.5 99.4995 22.6756 99.4995 50C99.4995 77.3244 77.3239 99.5 49.9995 99.5C22.6751 99.5 0.499512 77.3244 0.499512 50ZM49.9995 3.5C24.3319 3.5 3.49951 24.3324 3.49951 50C3.49951 75.6676 24.3319 96.5 49.9995 96.5C75.6671 96.5 96.4995 75.6676 96.4995 50C96.4995 24.3324 75.6671 3.5 49.9995 3.5ZM30.5715 30.572C33.3346 27.809 37.8014 27.809 40.5645 30.572L49.9993 40.0068L59.4341 30.572C62.1971 27.809 66.664 27.809 69.427 30.572C72.19 33.3351 72.19 37.8019 69.427 40.565L59.9922 49.9998L69.427 59.4346C72.19 62.1976 72.19 66.6644 69.427 69.4275C66.664 72.1905 62.1971 72.1905 59.4341 69.4275L49.9993 59.9927L40.5645 69.4275C37.8014 72.1905 33.3346 72.1905 30.5715 69.4275C27.8085 66.6644 27.8085 62.1976 30.5715 59.4346L40.0063 49.9998L30.5715 40.565C27.8085 37.8019 27.8085 33.3351 30.5715 30.572ZM38.4431 32.6934C36.8517 31.1019 34.2843 31.1019 32.6929 32.6934C31.1014 34.2848 31.1014 36.8522 32.6929 38.4436L44.249 49.9998L32.6929 61.5559C31.1014 63.1473 31.1014 65.7147 32.6929 67.3062C34.2843 68.8976 36.8517 68.8976 38.4431 67.3062L49.9993 55.75L61.5554 67.3062C63.1468 68.8976 65.7142 68.8976 67.3057 67.3062C68.8971 65.7147 68.8971 63.1473 67.3057 61.5559L55.7495 49.9998L67.3057 38.4436C68.8971 36.8522 68.8971 34.2848 67.3057 32.6934C65.7142 31.1019 63.1468 31.1019 61.5554 32.6934L49.9993 44.2495L38.4431 32.6934Z'
      fill='#D00A00'
    />
  </svg>
)

export default Declined
