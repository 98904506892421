import { createSlice } from '@reduxjs/toolkit'
import type { PageInfo } from 'api/schemas'
import type { PotentialProducersState } from './types'

const initialState: PotentialProducersState = {
  pageInfo: {},
  items: [],
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  filter: {
    type: null,
    value: null,
  },
}

const potentialProducersSlice = createSlice({
  name: 'potentialProducers',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }: { payload: Partial<PageInfo> }) => {
      state.pageInfo = payload
    },
    setSortingValue: (state, { payload }) => {
      state.sorting = payload
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    resetSorting: state => {
      state.sorting = initialState.sorting
    },
    reset: () => {
      return { ...initialState }
    },
  },
})

export const { actions, reducer: potentialProducers } = potentialProducersSlice
export const { setItems, setPageInfo, setSortingValue, resetSorting, reset } =
  actions
