import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import { PROHIBITED } from 'utils/consts/companyStatus'
import { useVerifyDataQuery } from 'api/hooks'
import AlertAccountNotVerified from '../AlertAccountNotVerified'
import AlertVerificationProhibited from '../AlertVerificationProhibited'
import { ls } from 'utils/my-localstorage'
import PersonCategories, { Roles } from 'utils/consts/userRoles'
import { getPersonCategory } from 'utils/company'
import {
  CREATE_BRAND_PATH,
  CREATE_PRODUCER_PATH,
  CREATE_WINE_PATH,
  DATABASE_PATH,
  EDIT_BRAND_PATH,
  EDIT_PRODUCER_PATH,
  EDIT_WINE_PATH,
  HOMEPAGE_PATH,
  MY_COMPANY_PATH,
  VIEW_BRAND_PATH,
  VIEW_PRODUCER_PATH,
  VIEW_WINE_PATH,
  VIEW_WINE_SLUG_PATH,
} from 'utils/consts/path'

const VISIBLE_ON_ROUTES = [
  EDIT_PRODUCER_PATH,
  EDIT_BRAND_PATH,
  EDIT_WINE_PATH,
  CREATE_WINE_PATH,
  VIEW_BRAND_PATH,
  VIEW_PRODUCER_PATH,
  CREATE_PRODUCER_PATH,
  MY_COMPANY_PATH,
  CREATE_BRAND_PATH,
  DATABASE_PATH,
  VIEW_WINE_PATH,
  VIEW_WINE_SLUG_PATH,
  HOMEPAGE_PATH,
]

const VerifyAlert = () => {
  const [isRouteOk, setIsRouteOk] = useState(false)
  const companyId = useSelector(state => state?.me?.selectedCompanyId)
  const { data } = useVerifyDataQuery({
    variables: { id: companyId },
    skip: !companyId || companyId === '0',
  })
  const location = useLocation()
  const company = data?.me?.company
  const showVerificationAlert = company && !company?.isVerifiedUser
  const showProhibitedAlert = company?.verificationStatus?.code === PROHIBITED

  useEffect(() => {
    const routeMath = VISIBLE_ON_ROUTES.some(route =>
      matchPath(location.pathname, route)
    )
    setIsRouteOk(routeMath)
  }, [location.pathname])

  const role = ls.getItem('wwl-role_code') || Roles.PERSON
  const category = getPersonCategory()
  const isCompany =
    role === Roles.PERSON && category !== PersonCategories.PERSON

  if (!isRouteOk || !isCompany) return null

  return (
    (showVerificationAlert && <AlertAccountNotVerified />) ||
    (showProhibitedAlert && <AlertVerificationProhibited />) ||
    null
  )
}

export default VerifyAlert
