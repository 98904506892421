import { FC, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import SelectedCompany from './SelectedCompany'
import CompaniesList from './CompaniesList'
import VerifyNotification from '../../Sidebar/Company/VerifyNotification'
import { getCompaniesList } from 'store/me'
import { useChangeCompany } from 'utils/hooks/useSelectedCompany'
import { HOMEPAGE_PATH } from 'utils/consts/path'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid var(--grey-80, #41435b);
`

interface CompanySelectorProps {
  onClose: () => void
  onVerify: () => void
}

const CompanySelector: FC<CompanySelectorProps> = ({ onClose, onVerify }) => {
  const items = useSelector(getCompaniesList)

  const [opened, setOpened] = useState(false)

  const changeCompany = useChangeCompany()
  const history = useHistory()
  const handleSelectCompany = (company: any) => {
    setOpened(false)
    changeCompany(company)
    onClose()
    history.push(HOMEPAGE_PATH)
  }

  if (!items || items.length === 0) return null

  return (
    <Wrapper>
      <SelectedCompany onToggle={() => setOpened(prev => !prev)} />

      {opened && (
        <CompaniesList
          items={items}
          onSelectCompany={handleSelectCompany}
          onClose={onClose}
        />
      )}

      <VerifyNotification onVerify={onVerify} />
    </Wrapper>
  )
}

export default CompanySelector
