import Cookies from 'components/Common/Cookies'
import { lazy, Suspense } from 'react'
import { Right } from './_styles'
import { MainContainer, MainWrapper } from '../ForgotPassword/Common/_styles'

const Left = lazy(() => import(/* webpackChunkName: "signup" */ './Left'))

const Signup = () => {
  const cookiePolicy = localStorage.getItem('wwl-cookie_policy') || false

  return (
    <MainWrapper>
      {!cookiePolicy && <Cookies />}
      <MainContainer>
        <Suspense fallback={null}>
          <Left />
        </Suspense>
        <Right />
      </MainContainer>
    </MainWrapper>
  )
}

export default Signup
