import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { TutorialData } from 'components/Common/Menu'
import CrossIcon from '../Icons/CrossIcon'
import TutorialFooter from './TutorialFooter'
import TutorialContent from './TutorialContent'
import { useCompleteTutorial } from './_methods'
import {
  Wrapper,
  Veil,
  Modal,
  TutorialHeader,
  TutorialHeaderLeft,
  TutorialHeaderRight,
} from './_styles'

interface ITutorialDialog {
  initialStep?: number
  tutorial: TutorialData
  onClose: (step: 'done' | number) => void
}

const TutorialDialog = (props: ITutorialDialog) => {
  const { initialStep, tutorial, onClose } = props

  const { t } = useTranslation()
  const [step, setStep] = useState(initialStep ?? 1)
  const element = document.getElementById('modal')
  if (!element) {
    if (process.env.NODE_ENV === 'production') return null
    else throw new Error('The element #portal wasn`t found')
  }

  const totalSteps = tutorial.steps.length

  const handlePrevStep = () => setStep(prev => (prev > 1 ? prev - 1 : 1))
  const handleNextStep = () =>
    setStep(prev => (prev < totalSteps ? prev + 1 : totalSteps))

  const { completeTutorial } = useCompleteTutorial()
  const handleFinish = () => {
    completeTutorial(tutorial.page)
    onClose('done')
  }

  return createPortal(
    <Wrapper>
      <Veil onClick={() => onClose(step)} />
      <Modal>
        <TutorialHeader>
          <TutorialHeaderLeft>{t('tutorial.title')}</TutorialHeaderLeft>
          <TutorialHeaderRight onClick={() => onClose(step)}>
            <CrossIcon />
          </TutorialHeaderRight>
        </TutorialHeader>
        <TutorialContent step={tutorial.steps[step - 1]} />
        <TutorialFooter
          step={step}
          totalSteps={totalSteps}
          onPrevStep={handlePrevStep}
          onNextStep={handleNextStep}
          onFinish={handleFinish}
        />
      </Modal>
    </Wrapper>,
    element
  )
}

export default TutorialDialog
