import { FC, useEffect, useRef, useState } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import UserAvatar from './UserAvatar'
import { getUserData } from 'store/me'
import UserDetails from './UserDetails'
import UserInfo from './UserInfo'
import { useLogoutModal } from '../../_methods'

import { menuItemStyles, TooltipWrapper } from '../_styles'

const Wrapper = styled.div`
  display: flex;
  padding: 8px;
  padding-top: 7px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid var(--grey-80, #41435b);
  background: var(--grey-100, #121432);
  position: relative;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  align-self: stretch;
  padding: 8px;
  user-select: none;
  ${menuItemStyles()};
`

interface UserProps {
  collapsed: boolean
}

const User: FC<UserProps> = ({ collapsed }) => {
  const data = useSelector(getUserData)

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'right-start',
      offset: [0, 16],
    })

  const [opened, setOpened] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const listener = (event: any) => {
      if (wrapperRef.current && wrapperRef.current.contains(event.target))
        return
      setOpened(false)
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  const { node: logoutModal, showLogoutModal } = useLogoutModal()

  return (
    <Wrapper ref={wrapperRef}>
      <Content ref={setTriggerRef} onClick={() => setOpened(prev => !prev)}>
        <UserAvatar avatar={data?.avatar} />
        {!collapsed && <UserInfo data={data} />}
      </Content>

      {collapsed && visible && (
        <TooltipWrapper ref={setTooltipRef} {...getTooltipProps()}>
          <UserInfo data={data} />
        </TooltipWrapper>
      )}

      {opened && (
        <UserDetails
          data={data}
          onClose={() => setOpened(false)}
          onLogout={showLogoutModal}
        />
      )}

      {logoutModal}
    </Wrapper>
  )
}

export default User
