import { useEffect, useMemo, useState } from 'react'
import ScreenContext from '../Context'
import breakpoint from 'utils/consts/breakpoints'

const ScreenProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const onSizeChange = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', onSizeChange)
    return () => window.removeEventListener('resize', onSizeChange)
  }, [])

  const value = useMemo(() => {
    const container = {
      s: screenWidth < breakpoint.container.m,
      m:
        screenWidth >= breakpoint.container.m &&
        screenWidth < breakpoint.container.l,
      l:
        screenWidth >= breakpoint.container.l &&
        screenWidth < breakpoint.container.xl,
      xl: screenWidth >= breakpoint.container.xl,
    }
    return {
      isMobile: screenWidth <= breakpoint.size.sm,
      isTablet:
        screenWidth > breakpoint.size.sm && screenWidth <= breakpoint.size.lg,
      isDesktop: screenWidth > breakpoint.size.lg,
      size: screenWidth,
      container,
      currentContainer: Object.keys(container).find(
        containerName => container[containerName]
      ),
    }
  }, [screenWidth])

  return (
    <ScreenContext.Provider value={value}>{children}</ScreenContext.Provider>
  )
}

export default ScreenProvider
