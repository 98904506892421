import { useUserTutorialCompletedQuery } from 'api/hooks'
import { ls } from 'utils/my-localstorage'

const LS_KEY = 'wwl-tutorial-open'

export const loadState = (page: string) => {
  const state = ls.getObject(LS_KEY)
  return state?.[page]
}

export const getClosedStep = (page: string) => {
  const state = loadState(page)
  return typeof state === 'number' ? state : 1
}

export const saveState = (page: string, value: any) => {
  let state = ls.getObject(LS_KEY)
  if (typeof state !== 'object') state = {}
  ls.setObject(LS_KEY, { ...state, [page]: value })
}

type CheckCallback = (completed: boolean) => void
export const useCheckTutorialCompleted = (page: string, cb: CheckCallback) => {
  useUserTutorialCompletedQuery({
    variables: { page },
    onCompleted(data) {
      cb(data?.me?.tutorialCompleted ?? false)
    },
  })
}
