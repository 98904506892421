import { FC, ReactNode } from 'react'
import { LinkFakeItem, MenuItemWrapper } from '../../_styles'

interface MenuFakeItemProps {
  children: ReactNode
  sub?: boolean
}

const MenuFakeItem: FC<MenuFakeItemProps> = ({ children, sub }) => {
  return (
    <MenuItemWrapper $sub={sub}>
      <LinkFakeItem $disabled>{children}</LinkFakeItem>
    </MenuItemWrapper>
  )
}

export default MenuFakeItem
