import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { TourTask } from 'store/me/types'
import {
  getTaskStepTotal,
  links,
  titles,
} from '../../../../Sidebar/GettingStarted/_methods'

const Wrapper = styled.li<{ $completed?: boolean }>`
  display: flex;
  padding: 12px;
  margin: 0;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%
    ),
    #121432;
`

const contentStyles = css`
  display: flex;
  align-items: center;
  gap: 12px;
`

const ContentSpan = styled.span`
  ${contentStyles};
`

const ContentLink = styled(Link)`
  ${contentStyles};
  text-decoration: none;
`

const ItemText = styled.div<{ $completed?: boolean }>`
  flex: 1;
  font: var(--typography-13-20-medium);
  color: ${props => (props.$completed ? '#A0A1AD' : '#e7e7ea')};
  text-decoration-line: ${props =>
    props.$completed ? 'line-through' : 'none'};
`

interface ItemProps {
  item: TourTask
  onClose: () => void
}

const Item: FC<ItemProps> = ({ item, onClose }) => {
  const { t } = useTranslation()

  if (item.completed)
    return (
      <Wrapper key={item.task} $completed>
        <ContentSpan>
          <IconCheck completed />
          <ItemText $completed>
            {t(titles[item.task]) + getTaskStepTotal(item)}
          </ItemText>
        </ContentSpan>
      </Wrapper>
    )

  return (
    <Wrapper key={item.task}>
      <ContentLink to={links[item.task]} onClick={onClose}>
        <IconCheck />
        <ItemText>{t(titles[item.task]) + getTaskStepTotal(item)}</ItemText>
        <IconChevronRight />
      </ContentLink>
    </Wrapper>
  )
}

// prettier-ignore
const IconCheck = ({ completed }: { completed?: boolean }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 24 24' fill='none'>
    <path
      fill={completed ? '#00A042' : '#41435B'}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm14.7-1.8-5 5a1 1 0 0 1-1.4 0l-3.5-3.5 1.4-1.4 2.8 2.79 4.3-4.3 1.4 1.42Z'
    />
  </svg>
)

// prettier-ignore
const IconChevronRight = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' fill='none' viewBox='0 0 20 20'>
    <path
      fill='#E7E7EA'
      fillRule='evenodd'
      d='M13.09 9.41c.32.33.32.85 0 1.18l-5 5A.83.83 0 0 1 6.9 14.4L11.32 10l-4.4-4.41A.83.83 0 0 1 8.08 4.4l5 5Z'
      clipRule='evenodd'
    />
  </svg>
)

export default Item
