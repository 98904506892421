import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import UserAvatar from '../UserAvatar'
import UserInfo from '../UserInfo'
import {
  CompaniesIcon,
  LogoutIcon,
  NotificationsIcon,
  SettingsIcon,
  UserIcon,
} from './Icons'

import { menuItemStyles } from '../../_styles'
import { Roles } from 'utils/consts/userRoles'
import { getUserRole } from 'store/me'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: var(--z-index-tooltip);
  background: var(--grey-100, #121432);
`
const Top = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%
    ),
    #121432;
`

const Body = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
`

const ListItemStyles = css`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 12px;
  padding: 12px;
  ${menuItemStyles()};
  font: var(--typography-13-20-medium);
`

const ListItemLink = styled(Link)`
  text-decoration: none;
  ${ListItemStyles}
`

const ListItemButton = styled.button`
  all: unset;
  cursor: pointer;
  ${ListItemStyles}
`

const Divider = styled.div`
  height: 4px;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  gap: 24px;
  align-items: center;
  height: 48px;
  width: 100%;
  color: var(--grey-10, #e7e7ea);
  text-align: center;
  font: var(--typography-16-24-semibold);
  border-bottom: 1px solid var(--grey-80, #41435b);
  background: var(--grey-100, #121432);
`

const BackButton = styled.button`
  all: unset;
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: stretch;
  align-self: stretch;
  padding: 16px;
`

interface UserDetailsProps {
  data: any
  onClose: () => void
  onSelect: () => void
  onLogout: () => void
}

const UserDetails: FC<UserDetailsProps> = props => {
  const { data, onClose, onSelect, onLogout } = props

  const { t } = useTranslation()
  const role = useSelector(getUserRole)

  const id = data?.id

  return (
    <Wrapper>
      <Header>
        <BackButton type='button' onClick={onClose}>
          <BackIcon />
        </BackButton>
        {t('common.menu.user.title')}
      </Header>

      <Content>
        <Top>
          <UserAvatar avatar={data.avatar64} size={64} />
          <UserInfo data={data} align='center' />
        </Top>

        <Body onClick={onSelect}>
          <ListItemLink to={`/acc/user/${id}/profile`}>
            <UserIcon />
            {t('common.menu.user.profile')}
          </ListItemLink>

          {role === Roles.PERSON && (
            <ListItemLink to={`/acc/user/${id}/companies`}>
              <CompaniesIcon />
              {t('common.menu.user.companies')}
            </ListItemLink>
          )}

          <ListItemLink to={`/acc/user/${id}/notification`}>
            <NotificationsIcon />
            {t('common.menu.user.notifications')}
          </ListItemLink>

          <ListItemLink to={`/acc/user/${id}/settings`}>
            <SettingsIcon />
            {t('common.menu.user.settings')}
          </ListItemLink>

          <Divider />

          <ListItemButton onClick={onLogout}>
            <LogoutIcon />
            {t('common.menu.logoutTip')}
          </ListItemButton>
        </Body>
      </Content>
    </Wrapper>
  )
}

// prettier-ignore
const BackIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path fill='#fff' fillRule='evenodd'
          d='M15.7 5.3a1 1 0 0 1 0 1.4L10.42 12l5.3 5.3a1 1 0 0 1-1.42 1.4l-6-6a1 1 0 0 1 0-1.4l6-6a1 1 0 0 1 1.42 0Z'
          clipRule='evenodd' />
  </svg>
)

export default UserDetails
