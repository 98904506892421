import { createSlice } from '@reduxjs/toolkit'
import { DictionaryState } from './types'

const initialState: DictionaryState = {
  countriesList: [],
}

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {
    setCountriesList: (state, { payload }) => {
      state.countriesList = payload
    },
    clearState: () => {
      return initialState
    },
  },
})

export const { actions, reducer: dictionary } = dictionarySlice
