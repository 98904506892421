import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

type ButtonSize = 'L' | 'M' | 'S'
type ButtonType = 'primary' | 'secondary'
type ButtonVariant = 'text' | 'flexible'

const sizes = {
  L: '48px',
  M: '32px',
  S: '24px',
}

const paddings = {
  L: '15px',
  M: '7px 15px',
  S: '3px 7px',
}

const paddingsText = {
  L: '16px 0',
  M: '8px 0',
  S: '4px 0',
}

const borderRadiuses = {
  L: '8px',
  M: '4px',
  S: '4px',
}

const fontSizes = {
  L: '14px',
  M: '12px',
  S: '10px',
}

const gaps = {
  L: '12px',
  M: '8px',
  S: '8px',
}

const iconSizes = {
  L: '22px',
  M: '16px',
  S: '16px',
}

const backgrounds: Record<ButtonType, string> = {
  primary: 'var(--brand-red, #88122b)',
  secondary: 'var(--grey-white, #FFF)',
}

const backgroundsHover: Record<ButtonType, string> = {
  primary: 'var(--brand-red-hover, #5F0D1E)',
  secondary: 'var(--grey-2-sw-10, #E7E7EA)',
}

const backgroundsDisabled: Record<ButtonType, string> = {
  primary: 'var(--grey-2-sw-10, #E7E7EA)',
  secondary: 'var(--grey-white, #FFF)',
}

const colors: Record<ButtonType, string> = {
  primary: 'var(--grey-white, #FFF)',
  secondary: 'var(--grey-2-sw-60, #717284)',
}

const colorsHover: Record<ButtonType, string> = {
  primary: 'var(--grey-white, #FFF)',
  secondary: 'var(--velvet-100, #3B42A5)',
}

const colorsDisabled: Record<ButtonType, string> = {
  primary: 'var(--grey-2-sw-20, #D0D0D6)',
  secondary: 'var(--grey-2-sw-20, #D0D0D6)',
}

const colorsText: Record<ButtonType, string> = {
  primary: 'var(--Velvet-100, #3B42A5)',
  secondary: 'var(--Grey2-SW-60, #717284)',
}

const colorsTextHover: Record<ButtonType, string> = {
  primary: 'var(--Grey-100, #121432)',
  secondary: 'var(--Grey-100, #121432)',
}

const colorsTextDisabled: Record<ButtonType, string> = {
  primary: 'var(--Grey2-SW-20, #D0D0D6)',
  secondary: 'var(--Grey2-SW-20, #D0D0D6)',
}

const borders: Record<ButtonType, string> = {
  primary: '1px solid transparent',
  secondary: '1px solid var(--grey-2-sw-10, #E7E7EA)',
}

const bordersHover: Record<ButtonType, string> = {
  primary: '1px solid transparent',
  secondary: '1px solid var(--grey-2-sw-10, #E7E7EA)',
}

const bordersDisabled: Record<ButtonType, string> = {
  primary: '1px solid transparent',
  secondary: '1px solid var(--grey-2-sw-10, #E7E7EA)',
}

const defaults = {
  $variant: 'flexible',
  $size: 'M',
  $type: 'secondary',
} as const

const getPropValue =
  (prop: keyof ButtonProps, values: Record<string, any>) =>
  (props: ButtonProps) =>
    // @ts-ignore
    values[props[prop] ?? defaults[prop]]

const ButtonStyles = css<ButtonProps>`
  font-family: var(--typography-normal);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${getPropValue('$size', gaps)};
  text-align: center;
  font-weight: 600;
  line-height: 16px;
  box-sizing: border-box;
  user-select: none;
  appearance: none;
  outline: none;
  white-space: nowrap;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background: ${props => {
    if (props.$variant === 'text') return 'none'
    return backgrounds[props.$type ?? defaults.$type]
  }};
  color: ${props => {
    return props.$variant === 'text'
      ? colorsText[props.$type ?? defaults.$type]
      : colors[props.$type ?? defaults.$type]
  }};
  border: ${props => {
    if (props.$variant === 'text') return 'none'
    return borders[props.$type ?? defaults.$type]
  }};
  border-radius: ${props => {
    if (props.$variant === 'text') return 0
    return borderRadiuses[props.$size ?? defaults.$size]
  }};

  --button-size: ${getPropValue('$size', sizes)};

  min-height: var(--button-size);
  padding: ${props => {
    return props.$variant === 'text'
      ? paddingsText[props.$size ?? defaults.$size]
      : paddings[props.$size ?? defaults.$size]
  }};
  font-size: ${getPropValue('$size', fontSizes)};

  :hover {
    background: ${props => {
      if (props.$variant === 'text') return 'none'
      return backgroundsHover[props.$type ?? defaults.$type]
    }};
    color: ${props => {
      return props.$variant === 'text'
        ? colorsTextHover[props.$type ?? defaults.$type]
        : colorsHover[props.$type ?? defaults.$type]
    }};
    border: ${props => {
      if (props.$variant === 'text') return 'none'
      return bordersHover[props.$type ?? defaults.$type]
    }};
  }

  &[data-disabled],
  &[disabled],
  &:disabled {
    background: ${props => {
      if (props.$variant === 'text') return 'none'
      // @ts-ignore
      return backgroundsDisabled[props.$type ?? defaults.$type]
    }};
    color: ${props => {
      return props.$variant === 'text'
        ? colorsTextDisabled[props.$type ?? defaults.$type]
        : colorsDisabled[props.$type ?? defaults.$type]
    }};
    border: ${props => {
      if (props.$variant === 'text') return 'none'
      return bordersDisabled[props.$type ?? defaults.$type]
    }};
  }

  svg {
    transition: 0.2s;
    fill: currentColor;
    width: ${getPropValue('$size', iconSizes)};
    height: ${getPropValue('$size', iconSizes)};
  }
`

export interface ButtonProps {
  $variant?: ButtonVariant
  $size?: ButtonSize
  $type?: ButtonType
  $fakeDisabled?: boolean
  disabled?: boolean
  // $icon: boolean
  // $responsive: boolean

  children: ReactNode
}

const addDefaults = (props: ButtonProps) => {
  return {
    $variant: props.$variant || 'flexible',
    $size: props.$size || 'M',
    $type: props.$type || 'secondary',
    'data-disabled': props.$fakeDisabled || undefined,
  }
}

export const Button = styled.button.attrs<ButtonProps>(addDefaults)`
  all: unset;
  ${ButtonStyles};
`

Button.defaultProps = {
  type: 'button',
}

export const ButtonLink = styled(Link).attrs<ButtonProps>(addDefaults)`
  text-decoration: none;
  ${ButtonStyles}
`
