import { ls } from 'utils/my-localstorage'
import { find, forEachObjIndexed, propEq } from 'ramda'
import { useSelector } from 'react-redux'
import {
  COUNTRIES_LIST,
  DATE_INTERVAL,
  EMAIL_STATUS_LIST,
  filterTypes,
  getOffersFiltersCollection,
  offersFiltersKey,
  offersFiltersName,
  RHEOSTAT,
  RRP_INTERVAL,
  SEARCH_USERS,
  CHECKBOX_LIST,
  RADIO_LIST,
  MULTISELECT_SWITCHER,
  INTERVAL,
  PRODUCT_RANK,
  PRICE_INTERVAL,
  SWITCHER,
  NUMBER,
  SCORE,
} from 'utils/consts'
import { useFilterStoreData } from 'utils/hooks/useFilterStoreData'
import type { RootState } from 'store'

export const useTableFiltersInputs = (tableName = '') => {
  const { storeName, filtersStorageName } = useFilterStoreData()

  const filtersFromStorage = ls.getObject(filtersStorageName)

  const filtersFromStore = useSelector(
    (state: RootState) => state?.tableFilters?.[tableName || storeName]?.filters
  )

  const getTableFilters = filters => {
    const inputs = [] as any[]
    forEachObjIndexed((value, key) => {
      const filterFromCollection = find(propEq('filterName', key))(
        getOffersFiltersCollection(storeName)
      )
      switch (filterFromCollection?.type) {
        case filterTypes[CHECKBOX_LIST]:
        case filterTypes[RADIO_LIST]:
          if (value.length > 0 && value != -1) {
            inputs.push({
              listInput: {
                key: filterFromCollection?.apiFilterName,
                byName: value,
              },
            })
          }
          break

        case filterTypes[MULTISELECT_SWITCHER]:
          if (
            filterFromCollection?.filterName ==
              offersFiltersName?.grapesFilter &&
            (value?.list?.length > 0 || value?.switcher)
          ) {
            inputs.push({
              complexInput: {
                key: 'grapeAndMonocepage',
                childrenInputs: [
                  {
                    booleanInput: {
                      key: 'grapeAndMonocepage.monocepage',
                      value: value?.switcher,
                    },
                  },
                  {
                    listInput: {
                      key: 'grapeAndMonocepage.grape',
                      byName: value?.list,
                    },
                  },
                ],
              },
            })
          }
          break
        case filterTypes[RHEOSTAT]:
        case filterTypes[PRODUCT_RANK]:
        case filterTypes[INTERVAL]:
          inputs.push({
            rangeInput: {
              key: filterFromCollection?.apiFilterName,
              ...value,
            },
          })
          break
        case filterTypes[RRP_INTERVAL]:
        case filterTypes[PRICE_INTERVAL]: {
          const priceIntervalKey =
            storeName === 'accBuyWineAnalysisRating'
              ? offersFiltersKey().offersPriceFilter
              : offersFiltersKey().priceFilter
          inputs.push({
            complexInput: {
              key:
                filterFromCollection?.type === filterTypes[RRP_INTERVAL]
                  ? filterFromCollection?.apiFilterName
                  : priceIntervalKey,
              childrenInputs: [
                ...(value?.currency
                  ? [
                      {
                        listInput: {
                          key:
                            filterFromCollection?.type ===
                            filterTypes[RRP_INTERVAL]
                              ? `${filterFromCollection?.apiFilterName}.currency`
                              : `${priceIntervalKey}.currency`,
                          byName: value?.currency,
                        },
                      },
                    ]
                  : []),
                ...(value?.interval
                  ? [
                      {
                        rangeInput: {
                          key:
                            filterFromCollection?.type ===
                            filterTypes[RRP_INTERVAL]
                              ? `${filterFromCollection?.apiFilterName}.value`
                              : `${priceIntervalKey}.value`,
                          minValue: value?.interval?.minValue,
                          maxValue: value?.interval?.maxValue,
                        },
                      },
                    ]
                  : []),
              ],
            },
          })
          break
        }
        case filterTypes[SCORE]:
          if (value != -1) {
            inputs.push({
              listInput: {
                key: filterFromCollection?.apiFilterName,
                byName: value,
              },
            })
          }
          break
        case filterTypes[NUMBER]:
          if (filterFromCollection?.nonFacetFilter) {
            inputs.push({
              [filterFromCollection?.apiFilterName]: Number(value),
            })
            break
          }

          if (value && value > 0) {
            inputs.push({
              rangeInput: {
                key: filterFromCollection?.apiFilterName,
                minValue: Number(value),
              },
            })
          }
          break
        case filterTypes[SWITCHER]:
          if (filterFromCollection?.nonFacetFilter) {
            inputs.push({
              [filterFromCollection?.apiFilterName]: value,
            })
            break
          }
          inputs.push({
            booleanInput: {
              key: filterFromCollection?.apiFilterName,
              value: value,
            },
          })
          break

        case filterTypes[DATE_INTERVAL]:
          inputs.push({
            dateRangeInput: {
              key: filterFromCollection?.apiFilterName,
              fromDate: value?.fromDate,
              toDate: value?.toDate,
            },
          })
          break

        case filterTypes[COUNTRIES_LIST]:
          inputs.push({
            countryIds: value,
          })
          break
        case filterTypes[EMAIL_STATUS_LIST]:
          inputs.push({
            emailStatuses: value,
          })
          break
        case filterTypes[SEARCH_USERS]:
          if (value.length > 0) {
            inputs.push({
              freeListInput: {
                key: filterFromCollection?.apiFilterName,
                byName: value,
              },
            })
          }
          break

        default:
          break
      }
    }, filters)
    return inputs
  }

  return { getTableFilters, filtersFromStore, filtersFromStorage }
}
