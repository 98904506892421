import styled from 'styled-components'
import BaseButton from '../BaseButton'

const Wrapper = styled(BaseButton)`
  min-width: 40px;
  min-height: 40px;
  background-color: var(--color-white);
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #e7e7ea;
  border-radius: 8px;
  transition: 0.2s;
  color: #717284;
  font: var(--typography-14-20-bold);

  svg {
    transition: 0.2s;
  }

  :focus-visible,
  :hover {
    background-color: #e7e7ea;
    color: var(--color-velvet);

    svg {
      fill: var(--color-velvet);

      path {
        transition: 0.2s;
        fill: var(--color-velvet);
      }
    }
  }
`
export const AddMoreButtonFlexable40 = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}
