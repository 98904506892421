import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TourTask } from 'store/me/types'
import Item from './Item'
import Footer from './Footer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: var(--z-index-tooltip);
  background: var(--grey-100, #121432);
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  gap: 24px;
  align-items: center;
  height: 48px;
  width: 100%;
  color: var(--grey-10, #e7e7ea);
  text-align: center;
  font: var(--typography-16-24-semibold);
  border-bottom: 1px solid var(--grey-80, #41435b);
  background: var(--grey-100, #121432);
`

const BackButton = styled.button`
  all: unset;
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
`

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 16px;
  margin: 0;
  width: 100%;
  flex: 1;
`

interface TourProps {
  tasks: TourTask[]
  onClose: () => void
}

const Tour: FC<TourProps> = ({ tasks, onClose }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Header>
        <BackButton type='button' onClick={onClose}>
          <BackIcon />
        </BackButton>
        {t('getting-started.title')}
      </Header>

      <List>
        {tasks.map(item => (
          <Item key={item.task} item={item} onClose={onClose} />
        ))}
      </List>

      <Footer onClose={onClose} />
    </Wrapper>
  )
}

// prettier-ignore
const BackIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path fill='#fff' fillRule='evenodd'
          d='M15.7 5.3a1 1 0 0 1 0 1.4L10.42 12l5.3 5.3a1 1 0 0 1-1.42 1.4l-6-6a1 1 0 0 1 0-1.4l6-6a1 1 0 0 1 1.42 0Z'
          clipRule='evenodd' />
  </svg>
)

export default Tour
