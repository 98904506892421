/*
 import styled from 'styled-components'
 import Dialog from 'components/Common/Modals/Dialog'

 const DialogDemo = ({ onClose }) => {
   return (
     <Dialog.Wrapper onClose={onClose} withMobileVariant>
       <Dialog.Main width="416px">
         <Dialog.Header title='DEMO' onClose={onClose} />
         <Dialog.Content padding='32px'>
           <Dialog.Title>Title</Dialog.Title>
           <Dialog.Message>Message</Dialog.Message>
           <Dialog.FooterNew>
             <Button $size='L' $type='secondary'>Cancel</Button>
             <Button $size='L' $type='primary'>Apply</Button>
           </Dialog.FooterNew>
         </Dialog.Content>
       </Dialog.Main>
     </Dialog.Wrapper>
   )
 }
 */

import Content from './Content'
import Footer from './Footer'
import FooterNew from './FooterNew'
import Header, { CloseButton } from './Header'
import Main from './Main'
import Message from './Message'
import Title from './Title'
import Wrapper from './Wrapper'

const Dialog = {
  Wrapper,
  Main,
  Header,
  Content,
  Title,
  Message,
  Footer,
  FooterNew,
  CloseButton,
} as const

export default Dialog
