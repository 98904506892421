import styled, { css } from 'styled-components'

type MenuItemStylesProps = {
  active?: boolean
  disabled?: boolean
}

export const menuItemStyles = ({
  active,
  disabled,
}: MenuItemStylesProps = {}) => css`
  border-radius: 8px;
  background: var(--grey-100, #121432);
  color: ${disabled
    ? 'var(--grey-70, #595A6F)'
    : active
    ? 'var(--grey-10, #E7E7EA)'
    : 'var(--grey-40, #A0A1AD)'};

  :hover {
    background: ${disabled
      ? 'var(--grey-100, #121432)'
      : 'linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #121432'};
  }

  cursor: ${disabled ? 'default' : 'pointer'};
`

export const TooltipWrapper = styled.div`
  display: flex;
  padding: 10px 12px;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background: var(--grey-100, #121432);
  z-index: var(--z-index-tooltip);
`
