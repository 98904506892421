import { useEffect } from 'react'
import { forEach, pathOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedCompanyId } from 'store/me'
import { useUnreadsSubscription } from 'api/hooks'

import {
  ACCEPTED_STATUS,
  BUYER_CONDITIONS_STATUS,
  BUYER_DECLINED_STATUS,
  EXPIRED_STATUS,
  SELLER_DECLINED_STATUS,
  SELLER_RESPONSE_STATUS,
} from 'utils/consts'

const getTabStatuses = item => {
  let active = false,
    accepted = false,
    archive = false
  switch (item?.wineOfferStatus?.code) {
    case SELLER_RESPONSE_STATUS:
    case BUYER_CONDITIONS_STATUS:
      active = true
      break
    case ACCEPTED_STATUS:
      accepted = true
      break
    case EXPIRED_STATUS:
    case BUYER_DECLINED_STATUS:
    case SELLER_DECLINED_STATUS:
      archive = true
      break
    default:
      break
  }
  return { active, accepted, archive }
}

const getNewAnswers = subscriptionData => {
  const buyerItems = pathOr(
    [],
    ['wineOffersUnreads', 'buyer', 'items'],
    subscriptionData
  )
  const sellerItems = pathOr(
    [],
    ['wineOffersUnreads', 'seller', 'items'],
    subscriptionData
  )
  let buyerHasActive = false,
    buyerHasAccepted = false,
    buyerHasArchive = false,
    sellerHasActive = false,
    sellerHasAccepted = false,
    sellerHasArchive = false

  forEach(item => {
    const { active, accepted, archive } = getTabStatuses(item)
    buyerHasActive = buyerHasActive ? buyerHasActive : active
    buyerHasAccepted = buyerHasAccepted ? buyerHasAccepted : accepted
    buyerHasArchive = buyerHasArchive ? buyerHasArchive : archive
  }, buyerItems)

  forEach(item => {
    const { active, accepted, archive } = getTabStatuses(item)
    sellerHasActive = sellerHasActive ? sellerHasActive : active
    sellerHasAccepted = sellerHasAccepted ? sellerHasAccepted : accepted
    sellerHasArchive = sellerHasArchive ? sellerHasArchive : archive
  }, sellerItems)

  return {
    buyer: {
      total: pathOr(
        0,
        ['wineOffersUnreads', 'buyer', 'total'],
        subscriptionData
      ),
      hasActive: buyerHasActive,
      hasAccepted: buyerHasAccepted,
      hasArchive: buyerHasArchive,
      sellerConditionsCount: (
        subscriptionData?.wineOffersUnreads?.buyer?.items ?? []
      ).find(item => item?.wineOfferStatus?.code === SELLER_RESPONSE_STATUS)
        ?.count,
    },
    seller: {
      total: pathOr(
        0,
        ['wineOffersUnreads', 'seller', 'total'],
        subscriptionData
      ),
      hasActive: sellerHasActive,
      hasAccepted: sellerHasAccepted,
      hasArchive: sellerHasArchive,
      buyerConditionsCount: (
        subscriptionData?.wineOffersUnreads?.seller?.items ?? []
      ).find(item => item?.wineOfferStatus?.code === BUYER_CONDITIONS_STATUS)
        ?.count,
    },
  }
}

const useUnreads = () => {
  const dispatch = useDispatch()
  const selectedCompanyId = useSelector(getSelectedCompanyId)

  const invalidCompany = !selectedCompanyId || selectedCompanyId === '0'

  useUnreadsSubscription({
    variables: { companyId: selectedCompanyId },
    skip: invalidCompany,
    context: { ignoreAccessErrors: true },
    onData({ data }) {
      const subscriptionData = data.data
      if (subscriptionData) {
        dispatch({
          type: 'negotiations/setNewAnswers',
          payload: getNewAnswers(subscriptionData),
        })
      }
    },
  })

  useEffect(() => {
    if (invalidCompany) {
      dispatch({
        type: 'negotiations/setNewAnswers',
        payload: null,
      })
    }
  }, [invalidCompany])
}

export default useUnreads
