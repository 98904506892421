import { useEffect, useState } from 'react'
import BannedModal from '../BannedModal'
import { getError } from 'utils/apollo/getError'
import useNotification from 'utils/notification'

const ErrorHandler = ({ error }) => {
  const errorCode = getError(error, 'code')

  const notify = useNotification()

  const [banModalVisible, setBanModalVisible] = useState(
    errorCode === 'AUTH_BLOCKED_ERROR'
  )

  useEffect(() => {
    if (errorCode === 'AUTH_BLOCKED_ERROR') {
      setBanModalVisible(true)
    }

    const errorMessage = getError(error, 'message')
    if (errorMessage) {
      notify({ text: errorMessage, color: '#d00a00' })
    }
  }, [errorCode])

  if (!banModalVisible) return null

  return <BannedModal closeModal={() => setBanModalVisible(false)} />
}

export default ErrorHandler
