import { useEffect } from 'react'

export const useUserBack = () => {
  if (!process.env.USERBACK_TOKEN) return

  useEffect(() => {
    window.Userback = window.Userback || {}
    window.Userback.access_token = process.env.USERBACK_TOKEN
    ;(function (d) {
      var s = d.createElement('script')
      s.async = true
      s.src = 'https://static.userback.io/widget/v1.js'
      ;(d.head || d.body).appendChild(s)
    })(document)

    return () => window?.Userback?.destroy?.()
  }, [])
}

