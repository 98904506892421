import { useEffect, useRef, useState } from 'react'
import Input from '../Input'
import Arrow from './Arrow'
import List from './List'
import { Wrapper, InputWrapper } from './_styles'

const Select = props => {
  const { list, value, onChange, ...inputProps } = props

  const wrapperRef = useRef()
  const inputRef = useRef()

  const [inputValue, setInputValue] = useState(() => {
    const item = (list || []).find(_ => _.value === value)
    return item ? item.label : ''
  })

  const [items, setItems] = useState(list)

  const [opened, setOpened] = useState(false)
  const [, setClickedOutside] = useState(false)
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])
  const handleClickInside = () => {
    setClickedOutside(false)
  }
  const handleClickOutside = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setClickedOutside(true)
      setOpened(false)
    }
  }
  const toggleOpened = () => {
    if (inputProps.disabled) return
    setOpened(!opened)

    if (!opened) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 0)
    }
  }

  const handleSelect = v => {
    setOpened(false)
    setInputValue(v.label)
    setItems(list)
    if (onChange) onChange(v.value)
  }

  const handleChangeText = e => {
    const val = e.target.value
    setInputValue(val)

    if (val.length) {
      const filteredList = (list || []).filter(item =>
        item.label.toLowerCase().includes(val.toLowerCase())
      )
      setItems(filteredList)
    } else {
      setItems(list)
    }
  }

  const handleFocus = e => {
    setOpened(true)

    if (inputProps.onFocus) {
      inputProps.onFocus(e)
    }
  }

  const handleBlur = e => {
    setOpened(false)

    if (inputProps.onBlur) {
      inputProps.onBlur(e)
    }
  }

  return (
    <Wrapper ref={wrapperRef} onClick={handleClickInside}>
      <InputWrapper opened={opened} disabled={inputProps.disabled}>
        <Input
          {...inputProps}
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          after={<Arrow toggleOpened={toggleOpened} opened={opened} />}
          value={inputValue}
          onChange={handleChangeText}
        />
      </InputWrapper>
      {opened && <List items={items} onSelect={handleSelect} />}
    </Wrapper>
  )
}

export default Select
