import styled from 'styled-components'
import ChevronDownIcon from './Icons/ChevronDown'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
  transform: ${props => (props.opened ? 'rotate(180deg)' : 'rotate(0deg)')};
`

const Arrow = props => {
  const { toggleOpened, opened } = props
  return (
    <Wrapper onMouseDown={toggleOpened} opened={opened}>
      <ChevronDownIcon />
    </Wrapper>
  )
}

export default Arrow
