import { createSlice } from '@reduxjs/toolkit'
import { GeoIndicationState } from './types'

const initialState: GeoIndicationState = {
  values: {},
  errors: {},
}

const geoIndicationSlice = createSlice({
  name: 'geoIndication',
  initialState,
  reducers: {
    setValue: (state, { payload }) => {
      state.values = {
        ...state.values,
        [payload.field]: payload.value,
      }
    },
    setError: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        [payload.field]: payload.value,
      }
    },
    init: (state, { payload }) => {
      state.values = {
        ...initialState.values,
        ...payload,
        initialName: payload?.name,
      }
      state.errors = { ...initialState.errors }
    },
    reset: () => {
      return initialState
    },
    resetPrefilledFields: state => {
      return {
        ...initialState,
        values: {
          ...initialState.values,
          ...(state.values?.country && { country: state.values?.country }),
          ...(state.values?.name && { name: state.values?.name }),
          ...(state.values?.translations && {
            translations: state.values?.translations,
          }),
        },
      }
    },
  },
})

export const { actions, reducer: geoIndication } = geoIndicationSlice
export const { setValue, setError, init, reset, resetPrefilledFields } = actions
