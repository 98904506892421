import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Arrow from 'components/Common/LanguageSwitcher/Select/Arrow'
import i18n from 'utils/i18n'
import FlagIcon from 'components/Common/FlagIcon'
import { LOCALES_TO_EXCLUDE } from 'utils/getLocaleId'

const Container = styled.div`
  position: relative;
  z-index: 2;
`
const Block = styled.div`
  position: absolute;
  top: 15px;
  right: -65px;
  width: 168px;
  background: var(--color-white);
  border-radius: 8px;
  color: black;

  @media (max-width: 1280px) {
    width: 128px;
    right: -10px;
  }
`
const List = styled.div`
  margin: 16px 8px;
`
const ItemTitle = styled.div`
  font: var(--typography-12-18-semibold);
  letter-spacing: 0.5px;
  color: ${props => (props.active ? 'var(--color-velvet)' : '#717284')};
`
const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  cursor: pointer;

  :hover {
    background: #f3f3f5;
    border-radius: 4px;
  }
  :hover ${ItemTitle} {
    color: var(--color-night);
  }
`
const ItemIcon = styled.div`
  margin: 0 8px 0 16px;
  display: flex;
  align-items: center;
  width: 24px;
  height: 17px;
`

const langs = [
  { name: 'EN', title: 'English', codeISO: 'EN', lng: 'en' },
  { name: 'FR', title: 'Français', codeISO: 'FR', lng: 'fr' },
  { name: 'ES', title: 'Español', codeISO: 'ES', lng: 'es' },
  { name: 'IT', title: 'Italiano', codeISO: 'IT', lng: 'it' },
  { name: 'DE', title: 'Deutsche', codeISO: 'DE', lng: 'de' },
  { name: 'RU', title: 'Русский', codeISO: 'RU', lng: 'ru' },
  { name: 'CH', title: '中國人', codeISO: 'CN', lng: 'zh' },
].filter(item => !LOCALES_TO_EXCLUDE.includes(item.lng))

const SelectItem = ({ item, isActive, setLang }) => {
  return (
    <Item onClick={() => setLang(item)}>
      <ItemIcon>
        <FlagIcon codeISO={item.codeISO} />
      </ItemIcon>
      <ItemTitle active={isActive}>{item.title}</ItemTitle>
    </Item>
  )
}

const Select = props => {
  const { chooseLang } = props

  const [activeLang, setActiveLang] = useState(null)

  useEffect(() => {
    const ls = localStorage.getItem('wwl-lng') || ''
    const lang = ls.substring(0, 2).toLocaleLowerCase()
    setActiveLang(lang)
  }, [])

  const setLang = i => {
    localStorage.setItem('wwl-lng', i.lng)
    i18n.changeLanguage(i.lng)
    chooseLang(i.name)
  }

  return (
    <div>
      <Container>
        <Block>
          <List>
            {langs.map(item => (
              <SelectItem
                key={item.lng}
                item={item}
                isActive={activeLang === item.lng}
                setLang={setLang}
              />
            ))}
          </List>
        </Block>
      </Container>
      <Arrow />
    </div>
  )
}

export default Select
