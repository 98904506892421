import { LOGIN_PAGE_PATH } from 'utils/consts/path'
import { getLocaleForPublicReview } from 'utils/getLocaleId'

export const redirectProductPage = () => {
  if (location.pathname.startsWith('/acc/product')) {
    const lang = getLocaleForPublicReview()
    location.pathname = `/${lang}${location.pathname.slice(4)}`
  }
}

export const handleAuthError = () => {
  // if (process.env.NODE_ENV !== 'production') return

  redirectProductPage()

  if (location.pathname !== LOGIN_PAGE_PATH) {
    location.pathname = LOGIN_PAGE_PATH
  }
}
