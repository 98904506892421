import { createSlice } from '@reduxjs/toolkit'
import { userNotificationState } from './types'

const initialState: userNotificationState = {
  initialState: {},
}

const userNotificationSlice = createSlice({
  name: 'userNotification',
  initialState,
  reducers: {
    setInitialData: (state, { payload }) => {
      state.initialState = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: userNotification } = userNotificationSlice
export const { setInitialData, reset } = actions
