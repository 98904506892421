const Success = () => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2256_13698)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 0.5C11.1716 0.5 10.5 1.17157 10.5 2V15.3787L0.93934 24.9393C0.658035 25.2206 0.5 25.6022 0.5 26V62C0.5 62.8284 1.17157 63.5 2 63.5H62C62.8284 63.5 63.5 62.8284 63.5 62V26C63.5 25.6022 63.342 25.2206 63.0607 24.9393L53.5 15.3787V2C53.5 1.17157 52.8284 0.5 52 0.5H12ZM4.33945 25.7819L10.5 19.6213V29.9916L4.33945 25.7819ZM32 44.6832L13.5 32.0416V3.5H50.5V32.0416L32 44.6832ZM59.6605 25.7819L53.5 29.9916V19.6213L59.6605 25.7819ZM42.9533 40.832L60.5 28.8418V60.5H3.5V28.8418L21.0467 40.832L8.93934 52.9393C8.35355 53.5251 8.35355 54.4749 8.93934 55.0607C9.52513 55.6464 10.4749 55.6464 11.0607 55.0607L23.5671 42.5543L31.1537 47.7385C31.664 48.0872 32.336 48.0872 32.8463 47.7385L40.4329 42.5543L52.9393 55.0607C53.5251 55.6464 54.4749 55.6464 55.0607 55.0607C55.6464 54.4749 55.6464 53.5251 55.0607 52.9393L42.9533 40.832Z'
        fill='#3B42A5'
      />
      <path
        d='M36.8839 18.3839C37.372 17.8957 37.372 17.1043 36.8839 16.6161C36.3957 16.128 35.6043 16.128 35.1161 16.6161L31 20.7322L28.8839 18.6161C28.3957 18.128 27.6043 18.128 27.1161 18.6161C26.628 19.1043 26.628 19.8957 27.1161 20.3839L30.1161 23.3839C30.6043 23.872 31.3957 23.872 31.8839 23.3839L36.8839 18.3839Z'
        fill='#00A042'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.75 20C21.75 25.6609 26.3391 30.25 32 30.25C37.6609 30.25 42.25 25.6609 42.25 20C42.25 14.3391 37.6609 9.75 32 9.75C26.3391 9.75 21.75 14.3391 21.75 20ZM39.75 20C39.75 24.2802 36.2802 27.75 32 27.75C27.7198 27.75 24.25 24.2802 24.25 20C24.25 15.7198 27.7198 12.25 32 12.25C36.2802 12.25 39.75 15.7198 39.75 20Z'
        fill='#00A042'
      />
    </g>
    <defs>
      <clipPath id='clip0_2256_13698'>
        <rect width='64' height='64' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Success
