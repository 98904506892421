import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'

type ButtonType = 'primary' | 'secondary'
type ButtonSize = 'S' | 'M' | 'L'

interface IconButtonProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, 'type'> {
  type: ButtonType
  size: ButtonSize
}

const sizes: Record<ButtonSize, string> = {
  S: '24px',
  M: '32px',
  L: '48px',
}

const bgColors: Record<ButtonType, string> = {
  primary: '#88122B',
  secondary: '#FFFFFF',
}

const paddingBySize: Record<ButtonSize, string> = {
  S: '0',
  M: '7px',
  L: '7px',
}

const borderRadiusBySize: Record<ButtonSize, string> = {
  S: '4px',
  M: '8px',
  L: '8px',
}

const bgColorsHover: Record<ButtonType, string> = {
  primary: '#5F0D1E',
  secondary: '#E7E7EA',
}

const bgColorsDisabled: Record<ButtonType, string> = {
  primary: '#E7E7EA',
  secondary: '#FFFFFF',
}

const borderColors: Record<ButtonType, string> = {
  primary: 'transparent',
  secondary: '#E7E7EA',
}

const borderColorsHover: Record<ButtonType, string> = {
  primary: 'transparent',
  secondary: 'transparent',
}

const borderColorsDisabled: Record<ButtonType, string> = {
  primary: 'transparent',
  secondary: '#E7E7EA',
}

const fgColors: Record<ButtonType, string> = {
  primary: '#FFFFFF',
  secondary: '#717284',
}

const fgColorsHover: Record<ButtonType, string> = {
  primary: '#FFFFFF',
  secondary: '#3B42A5',
}

const fgColorsDisabled: Record<ButtonType, string> = {
  primary: '#D0D0D6',
  secondary: '#D0D0D6',
}

export const Button = styled.button<{
  variant: ButtonType
  size: ButtonSize
}>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  --button-size: ${props => sizes[props.size]};

  width: var(--button-size);
  min-width: var(--button-size);
  height: var(--button-size);
  padding: ${props => paddingBySize[props.size]};
  border-radius: ${props => borderRadiusBySize[props.size]};
  background: ${props => bgColors[props.variant]};
  border: solid 1px ${props => borderColors[props.variant]};
  color: ${props => fgColors[props.variant]};

  :hover {
    background: ${props => bgColorsHover[props.variant]};
    border: solid 1px ${props => borderColorsHover[props.variant]};
    color: ${props => fgColorsHover[props.variant]};
  }

  [disabled],
  [disabled]:hover,
  :disabled,
  :disabled:hover {
    background: ${props => bgColorsDisabled[props.variant]};
    border: solid 1px ${props => borderColorsDisabled[props.variant]};
    color: ${props => fgColorsDisabled[props.variant]};
  }
`

Button.defaultProps = {
  type: 'button',
}

const IconButton: FC<IconButtonProps> = props => {
  const { type, size, children, ...rest } = props
  return (
    <Button type='button' variant={type} size={size} {...rest}>
      {children}
    </Button>
  )
}

export default IconButton
