import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { menuItemStyles } from '../../../_styles'

export const Wrapper = styled.div`
  display: flex;
  width: 256px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--grey-90, #292b46);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  left: calc(100% + 8px);
  bottom: 8px;
  z-index: var(--z-index-tooltip);
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
  align-self: stretch;
  background: var(--grey-100, #121432);
  border-radius: 8px 8px 0 0;
`

export const Body = styled.div`
  display: flex;
  padding: 8px 0;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  background: var(--grey-100, #121432);
  border-radius: 0 0 8px 8px;
`

export const ListItem = styled.div`
  display: flex;
  padding: 0 8px;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
`

const ListItemStyles = css`
  display: grid;
  grid-template-columns: 20px 1fr;
  padding: 8px;
  gap: 12px;
  ${menuItemStyles()};
  font: var(--typography-13-20-medium);
`

export const ListItemLink = styled(Link)`
  text-decoration: none;
  ${ListItemStyles}
`

export const ListItemButton = styled.button`
  all: unset;
  cursor: pointer;
  ${ListItemStyles}
`

export const Divider = styled.div`
  height: 1px;
  align-self: stretch;
  background: var(--grey-80, #41435b);
  margin: 8px 0;
`
