const CrossIcon = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0403 0.95958C13.4308 1.3501 13.4308 1.98327 13.0403 2.37379L8.41405 7.00002L13.0403 11.6262C13.4308 12.0168 13.4308 12.6499 13.0403 13.0405C12.6498 13.431 12.0166 13.431 11.6261 13.0405L6.99984 8.41423L2.37361 13.0405C1.98309 13.431 1.34992 13.431 0.959398 13.0405C0.568873 12.6499 0.568873 12.0168 0.959398 11.6262L5.58562 7.00002L0.959397 2.37379C0.568873 1.98327 0.568873 1.35011 0.959397 0.959581C1.34992 0.569057 1.98309 0.569057 2.37361 0.959581L6.99984 5.58581L11.6261 0.95958C12.0166 0.569056 12.6498 0.569056 13.0403 0.95958Z'
        fill='#121432'
      />
    </svg>
  )
}

export default CrossIcon
