import {
  cloneElement,
  FC,
  isValidElement,
  ReactNode,
  useMemo,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { usePopperTooltip } from 'react-popper-tooltip'
import type { Placement } from '@popperjs/core'
import CloseButton from 'components/Common/Tooltips/Popover/CloseButton'
import { DELAY_SHOW } from 'utils/tooltipModifier'
import { Arrow, Content, Wrapper, Header } from './_styles'
import breakpoints from 'utils/consts/breakpoints'
import HeaderCloseButton from 'components/Common/Tooltips/Popover/HeaderCloseButton'

interface PopoverProps {
  // тригер
  children: ReactNode

  // заголовок
  header?: ReactNode

  // содержимое поповера
  content: FC | ReactNode

  // вариант поповера: обычный по ховеру, по клику с крестиком
  mode?: 'hover' | 'click'

  // положение поповера
  placement?: Placement

  // событие при клике на кнопку закрытия
  onCloseClick?: (event: any) => void

  //
  forceVisible?: boolean

  // отключить интерактивность
  noPointerEvents?: boolean

  // максимальная высота popup'а
  maxHeight?: number
}

/**
 * @example
 * <Popover content={() => <ProductPopoverContent product={product} />} mode='hover'>
 *   {product.name} <span>{product.bottleSize}</span>
 * </Popover>
 */
const Popover: FC<PopoverProps> = props => {
  const {
    children,
    header,
    content: TooltipContent,
    mode = 'hover',
    placement = 'right',
    onCloseClick = () => {},
    forceVisible,
    noPointerEvents,
    maxHeight,
  } = props

  const [controlledVisible, setControlledVisible] = useState(false)

  const modifiers = useMemo(getModifiers(placement), [placement])

  const isHoverMode = mode === 'hover'

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    {
      trigger: mode,
      delayShow: isHoverMode ? DELAY_SHOW : 0,
      delayHide: isHoverMode ? 100 : 0,
      closeOnTriggerHidden: true,
      visible: controlledVisible,
      onVisibleChange: setControlledVisible,
      interactive: isHoverMode,
      offset,
    },
    { placement, modifiers }
  )

  // prettier-ignore
  const trigger = isValidElement(children) ?
    // @ts-ignore
    cloneElement(children, { ref: setTriggerRef }) :
    <span ref={setTriggerRef}>{children}</span>

  const isPopoverVisible = forceVisible !== false && (visible || forceVisible)

  return (
    <>
      {trigger}
      {isPopoverVisible &&
        createPortal(
          <Wrapper
            ref={setTooltipRef}
            {...getTooltipProps()}
            $noPointerEvents={noPointerEvents}
          >
            <Content $maxHeight={maxHeight}>
              {header && (
                <Header>
                  {header}
                  <HeaderCloseButton
                    onClick={e => {
                      setControlledVisible(false)
                      onCloseClick?.(e)
                    }}
                  />
                </Header>
              )}
              {typeof TooltipContent === 'function' ? (
                <TooltipContent />
              ) : (
                TooltipContent
              )}
              {mode === 'click' && !header && (
                <CloseButton
                  onClick={e => {
                    setControlledVisible(false)
                    onCloseClick?.(e)
                  }}
                />
              )}
            </Content>
            <Arrow {...getArrowProps()} />
          </Wrapper>,
          document.getElementById('tooltips')!
        )}
    </>
  )
}

const offset: [number, number] = [0, 12]

const getModifiers = (placement: Placement) => () =>
  [
    {
      name: 'offset',
      options: { offset },
    },
    {
      name: 'flip',
      options: {
        padding: window.innerWidth <= breakpoints.size.sm ? 8 : 16,
        fallbackPlacements:
          placement.includes('right') || placement.includes('left')
            ? ['right', 'left', 'bottom', 'top']
            : ['bottom', 'top', 'right', 'left'],
        boundary: document.querySelector('.popover-zone') || document.body,
      },
    },
    {
      name: 'preventOverflow',
      enabled: true,
      options: {
        padding: 8,
        boundary: document.querySelector('.popover-zone') || document.body,
      },
    },
  ]

export default Popover
