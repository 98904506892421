import { ls } from 'utils/my-localstorage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setSelectedCompanyId } from 'store/me'
import { getPersonCategory } from 'utils/company'
import PersonCategories, { Roles } from 'utils/consts/userRoles'

export const useSelectedCompany = () => {
  return useSelector(state => {
    const selectedCompanyId = state?.me?.selectedCompanyId

    if (!selectedCompanyId || selectedCompanyId === '0') return null

    const personCompanies = state?.me?.data?.companies ?? []

    return personCompanies.find(company => {
      return company?.id === selectedCompanyId
    })
  })
}

export function useChangeCompany() {
  const dispatch = useDispatch()

  return company => {
    const id = company?.id || '0'

    const category =
      company?.companyCategory?.masterName ?? PersonCategories.PERSON

    const isVerifiedUser = company?.isVerifiedUser ?? false

    ls.setItem('wwl-selected_company_id', id.toString())
    ls.setItem('wwl-person_category', category)
    ls.setItem('wwl-verified_company', isVerifiedUser.toString())

    dispatch(setSelectedCompanyId(id))
  }
}

export const useUserDataForRoutes = () => {
  return useSelector(state => {
    const selectedCompanyId = state?.me?.selectedCompanyId

    const lsRole = ls.getItem('wwl-role_code')
    const lsPersonCategory = getPersonCategory()

    const lsIsVerified = ls.getItem('wwl-verified_company') === 'true'

    const company = (state?.me?.data?.companies ?? []).find(company => {
      return company?.id === selectedCompanyId
    })

    return {
      role: state?.me?.data?.role?.code || lsRole || Roles.PERSON,
      category:
        company?.companyCategory?.masterName ||
        lsPersonCategory ||
        PersonCategories.PERSON,
      isVerified:
        company?.isVerifiedUser === undefined
          ? lsIsVerified || false
          : company?.isVerifiedUser,
    }
  }, shallowEqual)
}
