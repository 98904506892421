import { useFilterStoreData } from 'utils/hooks'
import { ls } from 'utils/my-localstorage'
import { useEffect, useState } from 'react'
import useScreen from 'utils/screen'
import usePrevious from 'utils/hooks/usePrevious'

export const PER_PAGE_BY_DEFAULT = 25
const LS_KEY = 'wwl-per-page'
const getValue = storeName =>
  ls.getObject(LS_KEY)?.[storeName] || PER_PAGE_BY_DEFAULT
const setLsValue = (storeName, value) =>
  ls.setObject(LS_KEY, { ...ls.getObject(LS_KEY), [storeName]: value })

export const useCalculatePageOnPerPageChange = () => {
  const { value: perPage } = usePerPage()
  const prevPerPage = usePrevious(perPage) || perPage

  return (page = 1) => {
    const currentOffset = (page - 1) * prevPerPage
    return Math.trunc(currentOffset / perPage) + 1
  }
}

export const usePerPage = () => {
  const { storeName } = useFilterStoreData()
  const { container } = useScreen()

  const [value, setValue] = useState(getValue(storeName))

  const handleSelect = value => {
    setLsValue(storeName, value)
    window.dispatchEvent(new Event('storage'))
  }

  useEffect(() => {
    setValue(getValue(storeName))
  }, [storeName])

  useEffect(() => {
    const handleStorage = () => {
      setValue(getValue(storeName))
    }

    window.addEventListener('storage', handleStorage)
    return () => window.removeEventListener('storage', handleStorage)
  }, [])

  useEffect(() => {
    if (container.s) {
      setLsValue(storeName, PER_PAGE_BY_DEFAULT)
      setValue(PER_PAGE_BY_DEFAULT)
    }
  }, [container.s])

  return {
    value,
    onSelect: handleSelect,
  }
}
