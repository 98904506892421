import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  RetailGeoIndicationsStore,
  Sorting,
  Filters,
  PageInfo,
} from './types'
import type { Currency, Merchant } from 'api/schemas'

const initialState: RetailGeoIndicationsStore = {
  items: [],
  pageInfo: null,
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  loading: true,
  error: null,
  query: '',
  filters: [],
  selectedItems: [],
  merchant: null,
}

export interface CurrencyRetail extends Omit<Currency, 'id'> {
  id: number | null
}
const retailGeoIndicationsSlice = createSlice({
  name: 'retailGeoIndications',
  initialState,
  reducers: {
    setItems: (state, { payload }: PayloadAction<any[]>) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload
    },
    setSortingValue: (state, { payload }: PayloadAction<Sorting | null>) => {
      state.sorting = payload
    },
    clear: () => {
      return initialState
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setQuery: (state, { payload }: PayloadAction<string>) => {
      state.query = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setFilters: (state, { payload }: PayloadAction<Filters>) => {
      state.filters = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload
    },

    setMerchant: (state, { payload }: PayloadAction<Merchant | null>) => {
      state.merchant = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: retailGeoIndications } =
  retailGeoIndicationsSlice
export const {
  setItems,
  setPageInfo,
  setSortingValue: setSort,
  clear,
  setLoading,
  setQuery,
  setFilters,
  setError,
  reset,
  setSelectedItems,
  setMerchant,
} = actions
