import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCompanyTourSubscription } from 'api/hooks'
import {
  getCompanyVerified,
  getSelectedCompanyId,
  setCompanyTour,
} from 'store/me'

const useCompanyTour = () => {
  const dispatch = useDispatch()
  const companyId = useSelector(getSelectedCompanyId)
  const isCompanyVerified = useSelector(getCompanyVerified)

  const invalidCompany = !companyId || companyId === '0' || !isCompanyVerified

  useCompanyTourSubscription({
    variables: { companyId },
    skip: invalidCompany,
    context: { ignoreAccessErrors: true },
    onData({ data }) {
      dispatch(
        setCompanyTour({ companyId, tour: data?.data?.companyTour ?? null })
      )
    },
  })

  useEffect(() => {
    if (invalidCompany) {
      dispatch(setCompanyTour({ companyId, tour: null }))
    }
  }, [invalidCompany])
}

export default useCompanyTour
