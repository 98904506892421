import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const Content = styled.div`
  margin-top: 8px;
  padding: 0 16px;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #d00a00;
`

export const WithErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ErrorMessage = props => {
  const { children, renderIfEmpty, ...rest } = props

  if (!children && !renderIfEmpty) return null

  return <Content {...rest}>{children}</Content>
}

export const WithErrorMessage = ({ children, error }) => {
  const { t } = useTranslation()

  return (
    <WithErrorMessageWrapper>
      {children}
      {error && <Content>{t(error)}</Content>}
    </WithErrorMessageWrapper>
  )
}

export default ErrorMessage
