import PersonCategories from 'utils/consts/userRoles'
import {
  CHECKBOX_LIST,
  filterTypes,
  INTERVAL,
  MULTISELECT_SWITCHER,
  NUMBER,
  RADIO_LIST,
  SCORE,
  SWITCHER,
} from './filters'
import i18n from '../i18n'
import {
  offersFiltersKey,
  offersFiltersName,
  offersFiltersTitle,
} from './offersFilters'
import { getPersonCategory } from 'utils/company'

export const sellWineMyOffersFiltersCollection = () => {
  const personCategory = getPersonCategory()
  switch (personCategory) {
    case PersonCategories.PRODUCER:
      return [
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.distributionFilter),
          filterName: offersFiltersName.distributionFilter,
          apiFilterName: offersFiltersKey().distributionFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.buyerCompanyCountryFilter),
          filterName: offersFiltersName.buyerCompanyCountryFilter,
          apiFilterName: offersFiltersKey().buyerCompanyCountryFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.buyerCompanyCategoryFilter),
          filterName: offersFiltersName.buyerCompanyCategoryFilter,
          apiFilterName: offersFiltersKey().buyerCompanyCategoryFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.buyerCompanyFilter),
          filterName: offersFiltersName.buyerCompanyFilter,
          apiFilterName: offersFiltersKey().buyerCompanyFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.brandFilter),
          filterName: offersFiltersName.brandFilter,
          apiFilterName: offersFiltersKey().brandFilter,
        },

        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.wineTypeFilter),
          filterName: offersFiltersName.wineTypeFilter,
          apiFilterName: offersFiltersKey().wineTypeFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.wineColourFilter),
          filterName: offersFiltersName.wineColourFilter,
          apiFilterName: offersFiltersKey().wineColourFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
          filterName: offersFiltersName.sweetnessTypeFilter,
          apiFilterName: offersFiltersKey().sweetnessTypeFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.vintageFilter),
          filterName: offersFiltersName.vintageFilter,
          apiFilterName: offersFiltersKey().vintageFilter,
        },
        {
          type: filterTypes[SWITCHER],
          title: i18n.t(offersFiltersTitle.showLatestVintageFilter),
          filterName: offersFiltersName.showLatestVintageFilter,
          apiFilterName: offersFiltersKey().showLatestVintageFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
          filterName: offersFiltersName.nationalGITypeFilter,
          apiFilterName: offersFiltersKey().nationalGITypeFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.geoIndicationFilter),
          filterName: offersFiltersName.geoIndicationFilter,
          apiFilterName: offersFiltersKey().geoIndicationFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.specialClassificationFilter),
          filterName: offersFiltersName.specialClassificationFilter,
          apiFilterName: offersFiltersKey().specialClassificationFilter,
        },
        {
          type: filterTypes[MULTISELECT_SWITCHER],
          title: i18n.t(offersFiltersTitle.grapesFilter),
          filterName: offersFiltersName.grapesFilter,
          apiFilterName: offersFiltersKey().grapesFilter,
          switchType: filterTypes[SWITCHER],
          switchFilterName: offersFiltersName.monocepageFilter,
          switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
          switchApiFilterName: offersFiltersKey().monocepageFilter,
        },
        {
          type: filterTypes[INTERVAL],
          title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
          filterName: offersFiltersName.alcoholLevelFilter,
          apiFilterName: offersFiltersKey().alcoholLevelFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.certificatesFilter),
          filterName: offersFiltersName.certificatesFilter,
          apiFilterName: offersFiltersKey().certificatesFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.bottleSizeFilter),
          filterName: offersFiltersName.bottleSizeFilter,
          apiFilterName: offersFiltersKey().bottleSizeFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.packageTypeFilter),
          filterName: offersFiltersName.packageTypeFilter,
          apiFilterName: offersFiltersKey().packageTypeFilter,
        },
        {
          type: filterTypes[NUMBER],
          title: i18n.t(offersFiltersTitle.minimalBatchFilter),
          filterName: offersFiltersName.minimalBatchFilter,
          apiFilterName: offersFiltersKey().minimalBatchFilter,
        },
        {
          type: filterTypes[RADIO_LIST],
          title: i18n.t(offersFiltersTitle.deliveryTermsFilter),
          filterName: offersFiltersName.deliveryTermsFilter,
          apiFilterName: offersFiltersKey().deliveryTermsFilter,
        },
        {
          type: filterTypes[RADIO_LIST],
          title: i18n.t(offersFiltersTitle.countryOfShipmentFilter),
          filterName: offersFiltersName.countryOfShipmentFilter,
          apiFilterName: offersFiltersKey().countryOfShipmentFilter,
        },
        {
          type: filterTypes[SCORE],
          title: i18n.t(offersFiltersTitle.wwlRatingFilter),
          filterName: offersFiltersName.wwlRatingFilter,
          apiFilterName: offersFiltersKey().wwlRatingFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.exhibitionFilter),
          filterName: offersFiltersName.exhibitionFilter,
          apiFilterName: offersFiltersKey().exhibitionFilter,
        },
        {
          type: filterTypes[SWITCHER],
          title: i18n.t(offersFiltersTitle.fromShortlistFilter),
          filterName: offersFiltersName.fromShortlistFilter,
          apiFilterName: offersFiltersKey().fromShortlistFilter,
        },
        {
          type: filterTypes[SWITCHER],
          title: i18n.t(offersFiltersTitle.exclusiveOffersFilter),
          filterName: offersFiltersName.exclusiveOffersFilter,
          apiFilterName: offersFiltersKey().exclusiveOffersFilter,
        },
        {
          type: filterTypes[SWITCHER],
          title: i18n.t(offersFiltersTitle.onlyCompanyFilter),
          filterName: offersFiltersName.onlyCompanyFilter,
          apiFilterName: offersFiltersKey().onlyCompanyFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.statusFilter),
          filterName: offersFiltersName.statusFilter,
          apiFilterName: offersFiltersKey().statusFilter,
        },
      ]

    default:
      return [
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.distributionFilter),
          filterName: offersFiltersName.distributionFilter,
          apiFilterName: offersFiltersKey().distributionFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.buyerCompanyCountryFilter),
          filterName: offersFiltersName.buyerCompanyCountryFilter,
          apiFilterName: offersFiltersKey().buyerCompanyCountryFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.buyerCompanyCategoryFilter),
          filterName: offersFiltersName.buyerCompanyCategoryFilter,
          apiFilterName: offersFiltersKey().buyerCompanyCategoryFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.buyerCompanyFilter),
          filterName: offersFiltersName.buyerCompanyFilter,
          apiFilterName: offersFiltersKey().buyerCompanyFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.countryFilter),
          filterName: offersFiltersName.countryFilter,
          apiFilterName: offersFiltersKey().countryFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.producerFilter),
          filterName: offersFiltersName.producerFilter,
          apiFilterName: offersFiltersKey().producerFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.brandFilter),
          filterName: offersFiltersName.brandFilter,
          apiFilterName: offersFiltersKey().brandFilter,
        },

        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.wineTypeFilter),
          filterName: offersFiltersName.wineTypeFilter,
          apiFilterName: offersFiltersKey().wineTypeFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.wineColourFilter),
          filterName: offersFiltersName.wineColourFilter,
          apiFilterName: offersFiltersKey().wineColourFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
          filterName: offersFiltersName.sweetnessTypeFilter,
          apiFilterName: offersFiltersKey().sweetnessTypeFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.vintageFilter),
          filterName: offersFiltersName.vintageFilter,
          apiFilterName: offersFiltersKey().vintageFilter,
        },
        {
          type: filterTypes[SWITCHER],
          title: i18n.t(offersFiltersTitle.showLatestVintageFilter),
          filterName: offersFiltersName.showLatestVintageFilter,
          apiFilterName: offersFiltersKey().showLatestVintageFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.wineRegionEUFilter),
          filterName: offersFiltersName.wineRegionEUFilter,
          apiFilterName: offersFiltersKey().wineRegionEUFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
          filterName: offersFiltersName.nationalGITypeFilter,
          apiFilterName: offersFiltersKey().nationalGITypeFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.geoIndicationFilter),
          filterName: offersFiltersName.geoIndicationFilter,
          apiFilterName: offersFiltersKey().geoIndicationFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.specialClassificationFilter),
          filterName: offersFiltersName.specialClassificationFilter,
          apiFilterName: offersFiltersKey().specialClassificationFilter,
        },
        {
          type: filterTypes[MULTISELECT_SWITCHER],
          title: i18n.t(offersFiltersTitle.grapesFilter),
          filterName: offersFiltersName.grapesFilter,
          apiFilterName: offersFiltersKey().grapesFilter,
          switchType: filterTypes[SWITCHER],
          switchFilterName: offersFiltersName.monocepageFilter,
          switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
          switchApiFilterName: offersFiltersKey().monocepageFilter,
        },
        {
          type: filterTypes[INTERVAL],
          title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
          filterName: offersFiltersName.alcoholLevelFilter,
          apiFilterName: offersFiltersKey().alcoholLevelFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.certificatesFilter),
          filterName: offersFiltersName.certificatesFilter,
          apiFilterName: offersFiltersKey().certificatesFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.bottleSizeFilter),
          filterName: offersFiltersName.bottleSizeFilter,
          apiFilterName: offersFiltersKey().bottleSizeFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.packageTypeFilter),
          filterName: offersFiltersName.packageTypeFilter,
          apiFilterName: offersFiltersKey().packageTypeFilter,
        },
        {
          type: filterTypes[NUMBER],
          title: i18n.t(offersFiltersTitle.minimalBatchFilter),
          filterName: offersFiltersName.minimalBatchFilter,
          apiFilterName: offersFiltersKey().minimalBatchFilter,
        },
        {
          type: filterTypes[RADIO_LIST],
          title: i18n.t(offersFiltersTitle.deliveryTermsFilter),
          filterName: offersFiltersName.deliveryTermsFilter,
          apiFilterName: offersFiltersKey().deliveryTermsFilter,
        },
        {
          type: filterTypes[RADIO_LIST],
          title: i18n.t(offersFiltersTitle.countryOfShipmentFilter),
          filterName: offersFiltersName.countryOfShipmentFilter,
          apiFilterName: offersFiltersKey().countryOfShipmentFilter,
        },
        {
          type: filterTypes[SCORE],
          title: i18n.t(offersFiltersTitle.wwlRatingFilter),
          filterName: offersFiltersName.wwlRatingFilter,
          apiFilterName: offersFiltersKey().wwlRatingFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.exhibitionFilter),
          filterName: offersFiltersName.exhibitionFilter,
          apiFilterName: offersFiltersKey().exhibitionFilter,
        },
        {
          type: filterTypes[SWITCHER],
          title: i18n.t(offersFiltersTitle.exclusiveOffersFilter),
          filterName: offersFiltersName.exclusiveOffersFilter,
          apiFilterName: offersFiltersKey().exclusiveOffersFilter,
        },
        {
          type: filterTypes[SWITCHER],
          title: i18n.t(offersFiltersTitle.onlyCompanyFilter),
          filterName: offersFiltersName.onlyCompanyFilter,
          apiFilterName: offersFiltersKey().onlyCompanyFilter,
        },
        {
          type: filterTypes[CHECKBOX_LIST],
          title: i18n.t(offersFiltersTitle.statusFilter),
          filterName: offersFiltersName.statusFilter,
          apiFilterName: offersFiltersKey().statusFilter,
        },
      ]
  }
}
