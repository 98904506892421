import i18n from 'utils/i18n'
import {
  filterTypes,
  CHECKBOX_LIST,
  DATE_INTERVAL,
  SEARCH_USERS,
  RADIO_LIST,
  INTERVAL,
} from 'utils/consts'

export const eLabelsFiltersName = {
  countryFilter: 'countryFilter',
  updateUserUsernameFilter: 'updateUserUsernameFilter',
  creationDateFilter: 'creationDateFilter',
  lastUpdateFilter: 'lastUpdateFilter',
  nationalGITypeFilter: 'nationalGITypeFilter',
  geoIndicationFilter: 'geoIndicationFilter',
  wineTypeFilter: 'wineTypeFilter',
  wineTypeEUFilter: 'wineTypeEUFilter',
  wineColourFilter: 'wineColourFilter',
  additionalInfoFilter: 'additionalInfoFilter',
  sweetnessTypeFilter: 'sweetnessTypeFilter',
  vintageFilter: 'vintageFilter',
  bottleSizeFilter: 'bottleSizeFilter',
  packageTypeFilter: 'packageTypeFilter',
  brandFilter: 'brandFilter',
  specialClassificationFilter: 'specialClassificationFilter',
  ingredientsFilledFilter: 'ingredientsFilledFilter',
  nutritionDeclarationFilledFilter: 'nutritionDeclarationFilledFilter',
  alcoholLevelFilter: 'alcoholLevelFilter',
}

const eLabelsFiltersTitle = {
  countryFilter: 'filters.names.country',
  updateUserUsernameFilter: 'filters.names.users',
  creationDateFilter: 'filters.names.creationDate',
  lastUpdateFilter: 'filters.names.lastUpdate',
  nationalGITypeFilter: 'filters.names.nationalGITypeFilter',
  geoIndicationFilter: 'filters.names.geoIndicationFilter',
  wineTypeFilter: 'filters.names.wineTypeFilter',
  wineTypeEUFilter: 'filters.names.wineTypeEUFilter',
  wineColourFilter: 'filters.names.wineColourFilter',
  additionalInfoFilter: 'filters.names.certificatesFilter',
  sweetnessTypeFilter: 'filters.names.sweetnessTypeFilter',
  vintageFilter: 'filters.names.vintageFilter',
  bottleSizeFilter: 'filters.names.bottleSizeFilter',
  packageTypeFilter: 'filters.names.packageTypeFilter',
  alcoholLevelFilter: 'filters.names.alcoholLevelFilter',
  brandFilter: 'filters.names.brandFilter',
  ingredientsFilledFilter: 'filters.names.ingredientsFilledFilter',
  nutritionDeclarationFilledFilter:
    'filters.names.nutritionDeclarationFilledFilter',
  specialClassificationFilter: 'filters.names.specialClassificationFilter',
}

const eLabelsFiltersKey = () => {
  return {
    countryFilter: 'wine.country',
    updateUserUsernameFilter: 'updateUserUsername',
    creationDateFilter: 'createdAt',
    lastUpdateFilter: 'updatedAt',
    nationalGITypeFilter: 'wine.nationalGIType',
    geoIndicationFilter: 'wine.geoIndication',
    wineTypeFilter: 'wine.wineType',
    wineTypeEUFilter: 'wine.wineTypeEU',
    wineColourFilter: 'wine.wineColor',
    additionalInfoFilter: 'wine.certification',
    sweetnessTypeFilter: 'wine.sweetnessLevel',
    vintageFilter: 'wine.vintage',
    bottleSizeFilter: 'bottleSize',
    packageTypeFilter: 'packageType',
    brandFilter: 'wine.brand',
    specialClassificationFilter: 'wine.specialClassification',
    ingredientsFilledFilter: 'ingredientsFilled',
    nutritionDeclarationFilledFilter: 'nutritionDeclarationFilled',
    alcoholLevelFilter: 'alcoholOnLabel',
  }
}
export const E_LABELS_ROUTE_NAME = 'accMyWinesELabels'
export const eLabelTableName = 'eLabels'

const eLabelsDefaultFilters = [
  eLabelsFiltersName.updateUserUsernameFilter,
  eLabelsFiltersName.countryFilter,
  eLabelsFiltersName.creationDateFilter,
  eLabelsFiltersName.lastUpdateFilter,
  eLabelsFiltersName.brandFilter,
  eLabelsFiltersName.nationalGITypeFilter,
  eLabelsFiltersName.geoIndicationFilter,
  eLabelsFiltersName.specialClassificationFilter,
  eLabelsFiltersName.wineTypeFilter,
  eLabelsFiltersName.wineTypeEUFilter,
  eLabelsFiltersName.wineColourFilter,
  eLabelsFiltersName.sweetnessTypeFilter,
  eLabelsFiltersName.vintageFilter,
  eLabelsFiltersName.bottleSizeFilter,
  eLabelsFiltersName.packageTypeFilter,
  eLabelsFiltersName.grapesFilter,
  eLabelsFiltersName.additionalInfoFilter,
  eLabelsFiltersName.alcoholLevelFilter,
  eLabelsFiltersName.ingredientsFilledFilter,
  eLabelsFiltersName.nutritionDeclarationFilledFilter,
]

// указываются списки фильтров и их порядок в зависимости от роута
export const includedELabelsFiltersOnRoute = {
  [E_LABELS_ROUTE_NAME]: {
    default: eLabelsDefaultFilters,
  },
}

// общая коллекция фильтров c их параметрами
export const getELabelsFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.countryFilter),
    filterName: eLabelsFiltersName.countryFilter,
    apiFilterName: eLabelsFiltersKey().countryFilter,
  },
  {
    type: filterTypes[SEARCH_USERS],
    title: i18n.t(eLabelsFiltersTitle.updateUserUsernameFilter),
    filterName: eLabelsFiltersName.updateUserUsernameFilter,
    apiFilterName: eLabelsFiltersKey().updateUserUsernameFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(eLabelsFiltersTitle.creationDateFilter),
    filterName: eLabelsFiltersName.creationDateFilter,
    apiFilterName: eLabelsFiltersKey().creationDateFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(eLabelsFiltersTitle.lastUpdateFilter),
    filterName: eLabelsFiltersName.lastUpdateFilter,
    apiFilterName: eLabelsFiltersKey().lastUpdateFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.nationalGITypeFilter),
    filterName: eLabelsFiltersName.nationalGITypeFilter,
    apiFilterName: eLabelsFiltersKey().nationalGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.geoIndicationFilter),
    filterName: eLabelsFiltersName.geoIndicationFilter,
    apiFilterName: eLabelsFiltersKey().geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.specialClassificationFilter),
    filterName: eLabelsFiltersName.specialClassificationFilter,
    apiFilterName: eLabelsFiltersKey().specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.wineTypeFilter),
    filterName: eLabelsFiltersName.wineTypeFilter,
    apiFilterName: eLabelsFiltersKey().wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.wineTypeEUFilter),
    filterName: eLabelsFiltersName.wineTypeEUFilter,
    apiFilterName: eLabelsFiltersKey().wineTypeEUFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.wineColourFilter),
    filterName: eLabelsFiltersName.wineColourFilter,
    apiFilterName: eLabelsFiltersKey().wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.sweetnessTypeFilter),
    filterName: eLabelsFiltersName.sweetnessTypeFilter,
    apiFilterName: eLabelsFiltersKey().sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.vintageFilter),
    filterName: eLabelsFiltersName.vintageFilter,
    apiFilterName: eLabelsFiltersKey().vintageFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.bottleSizeFilter),
    filterName: eLabelsFiltersName.bottleSizeFilter,
    apiFilterName: eLabelsFiltersKey().bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.packageTypeFilter),
    filterName: eLabelsFiltersName.packageTypeFilter,
    apiFilterName: eLabelsFiltersKey().packageTypeFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(eLabelsFiltersTitle.alcoholLevelFilter),
    filterName: eLabelsFiltersName.alcoholLevelFilter,
    apiFilterName: eLabelsFiltersKey().alcoholLevelFilter,
    defaultIntervalKey: 'alcoholLevels',
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.brandFilter),
    filterName: eLabelsFiltersName.brandFilter,
    apiFilterName: eLabelsFiltersKey().brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(eLabelsFiltersTitle.additionalInfoFilter),
    filterName: eLabelsFiltersName.additionalInfoFilter,
    apiFilterName: eLabelsFiltersKey().additionalInfoFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(eLabelsFiltersTitle.ingredientsFilledFilter),
    filterName: eLabelsFiltersName.ingredientsFilledFilter,
    apiFilterName: eLabelsFiltersKey().ingredientsFilledFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(eLabelsFiltersTitle.nutritionDeclarationFilledFilter),
    filterName: eLabelsFiltersName.nutritionDeclarationFilledFilter,
    apiFilterName: eLabelsFiltersKey().nutritionDeclarationFilledFilter,
  },
]
