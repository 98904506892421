import { FC } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import IconButton from 'components/Common/MasterComponents/Buttons/IconButton'
import logoSrc from './_assets/logo.svg'
import { HOMEPAGE_PATH } from 'utils/consts/path'

const Wrapper = styled.div`
  background: #fff;
  border-bottom: 1px solid #e7e7ea;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`

const Main = styled.div`
  margin: 12px auto;
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  max-width: ${512 + 16}px;
  padding: 0 16px;
  width: 100%;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
`

const Img = styled.img`
  cursor: pointer;
`

interface HeaderProps {
  withBackButton?: boolean
  backUrl?: string | null
}

const Header: FC<HeaderProps> = ({ backUrl, withBackButton }) => {
  const history = useHistory()

  const handleClick = () => {
    history.push(backUrl ?? HOMEPAGE_PATH)
  }

  return (
    <Wrapper>
      <Main>
        {withBackButton ? (
          <IconButton type='secondary' size='M' onClick={handleClick}>
            {/* prettier-ignore */}
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
              <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'
                    d='m7.5 4-4 4 4 4' />
              <path stroke='currentColor' strokeLinecap='round' strokeWidth='2' d='M5.5 8h7' />
            </svg>
          </IconButton>
        ) : (
          <div />
        )}

        <LogoWrapper>
          <Img src={logoSrc} alt='WWL logo' onClick={handleClick} />
        </LogoWrapper>
      </Main>
    </Wrapper>
  )
}

export default Header
