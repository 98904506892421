import styled from 'styled-components'

const Wrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const Text = styled.div`
  margin-left: 8px;
  font: var(--typography-14-24-medium);
  color: #121432;
  user-select: none;
`

export const Input = styled.input`
  appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;

  --size: 16px;

  width: var(--size);
  height: var(--size);
  background: #f3f3f5;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  align-self: flex-start;
  flex-shrink: 0;

  :hover {
    border-color: #3b42a5;
  }

  :checked {
    border-color: #d8d8d8;

    :hover {
      border-color: #3b42a5;
    }

    ::after {
      content: '';
      position: absolute;

      --dot-size: calc(var(--size) / 2);

      width: var(--dot-size);
      height: var(--dot-size);
      background: #3b42a5;
      border-radius: 50%;

      --dot-offset: calc((var(--size) - var(--dot-size)) / 2 - 1px);

      top: var(--dot-offset);
      left: var(--dot-offset);
    }
  }

  :disabled {
    border-color: #f3f3f5;

    & + ${Text} {
      color: #a7b2c1;
    }

    :checked {
      border-color: #d3d9e0;

      ::after {
        background: #d3d9e0;
      }
    }
  }
`

export const Radio = props => {
  const { children, ...rest } = props

  if (children) {
    return (
      <Wrapper>
        <Input type='radio' {...rest} />
        <Text>{children}</Text>
      </Wrapper>
    )
  }

  return <Input type='radio' {...rest} />
}

export default Radio
