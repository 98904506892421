import { FC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CompanyAvatar from '../CompanyAvatar'
import CompanyInfo from '../CompanyInfo'
import AddCompanyButton from './AddCompanyButton'
import { getSelectedCompanyId } from 'store/me'
import { menuItemStyles } from '../../_styles'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--grey-100, #121432);
  z-index: var(--z-index-tooltip);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ListItem = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;

  ${menuItemStyles()};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 2px;
  flex: 1;
  padding: 16px;
  max-width: 100%;
  overflow: hidden auto;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  gap: 24px;
  align-items: center;
  height: 48px;
  width: 100%;
  color: var(--grey-10, #e7e7ea);
  text-align: center;
  font: var(--typography-16-24-semibold);
  border-bottom: 1px solid var(--grey-80, #41435b);
  background: var(--grey-100, #121432);
`

const BackButton = styled.button`
  all: unset;
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
`

interface CompaniesListProps {
  items: any[]
  onSelectCompany: (company: any) => void
  onClose: () => void
}

const CompaniesList: FC<CompaniesListProps> = props => {
  const { items, onSelectCompany, onClose } = props

  const { t } = useTranslation()

  const selectedId = useSelector(getSelectedCompanyId)

  return (
    <Wrapper>
      <Header>
        <BackButton type='button' onClick={onClose}>
          <BackIcon />
        </BackButton>
        {t('common.menu.user.companiesList')}
      </Header>

      <List>
        {items.map(item => (
          <ListItem key={item.id} onClick={() => onSelectCompany(item)}>
            <CompanyAvatar
              avatar={item?.avatar}
              isVerified={item?.isVerifiedUser}
            />

            <CompanyInfo data={item} />

            {selectedId === item.id && <IconSelected />}
          </ListItem>
        ))}

        <AddCompanyButton />
      </List>
    </Wrapper>
  )
}

// prettier-ignore
const IconSelected = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M16.8687 5.21466C17.2104 5.55636 17.2104 6.11038 16.8687 6.45209L8.53539 14.7854C8.19368 15.1271 7.63966 15.1271 7.29795 14.7854L3.13128 10.6188C2.78957 10.2771 2.78957 9.72303 3.13128 9.38132C3.47299 9.03961 4.02701 9.03961 4.36872 9.38132L7.91667 12.9293L15.6313 5.21466C15.973 4.87295 16.527 4.87295 16.8687 5.21466Z'
          fill='#E7E7EA' />
  </svg>
)

// prettier-ignore
const BackIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path fill='#fff' fillRule='evenodd'
          d='M15.7 5.3a1 1 0 0 1 0 1.4L10.42 12l5.3 5.3a1 1 0 0 1-1.42 1.4l-6-6a1 1 0 0 1 0-1.4l6-6a1 1 0 0 1 1.42 0Z'
          clipRule='evenodd' />
  </svg>
)

export default CompaniesList
