// prettier-ignore
export const Retail = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.18155 1.82942C1.43684 1.44648 1.95424 1.343 2.33718 1.5983L6.08718 4.0983C6.26481 4.21672 6.39022 4.39881 6.43754 4.60699L6.76523 6.04883C6.80112 6.04411 6.83774 6.04167 6.87493 6.04167H18.1249C18.3876 6.04167 18.6348 6.16548 18.7922 6.37576C18.9495 6.58604 18.9985 6.85819 18.9244 7.11015L16.8411 14.1935C16.7368 14.5481 16.4113 14.7917 16.0416 14.7917H7.70826C7.31918 14.7917 6.98188 14.5224 6.89565 14.143L4.88601 5.3006L1.41268 2.98505C1.02974 2.72976 0.926257 2.21236 1.18155 1.82942ZM7.99466 11.4584L8.37345 13.125H15.4181L15.9083 11.4584H7.99466ZM7.617 9.79662C7.64694 9.79336 7.67736 9.79169 7.70817 9.79169H16.3984L17.0112 7.70834H7.14239L7.617 9.79662Z'
      fill='currentColor'
    />
    <path
      d='M9.7915 17.0834C9.7915 17.8888 9.13859 18.5417 8.33317 18.5417C7.52776 18.5417 6.87484 17.8888 6.87484 17.0834C6.87484 16.2779 7.52776 15.625 8.33317 15.625C9.13859 15.625 9.7915 16.2779 9.7915 17.0834Z'
      fill='currentColor'
    />
    <path
      d='M16.8748 17.0834C16.8748 17.8888 16.2219 18.5417 15.4165 18.5417C14.6111 18.5417 13.9582 17.8888 13.9582 17.0834C13.9582 16.2779 14.6111 15.625 15.4165 15.625C16.2219 15.625 16.8748 16.2779 16.8748 17.0834Z'
      fill='currentColor'
    />
  </svg>
)
