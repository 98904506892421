export const DELAY_SHOW = 500

export const offsetModifier = (val = [0, 0]) => ({
  name: 'offset',
  enabled: true,
  options: {
    offset: val,
  },
})
export const preventOverflownModifier = ({
  options = { padding: 8 },
  settings = {},
}) => ({
  name: 'preventOverflow',
  options: {
    altAxis: true,
    ...options,
  },
  ...settings,
})
export const mobileMenuModifier = ({ settings = {} }) => ({
  name: 'mobileMenu',
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn({ state }) {
    state.styles.popper.transform = `none`
    state.styles.popper.top = 'auto'
    state.styles.popper.right = 'auto'
    state.styles.popper.left = '0px'
    state.styles.popper.bottom = '0px'
  },
  ...settings,
})

const tooltipModifier = ({ offset = [0, 0] }) => {
  return [offsetModifier(offset)]
}

export default tooltipModifier
