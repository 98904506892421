import styled from 'styled-components'
import { styledScrollbar } from 'components/Common/GlobalStyles'

export const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
`

export const Label = styled.div<{ disabled?: boolean }>`
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: ${props => (props.disabled ? '#D0D0D6' : '#595a6f')};
  margin-bottom: 8px;
`

export const FieldWrapper = styled.div<{
  readOnly?: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: row;

  position: relative;
  border: 1px solid var(--color-sw-10);
  border-radius: 8px;
  overflow: hidden;

  :hover,
  :focus-within,
  :focus-visible {
    box-shadow: ${props => {
      if (!props.readOnly && !props.disabled) {
        return '0 3px 5px rgba(95, 99, 104, 0.05), 0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);'
      }
      return null
    }};
  }
`

export const Field = styled.textarea<{
  $height?: number
  $hasError?: boolean
  maxLength?: number
}>`
  width: 100%;
  resize: vertical;
  padding: 16px 48px 16px 16px;
  min-height: ${props => props.$height || 80}px;
  box-sizing: border-box;
  background: ${props => {
    if (props.readOnly) return 'var(--color-sw-10)'
    return '#ffffff'
  }};

  color: ${props => {
    if (props.$hasError) return '#D00A00'
    if (props.disabled) return 'var(--color-sw-20)'
    if (props.readOnly) return 'var(--color-sw-60)'
    return 'var(--night-sky)'
  }};

  border: none;
  outline: none;

  font: var(--typography-14-20-semibold);

  ::-webkit-resizer {
    opacity: 0;
  }

  ::placeholder {
    opacity: 1;
    color: ${props => {
      if (props.$hasError) return '#D00A00'
      if (props.disabled) return 'var(--color-sw-20)'
      return 'var(--color-sw-60)'
    }};
  }

  &:hover::placeholder {
    opacity: 1;
    color: ${props => {
      if (props.$hasError) return '#D00A00'
      if (props.disabled) return 'var(--color-sw-20)'
      return 'var(--color-night)'
    }};
  }

  &:focus::placeholder {
    color: ${props => {
      if (props.$hasError) return '#D00A00'
      return 'var(--color-sw-60)'
    }};
  }

  ${styledScrollbar};
`

export const Resizer = styled.div<{ disabled?: boolean; readOnly?: boolean }>`
  position: absolute;
  width: 22px;
  height: 22px;
  right: 0;
  bottom: 0;
  /* stylelint-disable-next-line */
  background-image: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.293.707C3.923.077 5 .523 5 1.414V4a1 1 0 01-1 1H1.414C.524 5 .077 3.923.707 3.293L3.293.707z' fill='${props =>
    props.disabled ? '%23D0D0D6' : '%23717284'}'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: top left;
  pointer-events: none;
  background-color: ${props => {
    if (props.readOnly) return 'var(--color-sw-10)'
    return '#ffffff'
  }};
`

export const Counter = styled.div<{
  hasError?: boolean
  disabled?: boolean
  children?: number
}>`
  position: absolute;
  right: 24px;
  bottom: 16px;

  font: var(--typography-10-16-semibold);

  color: ${props => {
    if (props.hasError) return '#D00A00'
    if (props.disabled) return 'var(--color-sw-20)'
    if (props.children && !isNaN(props.children) && props.children < 0) {
      return '#D00A00'
    }

    return '#717284'
  }};
`
export const Error = styled.div`
  font: var(--typography-11-16-medium);
  letter-spacing: 0.5px;
  padding-left: 16px;
  margin-top: 8px;
  color: var(--color-red);
`
