import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Ok from 'components/Common/Icons/Ok'
import Loader from 'components/Common/Loader'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled(PrimaryButtonFlexable48)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  border: none;
  outline: none;
  cursor: ${props => (props.fakeDisabled ? 'auto' : 'pointer')};
  border-radius: 8px;
  font: var(--typography-14-16-semibold);
  transition: background-color 0.2s;
`

const Button = props => {
  const { handleRequest, disabled, loading, isSuccess } = props

  const { t } = useTranslation()

  return (
    <Wrapper>
      <Container onClick={handleRequest} fakeDisabled={disabled}>
        {isSuccess ? (
          <Ok />
        ) : loading ? (
          <Loader color='#fff' size={'23px'} />
        ) : (
          <>{t('forgotPwd.send')}</>
        )}
      </Container>
    </Wrapper>
  )
}

export default Button
