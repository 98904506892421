export const emailMask =
  /^[a-zA-Z0-9][a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]{1,60}@[A-Za-z0-9]+([-]?[A-Za-z0-9]+)+([.][A-Za-z0-9]+([-]?[A-Za-z0-9]+)+)+$/

export const websiteMask =
  /^((https?:\/\/)?(?:www\.|(?!www))[A-zА-я0-9][A-zА-я0-9-]+[A-zА-я0-9]\.[^\s]{2,}|www\.[A-zА-я0-9][A-zА-я0-9-]+[A-zА-я0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[A-zА-я0-9]+\.[^\s]{2,}|www\.[A-zА-я0-9]+\.[^\s]{2,})/
export const phoneMask = /^\+?\d{2,15}$/

export const passwordMask = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\S{8,}$/

export const usernameMask = /^[\w\-.]{3,30}$/
