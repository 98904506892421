import styled from 'styled-components'
import SecondaryTextButton from '../SecondaryTextButton'

export const SecondaryTextButton48 = styled(SecondaryTextButton)`
  font-size: 14px;
  line-height: 16px;
  --button-size: 48px;

  min-height: var(--button-size);
  padding: 16px 0;
  letter-spacing: 0.3px;
`

export default SecondaryTextButton48
