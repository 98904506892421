export const OFFER_STATUS = 'OFFER'
export const NEGOTIATIONS_STATUS = 'NEGOTIATIONS'
export const BUYER_CONDITIONS_STATUS = 'BUYER_CONDITIONS'
export const SELLER_RESPONSE_STATUS = 'SELLER_RESPONSE'
export const ACCEPTED_STATUS = 'ACCEPTED'
export const BUYER_DECLINED_STATUS = 'BUYER_DECLINED'
export const SELLER_DECLINED_STATUS = 'SELLER_DECLINED'
export const EXPIRED_STATUS = 'EXPIRED'
export const DRAFT_STATUS = 'DRAFT'
export const ARCHIVED_STATUS = 'ARCHIVED'

export enum WineOfferStatusCode {
  Offer = 'OFFER',
  Negotiations = 'NEGOTIATIONS',
  BuyerConditions = 'BUYER_CONDITIONS',
  SellerResponse = 'SELLER_RESPONSE',
  Accepted = 'ACCEPTED',
  BuyerDeclined = 'BUYER_DECLINED',
  SellerDeclined = 'SELLER_DECLINED',
  Expired = 'EXPIRED',
  Draft = 'DRAFT',
  Archived = 'ARCHIVED',
}

export const wineOfferStatus = {
  [OFFER_STATUS]: 'OFFER',
  [NEGOTIATIONS_STATUS]: 'NEGOTIATIONS',
  [BUYER_CONDITIONS_STATUS]: 'BUYER_CONDITIONS',
  [SELLER_RESPONSE_STATUS]: 'SELLER_RESPONSE',
  [ACCEPTED_STATUS]: 'ACCEPTED',
  [BUYER_DECLINED_STATUS]: 'BUYER_DECLINED',
  [SELLER_DECLINED_STATUS]: 'SELLER_DECLINED',
  [EXPIRED_STATUS]: 'EXPIRED',
  [DRAFT_STATUS]: 'DRAFT',
  [ARCHIVED_STATUS]: 'ARCHIVED',
}

export const wineOfferStatusId = {
  [OFFER_STATUS]: 1,
  [NEGOTIATIONS_STATUS]: 2,
  [BUYER_CONDITIONS_STATUS]: 3,
  [SELLER_RESPONSE_STATUS]: 4,
  [ACCEPTED_STATUS]: 5,
  [BUYER_DECLINED_STATUS]: 6,
  [SELLER_DECLINED_STATUS]: 7,
  [EXPIRED_STATUS]: 8,
  [DRAFT_STATUS]: 9,
  [ARCHIVED_STATUS]: 10,
}

export const PUBLIC_TYPE = 'PUBLIC'
export const COMPANY_TYPE = 'COMPANY'
export const PERSONAL_TYPE = 'PERSONAL'
export const DEAL_TYPE = 'DEAL'

export const PUBLISHED_OFFER_STATUS = 'OFFER'
export const DRAFT_OFFER_STATUS = 'DRAFT'

export const PRODUCER_CATEGORY_ID = 1
export const WHOLESELLER_CATEGORY_ID = 3
export const RESTAURANT_CATEGORY_ID = 5
export const RETAILER_CATEGORY_ID = 6

export enum WineOfferTypeId {
  Public = 1,
  Personal = 2,
  Company = 3,
  Deal = 4,
}

export enum WineOfferTypeCode {
  Public = 'PUBLIC',
  Personal = 'PERSONAL',
  Company = 'COMPANY',
  Deal = 'DEAL',
}
