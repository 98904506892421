import Left from './Left'
import { MainContainer, MainWrapper } from '../ForgotPassword/Common/_styles'

const ConfirmMail = () => {
  return (
    <MainWrapper>
      <MainContainer>
        <Left />
      </MainContainer>
    </MainWrapper>
  )
}

export default ConfirmMail
