import { Suspense } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import Public from 'components/Common/Routes/ProgressRoute/Public'
import Private from 'components/Common/Routes/ProgressRoute/Private'
import { publicRoutes, usePrivateRoutes, useRedirects } from 'utils/routes'
import { LocationWatcher } from 'utils/hooks/useGoBack'
import GuestLoginDialogProvider from 'components/Common/Modals/Login/Context/Provider'
import ScreenProvider from 'utils/screen/Provider'
import NotificationProvider from 'utils/notification/Provider'
import { useUserBack } from 'utils/hooks'
import { useGTMInit } from 'utils/analytics/gtm'
import useSubscriptions from 'components/Common/Subscriptions'
import { PageLoaderWithMenu } from 'components/Common/Loader/PageLoader'

const App = () => {
  const redirects = useRedirects()
  const privateRoutes = usePrivateRoutes()

  useGTMInit()
  useUserBack()
  useSubscriptions()

  return (
    <Router>
      <NotificationProvider>
        <ScreenProvider>
          <GuestLoginDialogProvider>
            <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID || ''}>
              <Suspense fallback={<PageLoaderWithMenu />}>
                <Switch>
                  {redirects.map((item, i) => (
                    <Private
                      key={i}
                      exact
                      path={item.from}
                      component={() => (
                        <Redirect
                          to={{
                            pathname: item.to,
                            state: { isRedirect: true },
                          }}
                        />
                      )}
                    />
                  ))}

                  {privateRoutes.map((route, i) => (
                    <Private key={i} {...route} />
                  ))}

                  {publicRoutes.map((route, i) => (
                    <Public key={i} {...route} />
                  ))}
                </Switch>
              </Suspense>
              <LocationWatcher />
            </GoogleOAuthProvider>
          </GuestLoginDialogProvider>
        </ScreenProvider>
      </NotificationProvider>
    </Router>
  )
}

export default App
