import { useSendPasswordResetEmailMutation } from 'api/hooks'
import { getLocaleId } from 'utils/getLocaleId'
import { captureException } from 'utils/sentry'

export const useResetPassword = () => {
  const [sendPasswordResetEmail, { data, loading, error }] =
    useSendPasswordResetEmailMutation()

  const resetPassword = async email => {
    try {
      await sendPasswordResetEmail({
        variables: { email, localeId: getLocaleId() },
      })
    } catch (e) {
      captureException(e)
    }
  }

  return {
    error,
    loading,
    isSuccess: !!data,
    resetPassword,
  }
}
