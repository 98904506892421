import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Dialog from 'components/Common/Modals/Dialog'
import {
  ErrorTextButton48,
  PrimaryButtonFlexable48,
} from 'components/Common/MasterComponents'
import { useLogout } from './useLogout'

const Main = styled.div`
  max-width: 424px;
  width: 100%;
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`
const CancelButton = styled(ErrorTextButton48)`
  color: var(--color-sw-60);
`
const Title = styled.div`
  font: var(--typography-18-28-bold);
  font-style: normal;
  letter-spacing: 0.4px;
`

export function useLogoutModal() {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const logOut = useLogout()

  const node = visible ? (
    <Dialog.Wrapper onClose={() => setVisible(false)}>
      <Main>
        <Dialog.Header
          title={t('common.attention')}
          onClose={() => setVisible(false)}
        />
        <Dialog.Content padding='32px'>
          <Title>{t('common.logoutModal.title')}</Title>
          <Footer>
            <CancelButton onClick={() => setVisible(false)}>
              {t('common.cancel')}
            </CancelButton>

            <PrimaryButtonFlexable48 onClick={logOut}>
              {t('common.logoutModal.logout')}
            </PrimaryButtonFlexable48>
          </Footer>
        </Dialog.Content>
      </Main>
    </Dialog.Wrapper>
  ) : null

  return {
    node,
    showLogoutModal: () => setVisible(true),
  }
}
