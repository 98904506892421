import { captureException } from 'utils/sentry'

if (process.env.NODE_ENV === 'production') {
  window.onerror = error => {
    captureException(error)

    const errorText =
      typeof error === 'string' ? error : error?.toString() || ''
    const testErrorMessage = (expr: RegExp) => expr.test(errorText)

    const isLoadChunkError = [
      /ChunkLoadError: Loading.+failed/gm,
      /Failed to fetch dynamically imported module/gm,
      /error loading dynamically imported module/gm,
      /Importing a module script failed/gm,
      /Failed to load module script/gm,
      /SyntaxError: Unexpected token '<'/gm,
    ].some(testErrorMessage)

    if (isLoadChunkError) {
      return window.location.reload()
    }

    const isCodeError = testErrorMessage(
      /(of|is) (undefined|null|not defined)/gm
    )
    if (isCodeError) {
      window.location.replace('/acc/error')
    }
  }
}

export {}
