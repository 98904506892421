import { FC } from 'react'
import type { IconProps } from '../../types'

// prettier-ignore
const NotificationDefaultIcon: FC<IconProps> = ({ size = 20, color = '#717284' }) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3720_318104)'>
      <circle cx='10.0001' cy='10' r='10' fill={color} />
      <path
        d='M9.15838 4.21545V5.2857L9.15837 6.4393C9.15837 7.00028 7.89557 6.86769 7.89557 8.58176V15.1427C7.89557 15.6163 8.2749 16 8.74105 16H11.2592C11.7263 16 12.1047 15.6143 12.1047 15.1427V8.58176C12.1047 6.86769 10.8419 7.00024 10.8419 6.4393L10.8419 5.2857V4.21545C10.8419 4.09643 10.7511 4 10.6352 4H9.36457C9.25063 4 9.15838 4.09542 9.15838 4.21545Z'
        fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_3720_318104'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default NotificationDefaultIcon
