import i18n from 'utils/i18n'
import {
  filterTypes,
  CHECKBOX_LIST,
  SCORE,
  INTERVAL,
  SWITCHER,
  offersFiltersTitle,
  offersFiltersName,
  MULTISELECT_SWITCHER,
  RRP_INTERVAL,
  RADIO_LIST,
} from 'utils/consts'
import { Roles } from './userRoles'
import { ls } from '../my-localstorage'

export const RETAIL_ROUTE_NAMES = {
  retailEShops: 'accRetailEShops',
  retailWines: 'accRetailWines',
  retailGeoIndications: 'accRetailGeoIndications',
  retailBuyersWine: 'accRetailBuyersWine',
}
const retailWinesFiltersName = {
  additionalNameFilter: 'additionalNameFilter',
  withAdditionalNameFilter: 'withAdditionalNameFilter',
}
const retailWinesFilterTitle = {
  additionalNameFilter: 'filters.names.additionalName',
  withAdditionalNameFilter: 'filters.names.withAdditionalName',
}
export const retailEshopsFiltersKey = {
  wineCountryFilter: 'wineCountry',
  producerFilter: 'tradeName',
  brandFilter: 'brand',
  nationalGITypeFilter: 'nationalGIType',
  geoIndicationFilter: 'geoIndication',
  specialClassificationFilter: 'specialClassification',
  wineTypeFilter: 'wineType',
  wineColourFilter: 'wineColor',
  sweetnessTypeFilter: 'sweetnessLevel',
  vintageFilter: 'vintage',
  grapesFilter: 'grapeAndMonocepage',
  bottleSizeFilter: 'bottleSize',
  packageTypeFilter: 'packageType',
  alcoholLevelFilter: 'chemicalAnalysisAlcohol',
  certificatesFilter: 'certification',
  wwlRatingFilter: 'wines.rating',
  rrpFilter: 'recommendedRetailPrice',
  retailPriceFilter: 'retailPrice',
  monocepageFilter: 'grapeAndMonocepage.monocepage',
  eShopCountryFilter: 'country',
  eShopFilter: 'name',
}
export const retailWinesFiltersKey = {
  eShopCountryFilter: 'merchant.country',
  eShopFilter: 'merchant.name',
  wineCountryFilter: 'product.wine.country',
  producerFilter: 'product.wine.tradeName',
  brandFilter: 'product.wine.brand',
  nationalGITypeFilter: 'product.wine.nationalGIType',
  geoIndicationFilter: 'product.wine.geoIndication',
  specialClassificationFilter: 'product.wine.specialClassification',
  wineTypeFilter: 'product.wine.wineType',
  wineColourFilter: 'product.wine.wineColor',
  sweetnessTypeFilter: 'product.wine.sweetnessLevel',
  vintageFilter: 'product.wine.vintage',
  retailPriceFilter: 'pricePerBottle',
  rrpFilter: 'recommendedRetailPrice',
  wwlRatingFilter: 'product.wine.rating',
  grapesFilter: 'grapeAndMonocepage',
  alcoholLevelFilter: 'chemicalAnalysisAlcohol',
  certificatesFilter: 'product.wine.certification',
  bottleSizeFilter: 'product.bottleSize',
  packageTypeFilter: 'product.packageType',
  monocepageFilter: 'grapeAndMonocepage.monocepage',
  hasBottleImageFilter: 'product.hasBottleImage',
  hasFrontLabelImageFilter: 'product.hasFrontLabelImage',
  hasBackLabelImageFilter: 'product.hasBackLabelImage',
  hasVerifiedRepresentativeFilter: 'product.hasVerifiedRepresentative',
  additionalNameFilter: 'product.wine.keywords',
  withAdditionalNameFilter: 'product.wine.keywordsExists',
}
export const retailGIFiltersKey = {
  wineCountryFilter: 'wineCountry',
  producerFilter: 'tradeName',
  brandFilter: 'brand',
  nationalGITypeFilter: 'wineNationalGIType',
  geoIndicationFilter: 'wineGeoIndication',
  specialClassificationFilter: 'specialClassification',
  wineTypeFilter: 'wineType',
  wineColourFilter: 'wineColor',
  sweetnessTypeFilter: 'sweetnessLevel',
  vintageFilter: 'vintage',
  retailPriceFilter: 'retailPrice',
  rrpFilter: 'recommendedRetailPrice',
  wwlRatingFilter: 'rating',
  grapesFilter: 'grapeAndMonocepage',
  alcoholLevelFilter: 'chemicalAnalysisAlcohol',
  certificatesFilter: 'certification',
  bottleSizeFilter: 'bottleSize',
  packageTypeFilter: 'packageType',
  monocepageFilter: 'grapeAndMonocepage.monocepage',
}

export const getRetailEShopsFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.eShopCountryFilter),
    filterName: offersFiltersName.eShopCountryFilter,
    apiFilterName: retailEshopsFiltersKey.eShopCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.eShopFilter),
    filterName: offersFiltersName.eShopFilter,
    apiFilterName: retailEshopsFiltersKey.eShopFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineCountryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: retailEshopsFiltersKey.wineCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: retailEshopsFiltersKey.producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: retailEshopsFiltersKey.brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
    filterName: offersFiltersName.nationalGITypeFilter,
    apiFilterName: retailEshopsFiltersKey.nationalGITypeFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.geoIndicationFilter),
    filterName: offersFiltersName.geoIndicationFilter,
    apiFilterName: retailEshopsFiltersKey.geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: retailEshopsFiltersKey.specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineTypeFilter),
    filterName: offersFiltersName.wineTypeFilter,
    apiFilterName: retailEshopsFiltersKey.wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineColourFilter),
    filterName: offersFiltersName.wineColourFilter,
    apiFilterName: retailEshopsFiltersKey.wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
    filterName: offersFiltersName.sweetnessTypeFilter,
    apiFilterName: retailEshopsFiltersKey.sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: retailEshopsFiltersKey.vintageFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.retailPriceEshopsFilter),
    filterName: offersFiltersName.retailPriceFilter,
    apiFilterName: retailEshopsFiltersKey.retailPriceFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.rrpShortNameFilter),
    filterName: offersFiltersName.rrpFilter,
    apiFilterName: retailEshopsFiltersKey.rrpFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(offersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: retailEshopsFiltersKey.wwlRatingFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(offersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: retailEshopsFiltersKey.grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
    switchApiFilterName: retailEshopsFiltersKey.monocepageFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: retailEshopsFiltersKey.alcoholLevelFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: retailEshopsFiltersKey.certificatesFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.bottleSizeFilter),
    filterName: offersFiltersName.bottleSizeFilter,
    apiFilterName: retailEshopsFiltersKey.bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.packageTypeFilter),
    filterName: offersFiltersName.packageTypeFilter,
    apiFilterName: retailEshopsFiltersKey.packageTypeFilter,
  },
]
export const getRetailWinesFiltersCollection = () => {
  const role = ls.getItem('wwl-role_code')

  return [
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.eShopCountryFilter),
      filterName: offersFiltersName.eShopCountryFilter,
      apiFilterName: retailWinesFiltersKey.eShopCountryFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.eShopFilter),
      filterName: offersFiltersName.eShopFilter,
      apiFilterName: retailWinesFiltersKey.eShopFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.wineCountryFilter),
      filterName: offersFiltersName.countryFilter,
      apiFilterName: retailWinesFiltersKey.wineCountryFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.producerFilter),
      filterName: offersFiltersName.producerFilter,
      apiFilterName: retailWinesFiltersKey.producerFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.brandFilter),
      filterName: offersFiltersName.brandFilter,
      apiFilterName: retailWinesFiltersKey.brandFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(retailWinesFilterTitle.additionalNameFilter),
      filterName: retailWinesFiltersName.additionalNameFilter,
      apiFilterName: retailWinesFiltersKey.additionalNameFilter,
    },
    ...([Roles.SUPERADMIN, Roles.MANAGER, Roles.CONTENT].includes(role)
      ? [
          {
            type: filterTypes[RADIO_LIST],
            title: i18n.t(retailWinesFilterTitle.withAdditionalNameFilter),
            filterName: retailWinesFiltersName.withAdditionalNameFilter,
            apiFilterName: retailWinesFiltersKey.withAdditionalNameFilter,
          },
        ]
      : []),
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
      filterName: offersFiltersName.nationalGITypeFilter,
      apiFilterName: retailWinesFiltersKey.nationalGITypeFilter,
    },

    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.geoIndicationFilter),
      filterName: offersFiltersName.geoIndicationFilter,
      apiFilterName: retailWinesFiltersKey.geoIndicationFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.specialClassificationFilter),
      filterName: offersFiltersName.specialClassificationFilter,
      apiFilterName: retailWinesFiltersKey.specialClassificationFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.wineTypeFilter),
      filterName: offersFiltersName.wineTypeFilter,
      apiFilterName: retailWinesFiltersKey.wineTypeFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.wineColourFilter),
      filterName: offersFiltersName.wineColourFilter,
      apiFilterName: retailWinesFiltersKey.wineColourFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
      filterName: offersFiltersName.sweetnessTypeFilter,
      apiFilterName: retailWinesFiltersKey.sweetnessTypeFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.vintageFilter),
      filterName: offersFiltersName.vintageFilter,
      apiFilterName: retailWinesFiltersKey.vintageFilter,
    },
    {
      type: filterTypes[RRP_INTERVAL],
      title: i18n.t(offersFiltersTitle.retailPriceEshopsFilter),
      filterName: offersFiltersName.retailPriceFilter,
      apiFilterName: retailWinesFiltersKey.retailPriceFilter,
    },
    {
      type: filterTypes[RRP_INTERVAL],
      title: i18n.t(offersFiltersTitle.rrpShortNameFilter),
      filterName: offersFiltersName.rrpFilter,
      apiFilterName: retailWinesFiltersKey.rrpFilter,
    },
    {
      type: filterTypes[SCORE],
      title: i18n.t(offersFiltersTitle.wwlRatingFilter),
      filterName: offersFiltersName.wwlRatingFilter,
      apiFilterName: retailWinesFiltersKey.wwlRatingFilter,
    },
    {
      type: filterTypes[MULTISELECT_SWITCHER],
      title: i18n.t(offersFiltersTitle.grapesFilter),
      filterName: offersFiltersName.grapesFilter,
      apiFilterName: retailWinesFiltersKey.grapesFilter,
      switchType: filterTypes[SWITCHER],
      switchFilterName: offersFiltersName.monocepageFilter,
      switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
      switchApiFilterName: retailWinesFiltersKey.monocepageFilter,
    },
    {
      type: filterTypes[INTERVAL],
      title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
      filterName: offersFiltersName.alcoholLevelFilter,
      apiFilterName: retailWinesFiltersKey.alcoholLevelFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.certificatesFilter),
      filterName: offersFiltersName.certificatesFilter,
      apiFilterName: retailWinesFiltersKey.certificatesFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.bottleSizeFilter),
      filterName: offersFiltersName.bottleSizeFilter,
      apiFilterName: retailWinesFiltersKey.bottleSizeFilter,
    },
    {
      type: filterTypes[CHECKBOX_LIST],
      title: i18n.t(offersFiltersTitle.packageTypeFilter),
      filterName: offersFiltersName.packageTypeFilter,
      apiFilterName: retailWinesFiltersKey.packageTypeFilter,
    },
    {
      type: filterTypes[RADIO_LIST],
      title: i18n.t(offersFiltersTitle.hasBottleImageFilter),
      filterName: offersFiltersName.hasBottleImageFilter,
      apiFilterName: retailWinesFiltersKey.hasBottleImageFilter,
    },
    {
      type: filterTypes[RADIO_LIST],
      title: i18n.t(offersFiltersTitle.hasFrontLabelImageFilter),
      filterName: offersFiltersName.hasFrontLabelImageFilter,
      apiFilterName: retailWinesFiltersKey.hasFrontLabelImageFilter,
    },
    {
      type: filterTypes[RADIO_LIST],
      title: i18n.t(offersFiltersTitle.hasBackLabelImageFilter),
      filterName: offersFiltersName.hasBackLabelImageFilter,
      apiFilterName: retailWinesFiltersKey.hasBackLabelImageFilter,
    },
    {
      type: filterTypes[RADIO_LIST],
      title: i18n.t(offersFiltersTitle.hasVerifiedRepresentativeFilter),
      filterName: offersFiltersName.hasVerifiedRepresentativeFilter,
      apiFilterName: retailWinesFiltersKey.hasVerifiedRepresentativeFilter,
    },
  ]
}
export const getRetailGIFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineCountryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: retailGIFiltersKey.wineCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: retailGIFiltersKey.producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: retailGIFiltersKey.brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
    filterName: offersFiltersName.nationalGITypeFilter,
    apiFilterName: retailGIFiltersKey.nationalGITypeFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.geoIndicationFilter),
    filterName: offersFiltersName.geoIndicationFilter,
    apiFilterName: retailGIFiltersKey.geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: retailGIFiltersKey.specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineTypeFilter),
    filterName: offersFiltersName.wineTypeFilter,
    apiFilterName: retailGIFiltersKey.wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineColourFilter),
    filterName: offersFiltersName.wineColourFilter,
    apiFilterName: retailGIFiltersKey.wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
    filterName: offersFiltersName.sweetnessTypeFilter,
    apiFilterName: retailGIFiltersKey.sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: retailGIFiltersKey.vintageFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.retailPriceEshopsFilter),
    filterName: offersFiltersName.retailPriceFilter,
    apiFilterName: retailGIFiltersKey.retailPriceFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.rrpShortNameFilter),
    filterName: offersFiltersName.rrpFilter,
    apiFilterName: retailGIFiltersKey.rrpFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(offersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: retailGIFiltersKey.wwlRatingFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(offersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: retailGIFiltersKey.grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
    switchApiFilterName: retailGIFiltersKey.monocepageFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: retailGIFiltersKey.alcoholLevelFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: retailGIFiltersKey.certificatesFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.bottleSizeFilter),
    filterName: offersFiltersName.bottleSizeFilter,
    apiFilterName: retailGIFiltersKey.bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.packageTypeFilter),
    filterName: offersFiltersName.packageTypeFilter,
    apiFilterName: retailGIFiltersKey.packageTypeFilter,
  },
]
