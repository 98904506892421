import * as React from 'react'
import { PropsGetterArgs } from 'react-popper-tooltip/dist/types'
import { Wrapper, Arrow } from './_styles'

interface TooltipProps {
  maxWidth?: string
  visible?: boolean
  whiteSpace?: string
}

type TooltipGenerator = (
  children: React.ReactNode,
  tooltipProps?: TooltipProps
) => React.FC<{
  tooltipRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  getArrowProps: (args?: PropsGetterArgs) => {
    style: React.CSSProperties
    'data-popper-arrow': boolean
  }
  getTooltipProps: (args?: PropsGetterArgs) => {
    'data-popper-interactive': boolean | undefined
    style: React.CSSProperties
  }
}>

const defaultProps: TooltipProps = {
  maxWidth: 'none',
  visible: undefined,
  whiteSpace: 'normal',
}

export const Tooltip: TooltipGenerator =
  (children, tooltipProps = defaultProps) =>
  props => {
    const { tooltipRef, getArrowProps, getTooltipProps } = props
    const { maxWidth, visible, whiteSpace } = tooltipProps

    return (
      <Wrapper
        ref={tooltipRef}
        {...getTooltipProps()}
        $maxWidth={maxWidth}
        $visible={visible}
        $whiteSpace={whiteSpace}
      >
        {children}
        <Arrow {...getArrowProps()} />
      </Wrapper>
    )
  }

export default Tooltip
