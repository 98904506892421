import { FC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CompanyAvatar from '../CompanyAvatar'
import { getSelectedCompany } from 'store/me'
import { usePopperTooltip } from 'react-popper-tooltip'
import CompanyInfo from '../CompanyInfo'

import { menuItemStyles, TooltipWrapper } from '../../_styles'

const Wrapper = styled.button<{ $collapsed: boolean }>`
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 7px;
  width: calc(var(--sidebar-width) - 16px);
  border: 1px solid var(--grey-80, #41435b);
  ${menuItemStyles()};
  cursor: pointer;
  user-select: none;

  :hover {
    border: 1px solid var(--grey-80, #41435b);
  }
`

const ExpandButton = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface SelectedCompanyProps {
  collapsed?: boolean
  onToggle: () => void
}

const SelectedCompany: FC<SelectedCompanyProps> = props => {
  const { collapsed = false, onToggle } = props

  const data = useSelector(getSelectedCompany)

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'right-start',
      offset: [0, 16],
    })

  return (
    <>
      <Wrapper
        type='button'
        onClick={onToggle}
        ref={setTriggerRef}
        $collapsed={collapsed}
      >
        <CompanyAvatar
          avatar={data?.avatar}
          isVerified={data?.isVerifiedUser}
        />

        {!collapsed && (
          <>
            <CompanyInfo data={data} />
            <ExpandButton>
              <ExpandIcon />
            </ExpandButton>
          </>
        )}
      </Wrapper>

      {collapsed && visible && (
        <TooltipWrapper ref={setTooltipRef} {...getTooltipProps()}>
          <CompanyInfo data={data} />
        </TooltipWrapper>
      )}
    </>
  )
}

// prettier-ignore
const ExpandIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <path fill='#888998' fillRule='evenodd'
          d='M10 3.3c.23 0 .45.08.62.25l3.33 3.33a.87.87 0 1 1-1.24 1.24L10 5.4 7.29 8.12a.87.87 0 1 1-1.24-1.24l3.33-3.33a.87.87 0 0 1 .62-.26Zm3.95 8.58c.34.34.34.9 0 1.24l-3.33 3.33a.87.87 0 0 1-1.24 0l-3.33-3.33a.87.87 0 1 1 1.24-1.24L10 14.6l2.71-2.72a.87.87 0 0 1 1.24 0Z'
          clipRule='evenodd' />
  </svg>
)

export default SelectedCompany
