import { createSlice } from '@reduxjs/toolkit'
import { CompanyState } from './types'

const initialState: CompanyState = {
  values: {},
  errors: {},
}

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setValues: (state, { payload }) => {
      state.values = {
        ...state.values,
        isDirty: true,
        ...payload,
      }
    },
    setErrors: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        ...payload,
      }
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: company } = companySlice
export const { setValues, setErrors, reset } = actions
