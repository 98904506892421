import { useState } from 'react'
import styled from 'styled-components'
import Breadcrumbs from './Breadcrumbs'
import BurgerMenu from '../BurgerMenu'
import { useLogoutModal } from '../_methods'
import Tutorial from 'components/Common/Tutorial'

const Wrapper = styled.div`
  border-bottom: solid 1px var(--color-sw-10);
  display: grid;
  grid-template-columns: 48px 1fr 36px;
  height: 48px;
  padding-right: 0;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--grey-100, #121432);
  width: 100%;
`

const BurgerButton = styled.button`
  all: unset;
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TopPanel = ({ title, breadcrumbs, onVerify, icon, tutorial }) => {
  const [menuOpened, setMenuOpened] = useState(false)

  const { node: logoutModal, showLogoutModal } = useLogoutModal()

  return (
    <>
      <Wrapper>
        <BurgerButton type='button' onClick={() => setMenuOpened(true)}>
          <BurgerIcon />
        </BurgerButton>
        <Breadcrumbs name={title} items={breadcrumbs} icon={icon} />
        {tutorial && <Tutorial tutorial={tutorial} />}
      </Wrapper>

      {menuOpened && (
        <BurgerMenu
          onVerify={onVerify}
          onClose={() => setMenuOpened(false)}
          onLogout={showLogoutModal}
        />
      )}

      {logoutModal}
    </>
  )
}

// prettier-ignore
const BurgerIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path fill='#fff' fillRule='evenodd'
          d='M2 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm0 7a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm0 7a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z'
          clipRule='evenodd' />
  </svg>
)

export default TopPanel
