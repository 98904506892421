import { createElement } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { REVIEWS_MY_REVIEWS } from 'utils/consts/path'
import { menuItems } from './useMenuItems'

const EDIT_OFFER_REGEX = /\/offer.*\/edit/

export const useChapterIcon = icon => {
  const location = useLocation()
  const pathname = location.pathname

  if (icon) return icon

  const chapter = Object.values(menuItems).find(item => {
    return matchPath(pathname, { path: item.link, exact: !item.items })
  })

  if (chapter?.icon) return chapter?.icon

  if (
    pathname.startsWith('/acc/sell-wine') ||
    EDIT_OFFER_REGEX.test(pathname) ||
    pathname.startsWith('/acc/offer/create')
  ) {
    return menuItems.sellWine.icon
  } else if (pathname.startsWith('/acc/buy-wine')) {
    return menuItems.buyWine.icon
  } else if (
    pathname.startsWith('/acc/my-company') ||
    pathname.startsWith('/acc/company')
  ) {
    return menuItems.myCompany.icon
  } else if (
    pathname.startsWith('/acc/my-wines') ||
    pathname.startsWith('/acc/elabel')
  ) {
    return menuItems.myWines.icon
  } else if (
    pathname.startsWith('/acc/database') ||
    pathname.startsWith('/acc/product') ||
    pathname.startsWith('/acc/brand') ||
    pathname.startsWith('/acc/producer') ||
    pathname.startsWith('/acc/wine')
  ) {
    return menuItems.databasePerson.icon
  } else if (
    pathname.startsWith(REVIEWS_MY_REVIEWS) ||
    pathname.startsWith('/acc/review')
  ) {
    return menuItems.databaseManage.icon
  } else if (
    pathname.startsWith('/acc/administration') ||
    pathname.startsWith('/acc/images')
  ) {
    return menuItems.administrationManage.icon
  } else if (pathname === '/acc') {
    return menuItems.homepage.icon
  }

  return () => createElement('div')
}
