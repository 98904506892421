import { useCallback } from 'react'
import { Slide, toast } from 'react-toastify'
import NotificationContext from '../Context'
import ToastContainer from '../ToastContainer'
import * as Icons from 'components/Common/Icons/Notification'

const toastDefaultProps = {
  position: 'top-right',
  autoClose: 6500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Slide,
}

const toastColors = {
  default: '#717284',
  info: '#3B42A5',
  success: '#00A042',
  warning: '#CC7400',
  error: '#D00A00',
}

const toastIcons = {
  default: Icons.DefaultIcon,
  info: Icons.InfoIcon,
  success: Icons.SuccessIcon,
  warning: Icons.WarningIcon,
  error: Icons.ErrorIcon,
}

const NotificationProvider = ({ children }) => {
  const value = useCallback((options, type) => {
    let text, icon, color
    if (typeof options === 'string') {
      text = options
    } else {
      text = options.text
      type = options.type
      icon = options.icon
      color = options.color
    }
    toast(`${text}`, {
      ...toastDefaultProps,
      progressStyle: {
        background: toastColors[type] ?? color ?? 'var(--color-red)',
      },
      icon: toastIcons[type] ?? icon,
    })
  }, [])

  return (
    <NotificationContext.Provider value={value}>
      <ToastContainer />
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
