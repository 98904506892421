import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useNotification from 'utils/notification'
import { getUserRole } from 'store/me'
import {
  TradeOfferLotsFiltersDocument,
  TradeOfferLotsSpecificFilterDocument,
  AdminRetailWinesCountersDocument,
  AdminTradeOfferLotsPaginationDocument,
  RetailWinesCountersDocument,
  TradeOfferLotsPaginationDocument,
} from 'api/hooks'
import type { RootState } from 'store'
import {
  getSort,
  getPageInfo,
  setItems,
  setPageInfo,
  getCurrency,
} from 'store/retail-wines'
import { getSelectedCompanyId } from 'utils/company'
import { getLocaleId } from 'utils/getLocaleId'
import { useFilterStoreData, useTableFiltersInputs } from 'utils/hooks'
import { MerchantSort, FacetInput } from 'api/schemas'
import {
  useCalculatePageOnPerPageChange,
  usePerPage,
} from 'components/Common/Table/utils'
import { useParams } from 'react-router-dom'
import { ManagementRoles } from 'utils/consts/userRoles'
import { useLazyQuery } from '@apollo/client'

const tabsNames = {
  myRange: 'my-range',
  compare: 'compare',
}
export const RetailWinesCurrencyLocalStorage = 'retail-wines-currency'
export const preparePageName = (page: string) => {
  let slicedName = page.substring(3)
  return slicedName.charAt(0).toLowerCase() + slicedName.slice(1)
}
export const DEFAULT_SORT = MerchantSort.RetailCoefAsc
export const useGetVariables = () => {
  const dispatch = useDispatch()
  const { getTableFilters, filtersFromStorage } = useTableFiltersInputs()
  const { storeName } = useFilterStoreData()
  const { value: perPage } = usePerPage()
  const pageInfo = useSelector(getPageInfo)
  const { page = 1 } = pageInfo || {}
  const { sub } = useParams<{ sub: string }>()

  const query = useSelector(
    (state: RootState) =>
      state?.tableFilters?.[storeName]?.filters?.query ?? null
  )

  const facetsInputs = useMemo(
    () => getTableFilters(filtersFromStorage),
    [filtersFromStorage]
  )

  const filter = useMemo(
    () => ({
      ...(getSelectedCompanyId()
        ? { companyId: getSelectedCompanyId() }
        : { companyId: null }),
      onlyMyRange: sub === tabsNames.myRange ? true : false,
      onlyCompare: sub === tabsNames.compare ? true : false,
      ...(facetsInputs && facetsInputs.length > 0 ? { facetsInputs } : {}),
    }),
    [JSON.stringify(facetsInputs), getSelectedCompanyId(), sub]
  )

  useEffect(() => {
    dispatch(setPageInfo({ ...pageInfo, page: 1 }))
  }, [filter])

  const sort = useSelector(getSort)?.type || DEFAULT_SORT
  const retailWines =
    useSelector((state: RootState) => state?.retailWines?.items) || []
  const needBackToPrevPage = !retailWines.length && page > 1
  const pageName = preparePageName(storeName)
  const buyerCurrencyCode = useSelector(getCurrency)?.code || null
  return useMemo(
    () => ({
      localeId: getLocaleId(),
      page,
      ...(getSelectedCompanyId()
        ? { companyId: getSelectedCompanyId() }
        : { companyId: null }),
      ...(query && { query }),
      ...(sort && { sort }),
      filter,
      perPage,
      pageName,
      buyerCurrencyCode,
    }),
    [
      needBackToPrevPage,
      page,
      sort,
      query,
      filter,
      perPage,
      getSelectedCompanyId(),
      buyerCurrencyCode,
    ]
  )
}

const getFilterVariables = ({
  page,
  perPage = 50,
  query = '',
  filterKey = '',
  facetsInputs = null,
  filterSearch,
  sub,
}: {
  page?: number
  perPage?: number
  filterKey?: string
  facetsInputs?: FacetInput[] | null
  filterSearch?: string
  query?: string | null
  sub: string
}) => {
  return {
    localeId: getLocaleId(),
    page,
    companyId: getSelectedCompanyId(),
    perPage,
    filter: {
      companyId: getSelectedCompanyId(),
      onlyMyRange: sub === tabsNames.myRange ? true : false,
      onlyCompare: sub === tabsNames.compare ? true : false,
      ...(facetsInputs && facetsInputs.length > 0 && { facetsInputs }),
    },
    ...(query && { query }),
    ...(filterKey && { filterKey: filterKey }),
    ...(filterSearch && { filterSearch }),
  }
}

export const useTableData = () => {
  const dispatch = useDispatch()
  const notify = useNotification()
  const { sub } = useParams<{ sub: string }>()
  const { storeName, setPresetActionType } = useFilterStoreData()
  const requestVariables = useGetVariables()
  const calculatePage = useCalculatePageOnPerPageChange()

  const isAdmin = useIsAdmin()

  const { value: perPage } = usePerPage()
  const presetInStore = useSelector(
    (state: RootState) => state?.tableFilters?.[storeName]?.preset ?? null
  )
  const pageInfo = useSelector(getPageInfo)
  const query = useSelector(
    (state: RootState) =>
      state?.tableFilters?.[storeName]?.filters?.query ?? null
  )
  const getFiltersApplied = (storeName: string) => (state: RootState) =>
    state?.tableFilters?.[storeName]?.filtersApplied ?? false
  const filtersApplied = useSelector(getFiltersApplied(storeName))

  const [loading, setLoading] = useState(false)

  const [getTableData, { loading: queryLoading, error, data }] = useLazyQuery(
    isAdmin
      ? AdminTradeOfferLotsPaginationDocument
      : TradeOfferLotsPaginationDocument,
    {
      onError(error) {
        notify(error.graphQLErrors?.[0]?.message ?? 'error', 'error')
        setLoading(false)
      },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (!data?.tradeOfferLotsPagination) return
    const response = data?.tradeOfferLotsPagination
    dispatch(setItems(response?.items))
    dispatch(setPageInfo(response?.pageInfo))
    setLoading(false)
  }, [data])

  const requestTableData = useCallback(
    (vars: any = {}) => {
      getTableData({
        variables: {
          ...requestVariables,
          ...(vars
            ? vars?.filter
              ? {
                  filter: {
                    facetsInputs: vars?.filter,
                    ...requestVariables.filter,
                  },
                }
              : vars
            : vars),
        },
      })
    },
    [requestVariables]
  )

  useEffect(() => {
    const page = calculatePage(pageInfo?.page)
    requestTableData({ page })
    dispatch(setPageInfo({ ...pageInfo, page }))
  }, [perPage])

  useEffect(() => {
    requestTableData({})
  }, [filtersApplied, requestVariables])

  const filterQueryData = {
    getFiltersData: TradeOfferLotsFiltersDocument,
    getVariables: variables =>
      getFilterVariables({ ...variables, query: query, sub }),
    getSpecificFilterData: TradeOfferLotsSpecificFilterDocument,
    specificFilterDataPath: [
      'tradeOfferLotsFilters',
      'filterByKey',
      'valuesPagination',
    ],
  }

  useEffect(() => {
    if (queryLoading) {
      setLoading(true)
    }
  }, [queryLoading])

  useEffect(() => {
    if (presetInStore && !filtersApplied) {
      dispatch({
        type: setPresetActionType,
        payload: null,
      })
    }
  }, [])

  return {
    page: requestVariables?.page,
    loading,
    error,
    filterQueryData,
    requestTableData,
  }
}

export const useGetCounters = () => {
  const notify = useNotification()
  const isAdmin = useIsAdmin()

  const [countMyRange, setCountMyRange] = useState(0)
  const [countCompare, setCountCompare] = useState(0)
  const requestVariables = useGetVariables()
  const [getMyRangeData] = useLazyQuery(
    isAdmin ? AdminRetailWinesCountersDocument : RetailWinesCountersDocument,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
      onCompleted(data) {
        setCountMyRange(data?.myRangeList?.pageInfo?.totalItems ?? 0)
        setCountCompare(data?.compareList?.pageInfo?.totalItems ?? 0)
      },
      onError(error) {
        notify(error.graphQLErrors?.[0]?.message ?? 'error', 'error')
      },
    }
  )

  const getCounters = async () => {
    await getMyRangeData({
      variables: {
        ...requestVariables,
      },
    })
  }

  return {
    getCounters,
    countMyRange,
    countCompare,
  }
}

export const useIsAdmin = () => {
  const role = useSelector(getUserRole)

  return [
    ManagementRoles.SUPERADMIN,
    ManagementRoles.CONTENT,
    ManagementRoles.MANAGER,
  ].includes(role)
}
