import { FC } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LOGIN_PAGE_PATH } from 'utils/consts/path'
import PrimaryButton from 'components/Common/MasterComponents/Buttons/PrimaryButton'
import { ButtonContent } from 'components/Common/MasterComponents'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-sw-60);
  font: var(--typography-14-16-semibold);
`
const Title = styled.span`
  cursor: pointer;
  padding-top: 16px;
`
const ButtonUnFlexable = styled(PrimaryButton)`
  height: 48px;
  margin-top: 24px;
  padding: 16px;
  width: fit-content;
  cursor: ${props => (props.fakeDisabled ? 'auto' : 'pointer')};
  border-radius: 8px;
  font: var(--typography-14-16-semibold);
  transition: background-color 0.2s;
`
const BackToLogin: FC<{ completed: boolean }> = ({ completed }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const redirect = () => {
    history.push(LOGIN_PAGE_PATH)
  }

  return (
    <Wrapper>
      {completed ? (
        <ButtonUnFlexable onClick={redirect}>
          <ButtonContent>{t('confirmPwd.login')}</ButtonContent>
        </ButtonUnFlexable>
      ) : (
        <Title onClick={redirect}>{t('confirmPwd.back')}</Title>
      )}
    </Wrapper>
  )
}

export default BackToLogin
