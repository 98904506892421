import styled from 'styled-components'

/**
 * TabBar
 * https://www.figma.com/file/s78VjLqKnuxEEznjUG7OND/Master-Components-(WWL-Team)?node-id=1%3A32
 *
 * @example
 * <TabBar>
 *   <TabBarItem active onClick={}>Active item</TabBarItem>
 *   <TabBarItem onClick={}>Normal item</TabBarItem>
 *   <TabBarItem disabled>Disabled Item</TabBarItem>
 * </TabBar>
 *
 */

export const TabBar = styled.ul`
  &,
  * {
    box-sizing: border-box;
  }

  padding: 0;
  margin: 0;
  list-style: none;
  height: 72px;
  display: flex;
  flex-direction: row;
`

export const TabBarItem = styled.li<{
  disabled?: boolean
  active?: boolean
}>`
  font: var(--typography-14-16-semibold);
  display: flex;
  height: 100%;
  align-items: center;
  color: ${props => {
    if (props.disabled) return 'var(--color-sw-20)'
    if (props.active) return 'var(--color-brand-red)'
    return 'var(--color-night)'
  }};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${props => {
      if (props.disabled) return 'var(--color-sw-20)'
      return 'var(--color-brand-red-hover)'
    }};
  }

  position: relative;
  border-top: solid 4px transparent;
  border-bottom: solid 4px
    ${props => (props.active ? 'currentColor' : 'transparent')};
`
