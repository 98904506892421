import { FC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CompanyAvatar from '../CompanyAvatar'
import CompanyInfo from '../CompanyInfo'
import VerifyNotification from '../VerifyNotification'
import AddCompanyButton from './AddCompanyButton'
import { getSelectedCompanyId } from 'store/me'

import { menuItemStyles } from '../../_styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
  width: 256px;
  min-width: 256px;
  max-height: calc(100vh - 96px);
  border-radius: 8px;
  background: var(--grey-100, #121432);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24),
    0 0 0 1px rgba(255, 255, 255, 0.08) inset;
  z-index: var(--z-index-tooltip);
  position: absolute;
  top: 0;
  left: calc(100% + 8px);
  overflow: hidden;
`

const ListItem = styled.div`
  display: flex;
  padding: 0 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`

const Content = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  align-self: stretch;

  ${menuItemStyles()};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  padding: 8px 0;
  max-width: 100%;
  overflow: hidden auto;
`

interface CompaniesListProps {
  items: any[]
  collapsed: boolean
  onSelectCompany: (company: any) => void
  onVerify: () => void
  onHide: () => void
}

const CompaniesList: FC<CompaniesListProps> = props => {
  const { items, collapsed, onSelectCompany, onVerify } = props

  const selectedId = useSelector(getSelectedCompanyId)

  return (
    <Wrapper>
      {collapsed && <VerifyNotification borderless onVerify={onVerify} />}

      <List>
        {items.map(item => (
          <ListItem key={item.id} onClick={() => onSelectCompany(item)}>
            <Content>
              <CompanyAvatar
                avatar={item?.avatar}
                isVerified={item?.isVerifiedUser}
              />

              <CompanyInfo data={item} />

              {selectedId === item.id && <IconSelected />}
            </Content>
          </ListItem>
        ))}

        <AddCompanyButton />
      </List>
    </Wrapper>
  )
}

// prettier-ignore
const IconSelected = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M16.8687 5.21466C17.2104 5.55636 17.2104 6.11038 16.8687 6.45209L8.53539 14.7854C8.19368 15.1271 7.63966 15.1271 7.29795 14.7854L3.13128 10.6188C2.78957 10.2771 2.78957 9.72303 3.13128 9.38132C3.47299 9.03961 4.02701 9.03961 4.36872 9.38132L7.91667 12.9293L15.6313 5.21466C15.973 4.87295 16.527 4.87295 16.8687 5.21466Z'
          fill='#E7E7EA' />
  </svg>
)

export default CompaniesList
