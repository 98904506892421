import styled from 'styled-components'
import AttentionIcon from 'components/Common/Icons/Attention'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  background: rgba(208, 10, 0, 0.05);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  color: var(--color-red);
`

const Message = styled.div`
  font: var(--typography-12-16-medium);

  margin-left: 8px;
`

const AlertVerificationProhibited = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AttentionIcon size={16} />
      <Message>{t('myCompany.verificationProhibited')}</Message>
    </Wrapper>
  )
}

export default AlertVerificationProhibited
