export const container = {
  xl: 1600,
  l: 1280,
  m: 768,
  s: 360,
}

export const breakpoint = {
  xl: `(min-width: ${container.xl}px)`,
  l: `(max-width: ${container.xl - 1}px)`,
  m: `(max-width: ${container.l - 1}px)`,
  s: `(max-width: ${container.m - 1}px)`,
}

const size = {
  sm: 500,
  lg: 1200,
  xl: 1366,
}

const device = {
  mobile: `(max-width: ${size.sm}px)`,
  tablet: `(max-width: ${size.lg}px) and (min-width: ${size.sm + 1}px)`,
  laptop: `(max-width: ${size.xl}px) and (min-width: ${size.lg + 1}px)`,
  desktop: `(min-width: ${size.lg + 1}px)`,
}

const breakpoints = { size, device, container }

export default breakpoints
