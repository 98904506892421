import { FC, ReactNode } from 'react'
import { LinkMenuItem, MenuItemWrapper } from '../../_styles'

interface MenuSingleItemProps {
  isActive: boolean
  link: string
  children: ReactNode
  sub?: boolean
  disabled?: boolean
  onSelect?: () => void
}

const MenuSingleItem: FC<MenuSingleItemProps> = props => {
  const { children, isActive, link, sub, disabled, onSelect } = props
  return (
    <MenuItemWrapper
      $active={isActive}
      $disabled={disabled}
      $sub={sub}
      onClick={disabled ? undefined : onSelect}
    >
      <LinkMenuItem to={link}>{children}</LinkMenuItem>
    </MenuItemWrapper>
  )
}

export default MenuSingleItem
