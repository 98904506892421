import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import Popover from 'components/Common/Tooltips/Popover'
import { Button } from 'components/Common/MasterComponents/Button'
import TutorialButton from '../TutorialButton'
import {
  Wrapper,
  Veil,
  ButtonWrapper,
  PopoverWrapper,
  PopoverHeader,
  PopoverText,
  CloseButton,
} from './_styles'

interface ITipDialog {
  onOpenTutorial: () => void
  onClose: () => void
}

const TipDialog = (props: ITipDialog) => {
  const { onOpenTutorial, onClose } = props

  const { t } = useTranslation()

  return createPortal(
    <Wrapper>
      <Veil>
        <Popover
          placement='bottom-end'
          forceVisible
          content={
            <PopoverWrapper>
              <CloseButton type='button' onClick={onClose}>
                {/* prettier-ignore */}
                <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16' width='16px' height='16px'>
                  <path fill='currentColor'
                        d='M12.24 3.76c-.39-.4-.94-.47-1.23-.18L8 6.58l-3-3c-.3-.3-.85-.21-1.24.18-.4.39-.47.94-.18 1.23l3 3.01-3 3c-.3.3-.21.85.18 1.24.39.4.94.47 1.23.18l3.01-3 3 3c.3.3.85.21 1.24-.18.4-.39.47-.94.18-1.23L9.42 8l3-3c.3-.3.21-.85-.18-1.24Z' />
                </svg>
              </CloseButton>
              <PopoverHeader>{t('tutorial.popover.header')}</PopoverHeader>
              <PopoverText>
                {t('tutorial.popover.text')}
                <Button $size='S' $type='primary' onClick={onClose}>
                  {t('tutorial.popover.ok')}
                </Button>
              </PopoverText>
            </PopoverWrapper>
          }
        >
          <ButtonWrapper>
            <TutorialButton onClick={() => onOpenTutorial()} />
          </ButtonWrapper>
        </Popover>
      </Veil>
    </Wrapper>,
    document.getElementById('modal')!
  )
}

export default TipDialog
