import { FC } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import Layout from '../Layout'
import { Sub, Title, TitleLink } from '../Layout/_styles'
import { ChevronLeftIcon } from 'components/Common/Icons'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'
import ClearLink from 'components/Common/MasterComponents/ClearLink'

const Button = styled(PrimaryButtonFlexable48)`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  padding: 12px 24px;
`

const Error500: FC = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <>
        <Title>
          <Trans
            i18nKey='errorPage.500.title'
            t={t}
            components={[<TitleLink key='link' to='/acc' />]}
          />
        </Title>
        <Sub>{t('errorPage.500.description')}</Sub>
        <ClearLink to='/acc'>
          <Button>
            <ChevronLeftIcon size={25} color='white' />
            <div>{t('errorPage.500.home')}</div>
          </Button>
        </ClearLink>
      </>
    </Layout>
  )
}

export default Error500
