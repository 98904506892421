import styled from 'styled-components'
import PrimaryButton from '../PrimaryButton'

export const PrimaryButtonFlexable48 = styled(PrimaryButton)`
  font-size: 14px;
  line-height: 16px;
  --button-size: 48px;

  min-height: var(--button-size);
  border-radius: 8px;
  padding: 12px 16px;
  letter-spacing: 0.3px;
`
