import { FC } from 'react'

// prettier-ignore
const GoogleIcon: FC = () => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2420_928)'>
      <path
        d='M8.57275 6.54541V9.64362H12.9173C12.7265 10.64 12.154 11.4837 11.2954 12.0509L13.9153 14.0655C15.4418 12.6692 16.3224 10.6182 16.3224 8.18186C16.3224 7.6146 16.2711 7.06909 16.1756 6.5455L8.57275 6.54541Z'
        fill='#4285F4'
      />
      <path
        d='M4.04867 9.52295L3.45778 9.9712L1.36621 11.5857C2.69452 14.1966 5.41699 16.0003 8.57262 16.0003C10.7522 16.0003 12.5795 15.2876 13.9152 14.0658L11.2953 12.0512C10.5761 12.5312 9.65871 12.8221 8.57262 12.8221C6.47375 12.8221 4.69049 11.4185 4.05198 9.52757L4.04867 9.52295Z'
        fill='#34A853'
      />
      <path
        d='M1.36591 4.41455C0.815533 5.49088 0.5 6.70545 0.5 7.99998C0.5 9.29451 0.815533 10.5091 1.36591 11.5854C1.36591 11.5926 4.05193 9.51994 4.05193 9.51994C3.89048 9.03994 3.79505 8.53088 3.79505 7.99989C3.79505 7.46891 3.89048 6.95985 4.05193 6.47985L1.36591 4.41455Z'
        fill='#FBBC05'
      />
      <path
        d='M8.57278 3.18547C9.76168 3.18547 10.8184 3.59273 11.6624 4.3782L13.9741 2.08731C12.5724 0.792781 10.7524 0 8.57278 0C5.41715 0 2.69452 1.79637 1.36621 4.41458L4.05215 6.48004C4.69058 4.5891 6.47392 3.18547 8.57278 3.18547Z'
        fill='#EA4335'
      />
    </g>
    <defs>
      <clipPath id='clip0_2420_928'>
        <rect width='16' height='16' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
)

export default GoogleIcon
