import { Fragment } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { lineClamp } from 'components/Common/GlobalStyles'
import { useBredcrumbsItems } from 'utils/hooks'
import { useChapterIcon } from '../../_methods'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  color: var(--grey-100, #121432);
`

const BreadcrumbText = styled.span``

const BreadcrumbLink = styled(Link)`
  color: var(--grey-100, #121432);
  text-decoration: none;
`

const Separator = styled.span`
  margin: 0 8px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;
  }
`
const Crumbs = styled.div`
  display: block;
  font: var(--typography-12-16-medium);

  ${lineClamp()}
`

const Breadcrumbs = ({ name, items, icon }) => {
  const location = useLocation()
  const params = useParams()
  const pathname = location.pathname
  const itemsFromHook = useBredcrumbsItems({ pathname, name, ...params })

  let breadcrumbsItems = items ?? itemsFromHook
  if (!Array.isArray(breadcrumbsItems)) breadcrumbsItems = []

  const Icon = useChapterIcon(icon)

  return (
    <Wrapper>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}

      <Crumbs>
        {breadcrumbsItems.map((item, index) => (
          <Fragment key={item?.name || index}>
            {index > 0 && <Separator>/</Separator>}

            {item?.link && index !== breadcrumbsItems.length - 1 ? (
              <BreadcrumbLink to={item.link}>{item?.name}</BreadcrumbLink>
            ) : (
              <BreadcrumbText>{item?.name}</BreadcrumbText>
            )}
          </Fragment>
        ))}
      </Crumbs>
    </Wrapper>
  )
}

export default Breadcrumbs
