import styled, { css } from 'styled-components'

const ARROW_SIZE = 10
const ARROW_WRAPPER_SIZE = ARROW_SIZE * 2 + 12

const common = css`
  background: #fff;
  border: 1px solid #e7e7ea;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
`

export const Wrapper = styled.div<{ $noPointerEvents?: boolean }>`
  z-index: var(--z-index-tooltip);
  box-sizing: border-box;
  pointer-events: ${props => (props?.$noPointerEvents ? 'none' : 'auto')};

  & > * {
    box-sizing: border-box;
  }

  &[data-popper-reference-hidden='true'] {
    display: none;
  }
`

export const Content = styled.div<{ $maxHeight?: number }>`
  display: flex;
  flex-direction: column;
  ${common};
  border-radius: 8px;
  position: relative;
  max-height: ${props => (props.$maxHeight ? props.$maxHeight + 'px' : 'auto')};
`

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  overflow: hidden;
  width: ${ARROW_WRAPPER_SIZE}px;
  height: ${ARROW_WRAPPER_SIZE}px;

  &::after {
    content: '';
    display: block;
    width: ${ARROW_SIZE}px;
    height: ${ARROW_SIZE}px;
    transform: translate(${ARROW_SIZE + 2}px, 0) rotate(45deg);
    transform-origin: center;

    ${common};
  }

  ${Wrapper}[data-popper-placement^='top'] > & {
    bottom: ${-ARROW_WRAPPER_SIZE + 1}px;

    &::after {
      transform: translate(0, ${-ARROW_WRAPPER_SIZE / 2}px) rotate(45deg);
      border-radius: 0 0 3px;
    }
  }

  ${Wrapper}[data-popper-placement^='bottom'] > & {
    top: ${-ARROW_WRAPPER_SIZE + 1}px;

    &::after {
      transform: translate(0, ${ARROW_WRAPPER_SIZE / 2}px) rotate(45deg);
      border-radius: 3px 0 0;
    }
  }

  ${Wrapper}[data-popper-placement^='right'] > & {
    left: ${-ARROW_WRAPPER_SIZE + 1}px;

    &::after {
      transform: translate(${ARROW_WRAPPER_SIZE / 2}px, 0) rotate(45deg);
      border-radius: 0 0 0 3px;
    }
  }

  ${Wrapper}[data-popper-placement^='left'] > & {
    right: ${-ARROW_WRAPPER_SIZE + 1}px;

    &::after {
      transform: translate(${-ARROW_WRAPPER_SIZE / 2}px, 0) rotate(45deg);
      border-radius: 0 3px 0 0;
    }
  }
`

export const Header = styled.div`
  font: var(--typography-12-16-normal);
  padding: 12px 12px 12px 16px;
  font-weight: 600;
  color: #121432;
  border-bottom: 1px solid #e7e7ea;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`
