import styled from 'styled-components'
import breakpoints from 'utils/consts/breakpoints'

export const Wrapper = styled.section`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: var(--z-index-dialog);
`

export const Veil = styled.div`
  flex: 1 0 auto;
  height: 100%;
  background-color: rgba(18, 20, 50, 0.7);
  display: flex;
  align-items: start;
  justify-content: end;
`

export const ButtonWrapper = styled.div`
  margin: 6px 12px;
  padding: 8px 4px;

  @media screen and (min-width: ${breakpoints.container.l}px) {
    background: var(--color-g-3);
    margin: 8px 28px;
  }
`

export const PopoverWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 4px;
  background: var(--color-white);
  width: 275px;
  padding: 12px 32px 12px 16px;
  align-items: start;
`
export const PopoverHeader = styled.div`
  font: var(--typography-12-16-semibold);
`
export const PopoverText = styled.div`
  font: var(--typography-12-16-medium);
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`

export const CloseButton = styled.button`
  all: unset;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: #a0a1ad;

  :hover {
    color: #000;
  }
`
