import styled from 'styled-components'
import { breakpoint } from 'utils/consts/breakpoints'

interface BadgeProps {
  textColor: string
  bgColor: string
}

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  flex-wrap: wrap;
  height: 160px;
  border-bottom: 1px solid var(--color-sw-10);
  background: var(--color-grey2-G-2);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`
export const Badges = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 0 24px;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  flex-wrap: wrap;

  @media screen and ${breakpoint.s} {
    padding: 0 16px;
  }
`
export const Badge = styled.div<BadgeProps>`
  box-sizing: border-box;
  padding: 8px 12px;
  color: ${props => props?.textColor};
  background: ${({ bgColor }) => bgColor};
  font: var(--typography-14-16-semibold);
  border-radius: 999px;

  @media screen and ${breakpoint.s} {
    padding: 4px 8px;
    font: var(--typography-13-16-semibold);
  }
`
