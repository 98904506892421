import { capitalizeFirstLetter } from 'utils/string'
import formatDateFns from 'date-fns/format'
import formatDistanceFns from 'date-fns/formatDistance'
import compareAscFns from 'date-fns/compareAsc'
import isValidFns from 'date-fns/isValid'
import parseFns from 'date-fns/parse'
import startOfDayFns from 'date-fns/startOfDay'
import { de, enUS as en, es, fr, it, ru, zhCN as zh } from 'date-fns/locale'
import { getSelectedLocale } from 'utils/getLocaleId'

export const locales = { en, ru, fr, de, es, it, zh }

export const prettifyDate = (dateValue: string) => {
  const date = new Date(dateValue)
  const locale = getSelectedLocale()
  const month = capitalizeFirstLetter(
    date.toLocaleString(locale, { month: 'short' })
  )
  const day = date.getDate()
  const year = date.getFullYear()
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
  const seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()
  const time = `${date.getHours()}:${minutes}`
  const dmy = `${day} ${month} ${year}`

  return {
    day,
    month,
    year,
    time,
    seconds,
    dmy,
  }
}

export const formatDate = (
  date: unknown,
  format = 'dd.MM.yyyy',
  defaultValue = '-'
) => {
  if (!isValidDate(date)) return defaultValue
  const userLocale = getSelectedLocale()
  return formatDateFns(date, format, { locale: locales[userLocale] })
}

export const isValidDate = (date: any): date is Date => {
  // @ts-ignore
  return date instanceof Date && !isNaN(date)
}

export const getYearFromStringDate = (dateString: string) => {
  const date = new Date(dateString)
  if (isValidDate(date)) return date.getFullYear()
  return '-'
}

const DAY_IN_MS = 1000 * 60 * 60 * 24
export const getDaysAgo = (date: Date) => {
  if (!isValidDate(date)) return ''

  const baseDate = new Date()
  if (baseDate.getTime() - date.getTime() < DAY_IN_MS) return ''

  const userLocale = getSelectedLocale()
  return formatDistanceFns(date, baseDate, {
    addSuffix: true,
    locale: locales[userLocale],
  })
}

export const prepareInputDateForServer = (date: string) => {
  try {
    const d = parseFns(date, 'dd/MM/yyyy', 0)
    return formatDateFns(d, 'yyyy-MM-dd')
  } catch (e) {
    return null
  }
}

export const prepareServerDateForInput = (date: string) => {
  try {
    return date ? formatDateFns(new Date(date), 'dd/MM/yyyy') : ''
  } catch (e) {
    return ''
  }
}

const dateFormatsShort = {
  en: 'dd/MM/yyyy',
  fr: 'dd.MM.yyyy',
  es: 'dd/MM/yyyy',
  it: 'dd/MM/yyyy',
  de: 'dd.MM.yyyy',
}

export const formatDateNFRShort = (date: string, defaultValue: any) => {
  const d = new Date(date)
  if (!isValidDate(d)) return defaultValue
  const locale = getSelectedLocale()
  const format = dateFormatsShort[locale] || dateFormatsShort.en
  return formatDateFns(d, format)
}

/**
 * Проверяет, что дата не меньше другой даты
 * @param {string} value - Строковое значение даты в формате dd/MM/yyyy
 * @param {Date} date - дата, с которой сравнивают значение
 * @returns {boolean} true, если value совпадает с date или больше date
 */
export const isDateStrAfterDate = (value: string, date: Date) => {
  if (!value) return false
  try {
    const d = parseFns(value, 'dd/MM/yyyy', 0)
    return (
      isValidFns(d) && compareAscFns(startOfDayFns(d), startOfDayFns(date)) >= 0
    )
  } catch (e) {
    return false
  }
}
