import { forwardRef, useRef } from 'react'
import styled from 'styled-components'
import { Input } from 'components/Common/MasterComponents'
import { InputProps } from 'components/Common/MasterComponents/Input'

const InputWrapper = styled.div<{ opened?: boolean }>`
  z-index: ${props =>
    props.opened ? 'var(--z-index-input-opened)' : 'var(--z-index-input)'};
  position: relative;
`

interface InputControlProps extends InputProps {
  opened: boolean
  setOpen: (value: boolean) => void
}

const InputControl = forwardRef<HTMLInputElement, InputControlProps>(
  (props, ref) => {
    const { setOpen, opened, ...inputProps } = props

    const inputRef = useRef(ref)

    const handleFocus = e => {
      setOpen(true)

      if (inputProps.onFocus) {
        inputProps.onFocus(e)
      }
    }

    const handleBlur = e => {
      setOpen(false)

      if (inputProps.onBlur) {
        inputProps.onBlur(e)
      }
    }

    return (
      <InputWrapper opened={opened}>
        <Input
          {...inputProps}
          // @ts-expect-error
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </InputWrapper>
    )
  }
)

export default InputControl
