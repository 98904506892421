import { Children, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'

export const Block = styled.div`
  position: relative;
`

const Wrapper = props => {
  const { opened, setOpen, onSelectActiveRow, setActiveRow, children, items } =
    props

  const ref = useRef()

  const getNextItem = (prev, offset) => {
    const itemsArray = Children.toArray(items)
    const itemsCount = itemsArray.length
    let current = prev

    for (let i = 0; i < 5; i++) {
      current += offset
      if (current >= itemsCount) current = 0
      if (current < 0) current = itemsCount - 1

      const selectable = itemsArray?.[current]?.props?.selectable ?? true

      if (selectable) return current
    }

    return -1
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const handleKeyDown = useCallback(
    e => {
      if (opened) {
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          setActiveRow(prev => getNextItem(prev, 1))
        }

        if (e.key === 'ArrowUp') {
          e.preventDefault()
          setActiveRow(prev => getNextItem(prev, -1))
        }

        if (e.key === 'Enter') {
          e.preventDefault()
          onSelectActiveRow()
          setActiveRow(-1)
        }

        if (e.key === 'Escape') {
          e.preventDefault()
          setOpen(false)
        }
      } else {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
          setActiveRow(-1)
          setOpen(true)
        }
      }
    },
    [onSelectActiveRow, setOpen, opened]
  )

  return (
    <Block ref={ref} onKeyDown={handleKeyDown}>
      {children}
    </Block>
  )
}

export default Wrapper
