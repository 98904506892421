import { forwardRef, memo } from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.div`
  font: var(--typography-18-24-semibold);
  color: #fff;
  letter-spacing: 0.3px;
  /* text-transform: uppercase; */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px 0;
`

export const Close = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 25px 0 20px 24px;
  color: #fff;
`

export const CloseButton = forwardRef(({ onClose }, ref) => {
  return (
    <Close
      type='button'
      onClick={() => onClose && onClose()}
      aria-label='close'
      ref={ref}
    >
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.365038 0.365038C0.851754 -0.121679 1.6402 -0.121679 2.12692 0.365038L8 6.23812L13.8731 0.365038C14.3598 -0.121679 15.1482 -0.121679 15.635 0.365038C16.1217 0.851754 16.1217 1.6402 15.635 2.12692L9.76188 8L15.635 13.8731C16.1217 14.3598 16.1217 15.1482 15.635 15.635C15.1482 16.1217 14.3598 16.1217 13.8731 15.635L8 9.76188L2.12692 15.635C1.6402 16.1217 0.851754 16.1217 0.365038 15.635C-0.121679 15.1482 -0.121679 14.3598 0.365038 13.8731L6.23812 8L0.365038 2.12692C-0.121679 1.6402 -0.121679 0.851754 0.365038 0.365038Z'
          fill='currentColor'
        />
      </svg>
    </Close>
  )
})

const Header = ({ title, onClose }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {onClose && <CloseButton onClose={onClose} />}
    </Wrapper>
  )
}

export default memo(Header)
