import styled from 'styled-components'
import Main from 'components/Pages/ForgotPassword/Left/Main/Form/Main'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Header = styled.div`
  width: 100%;
  letter-spacing: 0.4px;
  color: var(--color-night);
  font: var(--typography-14-16-semibold);
  text-align: center;
  padding: 24px 8px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-sw-10);
`
const Block = styled.div`
  width: 100%;
`

const Form = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Block>
        <Header>{t('forgotPwd.header')}</Header>
        <Main />
      </Block>
    </Wrapper>
  )
}

export default Form
