import styled from 'styled-components'
import { breakpoint } from 'utils/consts/breakpoints'

export const Title = styled.div`
  font: var(--typography-18-24-semibold);
  color: var(--color-night);
  margin-bottom: 4px;
  text-align: center;
`
export const SubTitle = styled.div`
  color: var(--color-sw-60);
  font: var(--typography-14-20-medium);
  text-align: center;
  margin-bottom: 16px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 0;

  @media screen and ${breakpoint.s} {
    padding: 16px 16px 0;
  }
`
