const Ok = () => (
  <svg
    width='23'
    height='16'
    viewBox='0 0 23 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.1837 0.000713297C19.6241 0.0194377 19.093 0.237889 18.7049 0.612356C14.5844 4.44619 11.4384 7.64792 7.61401 11.273L3.54058 8.07733C3.10535 7.73561 2.53904 7.56867 1.96938 7.61233C1.39972 7.65758 0.873717 7.91192 0.505729 8.31761C0.13771 8.72485 -0.0420811 9.24913 0.00833772 9.77809C0.0587565 10.307 0.332666 10.7955 0.771245 11.1356L6.36378 15.5296C7.21745 16.1974 8.4778 16.149 9.26759 15.4172C13.9008 11.1059 17.1995 7.66224 21.7432 3.43363C22.3818 2.86098 22.5767 1.9825 22.2306 1.22258C21.8861 0.461143 21.0727 -0.0225948 20.1838 0.00081316L20.1837 0.000713297Z'
      fill='white'
    />
  </svg>
)

export default Ok
