import styled from 'styled-components'
import BaseButton from '../BaseButton'

export const AddMoreButtonFlexable48 = styled(BaseButton)`
  color: ${props =>
    props.disabled || props.fakeDisabled
      ? 'var(--color-sw-20)'
      : 'var(--color-velvet)'};
  padding: 15px;
  border-radius: 8px;

  --button-size: 48px;

  min-height: var(--button-size);
  border: 1px dashed
    ${
      props =>
        props.disabled || props.fakeDisabled
          ? 'var(--color-sw-10)'
          : '#C4C6E4' /* PV-30 */
    };
  background: transparent;

  :focus-visible,
  :hover {
    /* PV-10 */
    background: ${
      props =>
        props.disabled || props.fakeDisabled
          ? 'transparent'
          : '#ebecf6' /* PV-10 */
    };
    border: 1px dashed
      ${
        props =>
          props.disabled || props.fakeDisabled
            ? 'var(--color-sw-10)'
            : '#ebecf6' /* PV-10 */
      };
  }
`
