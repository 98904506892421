import { forwardRef } from 'react'
import styled from 'styled-components'

const CheckboxWrapper = styled.div`
  min-width: 16px;
  width: 16px;
  height: 16px;
  align-self: flex-start;
  position: relative;
  cursor: pointer;
`

const CheckboxMark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CheckboxInput = styled.input`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f5;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  transition: border-color 0.1s;

  :hover {
    border-color: #3b42a5;
  }

  & + ${CheckboxMark} {
    opacity: 0;
    transition: opacity 0.1s;

    path {
      fill: #3b42a5;
      stroke: #3b42a5;
    }
  }

  &:checked + ${CheckboxMark} {
    opacity: 1;
  }

  &[disabled],
  &:disabled {
    border-color: #f3f3f5;

    :hover {
      border-color: #f3f3f5;
    }

    ${CheckboxMark} path {
      fill: #d8d8d8;
      stroke: #d8d8d8;
    }
  }
`

const Wrapper = styled.label`
  display: flex;
`

const LabelWrapper = styled.div`
  font: var(--typography-12-16-normal);
  font-weight: 400;
  color: #595a6f;
  letter-spacing: 0.1px;
  margin-left: 8px;
  user-select: none;
`

export const CheckboxWithText16 = forwardRef((props, ref) => {
  const { children, ...rest } = props
  return (
    <Wrapper>
      <CheckboxWrapper>
        <CheckboxInput type='checkbox' {...rest} ref={ref} />
        <CheckboxMark>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='10'
            height='8'
            fill='none'
            viewBox='0 0 10 8'
          >
            <path d='M8.27 1a.83.83 0 0 0-.56.23c-1.54 1.44-2.72 2.64-4.15 4l-1.53-1.2a.85.85 0 0 0-1.14.09.71.71 0 0 0 .1 1.06l2.1 1.64c.32.25.79.24 1.09-.04 1.73-1.62 2.97-2.9 4.67-4.5a.71.71 0 0 0 .19-.82.81.81 0 0 0-.77-.46Z' />
          </svg>
        </CheckboxMark>
      </CheckboxWrapper>
      <LabelWrapper>{children}</LabelWrapper>
    </Wrapper>
  )
})
