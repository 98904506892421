import { passwordMask } from 'utils/masks'

export type FormState = {
  password: string
  repeatPassword: string
  passwordError: string
  repeatPasswordError: string
}

export const getPasswordError = (formState: FormState): string => {
  if (!formState.password) return 'error.required'
  if (!passwordMask.test(formState.password)) return 'confirmPwd.error.password'
  return ''
}

export const getRepeatPasswordError = (formState: FormState): string => {
  if (formState.password !== formState.repeatPassword)
    return 'confirmPwd.error.notMatch'
  return ''
}

export const isFormValid = (formState: FormState) => {
  return !getPasswordError(formState) && !getRepeatPasswordError(formState)
}
