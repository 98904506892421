import { useTranslation } from 'react-i18next'
import { getLocaleId } from 'utils/getLocaleId'
import useNotification from 'utils/notification'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData, setData } from 'store/me'
import {
  useCreateCompaniesFromExternalMutation,
  useUserExternalCompaniesQuery,
  useUserHideBecomeRepresentativeMutation,
} from 'api/hooks'

export interface ICountry {
  id: number
  localizedName: string
  codeISO: string
  legalEntityDiscriminator: string
  vatName: string
  identityNumberName: string
}

export interface ICompany {
  companyCategory: any
  country: ICountry
  id: string
  legalEntityDiscriminator: string
  tradeName: string
  selected?: boolean
}

export const useLoadExternalCompanies = () => {
  const { data, error, loading } = useUserExternalCompaniesQuery({
    variables: { localeId: getLocaleId() },
    fetchPolicy: 'no-cache',
  })

  return { data, loading, error }
}

export const useHideModal = () => {
  const notify = useNotification()
  const [runRequest] = useUserHideBecomeRepresentativeMutation()
  const dispatch = useDispatch()
  const meData = useSelector(getUserData)

  const hideModalRequest = async () => {
    try {
      await runRequest()
    } catch (error: any) {
      notify(error?.message, 'error')
    } finally {
      dispatch(setData({ ...meData, hideBecomeRepresentative: true }))
    }
  }
  return { hideModalRequest }
}

export const useBecomeRepresentative = () => {
  const { t } = useTranslation()
  const notify = useNotification()
  const [runMutation] = useCreateCompaniesFromExternalMutation()
  const dispatch = useDispatch()
  const meData = useSelector(getUserData)

  const becomeRepresentativeRequest = async (selectedCompanies: any[]) => {
    try {
      const response = await runMutation({
        variables: {
          input: {
            externalCompanyId: selectedCompanies.map(_ => _.id),
          },
        },
      })
      if (response?.errors) {
        const message = response.errors?.[0]?.message
        notify(message, 'error')
        return
      }
      if (selectedCompanies.length > 1) {
        notify(
          t('modals.becomeRepresentative.successCreateCompanies', {
            number: selectedCompanies.length,
          }),
          'success'
        )
      } else if (selectedCompanies.length === 1) {
        notify(
          t('modals.becomeRepresentative.successCreateCompany', {
            company: selectedCompanies[0]?.tradeName,
          }),
          'success'
        )
      }

      return
    } catch (error: any) {
      notify(error?.message, 'error')
    } finally {
      dispatch(setData({ ...meData, hideBecomeRepresentative: true }))
    }
  }

  return { becomeRepresentativeRequest }
}
