import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(270deg);
  }
`

const colors = props => keyframes`
  from {
    stroke: ${props.color};
  }
  to {
    stroke: ${props.color};
  }
`
const dash = keyframes`
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.size};
  width: ${props => props.size};
`
const Svg = styled.svg`
  animation: ${rotate} 1.4s linear infinite;
`

const Circle = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite,
    ${colors} 5.6s ease-in-out infinite;
`

const Loader = props => {
  const { color, size, width = 6 } = props
  return (
    <Wrapper size={size}>
      <Svg
        width={size}
        height={size}
        viewBox='0 0 66 66'
        xmlns='http://www.w3.org/2000/svg'
      >
        <Circle
          color={color}
          stroke={color}
          fill='none'
          strokeWidth={width}
          strokeLinecap='round'
          cx='33'
          cy='33'
          r={33 - width / 2 - 1}
        />
      </Svg>
    </Wrapper>
  )
}

export default Loader
