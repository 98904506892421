import { useSyncExternalStore } from 'react'
import breakpoints from 'utils/consts/breakpoints'

const desktopMql = matchMedia(`(min-width: ${breakpoints.container.l}px)`)
const getSnapshotDesktop = () => desktopMql.matches
const subscribeDesktop = (callback: any) => {
  desktopMql.addEventListener('change', callback)
  return () => desktopMql.removeEventListener('change', callback)
}

const mobileMql = matchMedia(`(max-width: ${breakpoints.container.m - 1}px)`)
const getSnapshotMobile = () => mobileMql.matches
const subscribeMobile = (callback: any) => {
  mobileMql.addEventListener('change', callback)
  return () => mobileMql.removeEventListener('change', callback)
}

export const useCheckMobile = () =>
  useSyncExternalStore(subscribeMobile, getSnapshotMobile)
export const useCheckDesktop = () =>
  useSyncExternalStore(subscribeDesktop, getSnapshotDesktop)
