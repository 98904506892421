import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import Big from 'components/Common/Icons/Logo/Big'
import { useTranslation } from 'react-i18next'
import ClearLink from 'components/Common/MasterComponents/ClearLink'
import { breakpoint } from 'utils/consts/breakpoints'
import { getLocaleForTermsPolitics } from 'utils/getLocaleId'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  overflow: auto;
  align-items: center;
  padding: 64px 64px 12px;
  flex-direction: column;
  box-sizing: border-box;

  @media screen and ${breakpoint.s} {
    padding: 32px 16px 12px;
  }
`
const Footer = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--color-night-sky);
`
const Icon = styled(ClearLink)`
  svg {
    * {
      fill: var(--color-brand-red);
    }

    height: 40px;
    width: 219px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 528px;
  text-align: center;

  @media screen and ${breakpoint.s} {
    width: 100%;
  }
`
const Link = styled.a`
  text-decoration: none;
  color: var(--color-night-sky);
`

const ErrorLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  const locale = getLocaleForTermsPolitics()

  return (
    <Wrapper>
      <Icon to='/acc'>
        <Big />
      </Icon>
      <ContentWrapper>{children}</ContentWrapper>
      <Footer>
        <Link
          target='_blank'
          rel='noreferrer'
          href={`https://worldwinelist.com/${locale}/terms-and-conditions`}
        >
          {t('errorPage.terms')}
        </Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;© {new Date().getFullYear()} World Wine List
      </Footer>
    </Wrapper>
  )
}

export default ErrorLayout
