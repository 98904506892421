import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 48px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #a0a1ad;

  @media (max-width: 1280px) {
    font-size: 14px;
  }

  @media (max-width: 880px) {
    justify-content: center;
  }

  @media (max-width: 450px) {
    padding-bottom: 15px;
    font-size: 11px;
  }
`

const Footer = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>{t('common.terms', { year: new Date().getFullYear() })}</Wrapper>
  )
}

export default Footer
