import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LogoIcon from 'components/Common/Icons/Logo/Big'

const Wrapper = styled.div`
  display: flex;
  margin-top: 48px;

  @media (max-width: 880px) {
    justify-content: center;
    margin-top: 63px;
  }

  @media (max-width: 500px) {
    justify-content: space-between;
    margin-top: 32px;
  }
`
const Container = styled.div`
  width: 320px;
  display: flex;
  justify-content: space-between;
`
const Icon = styled.div`
  svg {
    height: 40px;
    width: 219px;

    @media (max-width: 1280px) {
      height: 32px;
      width: 175px;
    }
  }
`

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <Icon>
          <Link to='/acc'>
            <LogoIcon />
          </Link>
        </Icon>
      </Container>
    </Wrapper>
  )
}

export default Header
