import Main from 'components/Pages/Login/Left/Main'
import Header from 'components/Pages/ForgotPassword/Common/Header'
import Footer from 'components/Pages/ForgotPassword/Common/Footer'
import { LeftWrapper } from 'components/Pages/ForgotPassword/Common/_styles'

const Left = () => {
  return (
    <LeftWrapper>
      <Header />
      <Main />
      <Footer />
    </LeftWrapper>
  )
}

export default Left
