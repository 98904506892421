import styled from 'styled-components'
import SecondaryTextButton from '../SecondaryTextButton'

export const SecondaryTextButton32 = styled(SecondaryTextButton)`
  font-size: 12px;
  line-height: 16px;
  --button-size: 32px;

  min-height: var(--button-size);
  padding: 8px 0;
`
