import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PRODUCER_CATEGORY_ID,
  WHOLESELLER_CATEGORY_ID,
  RESTAURANT_CATEGORY_ID,
  RETAILER_CATEGORY_ID,
} from 'utils/consts'
import DefaultTooltip from 'components/Common/Tooltips/DefaultTooltip'
import ProducerIconPath from './_assets/producer.svg'
import RestaurantIconPath from './_assets/restaurant.svg'
import RetailerIconPath from './_assets/retailer.svg'
import WholesellerIconPath from './_assets/wholeseller.svg'

interface CompanyTypeBadgeProps {
  type: number | undefined
}

/**
 * Бейдж типа компании
 *
 * https://wwl.atlassian.net/browse/DEV-6419
 *
 * @example
 * <CompanyTypeBadge type={company?.companyCategory?.id} />
 */
const CompanyTypeBadge: FC<CompanyTypeBadgeProps> = props => {
  const { type } = props

  const { t } = useTranslation()

  if (!type) return null

  const icon = icons[type]
  if (!icon) return null

  const tooltip = tooltips[type]
  if (!tooltip) return <img src={icon} width='24px' height='16px' alt='' />

  return (
    <DefaultTooltip
      placement='top'
      offset={12}
      tooltip={t(tooltip)}
      component='img'
      src={icon}
      width='24px'
      height='16px'
      alt=''
    />
  )
}

const icons = {
  [PRODUCER_CATEGORY_ID]: ProducerIconPath,
  [WHOLESELLER_CATEGORY_ID]: WholesellerIconPath,
  [RESTAURANT_CATEGORY_ID]: RestaurantIconPath,
  [RETAILER_CATEGORY_ID]: RetailerIconPath,
} as const

const tooltips = {
  [PRODUCER_CATEGORY_ID]: 'companyTypeBadge.producer',
  [WHOLESELLER_CATEGORY_ID]: 'companyTypeBadge.wholeseller',
  [RESTAURANT_CATEGORY_ID]: 'companyTypeBadge.restaurant',
  [RETAILER_CATEGORY_ID]: 'companyTypeBadge.retailer',
} as const

export default CompanyTypeBadge
