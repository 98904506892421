export const CHECKBOX_LIST = 'CHECKBOX_LIST'
export const MULTISELECT_SWITCHER = 'MULTISELECT_SWITCHER'
export const DATE_INTERVAL = 'DATE_INTERVAL'
export const SEARCH_USERS = 'SEARCH_USERS'
export const RADIO_LIST = 'RADIO_LIST'
export const SCORE = 'SCORE'
export const INTERVAL = 'INTERVAL'
export const PRICE_INTERVAL = 'PRICE_INTERVAL'
export const RRP_INTERVAL = 'RRP_INTERVAL'
export const SWITCHER = 'SWITCHER'
export const NUMBER = 'NUMBER'
export const RHEOSTAT = 'RHEOSTAT'
export const PRODUCT_RANK = 'PRODUCT_RANK'
export const COUNTRIES_LIST = 'COUNTRIES_LIST'
export const EMAIL_STATUS_LIST = 'EMAIL_STATUS_LIST'

export const filterTypes = {
  [CHECKBOX_LIST]: 'checkboxList',
  [MULTISELECT_SWITCHER]: 'multiselectSwitcher',
  [DATE_INTERVAL]: 'dateInterval',
  [SEARCH_USERS]: 'searchUsers',
  [RADIO_LIST]: 'radioList',
  [SCORE]: 'score',
  [INTERVAL]: 'interval',
  [PRICE_INTERVAL]: 'priceInterval',
  [SWITCHER]: 'switcher',
  [NUMBER]: 'number',
  [RRP_INTERVAL]: 'rrpInterval',
  [RHEOSTAT]: 'rheostat',
  [COUNTRIES_LIST]: 'countriesList',
  [EMAIL_STATUS_LIST]: 'emailStatusList',
  [PRODUCT_RANK]: 'productRank',
}
