import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import PrimaryButton from 'components/Common/MasterComponents/Buttons/PrimaryButton'
import { ButtonContent } from 'components/Common/MasterComponents'
import { useTranslation } from 'react-i18next'

const Wrapper = styled(PrimaryButton)`
  height: 48px;
  padding: 16px;
  width: fit-content;
  border-radius: 8px;
  font: var(--typography-14-16-semibold);
  transition: background-color 0.2s;
  cursor: ${props => (props.fakeDisabled ? 'auto' : 'pointer')};
`

const Button = ({ error, disabled }) => {
  const { t } = useTranslation()

  const history = useHistory()

  const redirect = () => {
    if (disabled) return
    history.push('/acc')
  }

  return (
    <Wrapper onClick={redirect} fakeDisabled={disabled}>
      {error ? (
        <ButtonContent> {t('confirmPwd.login')}</ButtonContent>
      ) : (
        <ButtonContent> {t('confirmMail.login')}</ButtonContent>
      )}
    </Wrapper>
  )
}

export default Button
