import styled from 'styled-components'

export const Content = styled.div<{ padding?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 3px 5px rgba(95, 99, 104, 0.05),
    0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);
  border-radius: 16px;
  width: 100%;

  ${props => props.padding && `padding: ${props.padding};`}
`

export default Content
