import styled from 'styled-components'

const Wrapper = styled.button.attrs(() => ({ type: 'button' }))`
  appearance: none;
  border: none;
  background: none;
  padding: 4px;
  margin: 0;
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 4px;
`

const CloseButton = props => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='none'
        viewBox='0 0 16 16'
      >
        <path
          fill='#A0A1AD'
          d='M12.2 3.8c-.3-.4-.9-.5-1.2-.2l-3 3-3-3c-.3-.3-.9-.2-1.2.2-.4.3-.5.9-.2 1.2l3 3-3 3c-.3.3-.2.9.2 1.2.3.4.9.5 1.2.2l3-3 3 3c.3.3.9.2 1.2-.2.4-.3.5-.9.2-1.2l-3-3 3-3c.3-.3.2-.9-.2-1.2Z'
        />
      </svg>
    </Wrapper>
  )
}

export default CloseButton
