import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GETTING_STARTED_PATH } from 'utils/consts/path'

const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  border-top: solid 1px #41435b;
  width: 100%;
`

const Button = styled(Link)`
  text-decoration: none;
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #41435b;
  background: #121432;
  color: #e7e7ea;
  text-align: center;
  font: var(--typography-12-16-semibold);
`

const Footer = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Button to={GETTING_STARTED_PATH} onClick={onClose}>
        {t('getting-started.go-to-tour-page')}
      </Button>
    </Wrapper>
  )
}

export default Footer
