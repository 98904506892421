import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Modal from './Modal'
import type { RootState } from 'store'

const getHideBecomeRepresentative = (state: RootState) => {
  return state?.me?.data?.hideBecomeRepresentative ?? true
}

const BecomeRepresentative = () => {
  const [showDialog, setShowDialog] = useState(false)
  const hideBecomeRepresentative = useSelector(getHideBecomeRepresentative)

  useEffect(() => {
    setShowDialog(!hideBecomeRepresentative)
  }, [hideBecomeRepresentative])

  if (!showDialog) return null

  return <Modal onClose={() => setShowDialog(false)} />
}

export default BecomeRepresentative
