import { FC, ReactNode } from 'react'
import { LinkMenuItem } from '../../_styles'

interface MenuSingleItemProps {
  isActive: boolean
  link: string
  children: ReactNode
  disabled?: boolean
}

const MenuSingleItem: FC<MenuSingleItemProps> = props => {
  const { children, isActive, link, disabled } = props

  return (
    <LinkMenuItem to={link} $active={isActive} $disabled={disabled}>
      {children}
    </LinkMenuItem>
  )
}

export default MenuSingleItem
