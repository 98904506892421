import { FC } from 'react'
import styled from 'styled-components'
import { getImageSrc } from 'utils/screen'
import { ImageThumbnail40Fragment } from 'api/operations'

const Avatar = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.16);
`

const TopRightBadge = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
`

interface CompanyAvatarProps {
  avatar?: ImageThumbnail40Fragment | null
  isVerified?: boolean
}

const CompanyAvatar: FC<CompanyAvatarProps> = ({ avatar, isVerified }) => {
  return (
    <Avatar>
      {avatar?.thumbnailUrl ? (
        <Image alt='company avatar' {...getImageSrc(avatar)} />
      ) : (
        <NoAvatarImage />
      )}

      {!isVerified && (
        <TopRightBadge>
          <NotVerifiedIcon />
        </TopRightBadge>
      )}
    </Avatar>
  )
}

// prettier-ignore
const NoAvatarImage = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none' viewBox='0 0 40 40'>
    <rect width='40' height='40' fill='#E7E7EA' rx='4' />
    <path fill='#A0A1AD' fillRule='evenodd'
          d='M28 14h-4v-2a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2h-4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V16a2 2 0 0 0-2-2Zm-10-2h4v2h-4v-2Z'
          clipRule='evenodd' />
    <rect width='39' height='39' x='.5' y='.5' stroke='#fff' strokeOpacity='.2' rx='3.5' />
  </svg>
)

// prettier-ignore
const NotVerifiedIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <circle cx='10' cy='10' r='9' fill='#D00A00' stroke='#fff' strokeWidth='2' />
    <path fill='#fff' fillRule='evenodd'
          d='M10 14.57a1.14 1.14 0 1 0 0-2.28 1.14 1.14 0 0 0 0 2.28Zm.06-3.43c-.54 0-.97-.38-.97-.85v-4c0-.48.43-.86.97-.86.53 0 .97.38.97.86v4c0 .47-.44.85-.97.85Z'
          clipRule='evenodd' />
  </svg>
)

export default CompanyAvatar
