import { FC } from 'react'
import type { IconProps } from '../../types'

// prettier-ignore
const NotificationSuccessIcon: FC<IconProps> = ({ size = 20, color = '#00A042' }) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3720_318111)'>
      <circle cx='10.0001' cy='10' r='10' fill={color} />
      <path d='M5.00012 9.99996L8.33346 13.3333L15.0001 6.66663' stroke='white' strokeWidth='1.8'
            strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_3720_318111'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default NotificationSuccessIcon
