import { useContext } from 'react'
import NotificationContext from './Context'

/**
 *  @example
 *  notify('warning message with type', 'warning')
 */
const useNotification = (): ((
  text: string,
  type?: 'default' | 'info' | 'success' | 'warning' | 'error'
) => void) => {
  return useContext(NotificationContext)
}

export default useNotification
