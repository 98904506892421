import styled from 'styled-components'

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
  outline: none;

  svg {
    transform: ${props => (props.opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  :read-only,
  [readonly] {
    color: var(--color-sw-60);
  }

  :disabled,
  [disabled] {
    color: var(--color-sw-20);
  }
`

const Arrow = props => {
  const { toggleOpened, opened, disabled, readOnly } = props

  const handleClick = () => {
    if (!disabled && !readOnly && toggleOpened) {
      toggleOpened()
    }
  }

  return (
    <Wrapper
      onMouseDown={handleClick}
      opened={opened}
      type='button'
      tabIndex={-1}
      disabled={disabled}
      readOnly={readOnly}
    >
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='arrow'
      >
        <path
          d='M4 6L8 10L12 6'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Wrapper>
  )
}

export default Arrow
