const Success = () => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.506 20.5C11.7715 9.40917 20.8453 0.5 32 0.5C43.1547 0.5 52.2285 9.40917 52.494 20.5H56C56.8284 20.5 57.5 21.1716 57.5 22V54C57.5 59.2467 53.2467 63.5 48 63.5H16C10.7533 63.5 6.5 59.2467 6.5 54V22C6.5 21.1716 7.17157 20.5 8 20.5H11.506ZM14.507 20.5C14.7717 11.0662 22.5022 3.5 32 3.5C41.4978 3.5 49.2283 11.0662 49.493 20.5H45.4909C45.2279 13.2758 39.2884 7.5 32 7.5C24.7116 7.5 18.7721 13.2758 18.5091 20.5H14.507ZM32 10.5C26.3686 10.5 21.7727 14.9332 21.5117 20.5H42.4883C42.2273 14.9332 37.6314 10.5 32 10.5ZM9.67393 55.5C10.3511 58.3667 12.9264 60.5 16 60.5H48C51.5899 60.5 54.5 57.5899 54.5 54V23.5H9.5V28.5H20C20.8284 28.5 21.5 29.1716 21.5 30V54C21.5 54.8284 20.8284 55.5 20 55.5H9.67393ZM9.5 31.5V34.5H18.5V31.5H9.5ZM9.5 37.5V40.5H18.5V37.5H9.5ZM9.5 43.5V46.5H18.5V43.5H9.5ZM9.5 49.5V52.5H18.5V49.5H9.5Z'
      fill='#3B42A5'
    />
    <path
      d='M42.8839 40.3839C43.372 39.8957 43.372 39.1043 42.8839 38.6161C42.3957 38.128 41.6043 38.128 41.1161 38.6161L37 42.7322L34.8839 40.6161C34.3957 40.128 33.6043 40.128 33.1161 40.6161C32.628 41.1043 32.628 41.8957 33.1161 42.3839L36.1161 45.3839C36.6043 45.872 37.3957 45.872 37.8839 45.3839L42.8839 40.3839Z'
      fill='#00A042'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.75 42C27.75 47.6609 32.3391 52.25 38 52.25C43.6609 52.25 48.25 47.6609 48.25 42C48.25 36.3391 43.6609 31.75 38 31.75C32.3391 31.75 27.75 36.3391 27.75 42ZM45.75 42C45.75 46.2802 42.2802 49.75 38 49.75C33.7198 49.75 30.25 46.2802 30.25 42C30.25 37.7198 33.7198 34.25 38 34.25C42.2802 34.25 45.75 37.7198 45.75 42Z'
      fill='#00A042'
    />
  </svg>
)

export default Success
