import { useEffect } from 'react'
import useNotification from 'utils/notification'

const Notify = props => {
  const notify = useNotification()

  useEffect(() => {
    notify(props)
  }, [])

  return null
}

export default Notify
