import { useTranslation } from 'react-i18next'
import Loader from 'components/Common/Loader'
import CompaniesList from './CompaniesList'
import { ICompany } from '../_methods'
import { Wrapper, SubTitle, Title } from './_styles'

interface IProps {
  onChange: (id: string) => void
  companies: ICompany[]
  loading: boolean
}

const Main = ({ companies, onChange, loading }: IProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Title>{t('modals.becomeRepresentative.title')}</Title>
      {companies.length > 0 ? (
        <SubTitle>{t('modals.becomeRepresentative.description1')}</SubTitle>
      ) : (
        <SubTitle>{t('modals.becomeRepresentative.description2')}</SubTitle>
      )}
      {loading ? (
        <Loader color='#3B42A5' size='30px' />
      ) : (
        companies.length > 0 && (
          <CompaniesList companies={companies} onChange={onChange} />
        )
      )}
    </Wrapper>
  )
}

export default Main
