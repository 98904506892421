import type { RootState } from 'store'

export const getSort = (state: RootState) => state?.retailWines?.sorting
export const getPageInfo = (state: RootState) =>
  state?.retailWines?.pageInfo ?? null
export const getLoading = (state: RootState) => state?.retailWines?.loading
export const getError = (state: RootState) => state?.retailWines?.error
export const getItems = (state: RootState) => state?.retailWines?.items
export const getQuery = (state: RootState) => state?.retailWines?.query ?? ''
export const getFilters = (state: RootState) =>
  state?.retailWines?.filters ?? []
export const getSelectedItems = (state: RootState) =>
  state?.retailWines?.selectedItems || []
export const getCurrency = (state: RootState) =>
  state?.retailWines?.currency ?? null
