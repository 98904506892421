import { getRefreshToken } from 'utils/token'

const API_URL = process.env.API_URL || ''

const refreshTokenMutation = (token: string) => {
  return JSON.stringify({
    variables: { token },
    query: `mutation RefreshToken($token: String!) {
      refreshToken(token: $token) {
        token, refreshToken
      }
    }`,
  })
}

export const fetchNewTokens = async () => {
  const t_r = getRefreshToken()

  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      body: refreshTokenMutation(t_r),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const result = await response.json()
    return result?.data?.refreshToken ?? {}
  } catch (e) {
    return {}
  }
}
