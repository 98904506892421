export const getOffsetModifier = offset => {
  return {
    name: 'offset',
    enabled: true,
    options: {
      offset: ({ placement }) => {
        if (Array.isArray(offset)) return offset
        let skidding = 0
        if (placement.includes('-start')) skidding = -8
        else if (placement.includes('-end')) skidding = 8
        return [skidding, offset]
      },
    },
  }
}
