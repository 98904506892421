import styled from 'styled-components'
import Left from './Left'
import { MainContainer, MainWrapper } from '../ForgotPassword/Common/_styles'

const Right = styled.div``
const ConfirmPassword = () => {
  return (
    <MainWrapper>
      <MainContainer>
        <Left />
        <Right />
      </MainContainer>
    </MainWrapper>
  )
}

export default ConfirmPassword
