import { createSlice } from '@reduxjs/toolkit'
import {
  CheckingPayload,
  ErrorPayload,
  FieldAndValue,
  FieldName,
  MerchantState,
  MerchantValues,
} from './types'
import validators from './validators'

const initialState: MerchantState = {
  values: {
    name: '',
    website: '',
    country: null,
    deliveryTime: null,
    deliveryTerms: '',
  },
  errors: {},
  checking: {},
}

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    setValue(state, { payload }: { payload: FieldAndValue }) {
      const { field, value } = payload
      // @ts-ignore
      state.values[field] = value
    },

    setError(state, { payload }: { payload: ErrorPayload }) {
      const { field, value } = payload
      state.errors[field] = value
    },

    setChecking(state, { payload }: { payload: CheckingPayload }) {
      const { field, value } = payload
      state.checking[field] = value
    },

    reset(state) {
      state.values = initialState.values
      state.errors = initialState.errors
      state.checking = initialState.checking
    },

    validateField(state, { payload }: { payload: FieldName }) {
      state.errors[payload] = validators[payload]?.(state.values)
    },

    validateForm(state) {
      const values = state.values

      const errors = Object.keys(validators).map(field => {
        return [field, validators[field](values)]
      })

      state.errors = Object.fromEntries(errors)
    },

    initForm(state, { payload }: { payload: MerchantValues }) {
      state.values = { ...initialState.values, ...payload }
      state.errors = initialState.errors
      state.checking = initialState.checking
    },
  },
})

export const { actions, reducer: merchant } = merchantSlice
export const {
  setValue,
  setError,
  setChecking,
  reset,
  validateField,
  validateForm,
  initForm,
} = actions
