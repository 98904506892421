import { lazy } from 'react'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import BG from 'assets/images/auth/login-background.jpg'

const Main = lazy(() =>
  import(/* webpackChunkName: "verify-company" */ './Main')
)

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  background-color: var(--color-night);
  background-image: url(${BG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  min-width: 318px;
  display: flex;
  justify-content: center;
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 768px;

  @media (max-width: 768px) {
    min-height: 1024px;
  }

  @media (max-width: 450px) {
    min-height: 768px;
  }
`

const Content = styled.div`
  max-width: 1048px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    width: 320px;
  }

  @media (max-width: 500px) {
    width: 288px;
  }
`

const VerifyCompany = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Header />
          <Main />
          <Footer />
        </Content>
      </Container>
    </Wrapper>
  )
}

export default VerifyCompany
