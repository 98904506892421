import styled from 'styled-components'

const Item = styled.div`
  cursor: pointer;
  border-left: 2px solid var(--color-white);
  font: var(--typography-12-20-semibold);
  padding: 7px 10px 7px 13px;
  min-height: 26px;
  margin: 2px 0;
  display: flex;
  align-items: center;

  :hover {
    background: #f9f9f9;
    border-left: 2px solid var(--color-velvet);
  }
`

const ListItem = props => {
  const { children, ...rest } = props

  return <Item {...rest}>{children}</Item>
}

export default ListItem
