import styled from 'styled-components'
import SecondaryTextButton48 from '../SecondaryTextButton48'

export const ErrorTextButton48 = styled(SecondaryTextButton48)`
  color: ${props =>
    props.disabled || props.fakeDisabled ? 'var(--color-sw-20)' : '#d00a00'};

  :focus-visible,
  :hover {
    color: ${props =>
      props.disabled || props.fakeDisabled ? 'var(--color-sw-20)' : '#B00B00'};
  }
`
