import { useTranslation } from 'react-i18next'
import { Wrapper, Badges, Badge } from './_styles'

const Header = () => {
  const { t } = useTranslation()
  const badgeList = [
    {
      text: t('modals.becomeRepresentative.badge.buyWines'),
      bgColor: 'rgba(16, 94, 69, 0.10)',
      textColor: '#105E45',
    },
    {
      text: t('modals.becomeRepresentative.badge.sellWines'),
      bgColor: 'rgba(148, 21, 116, 0.10)',
      textColor: '#941574',
    },
    {
      text: t('modals.becomeRepresentative.badge.createTS'),
      bgColor: 'rgba(35, 77, 165, 0.10)',
      textColor: '#234DA5',
    },
    {
      text: t('modals.becomeRepresentative.badge.printElabel'),
      bgColor: 'rgba(109, 47, 170, 0.10)',
      textColor: '#6D2FAA',
    },
    {
      text: t('modals.becomeRepresentative.badge.analize'),
      bgColor: 'rgba(140, 53, 23, 0.10)',
      textColor: '#8C3517',
    },
  ]

  return (
    <Wrapper>
      <Badges>
        {badgeList.map((badge, index) => (
          <Badge
            key={index}
            bgColor={badge?.bgColor}
            textColor={badge?.textColor}
          >
            {badge?.text}
          </Badge>
        ))}
      </Badges>
    </Wrapper>
  )
}

export default Header
