import styled, { css } from 'styled-components'
import { CompanyProps } from '..'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  row-gap: 8px;
  max-height: 240px;
  overflow: auto;
`
export const Company = styled.div<CompanyProps>`
  display: flex;
  align-items: center;
  text-align: left;
  flex-grow: 1;
  word-break: break-word;
  border: 1px solid var(--color-sw-10);
  border-radius: 8px;
  padding: 10px 12px;
  align-self: stretch;
  ${props =>
    props.selected &&
    css`
      background: var(--color-g-3);
      border: 1px solid var(--color-velvet);
    `}
`
export const CompanyName = styled.div`
  display: flex;
  gap: 4px;
  color: var(--color-night);
  text-overflow: ellipsis;
  font: var(--typography-14-16-semibold);
  margin-bottom: 4px;
`
export const TextWrapper = styled.div`
  flex-grow: 1;
`
export const CheckboxWrapper = styled.div`
  margin-left: 16px;
  align-self: center;
`
export const CompanyData = styled.div`
  margin-left: 4px;
`
export const Sub = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  margin-top: 4px;
  font: var(--typography-12-16-medium);
  color: var(--color-sw-60);
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`
