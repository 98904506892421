import { createSlice } from '@reduxjs/toolkit'
import { AwardState } from './types'

const initialState: AwardState = {
  values: {},
  errors: {},
  initialName: '',
}

const awardSlice = createSlice({
  name: 'award',
  initialState,
  reducers: {
    setValue: (state, { payload }) => {
      state.values = {
        ...state.values,
        [payload?.field]: payload?.value,
      }
    },
    setError: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        [payload?.field]: payload?.value,
      }
    },
    init: (state, { payload }) => {
      state.values = {
        ...initialState.values,
        ...payload,
        initialName: payload?.fullName,
      }
      state.errors = { ...initialState.errors }
    },
    reset: () => {
      return { ...initialState }
    },
  },
})

export const { actions, reducer: award } = awardSlice
export const { setValue, setError, init, reset } = actions
