import { useState } from 'react'
import styled from 'styled-components'
import { AttentionIcon } from 'components/Common/Icons'
import VerificationDialog from './VerificationDialog'
import { useTranslation } from 'react-i18next'
import useNotification from 'utils/notification'

export const Wrapper = styled.div`
  background: rgba(208, 10, 0, 0.05);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  color: #d00a00;
`

const Message = styled.div`
  font: var(--typography-12-16-medium);
  margin-left: 8px;
`

const Delimiter = styled.div`
  background: rgb(208, 10, 0, 0.15);
  width: 1px;
  height: 24px;
  margin: 0 16px;
`

const VerifyNow = styled.button`
  appearance: none;
  border: none;
  background: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font: var(--typography-12-16-semibold);
  color: #3b42a5;
`

const AlertAccountNotVerified = () => {
  const { t } = useTranslation()
  const notify = useNotification()
  const [verifyDialogOpened, setVerifyDialogOpened] = useState(false)

  const handleRequestSent = () => {
    setVerifyDialogOpened(false)
    notify(t('myCompany.notifications.verifySent'), 'info')
  }

  return (
    <>
      <Wrapper>
        <AttentionIcon size={16} />

        <Message>{t('myCompany.notVerified')}</Message>
        <Delimiter />
        <VerifyNow onClick={() => setVerifyDialogOpened(true)} type='button'>
          {t('myCompany.verifyNow')}
        </VerifyNow>
      </Wrapper>

      {verifyDialogOpened && (
        <VerificationDialog
          onRequestSent={handleRequestSent}
          onClose={() => setVerifyDialogOpened(false)}
        />
      )}
    </>
  )
}

export default AlertAccountNotVerified
