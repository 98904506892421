import { css } from 'styled-components'

type MenuItemStylesProps = {
  active?: boolean
  disabled?: boolean
}

export const menuItemStyles = (props: MenuItemStylesProps = {}) => css`
  border-radius: 8px;
  background: ${props.disabled
    ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #121432'
    : 'linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #121432'};
  color: ${props.disabled
    ? 'var(--grey-70, #595A6F)'
    : props.active
    ? 'var(--grey-10, #E7E7EA)'
    : 'var(--grey-40, #A0A1AD)'};

  :active {
    background: ${props.disabled
      ? 'var(--grey-100, #121432)'
      : 'linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #121432'};
  }
`
