import { FC } from 'react'
import type { IconProps } from '../../types'

// prettier-ignore
const NotificationInfoIcon: FC<IconProps> = ({ size = 20, color = '#3B42A5' }) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3720_318107)'>
      <circle cx='10.0001' cy='10' r='10' fill={color} />
      <path d='M10.0001 5V5.01' stroke='white' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.00012 9.01001H10.0001V15.01L8.00012 15H12.0001' stroke='white' strokeWidth='2'
            strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_3720_318107'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default NotificationInfoIcon
