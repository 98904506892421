import { FC, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import SubItem from './SubItem'
import { MenuItem } from '../../../../_methods'
import { ButtonMenuItem, CollapseButton } from '../../_styles'
import { getCompanyVerified } from 'store/me'

// prettier-ignore
const CollapseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path fill="currentColor" fillRule="evenodd" d="M9.41 6.91a.83.83 0 0 1 1.18 0l5 5a.83.83 0 0 1-1.18 1.18L10 8.68l-4.41 4.4a.83.83 0 1 1-1.18-1.17l5-5Z" clipRule="evenodd"/>
  </svg>
)

interface MenuItemWithSubItemsProps {
  isActive: boolean
  items: MenuItem['items']
  children: ReactNode
  disabled?: boolean
}

const MenuItemWithSubItems: FC<MenuItemWithSubItemsProps> = props => {
  const { children, isActive, items, disabled } = props

  const isVerified = useSelector(getCompanyVerified)

  const [opened, setOpened] = useState(isActive)
  const toggle = () => setOpened(prev => (disabled ? false : !prev))

  const location = useLocation()
  const currentPath = location.pathname

  return (
    <>
      <ButtonMenuItem onClick={toggle} $active={isActive} $disabled={disabled}>
        {children}

        <CollapseButton $opened={opened}>
          <CollapseIcon />
        </CollapseButton>
      </ButtonMenuItem>
      {!disabled &&
        opened &&
        items!.map(item => (
          <SubItem
            key={item.id}
            title={item.title}
            isPro={item.isPro}
            link={item.link}
            badge={item.badge}
            active={!!matchPath(currentPath, { path: item?.link })}
            disabled={!isVerified && item.forVerifiedOnly}
          />
        ))}
    </>
  )
}

export default MenuItemWithSubItems
