import { FC } from 'react'
import styled from 'styled-components'
import CompanyTypeBadge from 'components/Common/CompanyTypeBadge'
import { lineClamp } from 'components/Common/GlobalStyles'

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  max-width: 100%;
  overflow: hidden;
  user-select: none;
`

const Name = styled.div`
  color: var(--grey-10, #e7e7ea);
  font: var(--typography-13-16-semibold);
  max-width: 100%;
  overflow: hidden;
  ${lineClamp()};
`

const Type = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  color: var(--grey-50, #888998);
  font: var(--typography-12-16-medium);
  max-width: 100%;
  overflow: hidden;

  ${lineClamp()};
`

interface CompanyInfoProps {
  data?: {
    tradeName?: string | null
    companyCategory: {
      id: number
      shortName: string
    }
  }
}

const CompanyInfo: FC<CompanyInfoProps> = ({ data }) => {
  return (
    <Info>
      <Name>{data?.tradeName}</Name>
      <Type>
        <CompanyTypeBadge type={data?.companyCategory?.id} />
        {data?.companyCategory?.shortName}
      </Type>
    </Info>
  )
}

export default CompanyInfo
