import styled from 'styled-components'

export const Wrapper = styled.div<{
  $maxWidth?: string
  $visible?: boolean
  $whiteSpace?: string
}>`
  background: #f7d7ae;
  border-radius: 8px;
  padding: 8px 12px;
  max-width: ${props => props.$maxWidth};
  white-space: ${props => props.$whiteSpace};
  display: ${props =>
    props.$visible === undefined
      ? null
      : (props.$visible && 'block') || 'none'};
  font: var(--typography-12-16-normal);
  font-style: normal;
  font-weight: 500;
  color: #41435b;
  z-index: var(--z-index-tooltip);
  word-break: break-word;
  position: relative;
`

export const Arrow = styled.div`
  z-index: calc(var(--z-index-tooltip) - 1px);
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  width: 16px;
  height: 16px;

  [data-popper-placement^='top'] & {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' fill='none' viewBox='0 0 16 8'%3E%3Cpath fill='%23F7D7AE' d='M9.5 7.3 16 0H0l6.5 7.3a2 2 0 0 0 3 0Z'/%3E%3C/svg%3E%0A");
    width: 16px;
    height: 8px;
    bottom: -8px;
  }

  [data-popper-placement^='bottom'] & {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' fill='none' viewBox='0 0 16 8'%3E%3Cpath fill='%23F7D7AE' d='M6.5.7 0 8h16L9.5.7a2 2 0 0 0-3 0Z'/%3E%3C/svg%3E%0A");
    width: 16px;
    height: 8px;
    top: -8px;
  }

  [data-popper-placement^='left'] & {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' fill='none' viewBox='0 0 8 16'%3E%3Cpath fill='%23F7D7AE' d='M7.3 6.5 0 0v16l7.3-6.5a2 2 0 0 0 0-3Z'/%3E%3C/svg%3E%0A");
    width: 8px;
    height: 16px;
    right: -8px;
  }

  [data-popper-placement^='right'] & {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' fill='none' viewBox='0 0 8 16'%3E%3Cpath fill='%23F7D7AE' d='M.7 9.5 8 16V0L.7 6.5a2 2 0 0 0 0 3Z'/%3E%3C/svg%3E%0A");
    width: 8px;
    height: 16px;
    left: -8px;
  }
`
