import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import UserAvatar from '../UserAvatar'
import UserInfo from '../UserInfo'
import {
  CompaniesIcon,
  LogoutIcon,
  NotificationsIcon,
  SettingsIcon,
  UserIcon,
} from './Icons'
import {
  Wrapper,
  Header,
  Body,
  ListItem,
  ListItemLink,
  ListItemButton,
  Divider,
} from './_styles'
import { getUserRole } from 'store/me'
import { Roles } from 'utils/consts/userRoles'

interface UserDetailsProps {
  data: any
  onClose: () => void
  onLogout: () => void
}

const UserDetails: FC<UserDetailsProps> = ({ data, onClose, onLogout }) => {
  const { t } = useTranslation()
  const role = useSelector(getUserRole)

  const id = data?.id

  return (
    <Wrapper>
      <Header>
        <UserAvatar avatar={data.avatar64} size={64} />
        <UserInfo data={data} align='center' />
      </Header>
      <Body onClick={onClose}>
        <ListItem>
          <ListItemLink to={`/acc/user/${id}/profile`}>
            <UserIcon />
            {t('common.menu.user.profile')}
          </ListItemLink>
        </ListItem>

        {role === Roles.PERSON && (
          <ListItem>
            <ListItemLink to={`/acc/user/${id}/companies`}>
              <CompaniesIcon />
              {t('common.menu.user.companies')}
            </ListItemLink>
          </ListItem>
        )}

        <ListItem>
          <ListItemLink to={`/acc/user/${id}/notification`}>
            <NotificationsIcon />
            {t('common.menu.user.notifications')}
          </ListItemLink>
        </ListItem>
        <ListItem>
          <ListItemLink to={`/acc/user/${id}/settings`}>
            <SettingsIcon />
            {t('common.menu.user.settings')}
          </ListItemLink>
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemButton onClick={onLogout}>
            <LogoutIcon />
            {t('common.menu.logoutTip')}
          </ListItemButton>
        </ListItem>
      </Body>
    </Wrapper>
  )
}

export default UserDetails
