import {
  CHECKBOX_LIST,
  filterTypes,
  INTERVAL,
  NUMBER,
  PRICE_INTERVAL,
  RADIO_LIST,
  RHEOSTAT,
  RRP_INTERVAL,
  SCORE,
  SWITCHER,
  MULTISELECT_SWITCHER,
  PRODUCT_RANK,
} from 'utils/consts/filters'
import i18n from 'utils/i18n'
import {
  offersFiltersKey,
  offersFiltersName,
  offersFiltersTitle,
} from 'utils/consts/offersFilters'

export const getBuyWineAnalysisRatingFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: offersFiltersKey().offersProducerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: offersFiltersKey().offersBrandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.countryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: offersFiltersKey().offersCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: offersFiltersKey().offersSpecialClassification,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: offersFiltersKey().offersVintageFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(offersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: offersFiltersKey().grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
    switchApiFilterName: offersFiltersKey().monocepageFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: offersFiltersKey().offersCertificatesFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: offersFiltersKey().offersAlcoholLevelFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(offersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: offersFiltersKey().offersWwlRatingFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.rrpFilter),
    filterName: offersFiltersName.rrpFilter,
    apiFilterName: offersFiltersKey().offersRrpFilter,
  },
  {
    type: filterTypes[PRODUCT_RANK],
    title: i18n.t(offersFiltersTitle.rankFilter),
    filterName: offersFiltersName.rankFilter,
    apiFilterName: offersFiltersKey().offersRankFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(offersFiltersTitle.onlyWithOffersFilter),
    filterName: offersFiltersName.onlyWithOffersFilter,
    apiFilterName: offersFiltersKey().onlyWithOffersFilter,
  },
  // offers filters
  {
    type: filterTypes[PRICE_INTERVAL],
    title: i18n.t(offersFiltersTitle.priceFilter),
    filterName: offersFiltersName.priceFilter,
    apiFilterName: offersFiltersKey().offersPriceFilter,
  },
  {
    type: filterTypes[NUMBER],
    title: i18n.t(offersFiltersTitle.minimalBatchFilter),
    filterName: offersFiltersName.minimalBatchFilter,
    apiFilterName: offersFiltersKey().offersMinimalBatchFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(offersFiltersTitle.deliveryTermsFilter),
    filterName: offersFiltersName.deliveryTermsFilter,
    apiFilterName: offersFiltersKey().offersDeliveryTermsFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(offersFiltersTitle.countryOfShipmentFilter),
    filterName: offersFiltersName.countryOfShipmentFilter,
    apiFilterName: offersFiltersKey().offersCountryOfShipmentFilter,
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(offersFiltersTitle.priceCoefFilter),
    filterName: offersFiltersName.priceCoefFilter,
    apiFilterName: offersFiltersKey().offersPriceCoefFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(offersFiltersTitle.offersOnlyForMyCompanyFilter),
    filterName: offersFiltersName.offersOnlyForMyCompanyFilter,
    apiFilterName: offersFiltersKey().offersOnlyForMyCompanyFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.exhibitionFilter),
    filterName: offersFiltersName.exhibitionFilter,
    apiFilterName: offersFiltersKey().offersExhibitionFilter,
  },
]
