import styled from 'styled-components'

const Svg = styled.svg`
  cursor: pointer;
`

const HeaderCloseButton = props => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    fill='none'
    viewBox='0 0 16 16'
    {...props}
  >
    <path
      fill='#A0A1AD'
      d='M12.243 3.757c-.39-.39-.945-.47-1.238-.176L8 6.586 4.995 3.58c-.293-.293-.847-.214-1.238.176-.39.39-.47.945-.176 1.238L6.586 8 3.58 11.005c-.293.293-.214.847.176 1.238.39.39.945.47 1.238.176L8 9.415l3.005 3.005c.293.293.847.214 1.238-.176.39-.39.47-.945.176-1.238L9.415 8l3.005-3.005c.293-.293.214-.847-.176-1.238Z'
    />
  </Svg>
)

export default HeaderCloseButton
