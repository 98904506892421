import { useApolloClient } from '@apollo/client'
import { WebsiteAndEmailHaveSameDomainDocument } from 'api/hooks'

export const useCheckEmail = () => {
  const apolloClient = useApolloClient()

  return async (email, website) => {
    const response = await apolloClient.query({
      query: WebsiteAndEmailHaveSameDomainDocument,
      fetchPolicy: 'no-cache',
      variables: { email, website },
    })

    return response?.data?.websiteAndEmailHaveSameDomain
  }
}
