import { FC } from 'react'
import styled from 'styled-components'
import GettingStarted from './GettingStarted'
import Header from './Header'
import CompanySelector from './CompanySelector'
import Navigation from './Navigation'
import User from './User'
import AddCompanyButton from './AddCompanyButton'

const Wrapper = styled.div`
  &,
  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--grey-100, #121432);
  z-index: var(--z-index-tooltip);
`

interface BurgerMenuProps {
  onClose: () => void
  onVerify: () => void
  onLogout: () => void
}

const BurgerMenu: FC<BurgerMenuProps> = ({ onClose, onVerify, onLogout }) => {
  const handleVerify = () => {
    onVerify()
    onClose()
  }

  return (
    <Wrapper>
      <Header onClose={onClose} />
      <CompanySelector onClose={onClose} onVerify={handleVerify} />
      <Navigation onClose={onClose} />
      <AddCompanyButton onClose={onClose} />
      <GettingStarted onClose={onClose} />
      <User onClose={onClose} onLogout={onLogout} />
    </Wrapper>
  )
}

export default BurgerMenu
