import { useEffect } from 'react'
import progress from 'nprogress'
import 'assets/css/progress/index.css'
import PrivateRoute from 'components/Common/Routes/PrivateRoute'

const Private = props => {
  useEffect(() => {
    progress.done()
  })

  progress.start()

  return <PrivateRoute {...props} />
}

export default Private
