import { FC } from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  position: absolute;
  right: -4px;
  top: -4px;

  && {
    width: 12px;
    height: 12px;
  }
`

const Counter = styled.span`
  display: inline-flex;
  padding: 0 1px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  border-radius: 10px;
  background: var(--brand-red, #88122b);
  color: var(--grey-white, #fff);
  text-align: center;
  font: var(--typography-11-16-semibold);
  margin-left: 8px;
`

interface IndicatorProps {
  value?: boolean | number
}

const isNumber = (value: any): value is number => {
  return Number.isInteger(value)
}

const Indicator: FC<IndicatorProps> = ({ value }) => {
  if (value === true) {
    // prettier-ignore
    return (
      <Svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='6' cy='6' r='5' fill='#88122B' stroke='#242642' strokeWidth='2' />
      </Svg>
    )
  }

  if (isNumber(value) && value > 0) {
    return <Counter>{value}</Counter>
  }

  return null
}

export default Indicator
