import styled, { css } from 'styled-components'
import { SecondaryButtonFlexable32 } from '../SecondaryButtonFlexable32'

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: ${props => (props.iconOnly ? '0 -2px' : '0 8px 0 0')};
`

const Wrapper = styled(SecondaryButtonFlexable32)`
  ${props =>
    props.iconOnly &&
    css`
      border-radius: 8px;
    `}
`

export const SecondaryIconButtonFlexable32 = props => {
  const { icon, children, ...rest } = props
  const iconOnly = !children

  return (
    <Wrapper iconOnly={iconOnly} {...rest}>
      <Icon iconOnly={iconOnly}>{icon}</Icon>
      {children}
    </Wrapper>
  )
}
