import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import VerificationDialog from '../../../Common/VerifyAlert/AlertAccountNotVerified/VerificationDialog'
import useNotification from 'utils/notification'

interface VerifyDialogProps {
  onClose: () => void
}

const VerifyDialog: FC<VerifyDialogProps> = ({ onClose }) => {
  const { t } = useTranslation()

  const notify = useNotification()

  const handleRequestSent = () => {
    onClose()
    notify(t('myCompany.notifications.verifySent'), 'info')
  }

  return (
    <VerificationDialog onRequestSent={handleRequestSent} onClose={onClose} />
  )
}

export default VerifyDialog
