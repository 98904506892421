import styled from 'styled-components'

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #121432;
`

export default Title
