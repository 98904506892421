import styled from 'styled-components'
import Cookies from 'components/Common/Cookies'
import Left from './Left'
import { MainContainer, MainWrapper } from './Common/_styles'

const Right = styled.div``
const ForgotPassword = () => {
  const cookiePolicy = localStorage.getItem('wwl-cookie_policy') || false

  return (
    <MainWrapper>
      {!cookiePolicy && <Cookies />}
      <MainContainer>
        <Left />
        <Right />
      </MainContainer>
    </MainWrapper>
  )
}

export default ForgotPassword
