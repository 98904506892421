import styled from 'styled-components'

interface PrimaryButtonProps {
  disabled?: boolean
  fakeDisabled?: boolean
}

const BaseButton = styled.button.attrs<PrimaryButtonProps>(props => ({
  className: props.fakeDisabled || props.disabled ? 'disabled' : '',
}))<PrimaryButtonProps>`
  font-family: var(--typography-normal);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  appearance: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

BaseButton.defaultProps = {
  type: 'button',
}

export default BaseButton
