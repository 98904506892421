import styled from 'styled-components'
import ListItem from './ListItem'

const Wrapper = styled.div`
  z-index: 21;
  position: absolute;
  top: 38px;
  padding: 24px 0 10px;
  width: 100%;
  background: var(--color-white);
  border-left: 1px solid #e2e2ea;
  border-right: 1px solid #e2e2ea;
  border-bottom: 1px solid #e2e2ea;
  box-sizing: border-box;
  box-shadow: 0 3px 5px rgba(95, 99, 104, 0.05),
    0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);
  border-radius: 0 0 8px 8px;
`

const Content = styled.div`
  max-height: 264px;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
`

const List = props => {
  const { onSelect, items } = props

  return (
    <Wrapper>
      <Content>
        {(Array.isArray(items) ? items : []).map(item => (
          <ListItem key={item.id} onMouseDown={() => onSelect(item)}>
            {item.label}
          </ListItem>
        ))}
      </Content>
    </Wrapper>
  )
}

export default List
