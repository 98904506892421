import Login from 'components/Pages/Login'
import Signup from 'components/Pages/Signup'
import Error500 from 'components/Common/Errors/500'
import Error404 from 'components/Common/Errors/404'
import ConfirmMail from 'components/Pages/ConfirmMail'
import ForgotPassword from 'components/Pages/ForgotPassword'
import ConfirmPassword from 'components/Pages/ConfirmPassword'
import VerifyCompany from 'components/Pages/VerifyCompany'
import Payment from 'components/Pages/Payment'
import {
  LOGIN_PAGE_PATH,
  PAYMENT_PAGE_PATH,
  SIGNUP_PAGE_PATH,
} from 'utils/consts/path'

export const publicRoutes = [
  {
    title: 'Login',
    exact: true,
    path: LOGIN_PAGE_PATH,
    component: Login,
  },
  {
    title: 'Signup',
    exact: true,
    path: SIGNUP_PAGE_PATH,
    component: Signup,
  },
  {
    title: 'ConfirmMail',
    exact: true,
    path: '/acc/:type(confirm-mail|change-email)',
    component: ConfirmMail,
  },
  {
    title: 'ForgotPassword',
    exact: true,
    path: '/acc/forgot-password',
    component: ForgotPassword,
  },
  {
    title: 'ConfirmPassword',
    exact: true,
    path: '/acc/confirm-password',
    component: ConfirmPassword,
  },
  {
    title: 'VerifyCompany',
    exact: true,
    path: '/acc/verify-company',
    component: VerifyCompany,
  },
  {
    title: 'Payment',
    exact: true,
    path: PAYMENT_PAGE_PATH,
    component: Payment,
  },
  {
    title: 'Error',
    path: '/acc/error',
    component: Error500,
  },
  {
    title: 'Error404',
    path: '',
    component: Error404,
  },
]
