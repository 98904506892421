import styled from 'styled-components'
import SuccessIcon from 'components/Pages/ConfirmMail/Icons/Success'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Icon = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
`
const Title = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  color: var(--color-night);
  font: var(--typography-18-24-semibold);
  text-align: center;
`
const Text = styled.div`
  color: var(--color-sw-60);
  text-align: center;
  font: var(--typography-14-20-regular);
  margin-bottom: 24px;
`

const Success = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Icon>
        <SuccessIcon />
      </Icon>
      <Title>{t('confirmMail.gratz')}</Title>
      <Text>{t('confirmMail.successConfirmEmail')}</Text>
    </Wrapper>
  )
}

export default Success
