import { GraphQLError } from 'graphql'
import { path } from 'ramda'

export const getError = (error, info?: string) => {
  switch (info) {
    case 'message':
      return (
        path(['networkError', 'result', 'errors', '0', 'message'], error) ||
        path(['graphQLErrors', '0', 'message'], error)
      )
    case 'code':
      return (
        path(
          ['networkError', 'result', 'errors', '0', 'extensions', 'code'],
          error
        ) || path(['graphQLErrors', '0', 'extensions', 'code'], error)
      )
    default:
      return (
        path(['networkError', 'result', 'errors', '0', 'message'], error) ||
        path(['graphQLErrors', '0', 'message'], error)
      )
  }
}

export const getGraphQlErrorCode = (error: GraphQLError) => {
  return error?.extensions?.code
}
