import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useWineReviewsPendingSubscription } from 'api/hooks'
import { ManagementRoles } from 'utils/consts/userRoles'
import { ls } from 'utils/my-localstorage'
import { setReviewsPendings } from 'store/administration'

const useReviewPending = () => {
  const lsRole = ls.getItem('wwl-role_code') ?? ''
  const isManagement = Object.values(ManagementRoles).includes(lsRole)

  const dispatch = useDispatch()
  const { data: subscriptionData } = useWineReviewsPendingSubscription({
    skip: !isManagement,
    context: { ignoreAccessErrors: true },
  })

  useEffect(() => {
    if (subscriptionData) {
      dispatch(setReviewsPendings(subscriptionData?.wineReviewsPending?.count))
    }
  }, [subscriptionData])
}

export default useReviewPending
