import styled from 'styled-components'
import breakpoints from 'utils/consts/breakpoints'

const SvgDesktop = styled.svg`
  display: none;

  @media screen and (min-width: ${breakpoints.container.l}px) {
    display: block;
  }
`
const SvgMobile = styled.svg`
  display: block;

  @media screen and (min-width: ${breakpoints.container.l}px) {
    display: none;
  }
`

const QuestionIcon = () => {
  // prettier-ignore
  return (
    <>
      <SvgDesktop width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5.57145 4.18087C5.20231 4.50899 5.01188 4.94991 5.00016 5.50362H6.19108C6.20573 5.2546 6.29069 5.05684 6.44596 4.91036C6.60124 4.76095 6.80485 4.68624 7.0568 4.68624C7.30583 4.68624 7.50798 4.75362 7.66325 4.88839C7.81559 5.02315 7.89176 5.196 7.89176 5.40694C7.89176 5.61495 7.83903 5.78634 7.73356 5.9211C7.63102 6.05587 7.43766 6.20821 7.15348 6.37813C6.84587 6.55977 6.63053 6.75753 6.50749 6.9714C6.38151 7.19698 6.33317 7.4797 6.36247 7.81954L6.37126 8.07442H7.53581V7.84151C7.53581 7.62472 7.58708 7.44893 7.68962 7.31417C7.79216 7.1794 7.98991 7.02706 8.28288 6.85714C8.90983 6.488 9.22331 5.99141 9.22331 5.36739C9.22331 4.86348 9.03288 4.45479 8.65202 4.14132C8.2653 3.82491 7.75993 3.66671 7.13591 3.66671C6.4738 3.66671 5.95231 3.83809 5.57145 4.18087Z'
          fill='currentColor' />
        <path
          d='M7.8335 9.50004C7.8335 9.96028 7.4604 10.3334 7.00016 10.3334C6.53993 10.3334 6.16683 9.96028 6.16683 9.50004C6.16683 9.0398 6.53993 8.66671 7.00016 8.66671C7.4604 8.66671 7.8335 9.0398 7.8335 9.50004Z'
          fill='currentColor' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M7.00016 0.333374C3.31826 0.333374 0.333496 3.31814 0.333496 7.00004C0.333496 10.6819 3.31826 13.6667 7.00016 13.6667C10.6821 13.6667 13.6668 10.6819 13.6668 7.00004C13.6668 3.31814 10.6821 0.333374 7.00016 0.333374ZM1.66683 7.00004C1.66683 4.05452 4.05464 1.66671 7.00016 1.66671C9.94568 1.66671 12.3335 4.05452 12.3335 7.00004C12.3335 9.94556 9.94568 12.3334 7.00016 12.3334C4.05464 12.3334 1.66683 9.94556 1.66683 7.00004Z'
              fill='currentColor' />
      </SvgDesktop>

      <SvgMobile width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.85693 5.77124C7.30322 6.26343 7.01758 6.9248 7 7.75537H8.78638C8.80835 7.38184 8.93579 7.08521 9.1687 6.86548C9.40161 6.64136 9.70703 6.5293 10.085 6.5293C10.4585 6.5293 10.7617 6.63037 10.9946 6.83252C11.2231 7.03467 11.3374 7.29395 11.3374 7.61035C11.3374 7.92236 11.2583 8.17944 11.1001 8.38159C10.9463 8.58374 10.6562 8.81226 10.23 9.06714C9.76855 9.3396 9.44556 9.63623 9.26099 9.95703C9.07202 10.2954 8.99951 10.7195 9.04346 11.2292L9.05664 11.6116H10.8035V11.2622C10.8035 10.937 10.8804 10.6733 11.0342 10.4712C11.188 10.269 11.4846 10.0405 11.9241 9.78564C12.8645 9.23193 13.3347 8.48706 13.3347 7.55103C13.3347 6.79517 13.0491 6.18213 12.4778 5.71191C11.8977 5.2373 11.1396 5 10.2036 5C9.21045 5 8.42822 5.25708 7.85693 5.77124Z'
          fill='currentColor' />
        <path
          d='M11.25 13.75C11.25 14.4404 10.6904 15 10 15C9.30964 15 8.75 14.4404 8.75 13.75C8.75 13.0596 9.30964 12.5 10 12.5C10.6904 12.5 11.25 13.0596 11.25 13.75Z'
          fill='currentColor' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z'
              fill='currentColor' />
      </SvgMobile>
    </>
  )
}

export default QuestionIcon
