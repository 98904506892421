import type { RootState } from 'store'

export const getValues = (state: RootState) => {
  return state.wineCreate.values
}

export const getErrors = (state: RootState) => {
  return state.wineCreate.errors
}

// prettier-ignore
export const objectValueByPath = (values: any, path: string, defaultValue?: any) => {
    if (!path) return values

    const result = path.split('.').reduce((obj, field) => {
        const key = (isNaN(Number(field))) ? field : Number(field)
        return obj?.[key]
    }, values)

    return result === undefined ? defaultValue : result

}

export const getValue = <T = unknown>(path: string, defaultValue?: any) => {
  return (state: RootState) => {
    return objectValueByPath(state.wineCreate.values, path, defaultValue) as T
  }
}

export const getError = <T = unknown>(path: string, defaultValue?: any) => {
  return (state: RootState) => {
    return objectValueByPath(state.wineCreate.errors, path, defaultValue) as T
  }
}

export const getFormLoading = (state: RootState) => state?.wineCreate?.loading

export const getPreloadedData = (state: RootState) =>
  state?.wineCreate?.preloaded
