import { FC, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import UserAvatar from './UserAvatar'
import { getUserData } from 'store/me'
import UserDetails from './UserDetails'
import UserInfo from './UserInfo'
import { menuItemStyles } from '../_styles'

const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  padding-top: 15px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid var(--grey-80, #41435b);
  background: var(--grey-100, #121432);
  position: relative;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  align-self: stretch;
  padding: 12px;
  ${menuItemStyles()};
`

interface UserProps {
  onClose: () => void
  onLogout: () => void
}

const User: FC<UserProps> = ({ onClose, onLogout }) => {
  const data = useSelector(getUserData)

  const [opened, setOpened] = useState(false)

  return (
    <Wrapper>
      <Content onClick={() => setOpened(true)}>
        <UserAvatar avatar={data?.avatar} />
        <UserInfo data={data} />
      </Content>

      {opened && (
        <UserDetails
          data={data}
          onSelect={onClose}
          onClose={() => setOpened(false)}
          onLogout={onLogout}
        />
      )}
    </Wrapper>
  )
}

export default User
