/*
Имена ролей из поля masterName

query ($localeId: Int!) {
  companyCategories {
    id
    masterName
    localizedName(localeId: $localeId)
    isBuyer
    isSeller
  }
}
 */

export const PERSON = 'Person'
export const PRODUCER = 'Producer'
export const WHOLESALER = 'Wholesaler'
export const RESTAURANT = 'Restaurant'
export const RETAILER = 'Retailer/Independent wine shop'
export const CONTENT = 'CONTENT'
const ADMIN = 'ADMIN'
export const SUPERADMIN = 'SUPERADMIN'
const MANAGER = 'MANAGER'
export const PERSON_ROLE = 'PERSON'

const PersonCategories = {
  PERSON,
  PRODUCER,
  WHOLESALER,
  RESTAURANT,
  RETAILER,
}

export const Roles = {
  PERSON: PERSON_ROLE,
  CONTENT,
  ADMIN,
  SUPERADMIN,
  MANAGER,
}

export const PRIORITY_LEVELS = [
  PERSON_ROLE,
  MANAGER,
  CONTENT,
  ADMIN,
  SUPERADMIN,
]

export const RolesData = {
  [PERSON_ROLE]: {
    id: 1,
  },
  [CONTENT]: {
    id: 2,
  },
  [ADMIN]: {
    id: 3,
  },
  [SUPERADMIN]: {
    id: 4,
  },
  [MANAGER]: {
    id: 5,
  },
}

export const ManagementRoles = { CONTENT, ADMIN, SUPERADMIN, MANAGER }

export default PersonCategories
