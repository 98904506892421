import Loader from 'components/Common/Loader'
import Menu from 'components/Common/Menu'
import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoint } from 'utils/consts/breakpoints'

const LoaderWrapper = styled.div<{ $bgcolor?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.$bgcolor || 'var(--color-g-3)'};

  @media screen and ${breakpoint.m} {
    margin-top: 36px;
  }
`

interface PageLoaderProps {
  bgcolor?: string
}

const PageLoader: FC<PageLoaderProps> = ({ bgcolor }) => {
  return (
    <LoaderWrapper $bgcolor={bgcolor}>
      <Loader color='#3B42A5' size='30px' />
    </LoaderWrapper>
  )
}

export const PageLoaderWithMenu = () => {
  useEffect(() => {
    const tooltipsElement = document.getElementById('tooltips')!
    tooltipsElement.hidden = true

    return () => {
      tooltipsElement.hidden = false
    }
  }, [])

  return (
    <Menu>
      <PageLoader />
    </Menu>
  )
}

export default PageLoader
