import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WineCreateStore } from './types'
import { objectValueByPath } from './selectors'

const initialState: WineCreateStore = {
  preloaded: {},
  values: {
    wineCountryMatchesProducerCountry: true,
    packagesSize: [null],
    packagesType: [null],
  },
  errors: {},
  loading: true,
  isDirty: false,
}

const wineCreateSlice = createSlice({
  name: 'wineCreate',
  initialState,
  reducers: {
    setValue: (
      state,
      { payload }: PayloadAction<{ name: string; value: any }>
    ) => {
      state.values = {
        ...state.values,
        [payload.name]: payload.value,
      }
      state.isDirty = true
    },
    setError: (
      state,
      { payload }: PayloadAction<{ name: string; value: any }>
    ) => {
      state.errors = {
        ...state.errors,
        [payload.name]: payload.value,
      }
    },
    setArrayItemValue: (
      state,
      { payload }: PayloadAction<{ name: string; index: number; value: any }>
    ) => {
      const newValues = [...objectValueByPath(state.values, payload.name, [])]
      newValues[payload.index] = payload.value

      state.values = {
        ...state.values,
        [payload.name]: newValues,
      }
      state.isDirty = true
    },
    setArrayItemError: (
      state,
      { payload }: PayloadAction<{ name: string; index: number; value: any }>
    ) => {
      const newValues = [
        ...(objectValueByPath(state.errors, payload.name, []) ?? []),
      ]
      newValues[payload.index] = payload.value

      state.errors = {
        ...state.errors,
        [payload.name]: newValues,
      }
    },
    deleteArrayItem: (
      state,
      { payload }: PayloadAction<{ name: string; index: number }>
    ) => {
      state.values = {
        ...state.values,
        [payload.name]: objectValueByPath(
          state.values,
          payload.name,
          []
        ).filter((_: any, index: number) => index !== payload.index),
      }
    },
    setFormLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setPreloadedData: (state, { payload }: PayloadAction<any>) => {
      state.preloaded = payload
    },
    setErrors: (state, { payload }) => {
      state.errors = payload
    },
    setDirty: (state, { payload }: PayloadAction<boolean>) => {
      state.isDirty = payload
    },
    clear: (state, { payload }) => {
      const notClear = (payload ?? []).reduce((acc, item) => {
        acc[item] = state[item]
        return acc
      }, {})

      return {
        ...initialState,
        ...notClear,
      }
    },
    reset: (state, { payload }: PayloadAction<Record<string, any>>) => {
      state.values = {
        ...initialState.values,
        ...payload,
      }
      state.isDirty = false
      state.errors = null
    },
  },
})

export const { actions, reducer: wineCreate } = wineCreateSlice
export const {
  setValue,
  setError,
  setArrayItemValue,
  deleteArrayItem,
  setFormLoading,
  setPreloadedData,
  reset,
} = actions
