import { createSlice } from '@reduxjs/toolkit'
import { WarehousesState } from './types'

const initialState: WarehousesState = {
  item: {
    id: null,
    name: '',
    country: null,
    address: null,
  },
  items: [],
  formMode: null,
  countriesList: [],
  selectedItems: [],
  allCheckboxChecked: false,
  meta: {
    pageInfo: {},
    sorting: {
      type: null,
      name: null,
      value: null,
    },
  },
}

const warehousesSlice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    setCountry: (state, { payload }) => {
      state.item = { ...state.item, country: payload }
    },
    setCountriesList: (state, { payload }) => {
      state.countriesList = payload
    },
    setName: (state, { payload }) => {
      state.item = { ...state.item, name: payload }
    },
    setAddress: (state, { payload }) => {
      state.item = { ...state.item, address: payload }
    },
    formClear: state => {
      state.item = { ...initialState.item }
    },
    setItems: (state, { payload }) => {
      state.items = payload
    },
    setItem: (state, { payload }) => {
      state.item = payload
    },
    setSorting: (state, { payload }) => {
      state.meta = {
        ...state.meta,
        sorting: payload,
      }
    },
    setAlCheckboxChecked: (state, { payload }) => {
      state.allCheckboxChecked = payload
    },
    setPageInfo: (state, { payload }) => {
      state.meta = {
        ...state.meta,
        pageInfo: payload,
      }
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload
    },
    setFormMode: (state, { payload }) => {
      state.formMode = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: warehouses } = warehousesSlice
export const {
  setCountry,
  setCountriesList,
  setName,
  setAddress,
  formClear,
  setItems,
  setItem,
  setSorting,
  setAlCheckboxChecked,
  setPageInfo,
  setSelectedItems,
  setFormMode,
  reset,
} = actions
