import styled from 'styled-components'
import BaseButton from '../BaseButton'

const SecondaryTextButton = styled(BaseButton)`
  color: ${props =>
    props.disabled || props.fakeDisabled
      ? 'var(--color-sw-20)'
      : 'var(--color-sw-60)'};
  border: none;
  background: transparent;

  :focus-visible,
  :hover {
    color: ${props =>
      props.disabled || props.fakeDisabled
        ? 'var(--color-sw-20)'
        : 'var(--color-night-sky)'};
  }
`

export default SecondaryTextButton
