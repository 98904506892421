import styled, { css } from 'styled-components'
import BaseButton from '../BaseButton'

export const SecondaryButtonFlexable32_style = css`
  --button-size: 32px;

  min-height: var(--button-size);
  padding: 7px;
  color: var(--color-sw-60);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-sw-10);
  border-radius: 4px;
  cursor: pointer;
  letter-spacing: 0.3px;
  font: var(--typography-12-16-semibold);

  svg {
    fill: currentColor;
  }

  :focus-visible,
  :hover {
    background-color: ${props =>
      props.disabled || props.fakeDisabled
        ? 'var(--color-white)'
        : 'var(--color-sw-10)'};
    color: ${props =>
      props.disabled || props.fakeDisabled
        ? 'var(--color-sw-20)'
        : 'var(--color-velvet)'};
  }

  &.disabled,
  :disabled,
  [disabled] {
    color: var(--color-sw-20);
    border-color: var(--color-sw-10);
  }
`

export const SecondaryButtonFlexable32 = styled(BaseButton)`
  ${SecondaryButtonFlexable32_style}
`

export default SecondaryButtonFlexable32
