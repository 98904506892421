import { createSlice } from '@reduxjs/toolkit'
import { AddELabelModalStore, SettingsType, Step } from './types'

const initialState: AddELabelModalStore = {
  step: Step.wine,
  openedSteps: Step.wine,

  product: null,
  settings: null,
  eLabel: null,
  gtinError: null,
}

const addELabelModalSlice = createSlice({
  name: 'addELabelModal',
  initialState,
  reducers: {
    setProduct: (state, { payload }) => {
      state.product = payload
      state.settings = {
        linkType: 'digital',
        gtin: payload?.gtin ?? '',
        useWineGTIN: Boolean(payload?.gtin),
      }
    },
    nextStep: state => {
      const step = state.step === Step.wine ? Step.linkSettings : Step.preview
      const settings =
        step === Step.linkSettings && state.settings === null
          ? {
              linkType: 'digital',
              gtin: state.product?.gtin ?? '',
              useWineGTIN: Boolean(state.product?.gtin),
            }
          : state.settings

      state.step = step
      state.openedSteps = Math.max(step, state.openedSteps)
      state.settings = settings as SettingsType | null
    },
    setGTIN: (state, { payload }) => {
      state.settings = {
        ...state.settings,
        gtin: payload,
      } as SettingsType | null
    },
    setUseWineGTIN: (state, { payload }) => {
      state.settings = {
        ...state.settings,
        gtin: payload ? state.product?.gtin || '' : '',
        useWineGTIN: payload,
      } as SettingsType | null
    },
    setLinkType: (state, { payload }) => {
      state.settings = {
        ...state.settings,
        linkType: payload,
        gtin: payload === 'digital' ? state.product?.gtin || '' : '',
        useWineGTIN: Boolean(state.product?.gtin),
      }
    },
    setPreloadedProduct: (state, { payload }) => {
      state.product = payload
      state.step = Step.linkSettings
      state.openedSteps = Step.linkSettings
      state.settings = {
        linkType: 'digital',
        gtin: payload?.gtin ?? '',
        useWineGTIN: Boolean(payload?.gtin),
      }
    },
    setSelectedTab: (state, { payload }) => {
      state.step = payload
      state.openedSteps = Math.max(payload as Step, state.openedSteps)
    },
    setELabel: (state, { payload }) => {
      state.eLabel = payload
    },
    setGTINError: (state, { payload }) => {
      state.gtinError = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: addELabelModal } = addELabelModalSlice
export const {
  setProduct,
  nextStep,
  setGTIN,
  setUseWineGTIN,
  setLinkType,
  setPreloadedProduct,
  setSelectedTab,
  setELabel,
  setGTINError,
  reset,
} = actions
