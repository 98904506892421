import styled from 'styled-components'
import LogoIcon from 'components/Common/Icons/Logo/Big'
import LanguageSwitcher from 'components/Common/LanguageSwitcher'

const Wrapper = styled.div`
  display: flex;
`
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const Icon = styled.div`
  svg {
    height: 24px;
    width: 136px;
  }
`

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <Icon>
          <a href='https://worldwinelist.com/'>
            <LogoIcon />
          </a>
        </Icon>
        <LanguageSwitcher />
      </Container>
    </Wrapper>
  )
}

export default Header
