const Error = () => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2256_13767)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 0.5C11.1716 0.5 10.5 1.17157 10.5 2V15.3787L0.93934 24.9393C0.658035 25.2206 0.5 25.6022 0.5 26V62C0.5 62.8284 1.17157 63.5 2 63.5H62C62.8284 63.5 63.5 62.8284 63.5 62V26C63.5 25.6022 63.342 25.2206 63.0607 24.9393L53.5 15.3787V2C53.5 1.17157 52.8284 0.5 52 0.5H12ZM4.33945 25.7819L10.5 19.6213V29.9916L4.33945 25.7819ZM32 44.6832L13.5 32.0416V3.5H50.5V32.0416L32 44.6832ZM59.6605 25.7819L53.5 29.9916V19.6213L59.6605 25.7819ZM42.9533 40.832L60.5 28.8418V60.5H3.5V28.8418L21.0467 40.832L8.93934 52.9393C8.35355 53.5251 8.35355 54.4749 8.93934 55.0607C9.52513 55.6464 10.4749 55.6464 11.0607 55.0607L23.5671 42.5543L31.1537 47.7385C31.664 48.0872 32.336 48.0872 32.8463 47.7385L40.4329 42.5543L52.9393 55.0607C53.5251 55.6464 54.4749 55.6464 55.0607 55.0607C55.6464 54.4749 55.6464 53.5251 55.0607 52.9393L42.9533 40.832Z'
        fill='#3B42A5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.75 9.75V12.25H25.75V14C25.75 15.7069 26.6141 17.0654 27.6643 18.1505C28.3064 18.814 29.0691 19.4271 29.8454 20C29.0691 20.5729 28.3064 21.186 27.6642 21.8494C26.6141 22.9346 25.75 24.2931 25.75 26V27.75H23.75V30.25H40.25V27.75H38.25V26C38.25 24.2931 37.3859 22.9346 36.3357 21.8494C35.6937 21.186 34.9309 20.5729 34.1546 20C34.9309 19.4271 35.6937 18.814 36.3358 18.1505C37.3859 17.0654 38.25 15.7069 38.25 14V12.25H40.25V9.75H23.75ZM32 18.4718C31.0131 17.7713 30.1368 17.1105 29.4608 16.412C28.6363 15.56 28.25 14.7934 28.25 14V12.25H35.75V14C35.75 14.7934 35.3638 15.56 34.5392 16.412C33.8632 17.1105 32.9869 17.7713 32 18.4718ZM28.25 26C28.25 25.2066 28.6363 24.44 29.4608 23.5881C30.1367 22.8895 31.0131 22.2287 32 21.5282C32.9869 22.2287 33.8633 22.8895 34.5393 23.5881C35.3638 24.44 35.75 25.2065 35.75 26V27.75H28.25V26Z'
        fill='#D00A00'
      />
    </g>
    <defs>
      <clipPath id='clip0_2256_13767'>
        <rect width='64' height='64' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Error
