import styled from 'styled-components'
import breakpoints from 'utils/consts/breakpoints'

export const Wrapper = styled.section`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: var(--z-index-dialog);
`
export const Veil = styled.div`
  flex: 1 0 auto;
  height: 100%;
  background-color: rgba(18, 20, 50, 0.7);
`
export const Modal = styled.div`
  box-sizing: border-box;
  width: 384px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.container.m}px) {
    width: 100%;
    position: absolute;
    top: 96px;
    height: calc(100% - 96px);
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(18, 20, 50, 0.12) transparent;

    ::-webkit-scrollbar {
      width: 16px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 0 6px rgba(18, 20, 50, 0.12);
      border: solid 5px transparent;
    }
  }
`
export const TutorialHeader = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-sw-10);

  @media screen and (max-width: ${breakpoints.container.m}px) {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`
export const TutorialHeaderLeft = styled.div`
  display: flex;
  align-items: baseline;
  font: var(--typography-14-16-semibold);
  color: var(--color-night);
`
export const TutorialHeaderRight = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`
