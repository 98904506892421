import { FC } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import MenuItem from './MenuItem'
import { getCompanyVerified } from 'store/me'
import { useMenuItems } from '../../_methods'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 16px 0;
  overflow-y: auto;
  overflow-x: initial;
`

interface NavigationProps {
  onClose: () => void
}

const Navigation: FC<NavigationProps> = ({ onClose }) => {
  const items = useMenuItems()
  const isVerified = useSelector(getCompanyVerified)

  return (
    <Wrapper>
      {items.map(item => (
        <MenuItem
          key={item.id}
          item={item}
          disabled={!isVerified && item.forVerifiedOnly}
          onSelect={onClose}
        />
      ))}
    </Wrapper>
  )
}

export default Navigation
