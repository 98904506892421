import { FC } from 'react'
import type { IconProps } from '../types'

// prettier-ignore
const AttentionIcon: FC<IconProps> = ({ size = 24, color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12' r='11' fill={color} />
    <path fillRule='evenodd' clipRule='evenodd'
          d='M12 18.2857C12.8679 18.2857 13.5714 17.5821 13.5714 16.7142C13.5714 15.8464 12.8679 15.1428 12 15.1428C11.1321 15.1428 10.4286 15.8464 10.4286 16.7142C10.4286 17.5821 11.1321 18.2857 12 18.2857ZM12.0785 13.5714C11.3408 13.5714 10.7428 13.0437 10.7428 12.3928V6.89281C10.7428 6.2419 11.3408 5.71423 12.0785 5.71423C12.8162 5.71423 13.4142 6.2419 13.4142 6.89281V12.3928C13.4142 13.0437 12.8162 13.5714 12.0785 13.5714Z'
          fill='white' />
  </svg>
)

export default AttentionIcon
