import styled from 'styled-components'
import Breadcrumbs from './Breadcrumbs'
import Tutorial from 'components/Common/Tutorial'

export const Wrapper = styled.div`
  &,
  * {
    box-sizing: border-box;
  }

  background: var(--color-g-3);
  height: 48px;
  min-height: 48px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  color: var(--color-night);
`

const TopPanel = ({ title, breadcrumbs, icon, tutorial }) => {
  return (
    <Wrapper>
      <Breadcrumbs name={title} items={breadcrumbs} icon={icon} />
      {tutorial && <Tutorial tutorial={tutorial} />}
    </Wrapper>
  )
}

export default TopPanel
