import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PrimaryButtonFlexable32 } from 'components/Common/MasterComponents'
import { useTranslation } from 'react-i18next'
import { getLocaleForTermsPolitics } from 'utils/getLocaleId'
import Cookie from '../Image/cookie.png'

const Wrapper = styled.div`
  display: none;

  @media (max-width: 880px) {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.div`
  color: var(--color-night);
  font: var(--typography-14-22-semibold);
`
const Text = styled.div`
  font-size: 11px;
  line-height: 16px;
  color: #717284;
  text-align: center;
  width: 100%;
  margin: 8px 0 16px;

  a {
    cursor: pointer;
    color: var(--color-velvet);
    text-decoration: none;
  }
`
const Button = styled(PrimaryButtonFlexable32)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  border-radius: 16px;
  font: var(--typography-12-15-semibold);
`

interface IImage {
  link: string
}

const Image = styled.div<IImage>`
  height: 24px;
  min-width: 24px;
  background-image: url(${({ link }) => link});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 12px;
  background-position: 50%;
`

const Mobile = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const closeCookiePolicy = () => {
    localStorage.setItem('wwl-cookie_policy', 'true')
    history.push('')
  }
  const locale = getLocaleForTermsPolitics()

  return (
    <Wrapper>
      <Header>
        <Image link={Cookie} />
        <Title>{t('cookie.youHave')}</Title>
      </Header>
      <Text>
        {t('cookie.description')}{' '}
        <a
          href={`https://worldwinelist.com/${locale}/cookie-policy`}
          target='_blank'
          rel='noreferrer'
        >
          {t('cookie.policy')}
        </a>
        .
      </Text>
      <Button onClick={closeCookiePolicy}>{t('common.ok')}</Button>
    </Wrapper>
  )
}

export default Mobile
