import { useEffect, useRef } from 'react'

export const useScrollToActive = (isActive: boolean | undefined) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isActive && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [isActive])

  return ref
}
