import { useState } from 'react'
import styled from 'styled-components'
import { TutorialData } from 'components/Common/Menu'
import TipDialog from './TipDialog'
import TutorialButton from './TutorialButton'
import TutorialDialog from './TutorialDialog'
import {
  loadState,
  getClosedStep,
  saveState,
  useCheckTutorialCompleted,
} from './_methods'

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 48px;
  min-height: 48px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-night);
`

const Tutorial = ({ tutorial }: { tutorial: TutorialData }) => {
  const [tutorialVisible, setTutorialVisible] = useState(false)
  const [tipVisible, setTipVisible] = useState(false)

  useCheckTutorialCompleted(tutorial.page, finished => {
    if (finished) return
    const isFirstPageVisit = loadState(tutorial.page) === undefined
    if (!isFirstPageVisit) return
    setTutorialVisible(true)
  })

  return (
    <>
      <Wrapper>
        <TutorialButton onClick={() => setTutorialVisible(true)} />
      </Wrapper>

      {tipVisible && (
        <TipDialog
          onOpenTutorial={() => {
            setTipVisible(false)
            setTutorialVisible(true)
          }}
          onClose={() => setTipVisible(false)}
        />
      )}

      {tutorialVisible && (
        <TutorialDialog
          onClose={(step: 'done' | number) => {
            saveState(tutorial.page, step)
            setTutorialVisible(false)
            setTipVisible(step !== 'done')
          }}
          tutorial={tutorial}
          initialStep={getClosedStep(tutorial.page)}
        />
      )}
    </>
  )
}

export default Tutorial
