import { ls } from 'utils/my-localstorage'
import {
  useCompanySendVerificationEmailMutation,
  useCompanySendVerificationEmailToRepresenativeMutation,
  useCompanySendVerificationRequestMutation,
  useGetCompanyRepresentativesQuery,
} from 'api/hooks'
import Main from './Main'
import Notify from 'components/Common/Notify'
import { getError } from 'utils/apollo/getError'
import { getLocaleId } from 'utils/getLocaleId'
import { REQUEST as REQUEST_STATUS } from 'utils/consts/companyStatus'

const VerificationDialog = props => {
  const { onClose, onRequestSent } = props

  const companyId = ls.getItem('wwl-selected_company_id')

  const { data, loading, dataError } = useGetCompanyRepresentativesQuery({
    variables: { companyId },
  })

  const [verifyByEmail, { error: verifyByEmailError }] =
    useCompanySendVerificationEmailMutation()
  const [verifyByContent, { error: verifyByContentError }] =
    useCompanySendVerificationRequestMutation()
  const [verifyByUser, { error: verifyByUserError }] =
    useCompanySendVerificationEmailToRepresenativeMutation()
  const handleVerify = async (method, value) => {
    let success = false
    let response
    try {
      if (method === 'email') {
        response = await verifyByEmail({
          variables: {
            companyId,
            localeId: getLocaleId(),
            email: value,
          },
        })

        success = response?.data?.companySendVerificationEmail?.success ?? false
      }

      if (method === 'user') {
        response = await verifyByUser({
          variables: {
            companyId,
            localeId: getLocaleId(),
            representativeUserId: Number(value),
          },
        })

        success =
          response?.data?.companySendVerificationEmailToRepresenative
            ?.success ?? false
      }

      if (method === 'another') {
        response = await verifyByContent({
          variables: {
            companyId,
          },
        })

        success =
          response?.data?.companyVerificationRequest?.record?.id ?? false
      }
    } catch (e) {
      //
    }

    if (success) {
      onRequestSent()
      return true
    }

    return false
  }

  const companies = data?.me?.company?.representativeGroup?.companies ?? []
  const currentUserId = data?.me?.id
  const userIdsList = companies.map(company => company?.representativeUser?.id)
  const userLists = companies
    .map(company => {
      const id = company?.representativeUser?.id
      const label = company?.representativeUser?.username
      return { id, value: id, label }
    })
    .filter(
      (item, index) =>
        item.id &&
        item.id !== currentUserId &&
        userIdsList.indexOf(item.id) === index
    )

  const website = data?.me?.company?.website

  if (dataError)
    return <Notify text={getError(dataError, 'message')} color='#d00a00' />

  if (loading || !data) return null

  return (
    <>
      <Main
        onClose={onClose}
        userLists={userLists}
        website={website}
        onVerify={handleVerify}
        anotherDisabled={
          data?.me?.company?.verificationStatus?.code === REQUEST_STATUS
        }
      />

      {verifyByEmailError && (
        <Notify
          text={getError(verifyByEmailError, 'message')}
          color='#d00a00'
        />
      )}
      {verifyByUserError && (
        <Notify text={getError(verifyByUserError, 'message')} color='#d00a00' />
      )}
      {verifyByContentError && (
        <Notify
          text={getError(verifyByContentError, 'message')}
          color='#d00a00'
        />
      )}
    </>
  )
}

export default VerificationDialog
