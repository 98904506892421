import Loader from 'components/Common/Loader'
import { Wrapper, Content, LoaderWrapper } from './_styles'

/**
 * Отображает лоадер в кнопке, когда prop loading === true.
 * При этом содержимое кнопки скрывается, размер кнопки не меняется.
 * Размер и цвет лоадера подстраивается под используемую кнопку (из MasterComponents).
 *
 * @param  {boolean}         props.loading   Флаг, кнопка в состоянии loading
 * @param  {React.ReactNode} props.children  Текст кнопки
 * @return {React.ReactElement}
 *
 * @example
 * import {PrimaryFlexableButton32, ButtonContent} from 'components/Common/MasterComponents'
 * const Example = ({loading}) => {
 *   return (
 *     <PrimaryFlexableButton32>
 *       <ButtonContent loading={loading}>Save changes</ButtonContent>
 *     </PrimaryFlexableButton32>
 *   )
 * }
 */
export const ButtonContent = props => {
  const { loading, children } = props
  return (
    <Wrapper>
      <Content $isLoading={loading}>{children}</Content>
      <LoaderWrapper $isLoading={loading}>
        {loading && <Loader color='currentColor' />}
      </LoaderWrapper>
    </Wrapper>
  )
}

