import styled from 'styled-components'
import BaseButton from '../BaseButton'

export const TextButton16 = styled(BaseButton)`
  font-family: var(--typography-medium);
  font-size: 12px;
  --button-size: 16px;

  line-height: var(--button-size);
  color: ${props =>
    props.disabled || props.fakeDisabled
      ? 'var(--color-sw-20)'
      : 'var(--color-velvet)'};
  border: none;
  background: none;

  :focus-visible,
  :hover {
    color: ${props =>
      props.disabled || props.fakeDisabled
        ? 'var(--color-sw-20)'
        : 'var(--color-night-sky)'};
  }
`
