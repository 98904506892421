import { getLocaleId } from 'utils/getLocaleId'
import { useGetMeLazyQuery } from 'api/hooks'
import { initLocalStorage } from 'components/Common/Menu/_methods'
import { HOMEPAGE_PATH } from 'utils/consts/path'
import { useDispatch } from 'react-redux'
import { setData, setSelectedCompanyId } from 'store/me'
import { useHistory, useLocation } from 'react-router-dom'

export const useGetMeData = () => {
  const location = useLocation<any>()
  const history = useHistory()
  const dispatch = useDispatch()
  const [getMe] = useGetMeLazyQuery({
    variables: { localeId: getLocaleId() },
    context: { ignoreAccessErrors: true },
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (!data?.me) return
      const pathname = location?.state?.from?.pathname
      const redirectPath =
        pathname === '/' ? HOMEPAGE_PATH : pathname || HOMEPAGE_PATH
      const { selectedCompany } = initLocalStorage(data?.me)

      dispatch(setData(data.me))
      dispatch(setSelectedCompanyId(selectedCompany?.id))

      history.replace(redirectPath)
    },
  })
  return getMe
}
