import i18n from 'utils/i18n'
import PersonCategories from 'utils/consts/userRoles'

/**
 * Получает название и значение идентификатора компании из country и legalEntity.
 * Индентификатором может быть vat, branchNumber или identityNumber
 *
 * @param {object}     country     поле из ответа сервера с типом Country; должны
 *                                  быть запрошены поля: legalEntityDiscriminator,
 *                                  vatName, branchNumberName, identityNumberName
 *
 * @param {object} legalEntity поле из ответа сервера с типом LegalEntity;
 *                                  должны быть запрошены поля: vat, branchNumber,
 *                                  identityNumber
 *
 * @return {[string, string | undefined]}       массив из 2х элементов; первый - название
 *                                  идентификатора, второй - его значение
 */
export const getIdentityNumberData = (country, legalEntity) => {
  if (!country) return ['', '']

  warnFieldsNotPresent([
    !('legalEntityDiscriminator' in country) &&
      'country.legalEntityDiscriminator',
    !('vatName' in country) && 'country.vatName',
    !('branchNumberName' in country) && 'country.branchNumberName',
    !('identityNumberName' in country) && 'country.identityNumberName',
    ...(!legalEntity
      ? [legalEntity === undefined && 'legalEntity']
      : [
          !('vat' in legalEntity) && 'legalEntity.vat',
          !('branchNumber' in legalEntity) && 'legalEntity.branchNumber',
          !('identityNumber' in legalEntity) && 'legalEntity.identityNumber',
        ]),
  ])

  if (country?.legalEntityDiscriminator === 'VAT') {
    return [
      country?.vatName ?? i18n.t('company.create.vat.label'),
      legalEntity?.vat,
    ]
  }

  if (legalEntity?.branchNumber) {
    return [
      country?.branchNumberName ?? i18n.t('company.create.branchNumber.label'),
      legalEntity?.branchNumber,
    ]
  }

  return [
    country?.identityNumberName ??
      i18n.t('company.create.registrationNumber.label'),
    legalEntity?.identityNumber,
  ]
}

export const getRegistrationNumberData = (country, legalEntity) => {
  if (!country) return [i18n.t('company.create.registrationNumber.label'), '']

  warnFieldsNotPresent([
    !('branchNumberName' in country) && 'country.branchNumberName',
    !('identityNumberName' in country) && 'country.identityNumberName',

    ...(!legalEntity
      ? [legalEntity === undefined && 'legalEntity']
      : [
          !('branchNumber' in legalEntity) && 'legalEntity.branchNumber',
          !('identityNumber' in legalEntity) && 'legalEntity.identityNumber',
        ]),
  ])

  if (legalEntity?.branchNumber) {
    return [
      country?.branchNumberName ?? i18n.t('company.create.branchNumber.label'),
      legalEntity?.branchNumber,
    ]
  }

  return [
    country?.identityNumberName ??
      i18n.t('company.create.registrationNumber.label'),
    legalEntity?.identityNumber,
  ]
}

export const getVatData = (country, legalEntity) => {
  if (!country) return [i18n.t('company.create.vat.label'), '']

  warnFieldsNotPresent([
    !('vatName' in country) && 'country.vatName',

    ...(!legalEntity
      ? [legalEntity === undefined && 'legalEntity']
      : [!('vat' in legalEntity) && 'legalEntity.vat']),
  ])

  return [
    country?.vatName ?? i18n.t('company.create.vat.label'),
    legalEntity?.vat,
  ]
}

/**
 *
 * @param legalEntityForm
 * @return {string}
 */
export const getLegalEntityFormFullName = legalEntityForm => {
  return legalEntityForm?.shortName
    ? `${legalEntityForm.shortName} (${legalEntityForm?.originName})`
    : legalEntityForm?.originName || ''
}

const warnFieldsNotPresent = notPresentFields => {
  if (process.env.NODE_ENV !== 'production') {
    const notPresent = notPresentFields.filter(Boolean)

    if (notPresent.length > 0) {
      throw new Error('В запросе не хватает данных: ' + notPresent.join(', '))
    }
  }
}

/**
 * Получает из localStorage id выбранной компании или null, если выбрана
 * не компания (PERSON, CONTENT, ADMIN, SUPERADMIN, MANAGER.)
 *
 * @return {null|string}
 */
export const getSelectedCompanyId = (): string | null => {
  const selectedCompanyId = localStorage.getItem('wwl-selected_company_id')

  if (
    !selectedCompanyId ||
    selectedCompanyId === '0' ||
    selectedCompanyId === 'undefined'
  ) {
    return null
  }

  return selectedCompanyId
}

export const getSelectedCompanyIdRequired = (): string => {
  const companyId = getSelectedCompanyId()
  if (!companyId) {
    throw new Error('Required company id')
  }
  return companyId
}

export const getPersonCategory = (): string => {
  return localStorage.getItem('wwl-person_category') || PersonCategories.PERSON
}
