import { i18nWhitelistLangs } from '../i18n'

export const LOCALES_TO_EXCLUDE = ['zh', 'ru']

const localesMap = {
  en: 1,
  ru: 2,
  fr: 3,
  es: 4,
  it: 5,
  de: 6,
  zh: 7,
} as const

export const getLocaleId = (lang?: string): number => {
  if (!lang) {
    const langFromLs = localStorage.getItem('wwl-lng') ?? ''
    if (langFromLs.length) {
      lang = langFromLs.substring(0, 2).toLowerCase()
    }
  }

  if (!lang || LOCALES_TO_EXCLUDE.includes(lang) || !localesMap[lang]) {
    return localesMap.en
  }

  return localesMap[lang]
}

export const getSelectedLocale = () => {
  const lang = localStorage.getItem('wwl-lng') || ''
  if (i18nWhitelistLangs.includes(lang)) return lang
  else return 'en'
}

const localesForTermsPolitics = ['en', 'es', 'fr', 'it']
export const getLocaleForTermsPolitics = () => {
  const lang = localStorage.getItem('wwl-lng') || ''
  if (localesForTermsPolitics.includes(lang)) return lang
  else return 'en'
}

const publicReviewLocalesMap = new Set(['en', 'fr', 'es', 'it', 'de'])
export const getLocaleForPublicReview = () => {
  const browserLang = navigator.language
    ? navigator.language.split('-')[0]
    : 'en'
  return publicReviewLocalesMap.has(browserLang) ? browserLang : 'en'
}
