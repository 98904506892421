import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import MenuSingleItem from './MenuSingleItem'
import Indicator from './Indicator'
import MenuItemWithSubItems from './MenuItemWithSubItems'
import { MenuItem } from '../../../_methods'
import { IconWrapper, Text } from '../_styles'

interface MenuItemProps {
  item: MenuItem
  disabled?: boolean
  onSelect?: () => void
}

const NavMenuItem: FC<MenuItemProps> = ({ item, disabled, onSelect }) => {
  const { t } = useTranslation()

  const Icon = item.icon
  const isActive = !!useRouteMatch({ path: item?.link, exact: !item.items })

  const badge = item.badge ? useSelector(item.badge) : false

  const content = (
    <>
      <IconWrapper>
        <Icon />
        <Indicator value={badge} />
      </IconWrapper>
      <Text>{t(item.title)}</Text>
    </>
  )

  if (!item.items) {
    return (
      <MenuSingleItem
        isActive={isActive}
        link={item.link}
        disabled={disabled}
        onSelect={onSelect}
      >
        {content}
      </MenuSingleItem>
    )
  }

  return (
    <MenuItemWithSubItems
      isActive={isActive}
      items={item.items}
      disabled={disabled}
      onSelect={onSelect}
    >
      {content}
    </MenuItemWithSubItems>
  )
}

export default NavMenuItem
