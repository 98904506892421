import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Indicator from '../../../Indicator'
import { Text, MenuItemStyles, ProBadge } from '../../../_styles'
import proBadgeUrl from 'components/Pages/Retail/_assets/pro.png'

const ItemWrapper = styled(Link)<{
  $active?: boolean
  $disabled?: boolean
}>`
  ${MenuItemStyles};
  padding: 7px 8px;
  text-decoration: none;
  max-width: unset;
`

const FakeItem = styled.div<{
  $disabled?: boolean
}>`
  ${MenuItemStyles};
  padding: 7px 8px;
  text-decoration: none;
  max-width: unset;
`

interface SubItemProps {
  title: string
  link: string
  disabled?: boolean
  isPro?: boolean
  active?: boolean
  badge?: (state: any) => boolean | number
}

const SubItem: FC<SubItemProps> = props => {
  const { title, disabled, active, link, badge, isPro } = props

  const { t } = useTranslation()

  const counter = badge ? useSelector(badge) : false

  const content = (
    <Text>
      {t(title)} <Indicator value={counter} />
      {isPro && <ProBadge src={proBadgeUrl} />}
    </Text>
  )

  return disabled ? (
    <FakeItem $disabled>{content}</FakeItem>
  ) : (
    <ItemWrapper $active={active} to={link}>
      {content}
    </ItemWrapper>
  )
}

export default SubItem
