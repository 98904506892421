import styled, { css } from 'styled-components'
import { forwardRef, PropsWithChildren } from 'react'

export const TRANSITION_DURATION = 1000

export const Wrapper = styled.div<{
  $withBorder?: boolean
}>`
  transition: max-height ${TRANSITION_DURATION}ms;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  ${({ $withBorder }) =>
    $withBorder &&
    css`
      border-bottom: 1px solid var(--color-sw-10);
    `}
`
export const HEADER_CLASSNAME = 'collapsibleHeader'
const CollapsibleHeader = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ $withBorder?: boolean }>
>((props, ref) => <Wrapper {...props} className={HEADER_CLASSNAME} ref={ref} />)

export default CollapsibleHeader
