import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`
export const Block = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;

  :hover {
    box-shadow: 0 3px 5px rgba(95, 99, 104, 0.05),
      0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);
  }
`
export const IconWrapper = styled.div`
  min-width: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
  box-sizing: border-box;
`
export const IconBlock = styled.div`
  padding: 0 16px 0 5px;
  margin-right: 1px;
  height: 38px;
  display: flex;
  align-items: center;
`
export const Icon = styled.div`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Error = styled.div`
  padding: 8px 16px 0;
  display: flex;
  align-items: center;
  color: var(--color-red);
  font: var(--typography-11-16-regular);
`
