import { FC } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getCompanyVerified } from 'store/me'

const Wrapper = styled.div<{ $borderless?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;

  ${props =>
    props.$borderless
      ? css`
          padding: 12px 16px;
          background: linear-gradient(
              0deg,
              rgba(204, 116, 0, 0.16) 0%,
              rgba(204, 116, 0, 0.16) 100%
            ),
            #121432;
        `
      : css`
          padding: 8px;
          border-radius: 8px;
          border: 1px solid var(--other-orange, #cc7400);
          background: linear-gradient(
              0deg,
              rgba(204, 116, 0, 0.16) 0%,
              rgba(204, 116, 0, 0.16) 100%
            ),
            #121432;
        `}
`

const IconAndText = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 4px;
  color: var(--other-orange, #cc7400);
  font: var(--typography-12-16-medium);
`

const Button = styled.button`
  all: unset;
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: var(--other-orange, #cc7400);
  color: var(--grey-10, #e7e7ea);
  text-align: center;
  font: var(--typography-12-16-semibold);
  cursor: pointer;
`

interface VerifyNotificationProps {
  borderless?: boolean
  onVerify: () => void
}

const VerifyNotification: FC<VerifyNotificationProps> = ({
  borderless,
  onVerify,
}) => {
  const { t } = useTranslation()

  const isVerified = useSelector(getCompanyVerified)

  if (isVerified) return null

  return (
    <Wrapper $borderless={borderless}>
      <IconAndText>
        <Icon />
        {t('common.menu.verify.text')}
      </IconAndText>

      <Button type='button' onClick={onVerify}>
        {t('common.menu.verify.button')}
      </Button>
    </Wrapper>
  )
}

// prettier-ignore
const Icon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
    <circle cx='8' cy='8' r='7' fill='#CC7400' />
    <path fill='#fff' fillRule='evenodd'
          d='M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm.05-3c-.47 0-.85-.34-.85-.75v-3.5c0-.41.38-.75.85-.75s.85.34.85.75v3.5c0 .41-.38.75-.85.75Z'
          clipRule='evenodd' />
  </svg>
)

export default VerifyNotification
