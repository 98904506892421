import type { RootState } from 'store'

export const getValues = (state: RootState) => {
  return state.wineEdit.values
}

export const getErrors = (state: RootState) => {
  return state.wineEdit.errors
}

export const getIsDirty = (state: RootState) => {
  return state.wineEdit.isDirty ?? false
}

export const getOverwriteProduct = (state: RootState) => {
  return state.wineEdit.overwriteProduct
}

export const getPreloadedData = (state: RootState) => state?.wineEdit?.preloaded

// prettier-ignore
export const objectValueByPath = (values: any, path: string, defaultValue?: any) => {
  if (!path) return values

  const result = path.split('.').reduce((obj, field) => {
    const key = (isNaN(Number(field))) ? field : Number(field)
    return obj?.[key]
  }, values)

  return result === undefined ? defaultValue : result

}

export const getValue = <T = unknown>(path: string, defaultValue?: any) => {
  return (state: RootState) => {
    return objectValueByPath(state.wineEdit.values, path, defaultValue) as T
  }
}

export const getError = <T = unknown>(path: string, defaultValue?: any) => {
  return (state: RootState) => {
    return objectValueByPath(state.wineEdit.errors, path, defaultValue) as T
  }
}
