import styled from 'styled-components'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'

export const Error = styled.div`
  color: var(--color-red);
  font-size: 11px;
  line-height: 16px;
  min-height: 16px;
  margin: 4px 0 4px 16px;
`

export const TextButton = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  font: var(--typography-14-17-bold);
  color: #717284;
`

TextButton.defaultProps = {
  type: 'button',
}

export const OutlineButton = styled(PrimaryButtonFlexable48)`
  border: 1px solid #e7e7ea;
  background: ${props =>
    props.disabled || props.fakeDisabled ? '#DADAE0' : 'none'};
  color: ${props =>
    props.disabled || props.fakeDisabled ? '#3b42a5' : '#717284'};

  :hover {
    background: ${props =>
      props.disabled || props.fakeDisabled ? '#DADAE0' : '#e7e7ea'};
    color: #3b42a5;
  }
`
