import { FC } from 'react'
import styled from 'styled-components'

import { menuItemStyles } from '../../_styles'

export const CollapseButtonWrapper = styled.button<{ $collapsed: boolean }>`
  all: unset;
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(255, 255, 255, 0.16);
  position: absolute;
  right: -16px;
  top: 8px;
  transform: scale(${props => (props.$collapsed ? 1 : -1)});

  ${menuItemStyles()};
`

interface CollapseButtonProps {
  collapsed: boolean
  onToggle: () => void
}

const CollapseButton: FC<CollapseButtonProps> = ({ collapsed, onToggle }) => {
  return (
    <CollapseButtonWrapper
      type='button'
      onClick={onToggle}
      $collapsed={collapsed}
    >
      {/* prettier-ignore */}
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path fillRule='evenodd' clipRule='evenodd'
              d='M4.41058 4.41073C4.73602 4.0853 5.26366 4.0853 5.58909 4.41073L10.5891 9.41074C10.9145 9.73617 10.9145 10.2638 10.5891 10.5892L5.58909 15.5892C5.26366 15.9147 4.73602 15.9147 4.41058 15.5892C4.08514 15.2638 4.08514 14.7362 4.41058 14.4107L8.82133 9.99999L4.41058 5.58925C4.08514 5.26381 4.08514 4.73617 4.41058 4.41073ZM9.41058 4.41073C9.73602 4.0853 10.2637 4.0853 10.5891 4.41073L15.5891 9.41074C15.9145 9.73617 15.9145 10.2638 15.5891 10.5892L10.5891 15.5892C10.2637 15.9147 9.73602 15.9147 9.41058 15.5892C9.08515 15.2638 9.08515 14.7362 9.41058 14.4107L13.8213 9.99999L9.41058 5.58925C9.08515 5.26381 9.08515 4.73617 9.41058 4.41073Z'
              fill='#888998' />
      </svg>
    </CollapseButtonWrapper>
  )
}

export default CollapseButton
