import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ClearLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--color-velvet);
`

export default ClearLink
