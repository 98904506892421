import styled from 'styled-components'
import Left from 'components/Pages/Login/Left'
import Cookies from 'components/Common/Cookies'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { MainContainer, MainWrapper } from '../ForgotPassword/Common/_styles'

const Right = styled.div``
interface IUseLocation {
  isLogout: boolean
}

const Login = () => {
  const { state } = useLocation<IUseLocation>()
  const cookiePolicy = localStorage.getItem('wwl-cookie_policy') || false
  const client = useApolloClient()

  useEffect(() => {
    if (state?.isLogout) {
      client.resetStore()
    }
  }, [])

  return (
    <MainWrapper>
      {!cookiePolicy && <Cookies />}
      <MainContainer>
        <Left />
        <Right />
      </MainContainer>
    </MainWrapper>
  )
}

export default Login
