import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWineOfferRequestsUnreadsSubscription } from 'api/hooks'
import { getSelectedCompanyId } from 'store/me'
import { updateNotCompleted } from 'store/requests'

const useNotCompetedRequests = () => {
  const dispatch = useDispatch()

  const selectedCompanyId = useSelector(getSelectedCompanyId)
  const invalidCompany = !selectedCompanyId || selectedCompanyId === '0'

  const { data: subscriptionData } = useWineOfferRequestsUnreadsSubscription({
    variables: { companyId: selectedCompanyId },
    skip: invalidCompany,
    context: { ignoreAccessErrors: true },
  })

  useEffect(() => {
    if (invalidCompany) {
      dispatch(updateNotCompleted(0))
    } else if (subscriptionData) {
      dispatch(
        updateNotCompleted(
          subscriptionData?.wineOfferRequestsUnreads?.seller
            ?.notCompletedCount ?? 0
        )
      )
    }
  }, [subscriptionData, invalidCompany])
}

export default useNotCompetedRequests
