import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Filters, PageInfo, RetailBuyersWineStore, Sorting } from './types'
import { Company, CompanyCategory, Country, Currency } from 'api/schemas'

const initialState: RetailBuyersWineStore = {
  items: [],
  pageInfo: null,
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  loading: true,
  error: null,
  query: '',
  filters: [],
  currency: null,
  category: null,
  country: null,
  company: null,
}

export interface CurrencyRetailWines extends Omit<Currency, 'id'> {
  id: number | null
}
const retailBuyersWineSlice = createSlice({
  name: 'retailBuyersWine',
  initialState,
  reducers: {
    setItems: (state, { payload }: PayloadAction<any[]>) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload
    },
    setSortingValue: (state, { payload }: PayloadAction<Sorting | null>) => {
      state.sorting = payload
    },
    clear: () => {
      return initialState
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
    setQuery: (state, { payload }: PayloadAction<string>) => {
      state.query = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setFilters: (state, { payload }: PayloadAction<Filters>) => {
      state.filters = payload
      state.pageInfo = {
        ...state.pageInfo,
        page: 1,
      }
    },
    setCurrency: (
      state,
      { payload }: PayloadAction<CurrencyRetailWines | null>
    ) => {
      state.currency = payload
    },
    setCategory: (
      state,
      { payload }: PayloadAction<CompanyCategory | null>
    ) => {
      state.category = payload
    },
    setCountry: (state, { payload }: PayloadAction<Country | null>) => {
      state.country = payload
    },
    setCompany: (state, { payload }: PayloadAction<Company | null>) => {
      state.company = payload
    },

    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: retailBuyersWine } = retailBuyersWineSlice
export const {
  setItems,
  setPageInfo,
  setSortingValue: setSort,
  clear,
  setLoading,
  setQuery,
  setFilters,
  setError,
  reset,
  setCurrency,
  setCategory,
  setCountry,
  setCompany,
} = actions
