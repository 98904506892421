import { FC } from 'react'

// prettier-ignore
const FacebookIcon: FC = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_2440_1316)">
  <path fillRule="evenodd" clipRule="evenodd" d="M16.5 8.04888C16.5 3.60361 12.9183 0 8.5 0C4.08172 0 0.5 3.60361 0.5 8.04888C0.5 12.0663 3.42549 15.3962 7.25 16V10.3755H5.21875V8.04888H7.25V6.27562C7.25 4.25837 8.44434 3.1441 10.2717 3.1441C11.147 3.1441 12.0625 3.3013 12.0625 3.3013V5.28209H11.0537C10.0599 5.28209 9.75 5.90253 9.75 6.53905V8.04888H11.9687L11.6141 10.3755H9.75V16C13.5745 15.3962 16.5 12.0663 16.5 8.04888Z" fill="#1877F2"/>
  </g>
  <defs>
  <clipPath id="clip0_2440_1316">
  <rect width="16" height="16" fill="white" transform="translate(0.5)"/>
  </clipPath>
  </defs>
</svg>
)

export default FacebookIcon
