import i18n from 'utils/i18n'
import {
  filterTypes,
  CHECKBOX_LIST,
  DATE_INTERVAL,
  SCORE,
  SWITCHER,
  RHEOSTAT,
  SEARCH_USERS,
  ADMIN_ROUTE_NAMES,
  MULTISELECT_SWITCHER,
} from 'utils/consts'
import { Roles } from 'utils/consts/userRoles'

export const MY_REVIEW_ROUTE_NAME = 'accReviewsMyReviews'

export const myReviewsFiltersName = {
  countryFilter: 'countryFilter',
  brandFilter: 'brandFilter',
  producerFilter: 'producerFilter',
  nationalGITypeFilter: 'nationalGITypeFilter',
  wineRegionEUFilter: 'wineRegionEUFilter',
  geoIndicationFilter: 'geoIndicationFilter',
  wineTypeFilter: 'wineTypeFilter',
  wineColourFilter: 'wineColourFilter',
  sweetnessTypeFilter: 'sweetnessTypeFilter',
  vintageFilter: 'vintageFilter',
  grapesFilter: 'grapesFilter',
  additionalInfoFilter: 'additionalInfoFilter',
  wwlRatingFilter: 'wwlRatingFilter',
  monocepageFilter: 'monocepageFilter',
  samplingDateFilter: 'samplingDateFilter',
  createdAtFilter: 'createdAtFilter',
  bottleSizeFilter: 'bottleSizeFilter',
  packageTypeFilter: 'packageTypeFilter',
  drinkingWindowFilter: 'drinkingWindowFilter',
  usersFilter: 'usersFilter',
  alcoholLevelFilter: 'alcoholLevelFilter',
  specialClassificationFilter: 'specialClassificationFilter',
  euGITypeFilter: 'euGITypeFilter',
  venueTypeFilter: 'venueTypeFilter',
  recognitionStatusFilter: 'recognitionStatusFilter',
  statusFilter: 'statusFilter',
}
const myReviewsFiltersTitle = {
  countryFilter: 'filters.names.countryFilter',
  brandFilter: 'filters.names.brandFilter',
  producerFilter: 'filters.names.producer',
  nationalGITypeFilter: 'filters.names.nationalGITypeFilter',
  wineRegionEUFilter: 'filters.names.wineRegionEUFilter',
  geoIndicationFilter: 'filters.names.geoIndicationFilter',
  wineTypeFilter: 'filters.names.wineTypeFilter',
  wineColourFilter: 'filters.names.wineColourFilter',
  sweetnessTypeFilter: 'filters.names.sweetnessTypeFilter',
  vintageFilter: 'filters.names.vintageFilter',
  grapesFilter: 'filters.names.grapesFilter',
  monocepageFilter: 'filters.names.monocepageFilter',
  additionalInfoFilter: 'filters.names.certificatesFilter',
  wwlRatingFilter: 'filters.names.wwlRatingFilter',
  samplingDateFilter: 'filters.names.samplingDateFilter',
  privateReviewFilter: 'filters.names.privateReviewFilter',
  createdAtFilter: 'filters.names.createdAtFilter',
  bottleSizeFilter: 'filters.names.bottleSizeFilter',
  packageTypeFilter: 'filters.names.packageTypeFilter',
  drinkingWindowFilter: 'filters.names.drinkingWindowFilter',
  usersFilter: 'filters.names.reviewer',
  alcoholLevelFilter: 'filters.names.alcoholLevelFilter',
  specialClassificationFilter: 'filters.names.specialClassificationFilter',
  euGITypeFilter: 'filters.names.euGITypeFilter',
  venueTypeFilter: 'filters.names.venueTypeFilter',
  recognitionStatusFilter: 'filters.names.recognitionStatusFilter',
  statusFilter: 'filters.names.statusFilter',
}
const myReviewsFiltersKey = () => {
  return {
    countryFilter: 'product.wine.country',
    brandFilter: 'product.wine.brand',
    producerFilter: 'product.wine.tradeName',
    nationalGITypeFilter: 'product.wine.nationalGIType',
    wineRegionEUFilter: 'product.wine.wineRegionEU',
    geoIndicationFilter: 'product.wine.geoIndication',
    wineTypeFilter: 'product.wine.wineType',
    wineColourFilter: 'product.wine.wineColor',
    sweetnessTypeFilter: 'product.wine.sweetnessLevel',
    vintageFilter: 'product.wine.vintage',
    grapesFilter: 'grapeAndMonocepage',
    monocepageFilter: 'grapeAndMonocepage.monocepage',
    additionalInfoFilter: 'product.wine.certification',
    wwlRatingFilter: 'product.wine.rating',
    samplingDateFilter: 'date',
    createdAtFilter: 'createdAt',
    bottleSizeFilter: 'product.bottleSize',
    packageTypeFilter: 'packageType',
    drinkingWindowFilter: 'drinkingWindow',
    usersFilter: 'authorUsername',
    alcoholLevelFilter: 'product.alcoholOnLabel',
    specialClassificationFilter: 'product.wine.specialClassification',
    euGITypeFilter: 'product.wine.nationalGIType.euGIType',
    venueTypeFilter: 'placeType',
    recognitionStatusFilter: 'recognitionStatus',
    statusFilter: 'status',
  }
}
const defaultReviewFilters = [
  myReviewsFiltersName.producerFilter,
  myReviewsFiltersName.brandFilter,
  myReviewsFiltersName.countryFilter,
  myReviewsFiltersName.euGITypeFilter,
  myReviewsFiltersName.nationalGITypeFilter,
  myReviewsFiltersName.wineRegionEUFilter,
  myReviewsFiltersName.geoIndicationFilter,
  myReviewsFiltersName.specialClassificationFilter,
  myReviewsFiltersName.wineTypeFilter,
  myReviewsFiltersName.wineColourFilter,
  myReviewsFiltersName.sweetnessTypeFilter,
  myReviewsFiltersName.vintageFilter,
  myReviewsFiltersName.bottleSizeFilter,
  myReviewsFiltersName.packageTypeFilter,
  myReviewsFiltersName.grapesFilter,
  myReviewsFiltersName.monocepageFilter,
  myReviewsFiltersName.additionalInfoFilter,
  myReviewsFiltersName.alcoholLevelFilter,
  myReviewsFiltersName.wwlRatingFilter,
  myReviewsFiltersName.samplingDateFilter,
  myReviewsFiltersName.venueTypeFilter,
]

const ADMIN_FILTERS = [
  myReviewsFiltersName.usersFilter,
  myReviewsFiltersName.createdAtFilter,
  myReviewsFiltersName.drinkingWindowFilter,
  myReviewsFiltersName.samplingDateFilter,
  myReviewsFiltersName.producerFilter,
  myReviewsFiltersName.brandFilter,
  myReviewsFiltersName.countryFilter,
  myReviewsFiltersName.euGITypeFilter,
  myReviewsFiltersName.nationalGITypeFilter,
  myReviewsFiltersName.wineRegionEUFilter,
  myReviewsFiltersName.geoIndicationFilter,
  myReviewsFiltersName.specialClassificationFilter,
  myReviewsFiltersName.wineTypeFilter,
  myReviewsFiltersName.wineColourFilter,
  myReviewsFiltersName.sweetnessTypeFilter,
  myReviewsFiltersName.vintageFilter,
  myReviewsFiltersName.bottleSizeFilter,
  myReviewsFiltersName.packageTypeFilter,
  myReviewsFiltersName.grapesFilter,
  myReviewsFiltersName.monocepageFilter,
  myReviewsFiltersName.additionalInfoFilter,
  myReviewsFiltersName.alcoholLevelFilter,
  myReviewsFiltersName.wwlRatingFilter,
  myReviewsFiltersName.recognitionStatusFilter,
  myReviewsFiltersName.statusFilter,
]

export const includedReviewsFiltersOnRoute = {
  [MY_REVIEW_ROUTE_NAME]: {
    default: defaultReviewFilters,
  },
  [ADMIN_ROUTE_NAMES.adminReviews]: {
    [Roles.MANAGER]: ADMIN_FILTERS,
    [Roles.SUPERADMIN]: ADMIN_FILTERS,
    [Roles.CONTENT]: ADMIN_FILTERS,

    default: [],
  },
}

// общая коллекция фильтров c их параметрами
export const getMyReviewsFiltersCollection = () => [
  {
    type: filterTypes[SEARCH_USERS],
    title: i18n.t(myReviewsFiltersTitle.usersFilter),
    filterName: myReviewsFiltersName.usersFilter,
    apiFilterName: myReviewsFiltersKey().usersFilter,
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(myReviewsFiltersTitle.drinkingWindowFilter),
    filterName: myReviewsFiltersName.drinkingWindowFilter,
    apiFilterName: myReviewsFiltersKey().drinkingWindowFilter,
    step: 1,
    defaultIntervalKey: 'drinkingWindow',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.bottleSizeFilter),
    filterName: myReviewsFiltersName.bottleSizeFilter,
    apiFilterName: myReviewsFiltersKey().bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.packageTypeFilter),
    filterName: myReviewsFiltersName.packageTypeFilter,
    apiFilterName: myReviewsFiltersKey().packageTypeFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(myReviewsFiltersTitle.createdAtFilter),
    filterName: myReviewsFiltersName.createdAtFilter,
    apiFilterName: myReviewsFiltersKey().createdAtFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.producerFilter),
    filterName: myReviewsFiltersName.producerFilter,
    apiFilterName: myReviewsFiltersKey().producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.brandFilter),
    filterName: myReviewsFiltersName.brandFilter,
    apiFilterName: myReviewsFiltersKey().brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.countryFilter),
    filterName: myReviewsFiltersName.countryFilter,
    apiFilterName: myReviewsFiltersKey().countryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.wineRegionEUFilter),
    filterName: myReviewsFiltersName.wineRegionEUFilter,
    apiFilterName: myReviewsFiltersKey().wineRegionEUFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.euGITypeFilter),
    filterName: myReviewsFiltersName.euGITypeFilter,
    apiFilterName: myReviewsFiltersKey().euGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.nationalGITypeFilter),
    filterName: myReviewsFiltersName.nationalGITypeFilter,
    apiFilterName: myReviewsFiltersKey().nationalGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.geoIndicationFilter),
    filterName: myReviewsFiltersName.geoIndicationFilter,
    apiFilterName: myReviewsFiltersKey().geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.specialClassificationFilter),
    filterName: myReviewsFiltersName.specialClassificationFilter,
    apiFilterName: myReviewsFiltersKey().specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.wineColourFilter),
    filterName: myReviewsFiltersName.wineColourFilter,
    apiFilterName: myReviewsFiltersKey().wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.wineTypeFilter),
    filterName: myReviewsFiltersName.wineTypeFilter,
    apiFilterName: myReviewsFiltersKey().wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.sweetnessTypeFilter),
    filterName: myReviewsFiltersName.sweetnessTypeFilter,
    apiFilterName: myReviewsFiltersKey().sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.vintageFilter),
    filterName: myReviewsFiltersName.vintageFilter,
    apiFilterName: myReviewsFiltersKey().vintageFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(myReviewsFiltersTitle.grapesFilter),
    filterName: myReviewsFiltersName.grapesFilter,
    apiFilterName: myReviewsFiltersKey().grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: myReviewsFiltersName.monocepageFilter,
    switchTitle: i18n.t(myReviewsFiltersTitle.monocepageFilter),
    switchApiFilterName: myReviewsFiltersKey().monocepageFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.additionalInfoFilter),
    filterName: myReviewsFiltersName.additionalInfoFilter,
    apiFilterName: myReviewsFiltersKey().additionalInfoFilter,
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(myReviewsFiltersTitle.alcoholLevelFilter),
    filterName: myReviewsFiltersName.alcoholLevelFilter,
    apiFilterName: myReviewsFiltersKey().alcoholLevelFilter,
    defaultIntervalKey: 'alcoholLevels',
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(myReviewsFiltersTitle.wwlRatingFilter),
    filterName: myReviewsFiltersName.wwlRatingFilter,
    apiFilterName: myReviewsFiltersKey().wwlRatingFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(myReviewsFiltersTitle.samplingDateFilter),
    filterName: myReviewsFiltersName.samplingDateFilter,
    apiFilterName: myReviewsFiltersKey().samplingDateFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.venueTypeFilter),
    filterName: myReviewsFiltersName.venueTypeFilter,
    apiFilterName: myReviewsFiltersKey().venueTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.recognitionStatusFilter),
    filterName: myReviewsFiltersName.recognitionStatusFilter,
    apiFilterName: myReviewsFiltersKey().recognitionStatusFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myReviewsFiltersTitle.statusFilter),
    filterName: myReviewsFiltersName.statusFilter,
    apiFilterName: myReviewsFiltersKey().statusFilter,
  },
]
