import { FC, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { HOMEPAGE_PATH } from 'utils/consts/path'
import SelectedCompany from './SelectedCompany'
import CompaniesList from './CompaniesList'
import VerifyNotification from './VerifyNotification'
import { useChangeCompany } from 'utils/hooks/useSelectedCompany'
import { getCompaniesList } from 'store/me'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 8px;
  position: relative;
`

interface CompanyProps {
  collapsed: boolean
  onVerify: () => void
}

const Company: FC<CompanyProps> = ({ collapsed, onVerify }) => {
  const items = useSelector(getCompaniesList)

  const [opened, setOpened] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const listener = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  const changeCompany = useChangeCompany()
  const history = useHistory()
  const handleSelectCompany = (company: any) => {
    setOpened(false)
    changeCompany(company)
    history.push(HOMEPAGE_PATH)
  }

  const handleVerify = () => {
    setOpened(false)
    onVerify()
  }
  const handleHideCompaniesList = () => {
    setOpened(false)
  }

  if (!items || items.length === 0) return null

  return (
    <Wrapper ref={wrapperRef}>
      <SelectedCompany
        collapsed={collapsed}
        onToggle={() => setOpened(prev => !prev)}
      />
      {opened && (
        <CompaniesList
          items={items}
          onHide={handleHideCompaniesList}
          collapsed={collapsed}
          onSelectCompany={handleSelectCompany}
          onVerify={handleVerify}
        />
      )}

      {!collapsed && <VerifyNotification onVerify={handleVerify} />}
    </Wrapper>
  )
}

export default Company
