import { createSlice } from '@reduxjs/toolkit'
import { LocationState } from './types'

const initialState: LocationState = {
  prev: [],
}

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    pushPrevLocation: (state, { payload }) => {
      state.prev = [...state.prev, payload]
    },
    popPrevLocation: state => {
      state.prev = state.prev.slice(0, -1)
    },
  },
})

export const { actions, reducer: location } = locationSlice
export const { pushPrevLocation, popPrevLocation } = actions
