import styled from 'styled-components'
import { PrimaryButtonFlexable48 } from '../PrimaryButtonFlexable48'

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: ${props => (props.hasText ? '0 12px 0 0' : '0 -5px')};

  svg {
    transition: 0.2s;
    fill: currentColor;
  }
`

export const PrimaryIconButtonFlexable48 = props => {
  const { icon, children, ...rest } = props
  return (
    <PrimaryButtonFlexable48 {...rest}>
      <Icon hasText={!!children}>{icon}</Icon>
      {children}
    </PrimaryButtonFlexable48>
  )
}
