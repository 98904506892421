import { createSlice } from '@reduxjs/toolkit'
import { MyReviewsState } from './types'

const initialState: MyReviewsState = {
  pageInfo: {
    page: 1,
  },
  items: [],
  item: null,
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  query: '',
}

const myReviewsSlice = createSlice({
  name: 'myReviews',
  initialState,
  reducers: {
    setPageInfo: (state, { payload }) => {
      state.pageInfo = payload
    },
    setItems: (state, { payload }) => {
      state.items = payload
    },
    setItem: (state, { payload }) => {
      state.items = { ...state.items, [payload.id]: payload }
    },
    setReview: (state, { payload }) => {
      state.item = payload
    },
    setSorting: (state, { payload }) => {
      state.sorting = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: myReviews } = myReviewsSlice
export const { setPageInfo, setItems, setReview, reset } = actions
