import styled from 'styled-components'

const Wrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const Text = styled.div`
  margin-left: 8px;
  font: var(--typography-14-24-medium);
  color: #121432;
  user-select: none;
`

const Input = styled.input`
  appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  width: 16px;
  height: 16px;
  background: #f3f3f5;
  border: 1px solid #d8d8d8;
  border-radius: 50%;

  :hover {
    border-color: #3b42a5;
  }

  :checked,
  [checked] {
    border-color: #d8d8d8;

    :hover {
      border-color: #3b42a5;
    }

    ::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: #3b42a5;
      border-radius: 50%;
      top: 3px;
      left: 3px;
    }
  }

  :disabled,
  [disabled] {
    border-color: #f3f3f5;

    & + ${Text} {
      color: #a7b2c1;
    }

    :checked,
    [checked] {
      border-color: #d3d9e0;

      ::after {
        background: #d3d9e0;
      }
    }
  }

  [readonly] {
    border-color: #f3f3f5;
    background: #f3f3f5;

    :checked,
    [checked] {
      ::after {
        background: #d8d8d8;
      }
    }
  }
`

const Radio = props => {
  const { children, ...rest } = props

  return (
    <Wrapper>
      <Input type='radio' {...rest} />
      <Text>{children}</Text>
    </Wrapper>
  )
}

export default Radio
