import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoint } from 'utils/consts/breakpoints'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--typography-normal);
  word-break: break-word;
`
const Title = styled.div`
  font: var(--typography-44-52-semibold);
  color: #fff;
  margin-bottom: 24px;

  @media screen and ${breakpoint.m} {
    margin-bottom: 16px;
    font: var(--typography-36-44-semibold);
  }

  @media screen and ${breakpoint.s} {
    margin-bottom: 12px;
    font: var(--typography-28-36-semibold);
  }
`
const Text = styled.div`
  font: var(--typography-20-28-regular);
  color: #a0a1ad;
  margin-bottom: 0;

  @media screen and ${breakpoint.m} {
    font: var(--typography-18-24-regular);
    margin-bottom: 24px;
  }

  @media screen and ${breakpoint.s} {
    margin-bottom: 24px;
    font: var(--typography-16-20-regular);
  }
`
const Block = styled.div`
  text-align: left;

  @media screen and ${breakpoint.m} {
    text-align: center;
  }
`

const SideText = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Block>
        <Title>{t('login.slider.slide1.title')}</Title>
        <Text>{t('login.slider.slide1.text')}</Text>
      </Block>
    </Wrapper>
  )
}

export default SideText
