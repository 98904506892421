import styled, { css } from 'styled-components'
import BaseButton from '../BaseButton'

export const SecondaryButtonFlexable48_style = css`
  --button-size: 48px;

  min-height: var(--button-size);
  padding: 12px 16px;
  color: var(--color-sw-60);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-sw-10);
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.2s;
  letter-spacing: 0.3px;
  font: var(--typography-14-16-semibold);

  svg {
    transition: fill 0.2s, stroke 0.2s;
  }

  :focus-visible,
  :hover {
    background: var(--color-sw-10);
    color: var(--color-velvet);

    svg {
      fill: var(--color-velvet);
    }
  }

  :disabled,
  [disabled] {
    color: var(--color-sw-20);

    svg {
      fill: var(--color-sw-20);
    }
  }
`

export const SecondaryButtonFlexable48 = styled(BaseButton)`
  ${SecondaryButtonFlexable48_style}
`
