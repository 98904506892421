import { FC, ReactNode, useRef, SyntheticEvent } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import DialogContent from '../Content'
import DialogMain from '../Main'
import {
  Wrapper as DialogHeaderWrapper,
  Title as DialogHeaderTitle,
  Close as DialogHeaderIcon,
} from '../Header'
import { breakpoint, container } from 'utils/consts/breakpoints'

const mobileStyles = css`
  @media screen and ${breakpoint.s} {
    overflow: hidden;
    padding: 0;

    ${DialogMain} {
      max-height: 100%;
      height: 100%;
      max-width: unset;
      display: flex;
      flex-direction: column;

      &::after {
        content: unset;
      }
    }

    ${DialogContent} {
      flex: 1 1 auto;
      overflow: auto;
      box-shadow: none;
      border-radius: 0;
    }

    ${DialogHeaderWrapper} {
      background: white;
      border-bottom: 1px solid #e7e7ea;
      height: 55px;
      /* overflow: hidden; */
    }

    ${DialogHeaderTitle}, ${DialogHeaderIcon} {
      color: #121432;
      padding: 16px;
    }
  }
`

const Main = styled.dialog<{
  $withMobileVariant?: boolean
  $noPadding?: boolean
}>`
  position: fixed;
  inset: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${props => (props.$noPadding ? '0' : '16px')};
  z-index: var(--z-index-dialog);
  overflow: auto;
  width: 100%;
  height: 100%;
  border: none;
  font-family: var(--typography-normal);
  background: none;

  :last-child {
    background: rgba(18, 20, 50, 0.7);
  }

  ${props => props.$withMobileVariant && mobileStyles};

  @media screen and ${breakpoint.s} {
    padding: 0;
  }

  min-width: ${container.s}px;
`

interface WrapperProps {
  children: ReactNode
  onClose?: (e: SyntheticEvent) => void
  withMobileVariant?: boolean
  noPadding?: boolean
}

const Wrapper: FC<WrapperProps> = props => {
  const { children, onClose, withMobileVariant = false, noPadding } = props

  const ref = useRef<HTMLDialogElement>(null)
  const handleClick = event => {
    if (onClose && event.target === ref.current) {
      onClose(event)
    }
  }

  return ReactDOM.createPortal(
    <Main
      ref={ref}
      onMouseDown={handleClick}
      $withMobileVariant={withMobileVariant}
      $noPadding={noPadding}
    >
      {children}
    </Main>,
    document.getElementById('modal')!
  )
}

export default Wrapper
