import styled from 'styled-components'
import icon from './_assets/icon.svg'
import { useTranslation } from 'react-i18next'
import SendAgainButton from 'components/Pages/Signup/Left/Main/Form/Main/SendAgainButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 408px;
`
const Text = styled.div`
  font: var(--typography-18-24-semibold);
  font-weight: 600;
  letter-spacing: 0.3px;
  color: var(--color-night);
  margin-top: 16px;
  text-align: center;
`

const NeedVerifyEmail = ({ loading, onSendAgain }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <img src={icon} alt='not allowed icon' />
      <Text>{t('login.verifyEmailFirst')}</Text>
      <SendAgainButton loading={loading} onSendAgain={onSendAgain} />
    </Wrapper>
  )
}

export default NeedVerifyEmail
