import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import {
  ButtonContent,
  PrimaryButtonFlexable48,
  SecondaryTextButton48,
  SecondaryButtonFlexable48,
} from 'components/Common/MasterComponents'
import DefaultTooltip from 'components/Common/Tooltips/DefaultTooltip'
import { breakpoint } from 'utils/consts/breakpoints'
import { ICompany, useBecomeRepresentative, useHideModal } from '../_methods'

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  @media screen and ${breakpoint.s} {
    padding: 16px;
  }
`

interface IProps {
  onClose: () => void
  companies: ICompany[]
}

const Footer = ({ companies, onClose }: IProps) => {
  const history = useHistory()
  const { t } = useTranslation()

  const { hideModalRequest } = useHideModal()
  const { becomeRepresentativeRequest } = useBecomeRepresentative()
  const isDisabled = useMemo(
    () => companies.every(_ => !_.selected),
    [companies]
  )
  const selectedCompanies = useMemo(() => {
    return companies.filter(_ => _.selected) || []
  }, [companies])

  const handleAddCompany = async () => {
    await hideModalRequest()
    history.push('/acc/company/create')
  }

  const handleClose = async () => {
    await hideModalRequest()
    onClose()
  }

  const handleBecomerRepresentative = async () => {
    await becomeRepresentativeRequest(selectedCompanies)
    onClose()
  }

  return (
    <Wrapper>
      {companies.length > 0 && (
        <DefaultTooltip
          tooltip={t(
            'To become a representative, you must select at least one company from the list'
          )}
          tooltipProps={{ maxWidth: '380px' }}
          component={PrimaryButtonFlexable48}
          disabled={isDisabled}
          placement='top'
          onClick={handleBecomerRepresentative}
        >
          <ButtonContent>
            {t('modals.becomeRepresentative.becomeRepresentative')}
          </ButtonContent>
        </DefaultTooltip>
      )}

      {companies.length > 0 ? (
        <SecondaryButtonFlexable48 onClick={handleAddCompany}>
          <ButtonContent>
            {t('modals.becomeRepresentative.createCompany')}
          </ButtonContent>
        </SecondaryButtonFlexable48>
      ) : (
        <PrimaryButtonFlexable48 onClick={handleAddCompany}>
          <ButtonContent>
            {t('modals.becomeRepresentative.createCompany')}
          </ButtonContent>
        </PrimaryButtonFlexable48>
      )}
      <SecondaryTextButton48 onClick={handleClose}>
        <ButtonContent>
          {companies.length > 0
            ? t('modals.becomeRepresentative.later')
            : t('modals.becomeRepresentative.close')}
        </ButtonContent>
      </SecondaryTextButton48>
    </Wrapper>
  )
}

export default Footer
