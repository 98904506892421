import { FC } from 'react'
import styled from 'styled-components'
import { getImageSrc } from 'utils/screen'
import { ImageThumbnail40Fragment } from 'api/operations'

const Avatar = styled.div<{ $size: number }>`
  display: flex;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.16);
`

interface CompanyAvatarProps {
  size?: 40 | 64
  avatar?: ImageThumbnail40Fragment | null
  isVerified?: boolean
}

const UserAvatar: FC<CompanyAvatarProps> = ({ avatar, size = 40 }) => {
  return (
    <Avatar $size={size}>
      {avatar?.thumbnailUrl ? (
        <Image alt='user avatar' {...getImageSrc(avatar)} />
      ) : (
        <NoAvatarImage />
      )}
    </Avatar>
  )
}

// prettier-ignore
const NoAvatarImage = () => (
  <svg viewBox='0 0 32 32' width='64' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0Z'
          fill='#A0A1AD' />
    <path
      d='M16 4.80005C18.656 4.80005 20.8 6.94405 20.8 9.60005C20.8 12.256 18.656 14.4 16 14.4C13.344 14.4 11.2 12.256 11.2 9.60005C11.2 6.94405 13.344 4.80005 16 4.80005Z'
      fill='#717284' />
    <path
      d='M16 27.5199C12 27.5199 8.46402 25.4719 6.40002 22.3679C6.44802 19.1839 12.8 17.4399 16 17.4399C19.184 17.4399 25.552 19.1839 25.6 22.3679C23.536 25.4719 20 27.5199 16 27.5199Z'
      fill='#717284' />
  </svg>
)

export default UserAvatar
