import { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { useScrollToActive } from './_methods'

interface ItemProps {
  disabled?: boolean
  active?: boolean
}

export const Item = styled.div.attrs<ItemProps>(props => ({
  className: props.active ? 'active' : '',
}))<ItemProps>`
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: #121432;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  font: var(--typography-12-20-normal);
  font-weight: 600;
  text-align: left;
  padding: 10px;
  min-height: 26px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    left: 0;
    top: 2px;
    bottom: 2px;
    background: var(--color-white);
  }

  ${props =>
    props.disabled
      ? ''
      : css`
          :hover,
          &.active {
            background: #f9f9f9;

            &::before {
              background: var(--color-velvet);
            }
          }
        `};
`

interface OptionProps {
  children: ReactNode
  isActive?: boolean
  disabled?: boolean
  active?: boolean
  value: unknown
}

export const Option: FC<OptionProps> = props => {
  const { isActive, children, ...rest } = props

  const ref = useScrollToActive(isActive)

  return (
    <Item {...rest} ref={ref} active={isActive}>
      {children}
    </Item>
  )
}

export { useScrollToActive } from './_methods'
export { default as NoMatches } from './NoMatches'

export default Option
