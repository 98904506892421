import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import breakpoints from 'utils/consts/breakpoints'
import { TextButton16 } from 'components/Common/MasterComponents'
import QuestionIcon from '../Icons/QuestionIcon'

const Wrapper = styled(TextButton16)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;

  @media screen and (max-width: ${breakpoints.container.l - 1}px) {
    color: var(--color-white);

    :hover {
      color: var(--color-white);
    }
  }
`
const ButtonText = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.container.l}px) {
    display: block;
  }
`

const TutorialButton = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Wrapper onClick={onClick}>
      <QuestionIcon />
      <ButtonText>{t('tutorial.title')}</ButtonText>
    </Wrapper>
  )
}

export default TutorialButton
