import { ls } from 'utils/my-localstorage'
import { Roles } from 'utils/consts/userRoles'
import { Company } from './types'
import type { RootState } from 'store'

export const getCurrentUserId = (state: RootState) => state?.me?.data?.id

export const getUsername = (state: RootState) => state?.me?.data?.username

export const getSelectedCompanyId = (state: RootState) =>
  state?.me?.selectedCompanyId

export const getSelectedCompany = (state: RootState) => {
  const companyId = state?.me?.selectedCompanyId
  const companies = state?.me?.data?.companies ?? []
  return companies.find(v => v.id === companyId)
}
export const getCompanyVerified = (state: RootState) => {
  const company = getSelectedCompany(state)
  return company?.isVerifiedUser ?? false
}
export const getCompaniesList = (state: RootState) => {
  return state?.me?.data?.companies ?? ([] as Company[])
}
export const getUserData = (state: RootState) => {
  return state?.me?.data
}
export const getUserRole = (state: RootState) => {
  return (
    state?.me?.data?.role?.code || ls.getItem('wwl-role_code') || Roles.PERSON
  )
}
export const getIsPasswordSet = (state: RootState) => {
  return state?.me?.data?.isPasswordSet
}
export const getSelectedCompanyTour = (state: RootState) => {
  const company = getSelectedCompany(state)
  return (company?.isVerifiedUser ? company?.tour : null) ?? null
}
