/**
 * Делает желтый тултип со стрелочкой.
 *
 * Пример:
 * <DefaultTooltip
 *   placement='bottom'
 *   offset={12}
 *   tooltip={t('text inside tooltip bubble')}
 *   component={ProductTitleWrapper}
 *   onClick={handleProductTitleWrapperClick}
 * >
 *   Product name
 * </DefaultTooltip>
 *
 * tooltip        контентент тултипа (будет обернут в желтое облачко)
 * offset         отступ от тригера. необязательно, по умолчанию 16
 * placement      положение облачка относительно тригера.
 *                необязательно, по умолчанию 'right-start',
 * tooltipProps   доп параметры облачка. объект с полями maxWidth, visible.
 * component      обертка для тригера. необходима,чтобы было куда повесить ref.
 *                тип - компоенент или название тега, по умолчанию 'div'
 * rest           остальные пропсы вешаются на component
 */

import {
  ComponentType,
  FC,
  ReactNode,
  useMemo,
  // @ts-ignore
  JSX,
  AllHTMLAttributes,
} from 'react'
import { createPortal } from 'react-dom'
import { TriggerType, usePopperTooltip } from 'react-popper-tooltip'
import { Placement } from '@popperjs/core'
import Tooltip from '../HelpTip/Tooltip'
import { getOffsetModifier } from '../HelpTip/_methods'
import { DELAY_SHOW } from 'utils/tooltipModifier'

const trigger: TriggerType[] = ['hover', 'click']

interface DefaultTooltipProp extends AllHTMLAttributes<HTMLElement> {
  children?: ReactNode
  tooltip: ReactNode
  offset?: number
  placement?: Placement
  tooltipProps?: { maxWidth?: string; visible?: boolean }
  component?: ComponentType | keyof JSX.IntrinsicElements
}

const DefaultTooltip: FC<DefaultTooltipProp> = props => {
  const {
    children,
    tooltip,
    offset = 16,
    placement = 'right-start',
    tooltipProps,
    component: Component = 'div',
    ...rest
  } = props

  const modifiers = useMemo(
    () => [
      getOffsetModifier(offset),
      {
        name: 'preventOverflow',
        options: {
          tether: false,
          padding: 8,
          boundary: document.querySelector('.popover-zone') || document.body,
        },
      },
      {
        name: 'flip',
        options: {
          padding: 8,
          fallbackPlacements:
            placement.includes('right') || placement.includes('left')
              ? ['right', 'left', 'bottom', 'top']
              : ['bottom', 'top', 'right', 'left'],
          boundary: document.querySelector('.popover-zone') || document.body,
        },
      },
    ],
    [offset, placement, tooltip]
  )

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(
    { trigger, delayShow: DELAY_SHOW },
    { placement, modifiers }
  )

  return (
    <>
      {/* @ts-ignore */}
      <Component {...rest} ref={setTriggerRef}>
        {children}
      </Component>
      {visible &&
        createPortal(
          Tooltip(
            tooltip,
            tooltipProps
          )({
            tooltipRef: setTooltipRef,
            getArrowProps,
            getTooltipProps,
          }),
          document.getElementById('tooltips')!
        )}
    </>
  )
}

export default DefaultTooltip
