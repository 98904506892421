import { FC, ReactNode } from 'react'
import { LinkFakeItem } from '../../_styles'

interface MenuFakeItemProps {
  children: ReactNode
}

const MenuFakeItem: FC<MenuFakeItemProps> = ({ children }) => {
  return <LinkFakeItem $disabled>{children}</LinkFakeItem>
}

export default MenuFakeItem
