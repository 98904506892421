import styled from 'styled-components'

const Message = styled.div`
  font: var(--typography-14-20-semibold);
  letter-spacing: 0.3px;
  color: #717284;
  margin-top: 8px;
`

export default Message
