import { createSlice } from '@reduxjs/toolkit'
import { BrandsState } from './types'

const initialItem = null

const initialState: BrandsState = {
  item: initialItem,
  items: [],
  pageInfo: {},
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  typeFilter: 'all',
  filter: {
    type: null,
    value: null,
  },
}

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload
    },
    itemReset: state => {
      state.item = initialItem
    },
    setItem: (state, { payload }) => {
      state.item = payload
    },
    setItemField: (
      state,
      { payload }: { payload: { field: string; data: unknown } }
    ) => {
      if (!state.item) state.item = {}
      state.item[payload.field] = payload.data
    },
    clearItem: state => {
      state.item = null
    },
    setSortingValue: (state, { payload }) => {
      state.sorting = payload
    },
    setPageInfo: (state, { payload }) => {
      state.pageInfo = payload
    },
    resetSorting: state => {
      state.sorting = initialState.sorting
    },
    reset: () => {
      return initialState
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    setTypeFilter: (state, { payload }) => {
      state.typeFilter = payload
    },
  },
})

export const { actions, reducer: brands } = brandsSlice
export const {
  setItems,
  itemReset,
  setItem,
  setItemField,
  clearItem,
  reset,
  setFilter,
  setTypeFilter,
  setPageInfo,
} = actions
