import { isLogin } from 'utils/token'
import Main from './Main'

const VerifyAlert = () => {
  if (!isLogin()) return null

  return <Main />
}

export default VerifyAlert
