import styled from 'styled-components'
import Main from './Main'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
`

const Form = () => {
  return (
    <Wrapper>
      <Main />
    </Wrapper>
  )
}

export default Form
