import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import Header from './Header'
import Company from './Company'
import Navigation from './Navigation'
import User from './User'
import AddCompanyButton from './AddCompanyButton'
import GettingStarted from './GettingStarted'

const Wrapper = styled.div<{ $collapsed: boolean }>`
  &,
  * {
    box-sizing: border-box;
  }

  --animation-speed: 0.1s;

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  --sidebar-width: ${props => (props.$collapsed ? '72px' : '256px')};

  width: var(--sidebar-width);
  min-width: var(--sidebar-width);
  transition: width var(--animation-speed) cubic-bezier(0.2, 0, 0, 1);
  height: 100%;
  align-items: stretch;
  background: var(--grey-100, #121432);
`

const useSidebarOpened = () => {
  const [collapsed, setCollapsed] = useState(() => {
    return localStorage.getItem('wwl-menu_is_open') === 'false'
  })

  useEffect(() => {
    localStorage.setItem('wwl-menu_is_open', (!collapsed).toString())
  }, [collapsed])

  return { collapsed, setCollapsed }
}

interface SidebarProps {
  onVerify: () => void
}

const Sidebar: FC<SidebarProps> = ({ onVerify }) => {
  const { collapsed, setCollapsed } = useSidebarOpened()

  return (
    <Wrapper $collapsed={collapsed}>
      <Header collapsed={collapsed} setCollapsed={setCollapsed} />
      <Company collapsed={collapsed} onVerify={onVerify} />
      <Navigation collapsed={collapsed} />
      <GettingStarted collapsed={collapsed} />
      <AddCompanyButton collapsed={collapsed} />
      <User collapsed={collapsed} />
    </Wrapper>
  )
}

export default Sidebar
