import {
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  Suspense,
  useState,
} from 'react'
import styled from 'styled-components'
import Loader from '../Loader'
import Sidebar from './Sidebar'
import VerifyAlert from './Common/VerifyAlert'
import TopPanelDesktop from './TopPanelDesktop'
import TopPanelMobile from './TopPanelMobile'
import { useLoadUserData } from './_methods'
import VerifyDialog from './Sidebar/Company/VerifyDialog'
import BecomeRepresentativeDialog from 'components/Common/BecomeRepresentativeDialog'
import { isLogin } from 'utils/token'
import { useCheckDesktop } from 'utils/hooks/useMediaQuery'

const Wrapper = styled.div<{ $isDesktop: boolean }>`
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  display: flex;
  flex-direction: ${props => (props.$isDesktop ? 'row' : 'column')};
`

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Content = styled.div`
  height: 100%;
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

const fallback = (
  <LoaderWrapper>
    <Loader color='#3B42A5' size='32px' />
  </LoaderWrapper>
)

export type BreadcrumbItem = {
  name: string
  link?: string
}

export type TutorialStep = {
  title: string
  text: string
  image: {
    [lang: string]: {
      mobile: string
      desktop: string
    }
  }
  components?: {
    [lang: string]: {
      readonly [tagName: string]: ReactElement<
        any,
        string | JSXElementConstructor<any>
      >
    }
  }
}

export type TutorialData = {
  page: string
  steps: TutorialStep[]
}

interface MenuProps {
  title?: string
  breadcrumbs?: BreadcrumbItem[]
  icon?: FC
  tutorial?: TutorialData
  children: ReactNode
}

const Menu: FC<MenuProps> = props => {
  const { title, children, breadcrumbs, icon, tutorial } = props

  const isGuest = !isLogin()
  if (isGuest) return null

  useLoadUserData()
  const isDesktop = useCheckDesktop()

  const TopPanel = isDesktop ? TopPanelDesktop : TopPanelMobile

  const [verifyDialogOpened, setVerifyDialogOpened] = useState(false)

  return (
    <Wrapper $isDesktop={isDesktop}>
      {isDesktop && <Sidebar onVerify={() => setVerifyDialogOpened(true)} />}
      <Content>
        <TopPanel
          breadcrumbs={breadcrumbs}
          title={title}
          icon={icon}
          tutorial={tutorial}
          onVerify={() => setVerifyDialogOpened(true)}
        />
        <VerifyAlert />

        <PageContent className='popover-zone'>
          <Suspense fallback={fallback}>{children}</Suspense>
        </PageContent>
      </Content>

      {verifyDialogOpened && (
        <VerifyDialog onClose={() => setVerifyDialogOpened(false)} />
      )}
      <BecomeRepresentativeDialog />
    </Wrapper>
  )
}

export default Menu
