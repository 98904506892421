import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { PrimaryButtonFlexable32 } from 'components/Common/MasterComponents/Buttons'
import { SecondaryButtonFlexable32 } from 'components/Common/MasterComponents/Buttons'

const Wrapper = styled.section`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--color-white);
  padding: 12px 16px;
  display: flex;
  border-top: 1px solid var(--color-sw-10);
  justify-content: space-between;
`
const NextButton = styled(PrimaryButtonFlexable32)``
const PrevButton = styled(SecondaryButtonFlexable32)``

interface ITutorialFooter {
  step: number
  totalSteps: number
  onPrevStep: () => void
  onNextStep: () => void
  onFinish: () => void
}

const TutorialFooter = (props: ITutorialFooter) => {
  const { step, totalSteps, onPrevStep, onNextStep, onFinish } = props

  const { t } = useTranslation()

  const handleNextClick = () => {
    if (step < totalSteps) {
      onNextStep()
    } else {
      onFinish()
    }
  }
  return (
    <Wrapper>
      <PrevButton onClick={onPrevStep} disabled={step === 1}>
        {t('tutorial.dialog.footer.previous')}
      </PrevButton>
      <NextButton onClick={handleNextClick}>
        {step < totalSteps
          ? `${t('tutorial.dialog.footer.next')} (${step}/${totalSteps})`
          : t('tutorial.dialog.footer.finish')}
      </NextButton>
    </Wrapper>
  )
}

export default TutorialFooter
