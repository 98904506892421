import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopperTooltip } from 'react-popper-tooltip'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { lineClamp } from 'components/Common/GlobalStyles'
import { getSelectedCompanyTour } from 'store/me'
import { menuItemStyles, TooltipWrapper } from '../_styles'
import { getPercentCompleted, getTasksLeft } from './_methods'
import Progress from './Progress'
import Tour from './Tour'

const Wrapper = styled.div``

const Main = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 7px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid #41435b;
  background: #121432;

  ${menuItemStyles()};
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  flex: 1 0 0;
  max-width: 100%;
  user-select: none;
`

const Title = styled.div`
  color: #888998;
  font: var(--typography-12-16-medium);
  ${lineClamp()};
`

const TasksLeft = styled.div`
  color: #e7e7ea;
  font: var(--typography-13-16-semibold);
  ${lineClamp()};
`

const GettingStarted = ({ collapsed }) => {
  const tour = useSelector(getSelectedCompanyTour)
  if (!tour || tour.completed || tour.skipped) return null
  return <Content tour={tour} collapsed={collapsed} />
}

const Content = ({ tour, collapsed }) => {
  const { t } = useTranslation()

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'right-start',
      offset: [0, 16],
    })

  const [opened, setOpened] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const listener = (event: any) => {
      if (wrapperRef.current && wrapperRef.current.contains(event.target))
        return
      setOpened(false)
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  const info = (
    <TextContent>
      <Title>{t('getting-started.title')}</Title>
      <TasksLeft>
        {t('getting-started.tasks-left', { n: getTasksLeft(tour.tasks) })}
      </TasksLeft>
    </TextContent>
  )

  return (
    <Wrapper ref={wrapperRef}>
      <Main ref={setTriggerRef} onClick={() => setOpened(prev => !prev)}>
        {!collapsed && info}
        <Progress value={getPercentCompleted(tour.tasks)} />
      </Main>

      {collapsed && visible && (
        <TooltipWrapper ref={setTooltipRef} {...getTooltipProps()}>
          {info}
        </TooltipWrapper>
      )}

      {opened && <Tour tasks={tour.tasks} onClose={() => setOpened(false)} />}
    </Wrapper>
  )
}

export default GettingStarted
