import styled from 'styled-components'
import Loader from 'components/Common/Loader'
import HeaderView from '../Header/HeaderView'

const Wrapper = styled.div`
  font-family: var(--typography-normal);
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  scrollbar-gutter: stable;
  overflow: auto;
  max-height: 100%;
  min-height: 100%;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`

const LoadingState = () => {
  return (
    <Wrapper>
      <Content>
        <HeaderView />
        <Loader color='#3B42A5' size='32px' />
      </Content>
    </Wrapper>
  )
}

export default LoadingState
