import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUsername } from 'store/me'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

const gtmAddData = (data: any) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export const useGTMInit = () => {
  if (process.env.NODE_ENV !== 'production') return

  const username = useSelector(getUsername)

  useEffect(() => {
    if (!username) return
    gtmAddData({ user_id: username })
  }, [username])
}
