import { useEffect } from 'react'
import progress from 'nprogress'
import 'assets/css/progress/index.css'
import { Route } from 'react-router-dom'

const Public = props => {
  useEffect(() => {
    progress.done()
  })

  progress.start()

  return <Route {...props} />
}

export default Public
