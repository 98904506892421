import styled, { css } from 'styled-components'
import ClearLink from 'components/Common/MasterComponents/ClearLink'

const titleStyles = css`
  font: var(--typography-32-48-semibold);
  color: var(--color-brand-black);
`

export const Title = styled.div`
  ${titleStyles};
`

export const TitleLink = styled(ClearLink)`
  ${titleStyles};
  color: var(--color-brand-red);
`

export const Sub = styled.div`
  letter-spacing: 0.5px;
  font: var(--typography-20-32-medium);
  color: var(--color-night-sky);
  margin: 16px 0 48px;
`
