export const VERIFIED = 'VERIFIED'
export const UNVERIFIED = 'UNVERIFIED'
export const REQUEST = 'REQUEST'
export const AWAITING = 'AWAITING'
export const NOT_COMPLETED = 'NOT_COMPLETED'
export const REJECTED = 'REJECTED'
export const PROHIBITED = 'PROHIBITED'

export const canVerifyStatuses = [
  UNVERIFIED,
  REQUEST,
  AWAITING,
  NOT_COMPLETED,
  REJECTED,
  PROHIBITED,
]

export const canProhibitStatuses = [
  VERIFIED,
  UNVERIFIED,
  REQUEST,
  AWAITING,
  NOT_COMPLETED,
  REJECTED,
]

export const canAllowStatuses = [PROHIBITED]

export const canRejectStatuses = [REQUEST]

const companyStatus = {
  [UNVERIFIED]: {
    code: UNVERIFIED,
    id: 1,
  },
  [VERIFIED]: {
    code: VERIFIED,
    id: 2,
  },
  [REQUEST]: {
    code: REQUEST,
    id: 3,
  },
  [AWAITING]: {
    code: AWAITING,
    id: 4,
  },
  [NOT_COMPLETED]: {
    code: NOT_COMPLETED,
    id: 5,
  },
  [REJECTED]: {
    code: REJECTED,
    id: 6,
  },
  [PROHIBITED]: {
    code: PROHIBITED,
    id: 7,
  },
}

export default companyStatus
