import { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { lineClamp } from 'components/Common/GlobalStyles'
import { menuItemStyles } from '../../../_styles'

const Wrapper = styled.div`
  :not(:first-child) {
    margin-top: 6px;
  }
`

const Content = styled(Link)`
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
  text-decoration: none;
  ${menuItemStyles()};
`

const Text = styled.span`
  color: var(--grey-10, #e7e7ea);
  font: var(--typography-13-16-semibold);

  ${lineClamp()}
`

interface AddCompanyButtonProps {}

const AddCompanyButton: FC<AddCompanyButtonProps> = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Content to='/acc/company/create'>
        {/* prettier-ignore */}
        <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none' viewBox='0 0 40 40'>
          <circle cx='20' cy='20' r='20' fill='#fff' fillOpacity='.08' />
          <path fill='#B7B8C1'
                d='M20 12.5c-.69 0-1.25.42-1.25.93v5.32h-5.31c-.52 0-.94.56-.94 1.25s.42 1.25.94 1.25h5.31v5.3c0 .53.56.95 1.25.95s1.25-.42 1.25-.94v-5.31h5.31c.52 0 .94-.56.94-1.25 0-.7-.42-1.25-.94-1.25h-5.31v-5.32c0-.51-.56-.93-1.25-.93Z' />
        </svg>

        <Text>{t('common.menu.addNewCompany')}</Text>
      </Content>
    </Wrapper>
  )
}

export default AddCompanyButton
