import { createSlice } from '@reduxjs/toolkit'
import { ElabelEditState } from './types'

const initialState: ElabelEditState = {
  showAllGrapeVarieties: false,
  errors: {},
  preloadedState: {},
}

const elabelEditSlice = createSlice({
  name: 'elabelEdit',
  initialState,
  reducers: {
    setData: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        preloadedState: { ...payload },
      }
    },
    setPreloaded: (state, { payload }) => {
      state.preloadedState = { ...payload }
    },
    setError: (state, { payload }) => {
      state.errors = {
        ...state.errors,
        [payload.field]: payload.data,
      }
    },
    resetToPreloaded: state => {
      return {
        ...state,
        ...state.preloadedState,
        errors: {},
      }
    },
    setField: (state, { payload }) => {
      state[payload.field] = payload.data
    },
  },
})

export const { actions, reducer: elabelEdit } = elabelEditSlice
export const { setData, setPreloaded, setError, resetToPreloaded, setField } =
  actions
