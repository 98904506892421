import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { setTokens } from 'utils/token'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HOMEPAGE_PATH } from 'utils/consts/path'
import useNotification from 'utils/notification'
import { SecondaryButtonFlexable48 } from 'components/Common/MasterComponents'
import { FacebookIcon } from 'components/Common/Icons'
import BannedModal from '../BannedModal'
import { useSignInWithFacebookMutation } from 'api/hooks'
import FacebookLogin from '@greatsumini/react-facebook-login'

const IconWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  width: 16px;
  height: 16px;
`
const ButtonText = styled.div`
  font: var(--typography-14-16-semibold);
  color: var(--color-night);
`

const FacebookSignInButton = () => {
  const { t } = useTranslation()
  const location = useLocation<any>()
  const history = useHistory()
  const pathname = location?.state?.from?.pathname

  const redirectPath =
    pathname === '/' ? HOMEPAGE_PATH : pathname || HOMEPAGE_PATH

  const notify = useNotification()
  const [banModalVisible, setBanModalVisible] = useState(false)
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [signInWithFacebook] = useSignInWithFacebookMutation()

  const LoginButton = ({ handleClick }) => (
    <SecondaryButtonFlexable48 onClick={handleClick}>
      <IconWrapper>
        <FacebookIcon />
      </IconWrapper>
      <ButtonText>{t('login.withFacebook')}</ButtonText>
    </SecondaryButtonFlexable48>
  )
  const getTokens = async () => {
    try {
      const { data, errors } = await signInWithFacebook({
        variables: {
          accessToken,
        },
      })

      if (errors) {
        const errorCode = errors?.[0]?.extensions?.code
        if (errorCode === 'AUTH_BLOCKED_ERROR') {
          setBanModalVisible(true)
        }
      } else {
        const token = data?.signInWithFacebook?.token
        const refreshToken = data?.signInWithFacebook?.refreshToken

        if (token && refreshToken) {
          setTokens(token, refreshToken)
          history.replace(redirectPath)
        }
      }
    } catch (error: any) {
      notify(error.message, 'error')
    }
  }

  useEffect(() => {
    if (loginSuccess && accessToken) {
      getTokens()
    }
  }, [loginSuccess, accessToken])

  const handleLoginSuccess = async response => {
    const { accessToken: responseAccessToken } = response
    setAccessToken(responseAccessToken)
  }

  return (
    <>
      <FacebookLogin
        appId={process.env.FACEBOOK_APP_ID || ''}
        scope='public_profile, email'
        initParams={{
          version: 'v16.0',
        }}
        onFail={() => {
          setLoginSuccess(false)
        }}
        onProfileSuccess={res => {
          if (res?.error) {
            setLoginSuccess(false)
          } else {
            setLoginSuccess(true)
          }
        }}
        onSuccess={handleLoginSuccess}
        useRedirect={false}
        render={({ onClick }) => <LoginButton handleClick={onClick} />}
      />
      {banModalVisible && (
        <BannedModal closeModal={() => setBanModalVisible(false)} />
      )}
    </>
  )
}

export default FacebookSignInButton
