import { FC } from 'react'
import type { IconProps } from '../../types'

// prettier-ignore
const NotificationErrorIcon: FC<IconProps> = ({ size = 20, color = '#D00A00' }) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3720_318119)'>
      <circle cx='10.0001' cy='10' r='10' fill={color} />
      <path d='M6.00012 14L10.0001 10M10.0001 10L14.0001 6M10.0001 10L14.0001 14M10.0001 10L6.00012 6' stroke='white'
            strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_3720_318119'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>

  </svg>
)

export default NotificationErrorIcon
