import { FC } from 'react'
import type { IconProps } from '../types'

// prettier-ignore
const EyeInvisibleIcon: FC<IconProps> = ({ size = 16, color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M15.0881 0.155992C15.2961 -0.0519972 15.6374 -0.0519972 15.8453 0.155992C16.0533 0.363964 16.0532 0.705435 15.8452 0.913424L0.913271 15.8453C0.806614 15.9467 0.673302 16 0.534631 16C0.395961 16 0.262648 15.9467 0.155992 15.8453C-0.0519972 15.6374 -0.0519972 15.2961 0.155992 15.0881L3.84102 11.4031C2.24114 10.203 1.38254 8.57125 1.1159 8.00063C1.55328 7.07809 3.64904 3.20108 8.00063 3.20108C9.26993 3.20108 10.3365 3.52637 11.2324 4.01167L15.0881 0.155992ZM5.33439 8.00048C5.33439 8.54444 5.49971 9.04571 5.77702 9.46701L9.46734 5.77669C9.04604 5.49938 8.54475 5.33406 8.0008 5.33406C6.52894 5.33406 5.33439 6.52861 5.33439 8.00048Z'
          fill={color} />
    <path
      d='M10.6199 7.51531L12.9018 5.23282C14.0317 6.31542 14.6616 7.52598 14.8856 8.0006C14.4482 8.92313 12.3525 12.8001 8.00087 12.8001C7.15825 12.8001 6.40098 12.6562 5.72439 12.4109L7.51555 10.619C7.67022 10.651 7.83553 10.667 8.00085 10.667C9.47271 10.667 10.6673 9.47247 10.6673 8.00061C10.6673 7.83528 10.6513 7.66996 10.6199 7.51531Z'
      fill={color} />
  </svg>
)

export default EyeInvisibleIcon
